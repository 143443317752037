import { Injectable } from '@angular/core';

import { TrackingGuard as C24NgTrackingTrackingGuard } from '@vv-ham/ng-tracking';
import { Observable, of } from 'rxjs';
import { PreviousUrlPersistenceService } from '../services/previous-url-persistence.service';
import { UrlService } from '../services/url.service';

@Injectable({
    providedIn: 'root',
})
export class GeneralTrackingGuard implements C24NgTrackingTrackingGuard {
    constructor(
        private urlService: UrlService,
        private previousUrlPersistenceService: PreviousUrlPersistenceService
    ) {}

    public get canTrack$(): Observable<boolean> {
        const previousUrl = this.previousUrlPersistenceService.get();
        const currentUrl = this.urlService.currentUrlWithoutQueryParams;

        return of(previousUrl !== currentUrl);
    }
}
