import { Injectable } from '@angular/core';

import {
    Actions,
    createEffect,
    ofType,
    ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getEnvironmentRule } from '@pkv-frontend/data-domain/application';
import { setEnvironmentAction } from '../actions/application.actions';

@Injectable()
export class SetEnvironmentEffect {
    constructor(private readonly actions$: Actions) {}

    public readonly setEnvironment$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            map(() =>
                setEnvironmentAction({ environment: getEnvironmentRule() })
            )
        )
    );
}
