import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { ConsultantCookieDataModel } from '@pkv-frontend/data-domain/consultant';

const consultantCookieKey = 'consultant';

@Injectable({
    providedIn: 'root',
})
export class ConsultantDataService {
    constructor(private readonly cookieService: CookieService) {}

    public getConsultantData(): ConsultantCookieDataModel | undefined {
        const cookieValue = this.cookieService.get(consultantCookieKey);

        if (!cookieValue) {
            return undefined;
        }

        return jwtDecode<{ tokenData: ConsultantCookieDataModel }>(cookieValue)
            .tokenData;
    }
}
