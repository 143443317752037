import { Injectable } from '@angular/core';
import {
    DeviceOutputService,
    Environment,
} from '@pkv-frontend/data-domain/application';
import { ChatFlowEntryEnum } from '../enums/chat-flow-entry.enum';
import { ChattyConfig } from '../models/chatty/chatty-config.interface';

export type ChattyEnvironment = Environment.Test | Environment.Prod;

@Injectable({ providedIn: 'root' })
export class ChattyConfigBuilderService {
    constructor(private readonly deviceOutputService: DeviceOutputService) {}

    public build(
        chattyVersion: string,
        env: ChattyEnvironment,
        chatFlowEntry: ChatFlowEntryEnum,
        leadId?: number,
        forceNewSession = false
    ): ChattyConfig {
        const deviceoutput = this.deviceOutputService.getDeviceOutput();
        return {
            chattyVersion,
            name: 'chatty',
            onDemand: true,
            type: 'chatty',
            deviceoutput,
            env,
            contact: {
                button: {
                    img: 'https://cdn.chatty.check24.de/assets/icon/checkgpt_logo.svg',
                },
                layer: {
                    active: false,
                    img: 'https://cdn.chatty.check24.de/assets/icon/checkgpt_logo.svg',
                    name: 'Sophie',
                    contactLink: 'https://m.check24.de/unternehmen/kontakt',
                    openChatEvent: 'c24-chat-chatty-open',
                    openChatText: 'Chat starten',
                    subtitle: 'Ihre digitale Versicherungs-Assistentin',
                },
            },
            chatbot: {
                activeStateSticky: true,
                forceNewSession: forceNewSession,
                deviceoutput,
                client: {
                    chatFlowEntry: chatFlowEntry,
                    clientDetails: leadId
                        ? {
                              publicId: `PKV-${leadId}`,
                              productReference: `PKV-${leadId}`,
                          }
                        : {},
                    controlServerAddress:
                        this.getControlServerAddressByChattyEnvironment(env),
                    chatFlowName: 'v_pkv.live',
                    chatFlowRelease: 'active',
                },
                contact: {
                    button: {
                        active: false,
                    },
                    layer: {
                        active: false,
                    },
                    env,
                    events: {
                        christmas: {
                            enabled: false,
                        },
                    },
                },
            },
        };
    }

    private getControlServerAddressByChattyEnvironment(
        environment: ChattyEnvironment
    ): string {
        switch (environment) {
            case Environment.Prod:
                return 'https://api.chat.check24.de';
            case Environment.Test:
            default:
                return 'https://api.chat.check24-test.de';
        }
    }
}
