import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';

@Injectable({ providedIn: 'root' })
export class FilterFormDirtyFilterService {
    public retrieve(filterFormGroup: FormGroup): Partial<ResultFilter> {
        const dirtyFilter = {};
        const filterForm = filterFormGroup.getRawValue();

        Object.keys(filterForm).forEach((key: string) => {
            const field = filterFormGroup.get(key);
            if (!field?.dirty) {
                return;
            }
            dirtyFilter[key] = field?.value;
        });

        return dirtyFilter;
    }
}
