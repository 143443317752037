/**
 * Defines the scope of the domain to be set in a cookie.
 * All the examples assume that the original hostname is: pkv-828.m.krankenversicherung.check24.de
 * - Domain: Scoped on the full domain (e.g. on check24.de)
 * - Subdomain: Scoped on the subdomain and domain (e.g., on krankenversicherung.check24.de)
 * - Platform: Scoped on the platform domain (e.g., on d.krankenversicherung.check24.de)
 * - Exact: Scoped on the entire hostname (e.g., on pkv-828.m.krankenversicherung.check24.de)
 */
export enum DomainScopeEnum {
    Domain = 'Domain',
    Subdomain = 'Subdomain',
    Platform = 'Platform',
    Exact = 'Exact',
}
