import { createSelector } from '@ngrx/store';
import { LeadCreationTypeEnum } from '@pkv-frontend/business-domain/lead';
import {
    ConsultantCallbackOverlayState,
    OverlayStateAware,
} from '../models/consultant-callback-overlay.model';

import { consultantOverlayStateSelector } from './consultant-overlay-state.selector';

export const leadCreationTypeSelector = createSelector<
    OverlayStateAware,
    [ConsultantCallbackOverlayState],
    LeadCreationTypeEnum | undefined
>(
    consultantOverlayStateSelector,
    (state: ConsultantCallbackOverlayState): LeadCreationTypeEnum | undefined =>
        state.leadCreationType
);
