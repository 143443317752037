import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

import { experimentsConfig } from '../config/experiments.config';
import { ExperimentConfig } from '../model/experiment-config.model';
import { MatomoExperiment } from '../model/matomo-experiment.model';

@Injectable({ providedIn: 'root' })
export class MatomoAbTestingService {
    private experimentsSubject$: ReplaySubject<MatomoExperiment[]> =
        new ReplaySubject<MatomoExperiment[]>(1);
    public experiments$: Observable<MatomoExperiment[]> =
        this.experimentsSubject$.asObservable();

    constructor(
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    private createExperiment(experiment: ExperimentConfig): MatomoExperiment {
        if (this.windowReferenceService.nativeWindow.Matomo === undefined) {
            throw Error(
                'WindowRefService.nativeWindow.Matomo cannot be undefined'
            );
        }

        return new this.windowReferenceService.nativeWindow.Matomo.AbTesting.Experiment(
            experiment
        ) as MatomoExperiment;
    }

    public createExperiments(): void {
        const createdExperiments: MatomoExperiment[] = [];

        for (const experiment of experimentsConfig) {
            createdExperiments.push(this.createExperiment(experiment));
        }

        this.experimentsSubject$.next(createdExperiments);
    }
}
