import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';

export function parseDateRule(strDate: string | undefined): Date | null {
    if (!strDate) {
        return null;
    }
    const dateFormats = ['yyyy-MM-dd', 'dd.MM.yyyy'];

    for (const dateFormat of dateFormats) {
        const parsedDate = parse(strDate, dateFormat, new Date());
        if (isValid(parsedDate)) {
            return parsedDate;
        }
    }

    return null;
}
