import { AbstractControl } from '@angular/forms';
import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';

export function DateValidator(
    control: AbstractControl
): { [key: string]: boolean } | null {
    if (
        !control.value?.match(
            '^\\s*(3[01]|[12][0-9]|0[1-9]|[1-9])\\.(1[012]|0[1-9]|[1-9])\\.((?:19|20)\\d{2})\\s*$'
        )
    ) {
        return { validDate: true };
    }

    if (!isValid(parse(control.value, 'dd.MM.yyyy', new Date()))) {
        return { validDate: true };
    }

    return null;
}
