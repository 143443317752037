import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Environment } from '@pkv-frontend/data-domain/application';
import {
    ActiveConsultantModel,
    ConsultantCookieDataModel,
} from '@pkv-frontend/data-domain/consultant';
import { DeviceOutputEnum } from '@pkv-frontend/data-domain/php-frontend-data';
import { activeConsultantSelector } from '../store/selectors/active-consultant.selector';
import { consultantCookieDataSelector } from '../store/selectors/consultant-cookie-data.selector';
import { coreFeatureToggleSelector } from '../store/selectors/core-feature-toggle.selector';
import { deviceOutputSelector } from '../store/selectors/device-output.selector';
import { environmentSelector } from '../store/selectors/environment.selector';
import { initialProviderIdSelector } from '../store/selectors/initial-provider-id.selector';
import { isAlternativeOfferSelector } from '../store/selectors/is-alternative-offer.selector';
import { isCobrowsingSelector } from '../store/selectors/is-cobrowsing.selector';
import { isConsultantLoggedInSelector } from '../store/selectors/is-consultant-logged-in.selector';
import { isTariffChangeSelector } from '../store/selectors/is-tariff-change.selector';

@Injectable({
    providedIn: 'root',
})
export class ApplicationDataService {
    public readonly isAlternativeOffer$: Observable<boolean> =
        this.store$.select(isAlternativeOfferSelector);
    public readonly isConsultantLoggedIn$: Observable<boolean> =
        this.store$.select(isConsultantLoggedInSelector);
    public readonly isCobrowsing$: Observable<boolean | undefined> =
        this.store$.select(isCobrowsingSelector);
    public readonly isTariffChange$: Observable<boolean> = this.store$.select(
        isTariffChangeSelector
    );
    public readonly initialProviderId$: Observable<number | undefined> =
        this.store$.select(initialProviderIdSelector);

    public readonly environment$: Observable<Environment | undefined> =
        this.store$.select(environmentSelector);

    public readonly consultantCookieData$: Observable<
        ConsultantCookieDataModel | undefined
    > = this.store$.select(consultantCookieDataSelector);

    public readonly activeConsultant$: Observable<
        ActiveConsultantModel | undefined
    > = this.store$.select(activeConsultantSelector);

    public readonly deviceOutput$: Observable<DeviceOutputEnum | undefined> =
        this.store$.select(deviceOutputSelector);

    public readonly coreFeatureToggles$: Observable<Record<
        string,
        boolean
    > | null> = this.store$.select(coreFeatureToggleSelector);

    constructor(private readonly store$: Store) {}
}
