export const uspChipConstants = {
    INSURED_PERSON_CHILD: 'child',
    PROFESSION_GROUP: 'professionGroup',
    AGE_GROUP: 'ageGroup',
    ALL: 'all',
    PROFESSION_GROUP_PROFESSION_MAP: {
        employee: ['employee'],
        freelancer: ['freelancer'],
        student: ['student'],
        servant_candidate: ['servant_candidate'],
        servant: ['servant'],
        employee_freelancer_unemployed: [
            'employee',
            'freelancer',
            'unemployed',
        ],
        servant_servant_candidate: ['servant', 'servant_candidate'],
    },
    PROFESSION_GROUP_CHILD: 'child',
    PROFESSION_GROUP_SERVANT_CHILD: 'servant_child',
    PROFESSION_GROUP_CHILD_SERVANT_CHILD: 'child_servant_child',
    PROFESSION: {
        SERVANT: 'servant',
        SERVANT_CANDIDATE: 'servant_candidate',
    },
    DATE_FORMAT: 'YYYY-MM-DD',
};
