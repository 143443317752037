import { convertSnakeToCamelCase } from './snake-to-camel-case.converter';

export function convertSnakeCaseObjectPropertiesToCamelCase(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    snakeCaseObject: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
    if (Array.isArray(snakeCaseObject)) {
        return snakeCaseObject.map(convertSnakeCaseObjectPropertiesToCamelCase);
    }

    if (
        snakeCaseObject !== null &&
        snakeCaseObject !== undefined &&
        typeof snakeCaseObject === 'object' &&
        snakeCaseObject.constructor === Object
    ) {
        const camelCaseObject = {};
        Object.entries(snakeCaseObject).forEach(([key, value]) => {
            camelCaseObject[convertSnakeToCamelCase(key)] =
                convertSnakeCaseObjectPropertiesToCamelCase(value);
        });
        return camelCaseObject;
    }

    return snakeCaseObject;
}
