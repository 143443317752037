import {
    InsuredPersonEnum,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

export function getVpProfessionRule(
    profession: ProfessionEnum,
    insuredPerson: InsuredPersonEnum
): ProfessionEnum {
    if (insuredPerson !== InsuredPersonEnum.Child) {
        return profession;
    }

    if (
        [
            ProfessionEnum.ServantChild,
            ProfessionEnum.ServantCandidate,
            ProfessionEnum.Servant,
        ].includes(profession)
    ) {
        return ProfessionEnum.ServantChild;
    }

    return ProfessionEnum.Child;
}
