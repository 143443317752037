import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';
import { LowestPrice } from '@pkv-frontend/data-domain/result';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import {
    resultCustomFilterLowestPricePersistenceKey,
    resultCustomFilterSettingsPersistenceKey,
} from '../../../result/constants/result.constants';

import { enterResultPageAction } from '../../../result/store/actions/result.actions';
import { setCustomFilterSettingsAction } from '../actions/filter.actions';

@Injectable()
export class LoadCustomFilterSettingsFromStorageEffect {
    constructor(
        private actions$: Actions,
        private storageService: SessionStorageService,
        private phpFrontendDataService: PhpFrontendDataService
    ) {}

    public loadCustomFilterSettingsFromStorageEffect$: Observable<Action> =
        createEffect(() => {
            return this.actions$.pipe(
                ofType(enterResultPageAction),
                map(() =>
                    this.storageService.get<ResultFilter>(
                        resultCustomFilterSettingsPersistenceKey
                    )
                ),
                filter((resultFilters: ResultFilter | undefined) => {
                    return (
                        !!resultFilters &&
                        !this.phpFrontendDataService.data.isConsultantLoggedIn
                    );
                }),
                map((resultFilters: ResultFilter) => {
                    const lowestPrice = this.storageService.get<LowestPrice>(
                        resultCustomFilterLowestPricePersistenceKey
                    );

                    return setCustomFilterSettingsAction({
                        resultFilters,
                        lowestPrice,
                    });
                })
            );
        });
}
