import { createSelector } from '@ngrx/store';
import { AppointmentBookingDataResponseModel } from '@pkv-frontend/data-domain/lead-expert';
import {
    ConsultantCallbackOverlayState,
    OverlayStateAware,
} from '../models/consultant-callback-overlay.model';

import { consultantOverlayStateSelector } from './consultant-overlay-state.selector';

export const appointmentBookingDataSelector = createSelector<
    OverlayStateAware,
    [ConsultantCallbackOverlayState],
    AppointmentBookingDataResponseModel | undefined
>(
    consultantOverlayStateSelector,
    (
        state: ConsultantCallbackOverlayState
    ): AppointmentBookingDataResponseModel | undefined =>
        state.appointmentBookingData
);
