import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
    C24NgEventTrackingService,
    GeneralTrackingEventTrackingData,
} from '@vv-ham/ng-tracking';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import {
    LeadCreationApiResponseModel,
    LeadCreationApiService,
} from '@pkv-frontend/data-domain/lead-expert';
import { generalTrackingConfig } from '../../constants/general-tracking.config';
import { googleTagManagerConfig } from '../../constants/google-tag-manager.config';
import {
    createConsultantCallbackLead,
    leadCreationError,
    requestConsultantCallback,
} from '../actions/consultant-callback-overlay.actions';

@Injectable()
export class ConsultantCallbackLeadCreationEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly apiClient: LeadCreationApiService,
        private readonly eventTrackingService: C24NgEventTrackingService
    ) {}

    public consultantCallbackLeadCreation: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(createConsultantCallbackLead),
                tap(() => {
                    generalTrackingConfig.requestConsultantCallback.forEach(
                        (data: GeneralTrackingEventTrackingData) => {
                            this.eventTrackingService.trackEvent({
                                generalTracking: data,
                            });
                        }
                    );

                    this.eventTrackingService.trackEvent({
                        googleTagManager:
                            googleTagManagerConfig.requestConsultantCallback,
                    });
                }),
                exhaustMap(
                    (
                        action: ReturnType<typeof createConsultantCallbackLead>
                    ) => {
                        return this.apiClient
                            .create(
                                action.leadCreationApiRequest,
                                action.calculationParameter
                            )
                            .pipe(
                                map(
                                    (
                                        response: LeadCreationApiResponseModel
                                    ) => {
                                        if (!response) {
                                            return leadCreationError();
                                        }

                                        return requestConsultantCallback({
                                            leadCreationApiResponse: response,
                                            phoneNumber:
                                                action.leadCreationApiRequest
                                                    .phone,
                                        });
                                    }
                                ),
                                catchError(() => of(leadCreationError()))
                            );
                    }
                )
            );
        }
    );
}
