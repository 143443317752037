<header>
    <span>Development-Toolbar</span>
    <c24-ng-svg-icon-close (click)="closeToolbar.emit()" />
</header>
<section>
    <h3>Feature Toggles</h3>
    @for (featureToggle of featureToggles() | keyvalue; track featureToggle.key) {
        <feature-toggle
            [featureToggle]="featureToggle.value"
            (toggleFeature)="updateFeatureToggleStatus(featureToggle.value)"
        />
    }

    @if (isAnyFeatureEnabled()) {
        @if (!!featureToggles.length) {
            <button pkv-button (click)="clearFeatureToggles()">zurücksetzen</button>
        }
    }

    <h3>Application State</h3>
    <application-state />
</section>
