import { createReducer, on } from '@ngrx/store';

import { GkvTariffTileStateEnum } from '../../enums/gkv-tariff-tile-state.enum';

import { closeGkvTileAction } from '../actions/close-gkv-tile.action';
import { gkvTileStickyChangeAction } from '../actions/gkv-tile-sticky-change.action';
import { saveGkvTariffsAction } from '../actions/save-gkv-tariffs.action';
import { setGkvTileStateFromStorageAction } from '../actions/set-gkv-tile-state-from-storage.action';
import { toggleGkvTileState2And3Action } from '../actions/toggle-gkv-tile-state-2-and-3.action';
import { GkvStateModel } from '../models/gkv-state.model';

export const initialState: GkvStateModel = {
    tariffs: undefined,
    state: GkvTariffTileStateEnum.Big,
    lastCompactState: GkvTariffTileStateEnum.Medium,
};

export const gkvReducers = createReducer(
    initialState,
    on(
        saveGkvTariffsAction,
        (
            state: GkvStateModel,
            action: ReturnType<typeof saveGkvTariffsAction>
        ): GkvStateModel => {
            return {
                ...state,
                tariffs: action.tariffs,
            };
        }
    ),
    on(
        setGkvTileStateFromStorageAction,
        (
            state: GkvStateModel,
            action: ReturnType<typeof setGkvTileStateFromStorageAction>
        ): GkvStateModel => {
            return {
                ...state,
                state: action.isClosed
                    ? GkvTariffTileStateEnum.Hidden
                    : state.state,
            };
        }
    ),
    on(closeGkvTileAction, (state: GkvStateModel): GkvStateModel => {
        return {
            ...state,
            state: GkvTariffTileStateEnum.Hidden,
        };
    }),
    on(
        gkvTileStickyChangeAction,
        (
            state: GkvStateModel,
            action: ReturnType<typeof gkvTileStickyChangeAction>
        ): GkvStateModel => {
            return {
                ...state,
                state: action.isSticky
                    ? state.lastCompactState
                    : GkvTariffTileStateEnum.Big,
            };
        }
    ),
    on(toggleGkvTileState2And3Action, (state: GkvStateModel): GkvStateModel => {
        const newState =
            state.state === GkvTariffTileStateEnum.Small
                ? GkvTariffTileStateEnum.Medium
                : GkvTariffTileStateEnum.Small;
        return {
            ...state,
            state: newState,
            lastCompactState: newState,
        };
    })
);
