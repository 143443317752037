export enum ResultTrackingConfigEnum {
    OpenOverlay = 'openOverlay',
    CloseOverlay = 'closeOverlay',

    MainBenefits = 'mainBenefits', // Wichtigste Leistungen
    OutpatientBenefits = 'outpatientBenefits', // Ambulante Leistungen
    InpatientBenefits = 'inpatientBenefits', // Stationäre Leistungen
    DentalBenefits = 'dentalBenefits', // Zahnleistungen
    General = 'general', // Allgemein
}
