import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    AliasEmailValidator,
    EmailValidator,
} from '@pkv-frontend/business-domain/form';

import { UserService } from '@pkv-frontend/business-domain/user';
import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';

@Injectable()
export class ResultViaEmailFormBuilderService implements OnDestroy {
    private readonly destroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly userService: UserService
    ) {}

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public build(): FormGroup {
        const form = this.formBuilder.group({
            email: [
                null,
                {
                    updateOn: 'change',
                    validators: [
                        Validators.required,
                        AliasEmailValidator,
                        EmailValidator,
                    ],
                },
            ],
        });

        this.userService.ssoUserData$
            .pipe(
                filter((ssoUserData: SsoUserDataModel | null) => !!ssoUserData),
                takeUntil(this.destroy$)
            )
            .subscribe((ssoUserData: SsoUserDataModel) => {
                if (ssoUserData.email) {
                    form.get('email')?.patchValue(ssoUserData.email);
                }
            });

        return form;
    }
}
