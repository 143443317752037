import {
    GradeEnum,
    TreatmentAttendingDoctorEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { CustomFilterDefaultValuesModel } from '../models/custom-filter-default-values.model';

export const customFilterDefaultValues: CustomFilterDefaultValuesModel = {
    directMedicalConsultationBenefit: false,
    provisionContributionReimbursement: false,
    evaluationContributionStability: false,
    amedNonMedPractitionerReimbursement: false,
    cureAndRehab: false,
    treatmentAboveMaximumRate: false,
    treatmentAttendingDoctor: TreatmentAttendingDoctorEnum.Empty,
    noPdhospitalPayout: false,
    check24Grade: GradeEnum.All,
    betAmount: 0,
};
