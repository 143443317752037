import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { StateInsurancePrices } from '@pkv-frontend/data-domain/state-insurance';

export function gkvPriceTooltipDefaultContributionPercentageRule(
    item: GkvResultItem | undefined,
    stateInsurancePrices: StateInsurancePrices | undefined
): number | undefined {
    if (
        !item ||
        !stateInsurancePrices?.statutoryHealthInsuranceBasePercentage
    ) {
        return;
    }
    return (
        (item.tariff.contributionPercentage -
            stateInsurancePrices.statutoryHealthInsuranceBasePercentage) /
        100
    );
}
