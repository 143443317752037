import { Injectable } from '@angular/core';

import {
    CalculationParameterInput1,
    CalculationParameterInterface,
    InsuredPersonEnum,
    LegacyCalculationParameter,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { isEmployeeRule } from '../../rule/is-employee.rule';

@Injectable({
    providedIn: 'root',
})
export class CalculationParameterInformationService {
    public isServantOrServantCandidate<
        T extends
            | Pick<CalculationParameterInterface, 'profession'>
            | Pick<LegacyCalculationParameter, 'profession'>,
    >(calculationParameter: T): boolean {
        return (
            calculationParameter.profession === ProfessionEnum.Servant ||
            calculationParameter.profession === ProfessionEnum.ServantCandidate
        );
    }

    public isServantOrServantCandidateOrServantChild<
        T extends Pick<
            CalculationParameterInterface,
            'profession' | 'parentServantOrServantCandidate' | 'insuredPerson'
        >,
    >(calculationParameter: T): boolean {
        return (
            this.isServantOrServantCandidate(calculationParameter) ||
            this.isServantChild(calculationParameter)
        );
    }

    public isChild<
        T extends Pick<CalculationParameterInterface, 'insuredPerson'>,
    >(calculationParameter: T): boolean {
        return calculationParameter.insuredPerson === InsuredPersonEnum.Child;
    }

    public isServantChild<
        T extends Pick<
            CalculationParameterInterface,
            'profession' | 'parentServantOrServantCandidate' | 'insuredPerson'
        >,
    >(calculationParameter: T): boolean {
        return (
            this.isChild(calculationParameter) &&
            calculationParameter.parentServantOrServantCandidate
        );
    }

    // eslint-disable-next-line prettier/prettier
    public isEmployeeAndAdult(
        calculationParameter:
            | Pick<CalculationParameterInput1, 'profession' | 'insuredPerson'>
            | Pick<LegacyCalculationParameter, 'profession' | 'insured_person'>
    ): boolean {
        return (
            this.isEmployee(calculationParameter) &&
            this.isAdult(calculationParameter)
        );
    }

    public isAdult<
        T extends
            | Pick<CalculationParameterInput1, 'insuredPerson'>
            | Pick<LegacyCalculationParameter, 'insured_person'>,
    >(calculationParameter: T): boolean {
        if (
            Object.hasOwnProperty.call(calculationParameter, 'insured_person')
        ) {
            return (
                (<LegacyCalculationParameter>calculationParameter)
                    .insured_person === InsuredPersonEnum.Adult
            );
        }
        return (
            (<CalculationParameterInput1>calculationParameter).insuredPerson ===
            InsuredPersonEnum.Adult
        );
    }

    public isEmployee<
        T extends
            | Pick<CalculationParameterInterface, 'profession'>
            | Pick<LegacyCalculationParameter, 'profession'>,
    >(calculationParameter: T): boolean {
        return isEmployeeRule(calculationParameter.profession);
    }

    public isFreelancer<
        T extends
            | Pick<CalculationParameterInterface, 'profession'>
            | Pick<LegacyCalculationParameter, 'profession'>,
    >(calculationParameter: T): boolean {
        return calculationParameter.profession === ProfessionEnum.Freelancer;
    }

    public isUnemployed<
        T extends
            | Pick<CalculationParameterInterface, 'profession'>
            | Pick<LegacyCalculationParameter, 'profession'>,
    >(calculationParameter: T): boolean {
        return calculationParameter.profession === ProfessionEnum.Unemployed;
    }

    public isServantCandidate<
        T extends
            | Pick<CalculationParameterInterface, 'profession'>
            | Pick<LegacyCalculationParameter, 'profession'>,
    >(calculationParameter: T): boolean {
        return (
            calculationParameter.profession === ProfessionEnum.ServantCandidate
        );
    }
}
