import { createSelector } from '@ngrx/store';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterCustomFilterSettingsSelector = createSelector<
    FilterStateAware,
    [FilterState],
    ResultFilter | undefined
>(
    filterStateFeatureSelector,
    (state: FilterState) => state.customFilterSettings
);
