import { createSelector } from '@ngrx/store';
import { ResultItem } from '@pkv-frontend/data-domain/result';
import { ResultStateAware } from '../models/result-state.model';

import { resultCalculationResponseSelector } from './result-calculation-response.selector';

export const resultPromotionSelector = createSelector<
    ResultStateAware,
    [ReturnType<typeof resultCalculationResponseSelector>],
    ResultItem[] | undefined
>(
    resultCalculationResponseSelector,
    (result: ReturnType<typeof resultCalculationResponseSelector>) =>
        result?.promotion && Object.values(result?.promotion)
);
