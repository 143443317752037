import { createSelector } from '@ngrx/store';
import {
    ResultState,
    ResultStateAware,
    ResultStateResult,
} from '../models/result-state.model';

import { resultStateFeatureSelector } from './result-state-feature.selector';

export const silentResultCalculationResponseSelector = createSelector<
    ResultStateAware,
    [ResultState],
    ResultStateResult | undefined
>(resultStateFeatureSelector, (state: ResultState) => state.silentResult);
