import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { map } from 'rxjs/operators';
import { SortFieldEnum } from '@pkv-frontend/data-domain/result';
import { resultFilterSortfieldSelector } from '../../filter';
import { closeTransparencyInfoAction } from '../store/actions/transparency-info.actions';
import { transparencyInfoSortfieldsSelector } from '../store/selectors/transparency-info-sortfields.selector';

@Injectable({
    providedIn: 'root',
})
export class TransparencyInfoDataService {
    public readonly transparencyInfoSortfield$: Observable<
        SortFieldEnum | undefined
    > = combineLatest([
        this.store$.select(resultFilterSortfieldSelector),
        this.store$.select(transparencyInfoSortfieldsSelector),
    ]).pipe(
        map(
            ([sortfield, blacklist]: [
                SortFieldEnum | undefined,
                SortFieldEnum[],
            ]) => {
                if (!sortfield || blacklist.includes(sortfield)) {
                    return;
                }
                return sortfield;
            }
        )
    );

    constructor(private readonly store$: Store) {}

    public closeTransparencyInfo(sortfield: SortFieldEnum): void {
        this.store$.dispatch(closeTransparencyInfoAction({ sortfield }));
    }
}
