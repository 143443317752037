import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import {
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {
    APP_INITIALIZER,
    ApplicationRef,
    DoBootstrap,
    LOCALE_ID,
    NgModule,
} from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { C24NgFormElementsModule } from '@vv-ham/ng-desktop-form-elements';
import { C24NgTrackingModule } from '@vv-ham/ng-tracking';
import { CookieService } from 'ngx-cookie-service';
import {
    applicationFeatureKey,
    applicationReducers,
    ApplicationStoreModule,
    FetchActiveConsultantDataWhenInAlternativeOfferEffect,
    FetchCoreFeatureTogglesEffect,
    InitApplicationDataEffect,
    IsCobrowsingEffect,
    SetEnvironmentEffect,
    SetFirstInfoCookieEffect,
} from '@pkv-frontend/business-domain/application';
import {
    PreviousUrlPersistenceService,
    UrlService,
} from '@pkv-frontend/business-domain/tracking';
import { USER_DATA_PERSISTENCE_KEY_TOKEN } from '@pkv-frontend/business-domain/user';
import { CHATTY_VERSION } from '@pkv-frontend/data-domain/c24-app';
import { ConsultantUserPermissionEnum } from '@pkv-frontend/data-domain/consultant';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';
import { MatomoAbTestingFactory } from '@pkv-frontend/infrastructure/ab-testing';
import { ErrorHandlerModule } from '@pkv-frontend/infrastructure/error-handler';
import {
    ASSET_ROOT_TOKEN,
    DEVICE_ENVIRONMENT_TOKEN,
    DeviceEnvironmentEnum,
} from '@pkv-frontend/infrastructure/interfaces';
import { LazyModuleLoadingService } from '@pkv-frontend/infrastructure/lazy-loading';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import {
    AppComponent,
    selector as appComponentSelector,
} from './app.component';

import { TrackingSettingsService } from './business-domain/tracking/tracking-settings.service';
import { DevelopmentToolbarModule } from './presentation/features/development-toolbar/development-toolbar.module';

const COBRO_PAGE_PATH_NAME = '/beratung/';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

const appReducers: ActionReducerMap<State> = {
    [applicationFeatureKey]: applicationReducers,
};

registerLocaleData(localeDe);

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        C24NgFormElementsModule,
        C24NgTrackingModule.forRoot(TrackingSettingsService.get),
        DevelopmentToolbarModule,
        EffectsModule.forRoot([
            InitApplicationDataEffect,
            FetchCoreFeatureTogglesEffect,
            FetchActiveConsultantDataWhenInAlternativeOfferEffect,
            IsCobrowsingEffect,
            SetEnvironmentEffect,
            SetFirstInfoCookieEffect,
        ]),
        ErrorHandlerModule,
        StoreModule.forRoot(appReducers),
        ApplicationStoreModule,
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true,
        }),
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useValue: '/',
        },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE',
        },
        {
            provide: APP_INITIALIZER,
            useFactory:
                (
                    phpFrontendDataService: PhpFrontendDataService,
                    matomoAbTestingFactory: MatomoAbTestingFactory
                ) =>
                () => {
                    phpFrontendDataService.initialize();
                    matomoAbTestingFactory.init();
                },
            deps: [PhpFrontendDataService, MatomoAbTestingFactory],
            multi: true,
        },
        PreviousUrlPersistenceService,
        UrlService,
        CookieService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
            },
        },
        {
            provide: DEVICE_ENVIRONMENT_TOKEN,
            useValue: DeviceEnvironmentEnum.Desktop,
        },
        {
            provide: ASSET_ROOT_TOKEN,
            useValue: environment.assetsRoot,
        },
        {
            provide: CHATTY_VERSION,
            useValue: '2025-2-42',
        },
        {
            provide: USER_DATA_PERSISTENCE_KEY_TOKEN,
            useValue: 'pkv.userInput',
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule implements DoBootstrap {
    constructor(
        private frontendDataService: PhpFrontendDataService,
        private lazyLoadingService: LazyModuleLoadingService
    ) {}

    public ngDoBootstrap(applicationRef: ApplicationRef): void {
        if (document.querySelector(appComponentSelector)) {
            applicationRef.bootstrap(AppComponent);
        }

        this.handleLoadingOfVideoConsultationControlPanelModule();
        this.handleLoadingOfVideoConsultationModule();
    }

    private handleLoadingOfVideoConsultationModule(): void {
        if (window.location.pathname === COBRO_PAGE_PATH_NAME) {
            this.lazyLoadingService.loadModule(() =>
                import(
                    './presentation/features/video-consultation/video-consultation.module'
                ).then((m) => m.VideoConsultationModule)
            );
        }
    }

    private handleLoadingOfVideoConsultationControlPanelModule(): void {
        const hasVideoConsultationPermission =
            !!this.frontendDataService.data.userPermissions?.find(
                (permission: string) =>
                    permission ===
                    ConsultantUserPermissionEnum.VideoConsultation
            );

        if (!hasVideoConsultationPermission) {
            return;
        }

        this.lazyLoadingService.loadModule(() =>
            import(
                './presentation/features/video-consultation-control-panel/video-consultation-control-panel.module'
            ).then((m) => m.VideoConsultationControlPanelModule)
        );
    }
}
