import { createSelector } from '@ngrx/store';
import {
    ResultItem,
    SkeletonResultItem,
} from '@pkv-frontend/data-domain/result';

import { ResultStateAware } from '../models/result-state.model';

import { resultCalculationResponseSelector } from './result-calculation-response.selector';

export const resultCalculationResultSelector = createSelector<
    ResultStateAware,
    [ReturnType<typeof resultCalculationResponseSelector>],
    Array<ResultItem | SkeletonResultItem> | undefined
>(
    resultCalculationResponseSelector,
    (result: ReturnType<typeof resultCalculationResponseSelector>) =>
        result?.result
);
