import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BenefitPresetEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';

import {
    effectivePriceToggleChangeAction,
    filterChangeAction,
} from '../actions/filter.actions';
import { resultFilterCommittedResultFiltersSelector } from '../selectors/result-filter-committed-result-filters.selector';

@Injectable()
export class UpdateUrlForEffectivePriceToggleChangeEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store
    ) {}

    public updateUrlForEffectivePriceToggleChangeEffect$: Observable<Action> =
        createEffect(() => {
            return this.actions$.pipe(
                ofType(effectivePriceToggleChangeAction),
                concatLatestFrom(() =>
                    this.store$.select(
                        resultFilterCommittedResultFiltersSelector
                    )
                ),
                map(
                    ([action, currentResultFilters]: [
                        ReturnType<typeof effectivePriceToggleChangeAction>,
                        ResultFilter,
                    ]) =>
                        filterChangeAction({
                            resultFilters: {
                                ...currentResultFilters,
                                benefitPreset: action.enabled
                                    ? BenefitPresetEnum.Custom
                                    : currentResultFilters.benefitPreset,
                                effectivePrice: action.enabled,
                            },
                        })
                )
            );
        });
}
