import { AbstractControl, Validators } from '@angular/forms';

export function DateRequiredValidator(
    control: AbstractControl
): { [key: string]: boolean } | null {
    const requiredValidationResult = Validators.required(control);

    if (requiredValidationResult !== null) {
        return requiredValidationResult;
    }

    if (
        typeof control.value !== 'string' ||
        !dateStringHasThreeNonEmptyValues(control.value)
    ) {
        return { required: true };
    }
    return null;
}

function dateStringHasThreeNonEmptyValues(dateString: string): boolean {
    const split = dateString.split('.');
    if (split.length !== 3) {
        return false;
    }
    return split.every((part: string) => !!part);
}
