import { Injectable } from '@angular/core';
import { CalculationParameterInformationService } from '@pkv-frontend/business-domain/calculation-parameter';
import {
    CalculationParameterInput1,
    LegacyCalculationParameter,
} from '@pkv-frontend/data-domain/calculation-parameter';

@Injectable({
    providedIn: 'root',
})
export class ShowBetAmountRule {
    constructor(
        private readonly calculationParameterInformationService: CalculationParameterInformationService
    ) {}

    public apply(
        calculationParameter:
            | CalculationParameterInput1
            | LegacyCalculationParameter
    ): boolean {
        return this.calculationParameterInformationService.isEmployeeAndAdult(
            calculationParameter
        );
    }
}
