import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';

export function isEmployeeOrFreelancerRule(
    profession: ProfessionEnum | undefined
): boolean {
    return isEmployeeOrFreelancerRuleImplementation(profession);
}

function isEmployeeOrFreelancerRuleImplementation(
    profession: ProfessionEnum | undefined
): boolean {
    return (
        profession === ProfessionEnum.Freelancer ||
        profession === ProfessionEnum.Employee
    );
}
