import { Consultant } from '@pkv-frontend/data-domain/result';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';

function showConsultantCarouselRuleImplementation(
    consultants: Consultant[],
    callbackRequestState: RequestStateEnum,
    isConsultantLoggedIn: boolean,
    isCobrowsing: boolean
): boolean {
    return (
        consultants &&
        callbackRequestState !== RequestStateEnum.Initial &&
        !isCobrowsing &&
        !isConsultantLoggedIn
    );
}

export function showConsultantCarouselRule(
    consultants: Consultant[],
    callbackRequestState: RequestStateEnum,
    isConsultantLoggedIn: boolean,
    isCobrowsing: boolean
): boolean {
    return showConsultantCarouselRuleImplementation(
        consultants,
        callbackRequestState,
        isConsultantLoggedIn,
        isCobrowsing
    );
}
