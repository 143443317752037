export class NotUndefinedOrNullValidator {
    public static validate<T>(
        value: T | null | undefined,
        errorMessage?: string
    ): T {
        if (value === null || value === undefined) {
            throw new Error(
                errorMessage || 'property must be undefined and not null'
            );
        }

        return value;
    }
}
