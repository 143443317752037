import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';
import { Observable } from 'rxjs';

import { ErrorData } from '../models/error-data.model';
import { LogLevelEnum } from '../enums/log-level.enum';

@Injectable()
export class ErrorLoggerService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    public log(error: Error): Observable<any> {
        let additional: { [key: string]: string } = {};
        for (const [key, val] of Object.entries(error)) {
            if (!['name', 'message', 'stack'].includes(key)) {
                additional[key] = val;
            }
        }

        const body: ErrorData = {
            level: LogLevelEnum.Error,
            message: error.message,
            stack: this.buildStack(error.stack),
            uri: this.windowReferenceService.nativeWindow.location.href,
            additional,
        };

        return this.httpClient.post(
            '/api/v1/public/logging/javascript-error',
            body
        );
    }

    private buildStack(stack: string | undefined): string[] {
        if (typeof stack !== 'string') {
            return [];
        }

        return stack.split('\n').map((item: string) => item.trim());
    }
}
