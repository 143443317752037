import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { resultApiResultsLoadedAction } from '../../../result/store/actions/result.actions';
import {
    filterChangeAction,
    showFiltersAppliedMessageAction,
} from '../actions/filter.actions';

@Injectable()
export class ShowFiltersAppliedMessageEffect {
    constructor(private actions$: Actions) {}

    public showFiltersAppliedMessageEffect$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(filterChangeAction),
                switchMap(() =>
                    this.actions$.pipe(
                        ofType(resultApiResultsLoadedAction),
                        take(1)
                    )
                ),
                filter(
                    (action: ReturnType<typeof resultApiResultsLoadedAction>) =>
                        !!action.result.tariffinfo?.tariffNumber
                ),
                map(() => showFiltersAppliedMessageAction())
            );
        }
    );
}
