import { MatomoEventTrackingData } from '@vv-ham/ng-tracking';
import {
    PromotionTypeEnum,
    ResultItem,
} from '@pkv-frontend/data-domain/result';

import { ResultTrackingConfigEnum } from '../../result/enums/result-tracking-config.enum';

const category = 'Resultpage';

export const resultPromotionTariffDetailsTrackingConfig = (
    identifier: ResultTrackingConfigEnum,
    selectedTariffInformation: ResultItem
): MatomoEventTrackingData | undefined => {
    const promotionType = selectedTariffInformation?.tariff.promotionType;
    switch (identifier) {
        case ResultTrackingConfigEnum.OpenOverlay:
            return {
                category,
                name: 'result-tariff-item_tab-link_more-details',
                action: 'open',
            };

        case ResultTrackingConfigEnum.CloseOverlay:
            return {
                category,
                name: 'result-tariff-item_tab-link_more-details',
                action: 'close',
            };

        case ResultTrackingConfigEnum.MainBenefits:
            return {
                category,
                name: 'result-tariff-item_tab-link_more-details_prime-performance',
                action: `tariff-0-${transformPromotionType(promotionType)}`,
            };

        case ResultTrackingConfigEnum.OutpatientBenefits:
            return {
                category,
                name: 'result-tariff-item_tab-link_more-details_ambulant-performance',
                action: `tariff-0-${transformPromotionType(promotionType)}`,
            };

        case ResultTrackingConfigEnum.InpatientBenefits:
            return {
                category,
                name: 'result-tariff-item_tab-link_more-details_stationary-performance',
                action: `tariff-0-${transformPromotionType(promotionType)}`,
            };

        case ResultTrackingConfigEnum.DentalBenefits:
            return {
                category,
                name: 'result-tariff-item_tab-link_more-details_dental-performance',
                action: `tariff-0-${transformPromotionType(promotionType)}`,
            };
        case ResultTrackingConfigEnum.General:
            return {
                category,
                name: 'result-tariff-item_tab-link_more-details_general-performance',
                action: `tariff-0-${transformPromotionType(promotionType)}`,
            };
    }
};

export const transformPromotionType = (value: PromotionTypeEnum): string => {
    switch (value) {
        case PromotionTypeEnum.HighBenefit:
            return 'le';
        case PromotionTypeEnum.HighPriceBenefit:
            return 'ple';
        case PromotionTypeEnum.Gkv:
            return 'gkv';
        case PromotionTypeEnum.LowestPrice:
            return 'gp';
        case PromotionTypeEnum.Alternative:
            return 'alt';
        default:
            return value;
    }
};
