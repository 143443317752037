import { createSelector } from '@ngrx/store';
import { DeviceOutputEnum } from '@pkv-frontend/data-domain/php-frontend-data';
import {
    ApplicationState,
    ApplicationStateAware,
} from '../models/application-state.model';
import { applicationStateSelector } from './application-state.selector';

export const deviceOutputSelector = createSelector<
    ApplicationStateAware,
    [ApplicationState],
    DeviceOutputEnum | undefined
>(
    applicationStateSelector,
    (state: ApplicationState): DeviceOutputEnum | undefined =>
        state.deviceOutput
);
