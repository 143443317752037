import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';

import { ErrorHandlerService } from './service/error-handler.service';
import { ErrorLoggerService } from './service/error-logger.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        ErrorLoggerService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
    ],
})
export class ErrorHandlerModule {}
