import { RiskPremium } from '@pkv-frontend/data-domain/comparison';

function createUniqueRiskPremiumIdRuleImplementation(
    riskPremiums: RiskPremium[]
): number {
    let newRiskPremiumId = riskPremiums.length;
    while (
        riskPremiums.find((riskPremium) => riskPremium.id === newRiskPremiumId)
    ) {
        newRiskPremiumId++;
    }
    return newRiskPremiumId;
}

export function createUniqueRiskPremiumIdRule(
    riskPremiums: RiskPremium[]
): number {
    return createUniqueRiskPremiumIdRuleImplementation(riskPremiums);
}
