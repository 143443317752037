import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ItemInArrayValidator<T>(list: Array<T>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value && !list.includes(control.value)) {
            return { itemNotInArray: true };
        }
        return null;
    };
}
