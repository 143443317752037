import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from '@pkv-frontend/business-domain/user';
import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';

import { QuotePersonalizationService } from './quote-personalization.service';
@Injectable({
    providedIn: 'root',
})
export class ConsultantOverlayQuoteService {
    public readonly quote =
        'Wir unterstützen Sie bei Ihren Fragen zum Thema private Krankenversicherung.';

    public readonly personalizedQuote$: Observable<string> =
        this.userService.ssoUserData$.pipe(
            startWith(null),
            map((ssoUserData: SsoUserDataModel | null) => {
                if (ssoUserData?.lastName) {
                    return this.quotePersonalizationService.buildPersonalizedQuote(
                        this.quote,
                        ssoUserData
                    );
                }
                return this.quote;
            })
        );

    constructor(
        private readonly userService: UserService,
        private readonly quotePersonalizationService: QuotePersonalizationService
    ) {}
}
