import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { LegacyCalculationParameterQueryParserService } from '@pkv-frontend/business-domain/calculation-parameter';
import { LegacyCalculationParameter } from '@pkv-frontend/data-domain/calculation-parameter';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import {
    resetResultViaEmailFormAction,
    submitResultViaEmailFormAction,
} from '../store/actions/result-via-email.actions';
import { requestParameterEmailSelector } from '../store/selectors/request-parameter-email.selector';
import { requestStateSelector } from '../store/selectors/request-state.selector';

@Injectable({
    providedIn: 'root',
})
export class ResultViaEmailRequestStateDataService {
    public readonly calculationParameter$: Observable<LegacyCalculationParameter> =
        this.activatedRoute.queryParams.pipe(
            map((params: Params) =>
                this.calculationParameterQueryParserService.parse(params)
            )
        );
    public readonly requestState$: Observable<RequestStateEnum> =
        this.store$.select(requestStateSelector);
    public readonly requestEmail$: Observable<string | undefined> =
        this.store$.select(requestParameterEmailSelector);
    public isPending$: Observable<boolean> = this.requestState$.pipe(
        map((state: RequestStateEnum) => state === RequestStateEnum.Pending)
    );

    constructor(
        private readonly store$: Store,
        private readonly calculationParameterQueryParserService: LegacyCalculationParameterQueryParserService,
        private readonly activatedRoute: ActivatedRoute
    ) {}

    public resetForm(): void {
        this.store$.dispatch(resetResultViaEmailFormAction());
    }

    public submit(
        email: string,
        calculationParameter: LegacyCalculationParameter
    ): void {
        this.store$.dispatch(
            submitResultViaEmailFormAction({
                request: {
                    email,
                    calculationParameter,
                },
            })
        );
    }
}
