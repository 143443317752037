import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

const errorMessagesControlNameMapping: ValidationErrors = {
    phone: 'mobileNumber',
    firstname: 'firstName',
    lastname: 'lastName',
};

@Injectable({
    providedIn: 'root',
})
export class FormErrorMapper {
    public applyErrors(form: FormGroup, errors: ValidationErrors): void {
        for (const errorKey in errors) {
            if (!Object.prototype.hasOwnProperty.call(errors, errorKey)) {
                continue;
            }

            const formControl: AbstractControl | null = form.get(
                this.getControlName(errorKey)
            );

            if (formControl === null) {
                continue;
            }

            formControl.setErrors(errors[errorKey], {
                emitEvent: true,
            });

            formControl.markAsTouched();
        }
    }

    private getControlName(key: string): string {
        if (
            !Object.prototype.hasOwnProperty.call(
                errorMessagesControlNameMapping,
                key
            )
        ) {
            return key;
        }

        return errorMessagesControlNameMapping[key];
    }
}
