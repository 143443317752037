import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { GkvResultItem } from '../models/gkv-result-item.model';

@Injectable({
    providedIn: 'root',
})
export class GkvApiClient {
    constructor(private httpClient: HttpClient) {}

    public fetch(): Observable<GkvResultItem[]> {
        return this.httpClient.get<GkvResultItem[]>(
            '/api/public/v1/tariff/gkv'
        );
    }
}
