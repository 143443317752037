import { Injectable } from '@angular/core';

import { benefitPresetFilterValues } from '@pkv-frontend/business-domain/calculation-parameter';
import {
    BenefitPresetEnum,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { customFilterDefaultValues } from '../constants/custom-filter-default-values.constant';
import { BenefitPresetCustomFilterOverrideValues } from '../models/benefit-preset-custom-filter-override-values.model';

@Injectable({
    providedIn: 'root',
})
export class BenefitPresetCustomFilterValuesService {
    public buildBenefitPreset(
        benefitPreset: BenefitPresetEnum,
        profession: ProfessionEnum
    ): BenefitPresetCustomFilterOverrideValues {
        return {
            ...customFilterDefaultValues,
            ...benefitPresetFilterValues(benefitPreset, profession),
        };
    }
}
