import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortFieldEnum } from '@pkv-frontend/data-domain/result';
import { TransparencyInfoCookieService } from '../../services/transparency-info-cookie.service';

import { closeTransparencyInfoAction } from '../actions/transparency-info.actions';
import { transparencyInfoSortfieldsSelector } from '../selectors/transparency-info-sortfields.selector';

@Injectable()
export class PersistTransparencyInfoEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private transparencyInfoCookieService: TransparencyInfoCookieService
    ) {}

    public persistTransparencyInfoEffect$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeTransparencyInfoAction),
                concatLatestFrom(() =>
                    this.store$.select(transparencyInfoSortfieldsSelector)
                ),
                map(
                    ([, sortfields]: [
                        ReturnType<typeof closeTransparencyInfoAction>,
                        SortFieldEnum[],
                    ]) => {
                        this.transparencyInfoCookieService.set(sortfields);
                    }
                )
            );
        },
        { dispatch: false }
    );
}
