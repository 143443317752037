import { Injectable } from '@angular/core';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';

import {
    LegacyComparisonItem,
    LegacyComparisonItems,
} from '../models/legacy-comparison-item.model';

const storageKey = 'comparisonManager.selection';

@Injectable({
    providedIn: 'root',
})
export class LegacyComparisonPersistenceService {
    public items: LegacyComparisonItems;

    constructor(private sessionStorageService: SessionStorageService) {}

    public getItems(): LegacyComparisonItems | undefined {
        return this.sessionStorageService.get(storageKey);
    }

    public getItemIds(): number[] {
        const comparisonItems = this.getItems();

        if (!comparisonItems) {
            return [];
        }
        return comparisonItems.map<number>(
            (item: LegacyComparisonItem) => item.id
        );
    }

    public remove(): void {
        this.sessionStorageService.remove(storageKey);
    }
}
