import { createAction, props } from '@ngrx/store';
import { SortFieldEnum } from '@pkv-frontend/data-domain/result';

enum TransparencyInfoActionNamesEnum {
    Init = '[Result transparency info] Init',
    Loaded = '[Result transparency info] Loaded',
    Close = '[Result transparency info] Close',
}

/** Delete when mobile result page also uses enterResultPageAction */
export const initTransparencyInfoAction = createAction(
    TransparencyInfoActionNamesEnum.Init
);

export const transparencyInfoFromCookieLoadedAction = createAction(
    TransparencyInfoActionNamesEnum.Loaded,
    props<{
        sortfields: SortFieldEnum[];
    }>()
);

export const closeTransparencyInfoAction = createAction(
    TransparencyInfoActionNamesEnum.Close,
    props<{
        sortfield: SortFieldEnum;
    }>()
);
