import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    ResultItem,
    TariffPromotionDataValueValue,
} from '@pkv-frontend/data-domain/result';

import { TabTypeEnum } from '../../result/enums/tab-type.enum';

import { resultPromotionSelector } from '../../result/store/selectors/result-promotion.selector';
import { activeTariffVersionIdSelector } from '../store/selectors/active-tariff-version-id.selector';
import { resultPromotionFeedbackRatingFilterSelector } from '../store/selectors/result-promotion-feedback-rating-filter.selector';
import { tabSelector } from '../store/selectors/tab.selector';

@Injectable({ providedIn: 'root' })
export class PromotionDataService {
    constructor(private readonly store$: Store) {}

    public readonly activeTariffVersionId$: Observable<number | undefined> =
        this.store$.select(activeTariffVersionIdSelector);

    public readonly promotion$: Observable<ResultItem[] | undefined> =
        this.store$.select(resultPromotionSelector);

    public readonly tab$: Observable<TabTypeEnum | undefined> =
        this.store$.select(tabSelector);

    public readonly resultPromotionFeedbackRatingFilter$: Observable<
        number | undefined
    > = this.store$.select(resultPromotionFeedbackRatingFilterSelector);

    public getPromotionData(
        resultItem: ResultItem
    ): TariffPromotionDataValueValue {
        return Object.values(
            Object.values(resultItem.tariff.promotionData)[0]
        )[0];
    }
}
