import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from '@pkv-frontend/business-domain/user';
import { Consultant } from '@pkv-frontend/data-domain/result';
import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';

import { QuotePersonalizationService } from './quote-personalization.service';

@Injectable({
    providedIn: 'root',
})
export class ConsultantCarouselQuoteService {
    constructor(
        private readonly userService: UserService,
        private readonly quotePersonalizationService: QuotePersonalizationService
    ) {}

    public quote$(consultant: Consultant): Observable<string> {
        return this.userService.ssoUserData$.pipe(
            startWith(null),
            map((ssoUserData: SsoUserDataModel | null) =>
                this.quotePersonalizationService.buildQuote(
                    consultant,
                    ssoUserData
                )
            )
        );
    }
}
