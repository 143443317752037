import { AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';

export class GkvAutocompleteValidator {
    public static createValidator(
        autocompleteOption$: Observable<GkvResultItem[]>
    ): AsyncValidatorFn {
        return (): Observable<ValidationErrors | null> => {
            return autocompleteOption$.pipe(
                map((autocompleteOptions: GkvResultItem[]) =>
                    autocompleteOptions.length === 0 ? { pattern: true } : null
                ),
                take(1)
            );
        };
    }
}
