import { BenefitPresetEnum } from '../enums/benefit-preset.enum';
import { InsuredPersonEnum } from '../enums/insured-person.enum';
import { ProfessionEnum } from '../enums/profession.enum';
import { LegacyCalculationParameter } from '../models/legacy-calculation-parameter.model';

export const defaultLegacyCalculationParameterConstant: LegacyCalculationParameter =
    {
        pdhospital_payout_amount_value: '100',
        pdhospital_payout_start: '43',
        benefit_preset: BenefitPresetEnum.Comfort,
        insure_date: '2021-07-15',
        hospitalization_accommodation: 'double',
        dental: 'comfort',
        dental_treatment: 100,
        dental_dentures: 75,
        insured_person: InsuredPersonEnum.Adult,
        profession: ProfessionEnum.Unknown,
        birthdate: '1900-01-01',
        provision_costsharing_limit: '500',
        check24_grade: 'all',
        reduced_results: 'yes',
    };
