import { Injectable } from '@angular/core';

const germanDateFormatRegExp =
    '^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})\\s*$';

@Injectable({ providedIn: 'root' })
export class IsGermanDateFormatRule {
    public apply(dateString: string): boolean {
        return new RegExp(germanDateFormatRegExp).test(dateString);
    }
}
