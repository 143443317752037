import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { C24NgEventTrackingService } from '@vv-ham/ng-tracking';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LeadCreationPageService } from '@pkv-frontend/business-domain/checkout';
import { LeadCreationPageEnum } from '@pkv-frontend/data-domain/lead-expert';
import { ConsultantOverlayTrackingCategoryEnum } from '../../enums/consultant-overlay-tracking-category.enum';
import {
    ConsultantOverlayTrackingConfig,
    ConsultantOverlayTrackingConfigFactory,
} from '../../factory/consultant-overlay-tracking-config.factory';

import { finishConsultantCallbackRequest } from '../actions/consultant-callback-overlay.actions';

@Injectable()
export class TrackLeadCreationSuccessEffect {
    constructor(
        private actions$: Actions,
        private eventTrackingService: C24NgEventTrackingService,
        private consultantOverlayTrackingConfigFactory: ConsultantOverlayTrackingConfigFactory,
        private leadCreationPageService: LeadCreationPageService
    ) {}

    public readonly finishConsultantCallbackRequest$: Observable<Action> =
        createEffect(
            () => {
                return this.actions$.pipe(
                    ofType(finishConsultantCallbackRequest),
                    tap(() => {
                        const trackingCategory: ConsultantOverlayTrackingCategoryEnum =
                            this.leadCreationPageService.getFromUrl() ===
                            LeadCreationPageEnum.Comparison
                                ? ConsultantOverlayTrackingCategoryEnum.CompareExpert
                                : ConsultantOverlayTrackingCategoryEnum.Expert;

                        const trackingConfig: ConsultantOverlayTrackingConfig =
                            this.consultantOverlayTrackingConfigFactory.createByCategory(
                                trackingCategory
                            );

                        this.eventTrackingService.trackEvent({
                            matomo: trackingConfig.instantAppointmentSuccess,
                        });
                    })
                );
            },
            { dispatch: false }
        );
}
