import { AbstractControl } from '@angular/forms';

import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';

export function ProfessionValidator(
    control: AbstractControl
): { [key: string]: boolean } | null {
    if (!Object.values(ProfessionEnum).includes(control.value)) {
        return { notIn: true };
    }

    return null;
}
