import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { LeadAuthentication } from '@pkv-frontend/data-domain/lead';

import { InstantAppointmentAvailable } from '../models/instant-appointment-available.model';

const baseUrl = '/api/v1/public/checkout/instant-appointment';

@Injectable({
    providedIn: 'root',
})
export class InstantAppointmentRequestApiClient {
    constructor(private httpClient: HttpClient) {}

    public create(
        leadAuthentication: LeadAuthentication,
        phoneNumber: string
    ): Observable<boolean> {
        return this.httpClient
            .post(baseUrl, {
                leadId: leadAuthentication.leadId,
                leadAccessKey: leadAuthentication.leadAccessKey,
                phoneNumber: phoneNumber,
            })
            .pipe(mapTo(true));
    }

    public getCanShowDirectAppointment(
        leadId?: number
    ): Observable<InstantAppointmentAvailable> {
        const url = leadId ? `${baseUrl}/${leadId}` : `${baseUrl}/`;
        return this.httpClient.get<InstantAppointmentAvailable>(url);
    }
}
