import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';

import { catchError, exhaustMap, map } from 'rxjs/operators';
import { GkvApiClient, GkvResultItem } from '@pkv-frontend/data-domain/gkv';

import { enterResultPageAction } from '../../../result/store/actions/result.actions';
import { saveGkvTariffsAction } from '../actions/save-gkv-tariffs.action';

@Injectable()
export class FetchGkvTariffsEffect {
    constructor(
        private actions$: Actions,
        private apiClient: GkvApiClient
    ) {}

    public fetchGkvTariffsEffect$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(enterResultPageAction),
            exhaustMap(() =>
                this.apiClient.fetch().pipe(
                    map(
                        (tariffs: GkvResultItem[]) =>
                            saveGkvTariffsAction({ tariffs }),
                        catchError(() => EMPTY)
                    )
                )
            )
        );
    });
}
