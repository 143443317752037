import { C24NgClosedByTypeEnum } from '@vv-ham/ng-desktop-modal';

import { ConsultantOverlayCloseByTypeEnum } from '../enums/consultant-overlay-close-by-type.enum';

export const NgClosedByEnumToOverlayClosedByEnumMap: {
    [key in C24NgClosedByTypeEnum]: ConsultantOverlayCloseByTypeEnum;
} = {
    [C24NgClosedByTypeEnum.CloseIcon]:
        ConsultantOverlayCloseByTypeEnum.ModalCloseIcon,
    [C24NgClosedByTypeEnum.Background]:
        ConsultantOverlayCloseByTypeEnum.Background,
    [C24NgClosedByTypeEnum.EscapeKeyDown]:
        ConsultantOverlayCloseByTypeEnum.EscapeKeyDown,
};
