import { createReducer, on } from '@ngrx/store';

import { BenefitPresetEnum } from '@pkv-frontend/data-domain/calculation-parameter';

import { gkvProviderChangeAction } from '../../../gkv/store/actions/gkv-provider-change.action';
import {
    resetResult,
    resultApiResultsLoadedAction,
    resultPageHistoryBackTriggeredAction,
} from '../../../result/store/actions/result.actions';
import {
    effectivePriceToggleChangeAction,
    filterChangeAction,
    gkvCrossSellingChangeAction,
    hideFiltersAppliedMessageAction,
    resetConsultantFilterChangeAction,
    resetFilterChipsAction,
    setCustomFilterSettingsAction,
    setCustomPresetLowestPricesAction,
    setFilterProvidersAction,
    setLoadResultSilently,
    showFiltersAppliedMessageAction,
    sortChangeAction,
    updateFiltersFromUrlAction,
} from '../actions/filter.actions';
import { FilterState } from '../models/filter-state.model';

export const initialState: FilterState = {
    calculationParameters: undefined,
    sortApiParameters: undefined,
    positionParameters: undefined,
    resultFilters: undefined,
    committedResultFilters: undefined,
    customFilterSettings: undefined,
    lowestCustomPrice: undefined,
    appliedMessageVisible: false,
    crossSellingOverlay: undefined,
    filterChips: [],
    providers: [],
    loadResultSilently: false,
    simplifiedResponse: false,
    isLoading: false,
};

export const filterReducers = createReducer(
    initialState,
    on(
        updateFiltersFromUrlAction,
        (
            state: FilterState,
            action: ReturnType<typeof updateFiltersFromUrlAction>
        ): FilterState => {
            return {
                ...state,
                calculationParameters: action.calculationParameters,
                sortApiParameters: action.sortApiParameters,
                positionParameters: action.positionParameters,
                resultFilters: state.consultantFilterChange
                    ? state.resultFilters
                    : action.resultFilters,
                committedResultFilters: state.loadResultSilently
                    ? state.committedResultFilters
                    : action.resultFilters,
                customFilterSettings:
                    action.resultFilters.benefitPreset ===
                    BenefitPresetEnum.Custom
                        ? action.resultFilters
                        : state.customFilterSettings,
                crossSellingOverlay: action.crossSellingOverlay,
                filterChips: action.filterChips,
            };
        }
    ),
    on(
        filterChangeAction,
        effectivePriceToggleChangeAction,
        sortChangeAction,
        gkvProviderChangeAction,
        gkvCrossSellingChangeAction,
        resultPageHistoryBackTriggeredAction,
        (state: FilterState): FilterState => {
            return {
                ...state,
                appliedMessageVisible: false,
            };
        }
    ),
    on(
        filterChangeAction,
        (
            state: FilterState,
            action: ReturnType<typeof filterChangeAction>
        ): FilterState => {
            return {
                ...state,
                resultFilters: action.resultFilters,
                consultantFilterChange: !!action.consultantFilterChange,
                simplifiedResponse: false,
                isLoading: true,
            };
        }
    ),
    on(sortChangeAction, (state: FilterState): FilterState => {
        return {
            ...state,
            consultantFilterChange: false,
            loadResultSilently: false,
        };
    }),
    on(
        resetConsultantFilterChangeAction,
        (
            state: FilterState,
            action: ReturnType<typeof resetConsultantFilterChangeAction>
        ): FilterState => {
            return {
                ...state,
                consultantFilterChange: false,
                resultFilters: action.resetFiltersToCommittedFilters
                    ? state.committedResultFilters
                    : state.resultFilters,
            };
        }
    ),
    on(
        setCustomPresetLowestPricesAction,
        (
            state: FilterState,
            action: ReturnType<typeof setCustomPresetLowestPricesAction>
        ): FilterState => {
            return {
                ...state,
                lowestCustomPrice: action.lowestPrice,
            };
        }
    ),
    on(
        setCustomFilterSettingsAction,
        (
            state: FilterState,
            action: ReturnType<typeof setCustomFilterSettingsAction>
        ): FilterState => {
            return {
                ...state,
                customFilterSettings: action.resultFilters,
                lowestCustomPrice: action.lowestPrice,
            };
        }
    ),
    on(showFiltersAppliedMessageAction, (state: FilterState): FilterState => {
        return {
            ...state,
            isLoading: false,
            appliedMessageVisible: true,
        };
    }),
    on(hideFiltersAppliedMessageAction, (state: FilterState): FilterState => {
        return {
            ...state,
            appliedMessageVisible: false,
        };
    }),
    on(
        setFilterProvidersAction,
        (
            state: FilterState,
            action: ReturnType<typeof setFilterProvidersAction>
        ): FilterState => ({
            ...state,
            providers: action.providers,
        })
    ),
    on(
        setLoadResultSilently,
        (
            state: FilterState,
            action: ReturnType<typeof setLoadResultSilently>
        ): FilterState => ({
            ...state,
            loadResultSilently: action.loadResultSilently,
        })
    ),
    on(
        resetFilterChipsAction,
        (state: FilterState): FilterState => ({
            ...state,
            loadResultSilently: false,
        })
    ),
    on(
        filterChangeAction,
        effectivePriceToggleChangeAction,
        (state: FilterState): FilterState => {
            return { ...state, simplifiedResponse: false };
        }
    ),
    on(resultApiResultsLoadedAction, (state: FilterState): FilterState => {
        return {
            ...state,
            simplifiedResponse: true,
        };
    }),
    on(resetResult, (state: FilterState): FilterState => {
        return {
            ...state,
            loadResultSilently: false,
        };
    })
);
