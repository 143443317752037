import { Injectable } from '@angular/core';

import { ComparisonItem } from '@pkv-frontend/data-domain/comparison';
import { PromotionTypeEnum } from '@pkv-frontend/data-domain/result';
import { StateInsurancePrices } from '@pkv-frontend/data-domain/state-insurance';
import {
    ComparisonHeaderDataCellInterface,
    ComparisonHeaderDataCellPriceInfoInterface,
    ComparisonHeaderDataInterface,
} from '../models/comparison-header-data.interface';

import { CalculateYearlySavingsService } from './calculate-yearly-savings.service';

@Injectable({ providedIn: 'root' })
export class ComparisonHeaderDataBuilderService {
    constructor(
        private readonly calculateYearlySavingsService: CalculateYearlySavingsService
    ) {}

    public build(
        comparisonItems: ComparisonItem[],
        stateInsurancePrices: StateInsurancePrices
    ): ComparisonHeaderDataInterface {
        const data: ComparisonHeaderDataInterface = { cells: [] };

        for (const comparisonItem of comparisonItems) {
            const promotion =
                comparisonItem.tariff.promotion &&
                comparisonItem.tariff.promotion.type
                    ? comparisonItem.tariff.promotion
                    : null;

            const cell: ComparisonHeaderDataCellInterface = {
                tariffId: comparisonItem.tariff.tariffId,
                tariffVersionId: comparisonItem.tariff.tariffVersionId,
                providerLogo: comparisonItem.tariff.providerLogo,
                providerName: comparisonItem.tariff.providerName,
                providerId: comparisonItem.tariff.providerId,
                tariffName: comparisonItem.tariff.tariffName,
                tariffMarketingName: comparisonItem.tariff.tariffMarketingName,
                tariffShouldOnlyUseMarketingName:
                    comparisonItem.tariff.tariffShouldOnlyUseMarketingName,
                priceInfo: Object.assign(
                    {},
                    comparisonItem.tariff
                        .priceInfo as ComparisonHeaderDataCellPriceInfoInterface
                ),
                promotion: promotion,
                isCompletable: comparisonItem.tariff.isCompletable,
                grade: comparisonItem.tariff.grade,
                isGoldGrade: comparisonItem.tariff.isGoldGrade,
                totalCountBestFeatures:
                    comparisonItem.tariff.totalCountBestFeatures,
                isFavorite: false,
                isGkv: comparisonItem.tariff.isGkv,
                interferer: comparisonItem.tariff.interferer,
                effectivePrice: comparisonItem.tariff.effectivePrice,
                friendAdvertising:
                    comparisonItem.tariff.friendAdvertising || null,
            };

            cell.priceInfo.saving =
                this.calculateYearlySavingsService.calculate(
                    cell.priceInfo.contributionPerYear,
                    stateInsurancePrices.employeeTotalYearlyContribution
                );

            if (cell.isGkv) {
                cell.promotion = {
                    type: PromotionTypeEnum.Gkv,
                    title: '',
                };
            }

            data.cells.push(cell);
        }

        return data as ComparisonHeaderDataInterface;
    }
}
