import {
    ChangeDetectionStrategy,
    Component,
    input,
    output,
} from '@angular/core';
import { FeatureToggleModel } from '@pkv-frontend/infrastructure/feature-toggle';
import { ToggleColorEnum } from '@pkv-frontend/presentation/ui-toggle';

@Component({
    selector: 'feature-toggle',
    templateUrl: './feature-toggle.component.html',
    styleUrls: ['./feature-toggle.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureToggleComponent {
    protected readonly featureToggle = input.required<FeatureToggleModel>();

    protected readonly toggleFeature = output();

    protected readonly toggleColor = ToggleColorEnum.Positive;
}
