import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ParametersParserService } from '@pkv-frontend/business-domain/calculation-parameter';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';

import { ResultNavigationService } from '../../../result/services/result-navigation.service';
import { resetConsultantFilterChangeAction } from '../actions/filter.actions';
import { resultFilterCommittedResultFiltersSelector } from '../selectors/result-filter-committed-result-filters.selector';

@Injectable()
export class UpdateUrlForResetToCommitedResultFiltersEffect {
    public effect$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(resetConsultantFilterChangeAction),
                filter(
                    (
                        action: ReturnType<
                            typeof resetConsultantFilterChangeAction
                        >
                    ) => action.resetFiltersToCommittedFilters
                ),
                concatLatestFrom(() => [
                    this.store$.select(
                        resultFilterCommittedResultFiltersSelector
                    ),
                ]),
                map(
                    ([, commitedResultFilters]: [
                        ReturnType<typeof resetConsultantFilterChangeAction>,
                        ResultFilter,
                    ]) => {
                        const queryParams: Params =
                            this.parametersParserService.downgrade(
                                commitedResultFilters
                            );
                        this.resultNavigationService.navigate(queryParams);
                    }
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly resultNavigationService: ResultNavigationService,
        private readonly parametersParserService: ParametersParserService,
        private readonly store$: Store
    ) {}
}
