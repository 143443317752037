import { createSelector } from '@ngrx/store';
import {
    ConsultantCallbackOverlayState,
    OverlayStateAware,
} from '../models/consultant-callback-overlay.model';

import { consultantOverlayStateSelector } from './consultant-overlay-state.selector';

export const isVisibleSelector = createSelector<
    OverlayStateAware,
    [ConsultantCallbackOverlayState],
    boolean
>(
    consultantOverlayStateSelector,
    (state: ConsultantCallbackOverlayState): boolean => state.isVisible
);
