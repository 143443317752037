import { createSelector } from '@ngrx/store';

import { ComparisonStateAwareModel } from '../models/comparison-state-aware.model';
import { ComparisonStateModel } from '../models/comparison-state.model';
import { ComparisonTariffItem } from '../models/comparison-tariff-item.model';

import { comparisonFeatureSelector } from './comparison-feature.selector';

export const comparisonTariffsSelector = createSelector<
    ComparisonStateAwareModel,
    [ComparisonStateModel],
    ComparisonTariffItem[]
>(
    comparisonFeatureSelector,
    (state: ComparisonStateModel) => state.tariffItems
);
