import { Injectable } from '@angular/core';
import {
    ComparisonItem,
    ComparisonTableDataCellTypeEnum,
    ComparisonTableDataRowTypeEnum,
} from '@pkv-frontend/data-domain/comparison';
import { followUpTariffContentConstant } from '../constants/follow-up-tarif-content.constant';
import {
    ComparisonTableDataCaptionInterface,
    ComparisonTableDataRowInterface,
    ComparisonTableDataCellInterface,
    ComparisonTableDataFollowUpTariffInterface,
    ComparisonTableDataInterface,
} from '../models/comparison-table-data.interface';

@Injectable({
    providedIn: 'root',
})
export class ComparisonTableFollowUpTariffDataBuilderService {
    public build(
        comparisonItems: ComparisonItem[]
    ): ComparisonTableDataInterface {
        const data: Partial<ComparisonTableDataInterface> = {};
        let cells: ComparisonTableDataCellInterface[] = [];

        for (const comparisonItem of comparisonItems) {
            data.caption = this.populateCaption(data.caption, comparisonItem);
            cells = this.buildRowCells(cells, comparisonItem);
        }

        data.rows = this.populateRowCells(data.rows, cells, comparisonItems);

        data.key = ComparisonTableDataCellTypeEnum.FollowUpTariff;

        return data as ComparisonTableDataInterface;
    }

    private buildRowCells(
        cells: ComparisonTableDataCellInterface[],
        comparisonItem: ComparisonItem
    ): ComparisonTableDataCellInterface[] {
        const followUpTariff: ComparisonTableDataFollowUpTariffInterface | null =
            comparisonItem.followUpTariff;

        cells.push({
            type: ComparisonTableDataCellTypeEnum.FollowUpTariff,
            followUpTariff: followUpTariff || {
                emptyContent: followUpTariffContentConstant.emptyCellCaption,
                emptyCellTooltipText:
                    followUpTariffContentConstant.notDepositedTooltip,
            },
            isBestFeature: !!comparisonItem.followUpTariff?.isBestFeature,
        });

        return cells;
    }

    private populateCaption(
        caption: ComparisonTableDataCaptionInterface | undefined,
        comparisonItem: ComparisonItem
    ): ComparisonTableDataCaptionInterface {
        const captionOut: ComparisonTableDataCaptionInterface = caption || {
            cells: [],
            label: '',
        };

        if (
            comparisonItem.followUpTariff === null ||
            comparisonItem.followUpTariff === undefined
        ) {
            captionOut.label = followUpTariffContentConstant.label;
            captionOut.cells.push({});

            return captionOut;
        }

        captionOut.cells = captionOut.cells || [];
        captionOut.cells.push({
            points: comparisonItem.followUpTariff.points,
            maxPoints: comparisonItem.followUpTariff.maxPoints,
            color: comparisonItem.followUpTariff.color,
        });

        if (captionOut.label === undefined) {
            captionOut.label = comparisonItem.followUpTariff.label;
        }

        return captionOut;
    }

    private populateRowCells(
        rows: ComparisonTableDataRowInterface[] | undefined,
        cells: ComparisonTableDataCellInterface[],
        comparisonItems: ComparisonItem[]
    ): ComparisonTableDataRowInterface[] {
        const rowsOut: ComparisonTableDataRowInterface[] = rows || [];

        const row: ComparisonTableDataRowInterface = {
            type: ComparisonTableDataRowTypeEnum.Row,
            isCollapsible: false,
            key: ComparisonTableDataCellTypeEnum.FollowUpTariff,
            label: followUpTariffContentConstant.rowCaption,
            tooltip: followUpTariffContentConstant.tooltip,
            allItemsEqual: this.areAllItemsEqual(comparisonItems),
            cells: cells,
        };

        rowsOut.push(row);

        return rowsOut;
    }

    private areAllItemsEqual(items: ComparisonItem[]): boolean {
        return items.some((item: ComparisonItem) => {
            return item.followUpTariff && item.followUpTariff.allItemsEqual;
        });
    }
}
