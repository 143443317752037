import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

const coBroConnectionEventName = 'session_joined';

@Injectable({
    providedIn: 'root',
})
export class IsCobrowsingService {
    public readonly isCobrowsing$: Observable<boolean> = fromEvent(
        this.windowReferenceService.nativeWindow,
        coBroConnectionEventName
    ).pipe(map(() => true));

    constructor(
        private readonly windowReferenceService: WindowReferenceService
    ) {}
}
