import { createSelector } from '@ngrx/store';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import {
    ResultViaEmailState,
    ResultViaEmailStateAware,
} from '../models/result-via-email-state.model';

import { resultViaEmailFeatureSelector } from './result-via-email.feature-selector';

export const requestStateSelector = createSelector<
    ResultViaEmailStateAware,
    [ResultViaEmailState],
    RequestStateEnum
>(resultViaEmailFeatureSelector, (state: ResultViaEmailState) => state.state);
