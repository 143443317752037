import { Injectable } from '@angular/core';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

const tidSessionName = 'tid';
const tidUrlParameter = 'tid';
const tidPhpFrontendDataKey = 'tid';

@Injectable({
    providedIn: 'root',
})
export class GeneralTrackingTidService {
    public static getTid(): string | null {
        const tidPhpFrontendData =
            WindowReferenceService.nativeWindow.phpFrontendData.config
                ?.trackingBaseConfig[tidPhpFrontendDataKey];
        const tidSession = sessionStorage.getItem(tidSessionName);
        const tidUrl = new URLSearchParams(window.location.search).get(
            tidUrlParameter
        );

        const tid = tidSession ?? tidPhpFrontendData ?? tidUrl;

        if (tid && tid !== tidSession) {
            this.setTidSession(tid);
        }

        return tid;
    }

    // set session tid
    public static setTidSession(tid: string): void {
        sessionStorage.setItem(tidSessionName, tid);
    }
}
