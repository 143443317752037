import {
    InsuredPersonEnum,
    ProfessionEnum,
    CalculationParameterInterface,
} from '@pkv-frontend/data-domain/calculation-parameter';

const allowedProfessions = [
    ProfessionEnum.Employee,
    ProfessionEnum.Freelancer,
    ProfessionEnum.Unemployed,
    ProfessionEnum.Student,
    ProfessionEnum.ServantCandidate,
];

export function showEffectivePriceToggleRule(
    calculationParameter: CalculationParameterInterface | undefined
): boolean {
    return (
        !!calculationParameter &&
        calculationParameter.insuredPerson === InsuredPersonEnum.Adult &&
        allowedProfessions.includes(calculationParameter.profession)
    );
}
