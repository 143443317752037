import {
    SendResultViaEmailRequestModel,
    SendResultViaEmailResponseModel,
} from '@pkv-frontend/data-domain/result';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';

export const resultViaEmailFeatureKey = 'result-via-email';

export interface ResultViaEmailState {
    requestParameters?: undefined | SendResultViaEmailRequestModel;
    state: RequestStateEnum;
    result?: undefined | SendResultViaEmailResponseModel;
}

export interface ResultViaEmailStateAware {
    [resultViaEmailFeatureKey]: ResultViaEmailState;
}
