import { createFeatureSelector } from '@ngrx/store';
import {
    ConsultantCarouselState,
    consultantCarouselFeatureKey,
} from '../models/consultant-carousel-state.model';

export const consultantCarouselStateSelector =
    createFeatureSelector<ConsultantCarouselState>(
        consultantCarouselFeatureKey
    );
