import {
    ChangeDetectionStrategy,
    Component,
    computed,
    output,
    Signal,
} from '@angular/core';
import {
    FeatureToggleEnum,
    FeatureToggleManagerService,
    FeatureToggleModel,
} from '@pkv-frontend/infrastructure/feature-toggle';

@Component({
    selector: 'development-toolbar-overlay',
    templateUrl: './development-toolbar-overlay.component.html',
    styleUrls: ['./development-toolbar-overlay.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevelopmentToolbarOverlayComponent {
    protected readonly closeToolbar = output();

    protected readonly featureToggles: Signal<{
        [key: string]: FeatureToggleModel;
    }> = this.featureToggleManagerService.featureToggles;

    protected readonly isAnyFeatureEnabled = computed(() =>
        Object.values(this.featureToggles()).some(
            (featureToggle) => featureToggle.isEnabled
        )
    );

    protected readonly FeatureToggleEnum = FeatureToggleEnum;

    constructor(
        private readonly featureToggleManagerService: FeatureToggleManagerService
    ) {}

    public updateFeatureToggleStatus(featureToggle: FeatureToggleModel): void {
        this.featureToggleManagerService.toggleFeatureStatus(
            featureToggle.identifier
        );
    }

    public clearFeatureToggles(): void {
        this.featureToggleManagerService.clearFeatureToggles();
    }
}
