import { createSelector } from '@ngrx/store';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { FilterStateAware } from '../models/filter-state.model';

import { resultFilterCalculationParametersSelector } from './result-filter-calculation-parameters.selector';

export const resultFilterEffectivePriceSelector = createSelector<
    FilterStateAware,
    [CalculationParameterInterface | undefined],
    boolean
>(
    resultFilterCalculationParametersSelector,
    (calculationParameters: CalculationParameterInterface | undefined) =>
        calculationParameters?.effectivePrice ?? false
);
