import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { format } from 'date-fns/format';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
    CalculationParameterInterface,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { SortFieldEnum, SortOrderEnum } from '@pkv-frontend/data-domain/result';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { parseDateRule } from '@pkv-frontend/infrastructure/date';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';
import { shouldOpenCrossSellingOverlayRule } from '../rules/should-open-cross-selling-overlay.rule';
import {
    clearSilentResult,
    cureAndRehabChangeAction,
    filterChangeAction,
    gkvCrossSellingChangeAction,
    resetConsultantFilterChangeAction,
    resetFilterChipsAction,
    setLoadResultSilently,
    sortChangeAction,
} from '../store/actions/filter.actions';

import { loadResultSilentlySelector } from '../store/selectors/load-result-silent.selector';
import { resultFilterCalcParamProfessionSelector } from '../store/selectors/result-filter-calc-param-profession.selector';
import { resultFilterCalculationParametersSelector } from '../store/selectors/result-filter-calculation-parameters.selector';
import { resultFilterChipsSelector } from '../store/selectors/result-filter-chips.selector';
import { resultFilterCommittedResultFiltersSelector } from '../store/selectors/result-filter-committed-result-filters.selector';
import { resultFilterCrossSellingOverlaySelector } from '../store/selectors/result-filter-cross-selling-overlay.selector';
import { resultFilterCustomFilterSettingsSelector } from '../store/selectors/result-filter-custom-filter-settings.selector';
import { resultFilterProviderIdsSelector } from '../store/selectors/result-filter-provider-ids.selector';
import { resultFilterProvidersSelector } from '../store/selectors/result-filter-providers.selector';
import { resultFilterResultFiltersSelector } from '../store/selectors/result-filter-result-filters.selector';
import { resultFilterShowEffectivePriceToggleSelector } from '../store/selectors/result-filter-show-effective-price-toggle.selector';
import { resultFilterSortfieldSelector } from '../store/selectors/result-filter-sortfield.selector';
import { resultFilterSortorderSelector } from '../store/selectors/result-filter-sortorder.selector';

@Injectable({
    providedIn: 'root',
})
export class ResultFilterDataService {
    public readonly filterChips$ = this.store$.select(
        resultFilterChipsSelector
    );
    public readonly resultFilters$ = this.store$.select(
        resultFilterResultFiltersSelector
    );
    public readonly committedResultFilters$ = this.store$.select(
        resultFilterCommittedResultFiltersSelector
    );
    public readonly customFilterSettings$ = this.store$.select(
        resultFilterCustomFilterSettingsSelector
    );
    public readonly providers$ = this.store$.select(
        resultFilterProvidersSelector
    );
    public readonly providerIds$ = this.store$.select(
        resultFilterProviderIdsSelector
    );
    public readonly showEffectivePriceToggle$: Observable<boolean> =
        this.store$.select(resultFilterShowEffectivePriceToggleSelector);
    public readonly loadResultSilently$: Observable<boolean> =
        this.store$.select(loadResultSilentlySelector);

    public readonly sortField$: Observable<SortFieldEnum | undefined> =
        this.store$.select(resultFilterSortfieldSelector);

    public readonly sortOrder$: Observable<SortOrderEnum | undefined> =
        this.store$.select(resultFilterSortorderSelector);

    private sortField: SortFieldEnum | undefined;
    private sortOrder: SortOrderEnum | undefined;

    constructor(private readonly store$: Store) {
        this.sortField$.subscribe(
            (data: SortFieldEnum | undefined) => (this.sortField = data)
        );
        this.sortOrder$.subscribe(
            (data: SortOrderEnum | undefined) => (this.sortOrder = data)
        );
    }

    public readonly calculationParameters$: Observable<CalculationParameterInterface> =
        this.store$.select(resultFilterCalculationParametersSelector).pipe(
            filter(
                (calcParams: CalculationParameterInterface | undefined) =>
                    calcParams !== undefined
            ),
            map(
                (calcParams: CalculationParameterInterface | undefined) =>
                    calcParams as CalculationParameterInterface
            )
        );

    public readonly shouldOpenCrossSellingOverlay$: Observable<boolean> =
        combineLatest([
            this.store$.select(resultFilterCrossSellingOverlaySelector),
            this.store$.select(resultFilterCalcParamProfessionSelector),
        ]).pipe(
            map(
                ([openCrossSelling, profession]: [
                    YesNoEnum | undefined,
                    ProfessionEnum | undefined,
                ]) => {
                    return shouldOpenCrossSellingOverlayRule(
                        openCrossSelling,
                        profession
                    );
                }
            )
        );

    public updateSortOptions(
        sortField: SortFieldEnum,
        sortOrder?: SortOrderEnum
    ): void {
        if (!sortOrder) {
            sortOrder = SortOrderEnum.Asc;
        }
        if (this.sortField !== sortField || this.sortOrder !== sortOrder) {
            this.store$.dispatch(
                sortChangeAction({
                    sortfield: sortField,
                    sortorder: sortOrder,
                })
            );
        }
    }

    public updateCalcParamFromCrossSellingOverlay(birthdate: string): void {
        this.store$.dispatch(
            gkvCrossSellingChangeAction({
                birthdate: format(<Date>parseDateRule(birthdate), 'yyyy-MM-dd'),
            })
        );
    }

    public resetFilterChips(filterChips: string[]): void {
        this.store$.dispatch(resetFilterChipsAction({ filterChips }));
    }

    public updateResultFilter(
        resultFilters: ResultFilter,
        consultantFilterChange: boolean,
        loadResultSilently = false
    ): void {
        this.store$.dispatch(
            filterChangeAction({
                resultFilters,
                consultantFilterChange,
                loadResultSilently: loadResultSilently,
            })
        );
    }

    public resetConsultantFilterChange(
        resetFiltersToCommittedFilters: boolean
    ): void {
        this.store$.dispatch(
            resetConsultantFilterChangeAction({
                resetFiltersToCommittedFilters,
            })
        );
    }

    public setLoadResultSilently(silent: boolean): void {
        this.store$.dispatch(
            setLoadResultSilently({
                loadResultSilently: silent,
            })
        );
    }

    public cureAndRehabChange(enabled: boolean): void {
        this.store$.dispatch(cureAndRehabChangeAction({ enabled }));
    }

    public clearSilentResult(): void {
        this.store$.dispatch(clearSilentResult());
    }
}
