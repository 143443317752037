import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    QueryParameterMapTypesEnum,
    queryParameterMap,
} from '@pkv-frontend/business-domain/calculation-parameter';

const stateKey = 'internalResultNavigation';

@Injectable({
    providedIn: 'root',
})
export class ResultNavigationService {
    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router
    ) {}

    public navigate(params: Params) {
        this.router.navigate([], {
            queryParams: this.mergeQueryParams(params),
            replaceUrl: !!history.state?.[stateKey],
            state: {
                [stateKey]: true,
            },
        });
    }

    public navigateAlwaysReplace(params: Params) {
        this.router.navigate([], {
            queryParams: this.mergeQueryParams(params),
            replaceUrl: true,
            state: {
                [stateKey]: !!history.state?.[stateKey],
            },
        });
    }

    private mergeQueryParams(params: Params): { [key: string]: unknown } {
        const mergedQueryParams = {
            ...this.activatedRoute.snapshot.queryParams,
            ...params,
        };

        // remove "provider" in favor of "provider[]"
        for (const [, mapType] of Object.entries(queryParameterMap)) {
            if (
                mapType.type === QueryParameterMapTypesEnum.Array &&
                mergedQueryParams[mapType.key] !== undefined &&
                mergedQueryParams[mapType.key + '[]'] !== undefined
            ) {
                mergedQueryParams[mapType.key] = undefined;
            }
        }
        return mergedQueryParams;
    }
}
