import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FormatIbanService {
    public format(value: string | null): string | null {
        if (value != null) {
            value = value
                .replace(/\s/g, '')
                .replace(/[a-z]/g, (match) => match.toUpperCase())
                .replace(/(.{4})/g, '$1 ')
                .trim();
        }
        return value;
    }

    public maskValue(value: string | null): string {
        value = this.format(value);
        if (!value) {
            return '';
        }

        const parts = value.split(' ').filter((part: string) => !!part);
        const digitRegex = /\d/g;
        const partsLength = parts.length;
        const maskedParts: string[] = [];

        for (const [index, part] of Object.entries(parts)) {
            const numbIndex = parseInt(index, 10);
            if (numbIndex === 0 || numbIndex === partsLength - 1) {
                maskedParts.push(part);
                continue;
            } else if (numbIndex === partsLength - 2) {
                const maskLength = parts[partsLength - 1].length;
                const mask = '*'.repeat(maskLength);
                maskedParts.push(
                    part.replace(new RegExp(`^.{${maskLength}}`), mask)
                );
                continue;
            }

            maskedParts.push(part.replace(digitRegex, '*'));
        }

        return maskedParts.join(' ');
    }
}
