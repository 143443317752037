import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import {
    ProvidersApiClient,
    ResultFilterProviderModel,
} from '@pkv-frontend/data-domain/result';

import { enterResultPageAction } from '../../../result/store/actions/result.actions';
import { setFilterProvidersAction } from '../actions/filter.actions';

const persistenceKey = 'result.filter-providers';

@Injectable()
export class LoadFilterProvidersEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly storageService: SessionStorageService,
        private readonly providersApiClient: ProvidersApiClient
    ) {}

    public loadFilterProvidersEffect$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(enterResultPageAction),
            map(() => {
                try {
                    return this.storageService.get<ResultFilterProviderModel[]>(
                        persistenceKey
                    );
                } catch (e) {
                    return undefined;
                }
            }),
            exhaustMap((storedProviders: ResultFilterProviderModel[]) => {
                if (storedProviders) {
                    return of(
                        setFilterProvidersAction({ providers: storedProviders })
                    );
                }

                return this.providersApiClient.fetch().pipe(
                    tap((providers: ResultFilterProviderModel[]) => {
                        this.storageService.set(persistenceKey, providers);
                    }),
                    map((providers: ResultFilterProviderModel[]) =>
                        setFilterProvidersAction({ providers })
                    )
                );
            })
        );
    });
}
