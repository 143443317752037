import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';

import { LeadCreationPageService } from '@pkv-frontend/business-domain/checkout';
import { storeMetaReducer } from '@pkv-frontend/infrastructure/store';
import { CallbackAvailabilityEffect } from './store/effects/callback-availability.effect';
import { ConsultantDataEffect } from './store/effects/consultant-data.effect';
import { ToggleAdditionalInfoTrackingEffect } from './store/effects/toggle-additional-infos-tracking-effect.service';
import { consultantCarouselFeatureKey } from './store/models/consultant-carousel-state.model';
import { consultantCarouselReducers } from './store/reducers/consultant-carousel.reducers';

export function storeMetaReducerFactory(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reducer: ActionReducer<any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ActionReducer<any> {
    return storeMetaReducer(consultantCarouselFeatureKey)(reducer);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const metaReducers: Array<MetaReducer<any, any>> = [storeMetaReducerFactory];

@NgModule({
    providers: [LeadCreationPageService],
    imports: [
        EffectsModule.forFeature([
            ConsultantDataEffect,
            CallbackAvailabilityEffect,
            ToggleAdditionalInfoTrackingEffect,
        ]),
        StoreModule.forFeature(
            consultantCarouselFeatureKey,
            consultantCarouselReducers,
            {
                metaReducers,
            }
        ),
    ],
})
export class ConsultantCarouselStoreModule {}
