import { RadioListRowVariantEnum } from '../enums/radio-list-row-variant.enum';
import { FilterRadioListConfig } from '../models/filter-radio-list.config.model';

export const hospitalizationAccommodationServiceLevelSelectionConfig: FilterRadioListConfig =
    {
        radioListVariant: RadioListRowVariantEnum.Single,
        options: [
            {
                label: '1-Bett-Zimmer',
                value: 'single',
            },
            {
                label: '2-Bett-Zimmer und besser',
                value: 'double',
            },
            {
                label: 'egal',
                value: 'multi',
            },
        ],
    };
