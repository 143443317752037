import { createSelector } from '@ngrx/store';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { FilterStateAware } from '../../../filter/store/models/filter-state.model';
import { resultFilterCalculationParametersSelector } from '../../../filter/store/selectors/result-filter-calculation-parameters.selector';
import {
    ResultStateAware,
    ResultStateResult,
} from '../../../result/store/models/result-state.model';

import { resultCalculationResponseSelector } from '../../../result/store/selectors/result-calculation-response.selector';
import { gkvPriceRule } from '../../rules/gkv-price.rule';
import { GkvStateAware } from '../models/gkv-state.model';

import { selectedOrAverageGkvSelector } from './selected-or-average-gkv.selector';

export const gkvPriceSelector = createSelector<
    FilterStateAware & ResultStateAware & GkvStateAware,
    [
        CalculationParameterInterface | undefined,
        ResultStateResult | undefined,
        GkvResultItem | undefined,
    ],
    number | undefined
>(
    resultFilterCalculationParametersSelector,
    resultCalculationResponseSelector,
    selectedOrAverageGkvSelector,
    gkvPriceRule
);
