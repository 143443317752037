import { RiskPremium } from '@pkv-frontend/data-domain/comparison';

const maxAmountOfComparisonItems = 3;

function calculateMonthlyRiskPremiumContributionsRuleImplementation(
    riskPremiums: RiskPremium[]
): number[] {
    const monthlyRiskPremiums: number[] = [];
    for (
        let comparisonItemIndex = 0;
        comparisonItemIndex < maxAmountOfComparisonItems;
        comparisonItemIndex++
    ) {
        const monthlyRiskPremium = riskPremiums.reduce(
            (monthlyContribution: number, riskPremium: RiskPremium) =>
                monthlyContribution +
                riskPremium.contributions[comparisonItemIndex],
            0
        );
        monthlyRiskPremiums.push(monthlyRiskPremium);
    }
    return monthlyRiskPremiums;
}

export function calculateMonthlyRiskPremiumContributionsRule(
    riskPremiums: RiskPremium[]
): number[] {
    return calculateMonthlyRiskPremiumContributionsRuleImplementation(
        riskPremiums
    );
}
