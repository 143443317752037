import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';

const routes: Routes = [
    {
        path: FeatureRouteEnum.Input1,
        loadChildren: () =>
            import('./presentation/pages/input1/input1.module').then(
                (m) => m.Input1Module
            ),
    },
    {
        path: FeatureRouteEnum.PageThankYou,
        loadChildren: () =>
            import('./presentation/pages/thank-you/page-thank-you.module').then(
                (m) => m.PageThankYouModule
            ),
    },
    {
        path: FeatureRouteEnum.RegisterPage,
        loadChildren: () =>
            import('./presentation/pages/register/page-register.module').then(
                (m) => m.PageRegisterModule
            ),
    },
    {
        path: FeatureRouteEnum.ResultPage,
        loadChildren: () =>
            import('./presentation/pages/result/result.module').then(
                (m: { ResultModule }) => m.ResultModule
            ),
    },
    {
        path: FeatureRouteEnum.ComparisonPage,
        loadChildren: () =>
            import(
                './presentation/pages/comparison/page-comparison.module'
            ).then((m) => m.PageComparisonModule),
    },
    {
        path: FeatureRouteEnum.ThirdViewPage,
        loadChildren: () =>
            import('./presentation/pages/third-view/third-view.module').then(
                (m) => m.ThirdViewModule
            ),
        data: {
            tracking: {
                matomo: [
                    {
                        customTitle: location.hostname + '/ThirdView',
                    },
                ],
            },
        },
    },
    {
        path: FeatureRouteEnum.AppointmentBookingPage,
        loadChildren: () =>
            import(
                './presentation/pages/appointment-booking/page-appointment-booking.module'
            ).then((m) => m.PageAppointmentBookingModule),
    },
    {
        path: FeatureRouteEnum.ErrorPage,
        loadChildren: () =>
            import('./presentation/pages/error/page-error.module').then(
                (m) => m.PageErrorModule
            ),
    },
    {
        path: FeatureRouteEnum.UploadPage,
        loadChildren: () =>
            import('./presentation/pages/upload/page-upload.module').then(
                (m) => m.PageUploadModule
            ),
    },
    {
        path: FeatureRouteEnum.DirectConsultationAppointmentBookingPage,
        loadChildren: () =>
            import(
                './presentation/pages/direct-consultation-appointment-booking/page-direct-consultation-appointment-booking.module'
            ).then((m) => m.PageDirectConsultationAppointmentBookingModule),
    },
    {
        path: FeatureRouteEnum.CoBrowsingPresenter,
        loadChildren: () =>
            import(
                './presentation/pages/co-browsing-presenter/co-browsing-presenter.module'
            ).then((m) => m.CoBrowsingPresenterModule),
    },
    {
        path: FeatureRouteEnum.CoBrowsingDocumentsForm,
        loadChildren: () =>
            import(
                './presentation/pages/co-browsing-documents-form/co-browsing-documents-form.module'
            ).then((m) => m.CoBrowsingDocumentsFormModule),
    },
    {
        path: FeatureRouteEnum.CoBrowsingApplicationForm,
        loadChildren: () =>
            import(
                './presentation/pages/co-browsing-application-form/co-browsing-application-form.routes'
            ).then((m) => m.routes),
    },
    {
        path: FeatureRouteEnum.CoBrowsingSignature,
        loadChildren: () =>
            import(
                './presentation/pages/co-browsing-signature/co-browsing-signature.routes'
            ).then((m) => m.routes),
    },
    {
        path: FeatureRouteEnum.CoBrowsingCalculator,
        loadChildren: () =>
            import(
                './presentation/pages/co-browsing-calculator/co-browsing-calculator.routes'
            ).then((m) => m.routes),
    },
    {
        path: FeatureRouteEnum.CoBrowsingFamilyOffer,
        loadChildren: () =>
            import(
                './presentation/pages/co-browsing-family-offer/co-browsing-family-offer.routes'
            ).then((m) => m.routes),
    },
    {
        path: FeatureRouteEnum.FriendAdvertisingReward,
        loadChildren: () =>
            import(
                './presentation/pages/friend-advertising-reward/friend-advertising-reward.module'
            ).then((m) => m.FriendAdvertisingRewardModule),
    },
    {
        path: FeatureRouteEnum.RedirectPage,
        loadChildren: () =>
            import('./presentation/pages/redirect/redirect-page.routes').then(
                (modules) => modules.routes
            ),
    },
    {
        path: FeatureRouteEnum.Termination,
        loadChildren: () =>
            import('./presentation/pages/termination/termination.routes').then(
                (modules) => modules.routes
            ),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
