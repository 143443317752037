import { createAction, props } from '@ngrx/store';
import {
    CoreFeatureToggleEnum,
    Environment,
} from '@pkv-frontend/data-domain/application';
import { ActiveConsultantModel } from '@pkv-frontend/data-domain/consultant';
import { ApplicationState } from '../models/application-state.model';

enum ApplicationActionEnum {
    ApplicationDataInitiated = '[Application] Application data initiated',
    ActiveConsultantDataLoaded = '[Application] Active consultant data loaded',
    SetIsCobrowsing = '[Application] Set is cobrowsing indcator',
    SetEnvironmentAction = '[Application] Set environment',
    CoreFeatureTogglesLoaded = '[Application] Core feature toggles loaded',
}

export const applicationDataInitiatedAction = createAction(
    ApplicationActionEnum.ApplicationDataInitiated,
    props<{ state: Partial<ApplicationState> }>()
);

export const activeConsultantDataLoadedAction = createAction(
    ApplicationActionEnum.ActiveConsultantDataLoaded,
    props<{ activeConsultant: ActiveConsultantModel }>()
);

export const setIsCobrowsingAction = createAction(
    ApplicationActionEnum.SetIsCobrowsing,
    props<{ isCobrowsing: boolean }>()
);

export const setEnvironmentAction = createAction(
    ApplicationActionEnum.SetEnvironmentAction,
    props<{ environment: Environment }>()
);

export const coreFeatureTogglesLoadedAction = createAction(
    ApplicationActionEnum.CoreFeatureTogglesLoaded,
    props<{
        coreFeatureToggles: Record<CoreFeatureToggleEnum, boolean> | null;
    }>()
);
