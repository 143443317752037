import { GeneralTrackingEventTrackingData } from '@vv-ham/ng-tracking';

export const generalTrackingConfig = {
    openConsultantCallbackOverlay: [
        {
            siteId: 79,
            areaId: 'Rueckruf',
        },
        {
            siteId: 92,
            areaId: 'Rueckruf',
            actionId: 607,
        },
    ],
    requestConsultantCallback: [
        {
            siteId: 79,
            areaId: 'Rueckruf',
            actionId: 463,
        },
        {
            siteId: 92,
            areaId: 'Rueckruf',
            actionId: 619,
        },
    ],
    openExpertOverlay: [
        {
            siteId: 79,
            areaId: 'Expertenberatung',
        },
        {
            siteId: 92,
            areaId: 'Expertenberatung',
            actionId: 607,
        },
    ],
} satisfies Record<string, GeneralTrackingEventTrackingData[]>;
