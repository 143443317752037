export enum ProfessionEnum {
    Employee = 'employee',
    Freelancer = 'freelancer',
    Student = 'student',
    Unemployed = 'unemployed',
    Servant = 'servant',
    ServantCandidate = 'servant_candidate',
    Unknown = 'unknown',
    Intern = 'intern',

    // VPs can have the profession child
    Child = 'child',
    ServantChild = 'servant_child',
    All = 'all',
}
