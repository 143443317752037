import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApplicationDataService } from '@pkv-frontend/business-domain/application';

import {
    debugToggleChangeAction,
    ResultDataService,
} from '@pkv-frontend/business-domain/result';
import { CoreFeatureToggleEnum } from '@pkv-frontend/data-domain/application';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import {
    DeviceOutputEnum,
    PhpFrontendDataService,
} from '@pkv-frontend/data-domain/php-frontend-data';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';
import { ToggleColorEnum } from '@pkv-frontend/presentation/ui-toggle';

@Component({
    selector: 'application-state',
    templateUrl: './application-state.component.html',
    styleUrls: ['./application-state.component.less'],
})
export class ApplicationStateComponent {
    constructor(
        private readonly resultDataService: ResultDataService,
        private readonly phpFrontendDataService: PhpFrontendDataService,
        private readonly store$: Store,
        private readonly applicationDataService: ApplicationDataService
    ) {}

    public deviceOutput: DeviceOutputEnum =
        this.phpFrontendDataService.data.deviceOutput;

    public isAlternativeOffer: boolean =
        this.phpFrontendDataService.data.isAlternativeOffer;

    public isConsultantLoggedIn: boolean =
        this.phpFrontendDataService.data.isConsultantLoggedIn;

    public isEffectivePriceEnabled$: Observable<boolean> =
        this.resultDataService.effectivePriceEnabled$;

    public readonly calculationParameter$: Observable<
        CalculationParameterInterface | undefined
    > = this.resultDataService.calculationParameter$;

    public readonly coreFeatureToggles$: Observable<Record<
        string,
        boolean
    > | null> = this.applicationDataService.coreFeatureToggles$;

    public readonly coreFeatureToggleList: CoreFeatureToggleEnum[] =
        Object.values(CoreFeatureToggleEnum);

    public readonly ToggleColorEnum: typeof ToggleColorEnum = ToggleColorEnum;
    public readonly YesNoEnum: typeof YesNoEnum = YesNoEnum;

    public debugToggleChangeAction(enabled: boolean): void {
        this.store$.dispatch(debugToggleChangeAction({ enabled }));
    }
}
