export enum YesNoEnum {
    Yes = 'yes',
    No = 'no',
}

export const boolToYesNo = (value: boolean | undefined | null): YesNoEnum => {
    return value ? YesNoEnum.Yes : YesNoEnum.No;
};

export const yesNoToBool = (value: YesNoEnum): boolean => {
    return value === YesNoEnum.Yes;
};
