import { createSelector } from '@ngrx/store';
import {
    ResultViaEmailState,
    ResultViaEmailStateAware,
} from '../models/result-via-email-state.model';

import { resultViaEmailFeatureSelector } from './result-via-email.feature-selector';

export const requestParameterEmailSelector = createSelector<
    ResultViaEmailStateAware,
    [ResultViaEmailState],
    string | undefined
>(
    resultViaEmailFeatureSelector,
    (state: ResultViaEmailState) => state.requestParameters?.email
);
