import { Injectable } from '@angular/core';

import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';
import {
    LegacyCalculationParameter,
    CalculationParameterInterface,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { QueryParams } from '@pkv-frontend/data-domain/location';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { IsGermanDateFormatRule } from '@pkv-frontend/infrastructure/date';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';

import { queryParameterMap } from '../constants/query-parameter-map.constant';
import { QueryParameterMapTypesEnum } from '../enums/query-parameter-map-types.enum';

const defaultDateFormat = 'yyyy-MM-dd';
const germanDateFormat = 'dd.MM.yyyy';

@Injectable({
    providedIn: 'root',
})
export class ParametersParserService {
    constructor(private isGermanDateFormatRule: IsGermanDateFormatRule) {}

    public downgrade(
        parameters: QueryParams | ResultFilter | CalculationParameterInterface
    ): LegacyCalculationParameter {
        const downgradedParameter = {};

        Object.keys(queryParameterMap).forEach((key: string) => {
            let paramKey: string | undefined;

            if (key in parameters) {
                paramKey = key;
            } else if (queryParameterMap[key].key in parameters) {
                paramKey = queryParameterMap[key].key;
            }

            if (!paramKey) {
                return;
            }

            switch (queryParameterMap[key].type) {
                case QueryParameterMapTypesEnum.Date:
                    if (!parameters[paramKey]) {
                        return;
                    }
                    if (parameters[paramKey] instanceof Date) {
                        downgradedParameter[queryParameterMap[key].key] =
                            format(parameters[paramKey], defaultDateFormat);
                        return;
                    }
                    downgradedParameter[queryParameterMap[key].key] =
                        this.isGermanDateFormatRule.apply(parameters[paramKey])
                            ? format(
                                  parse(
                                      parameters[paramKey],
                                      germanDateFormat,
                                      new Date()
                                  ),
                                  defaultDateFormat
                              )
                            : parameters[paramKey];

                    break;
                case QueryParameterMapTypesEnum.Boolean:
                    downgradedParameter[queryParameterMap[key].key] =
                        parameters[paramKey] ? YesNoEnum.Yes : YesNoEnum.No;
                    break;
                case QueryParameterMapTypesEnum.Array:
                    downgradedParameter[queryParameterMap[key].key + '[]'] =
                        parameters[paramKey];
                    break;
                default:
                    downgradedParameter[queryParameterMap[key].key] =
                        parameters[paramKey] !== undefined
                            ? '' + parameters[paramKey]
                            : '';
            }
        });

        return downgradedParameter;
    }
}
