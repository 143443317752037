export enum ContributionCarrierEnum {
    Association = 'association',
    Bw = 'bw',
    By = 'by',
    Be = 'be',
    Bb = 'bb',
    Hb = 'hb',
    Hh = 'hh',
    He = 'he',
    Mv = 'mv',
    Ni = 'ni',
    Nw = 'nw',
    Rp = 'rp',
    Sl = 'sl',
    Sn = 'sn',
    St = 'st',
    Sh = 'sh',
    Th = 'th',
}
