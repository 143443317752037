import { createSelector } from '@ngrx/store';
import { Consultant } from '@pkv-frontend/data-domain/result';
import {
    ConsultantCarouselState,
    ConsultantCarouselStateAware,
} from '../models/consultant-carousel-state.model';

import { consultantCarouselStateSelector } from './consultant-carousel-state.selector';

export const consultantsSelector = createSelector<
    ConsultantCarouselStateAware,
    [ConsultantCarouselState],
    Consultant[] | undefined
>(
    consultantCarouselStateSelector,
    (state: ConsultantCarouselState): Consultant[] | undefined => {
        if (!state.consultants?.length) {
            return undefined;
        }
        return [
            state.consultants[
                state.controls.index <= 0
                    ? state.consultants.length - 1
                    : state.controls.index - 1
            ],
            state.consultants[state.controls.index],
            state.consultants[
                (state.controls.index + 1) % state.consultants.length
            ],
        ];
    }
);
