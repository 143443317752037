import { createSelector } from '@ngrx/store';
import {
    ResultApiParametersModel,
    SortApiParametersModel,
    SortOrderEnum,
} from '@pkv-frontend/data-domain/result';

import { FilterStateAware } from '../models/filter-state.model';

import { resultFilterSortApiParametersSelector } from './result-filter-sort-api-parameters.selector';

export const resultFilterSortorderSelector = createSelector<
    FilterStateAware,
    [SortApiParametersModel | undefined],
    SortOrderEnum | undefined
>(
    resultFilterSortApiParametersSelector,
    (apiParameters: ResultApiParametersModel | undefined) =>
        apiParameters?.sortorder
);
