import { createSelector } from '@ngrx/store';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { FilterStateAware } from '../../../filter/store/models/filter-state.model';

import { resultFilterCalculationParametersSelector } from '../../../filter/store/selectors/result-filter-calculation-parameters.selector';
import { gkvPriceTextRule } from '../../rules/gkv-price-text.rule';

export const gkvPriceTextSelector = createSelector<
    FilterStateAware,
    [CalculationParameterInterface | undefined],
    string
>(resultFilterCalculationParametersSelector, gkvPriceTextRule);
