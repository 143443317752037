import { ContributionCarrierEnum } from '@pkv-frontend/data-domain/calculation-parameter';

export type ContributionCarrierTranslation = {
    [key in ContributionCarrierEnum]: string;
};

export const contributionCarrierTranslationConstants: ContributionCarrierTranslation =
    {
        [ContributionCarrierEnum.Association]: 'Bund',
        [ContributionCarrierEnum.Bw]: 'Baden-Württemberg',
        [ContributionCarrierEnum.By]: 'Bayern',
        [ContributionCarrierEnum.Be]: 'Berlin',
        [ContributionCarrierEnum.Bb]: 'Brandenburg',
        [ContributionCarrierEnum.Hb]: 'Bremen',
        [ContributionCarrierEnum.Hh]: 'Hamburg',
        [ContributionCarrierEnum.He]: 'Hessen',
        [ContributionCarrierEnum.Mv]: 'Mecklenburg-Vorpommern',
        [ContributionCarrierEnum.Ni]: 'Niedersachsen',
        [ContributionCarrierEnum.Nw]: 'Nordrhein-Westfalen',
        [ContributionCarrierEnum.Rp]: 'Rheinland-Pfalz',
        [ContributionCarrierEnum.Sl]: 'Saarland',
        [ContributionCarrierEnum.Sn]: 'Sachsen',
        [ContributionCarrierEnum.St]: 'Sachsen-Anhalt',
        [ContributionCarrierEnum.Sh]: 'Schleswig-Holstein',
        [ContributionCarrierEnum.Th]: 'Thüringen',
    };
