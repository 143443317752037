import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
    PhoneNumberApiValidator,
    phoneNumberSchemaValidator,
} from '@pkv-frontend/business-domain/form';

import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';
import { OverlayFormEnum } from '../enums/overlay-form.enum';

export type ConsultantCallbackFormGroup = ReturnType<
    ConsultantCallbackFormService['createConsultantCallbackForm']
>;

@Injectable({
    providedIn: 'root',
})
export class ConsultantCallbackFormService {
    constructor(
        private formBuilder: FormBuilder,
        private phoneNumberApiValidator: PhoneNumberApiValidator
    ) {}

    public createConsultantCallbackForm(prefillData?: SsoUserDataModel) {
        return this.formBuilder.group(
            {
                [OverlayFormEnum.MobileNumber]: [
                    prefillData?.mobileNumber,
                    {
                        validators: [
                            Validators.required,
                            phoneNumberSchemaValidator(),
                        ],
                        asyncValidators: [
                            this.phoneNumberApiValidator.validate.bind(
                                this.phoneNumberApiValidator
                            ),
                        ],
                    },
                ],
            },
            { updateOn: 'change' }
        );
    }
}
