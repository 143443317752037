import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

export type FormValues = {
    [key: string]: unknown;
}[];

@Injectable({
    providedIn: 'root',
})
export class PatchFormValuesService {
    public patchValues(form: FormGroup, currentValues: FormValues): void {
        Object.keys(currentValues).forEach((key: string) => {
            form.patchValue(
                { [key]: currentValues[key] },
                { emitEvent: false }
            );
        });
    }
}
