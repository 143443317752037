import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultFilterProviderModel } from '../filter/models/result-filter-provider.model';

const url = '/api/v1/public/result/providers';

@Injectable({
    providedIn: 'root',
})
export class ProvidersApiClient {
    constructor(private readonly httpClient: HttpClient) {}

    public fetch(): Observable<ResultFilterProviderModel[]> {
        return this.httpClient.get<ResultFilterProviderModel[]>(url);
    }
}
