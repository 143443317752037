import { createSelector } from '@ngrx/store';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { GkvStateAware } from '../models/gkv-state.model';

import { averageGkvSelector } from './average-gkv.selector';
import { selectedGkvSelector } from './selected-gkv.selector';

export const selectedOrAverageGkvSelector = createSelector<
    GkvStateAware,
    [GkvResultItem | undefined, GkvResultItem | undefined],
    GkvResultItem | undefined
>(
    selectedGkvSelector,
    averageGkvSelector,
    (
        selectedGkv: GkvResultItem | undefined,
        averageGkv: GkvResultItem | undefined
    ) => selectedGkv || averageGkv
);
