import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ApplicationDataService } from '@pkv-frontend/business-domain/application';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import {
    isGkvResultItemRule,
    ResultItem,
} from '@pkv-frontend/data-domain/result';
import {
    addTariffsToComparisonAction,
    fetchTariffsWithEyes,
    removeAllTariffsFromComparisonAction,
    removeGkvTariffFromComparisonAction,
    removeTariffsFromComparisonAction,
    setComparisonBarVisibilityAction,
    toggleComparisonBarAction,
} from '../state/actions/comparison.actions';
import { ComparisonTariffItem } from '../state/models/comparison-tariff-item.model';
import { comparisonBarVisibilitySelector } from '../state/selectors/comparison-bar-visibility.selector';
import { comparisonMaximumTariffsReachedSelector } from '../state/selectors/comparison-maximum-tariffs-reached.selector';
import { comparisonTariffHasEyeIconSelector } from '../state/selectors/comparison-tariff-has-eye-icon.selector';
import { comparisonTariffsSelector } from '../state/selectors/comparison-tariffs.selector';
import { showComparisonBarSelector } from '../state/selectors/show-comparison-bar.selector';
import { ComparisonPersistenceService } from './comparison-persistence.service';

import { ComparisonTariffItemBuilder } from './comparison-tariff-item.builder';
import { ComparisonUrlBuilder } from './comparison-url.builder';

@Injectable({
    providedIn: 'root',
})
export class ComparisonDataService {
    public readonly isAnyItemInComparison$: Observable<boolean> = this.store$
        .select(comparisonTariffsSelector)
        .pipe(
            map(
                (comparisonTariffs: ComparisonTariffItem[]) =>
                    comparisonTariffs.length > 0
            )
        );
    public readonly isMaximumComparisonAmountReached$: Observable<boolean> =
        this.store$.select(comparisonMaximumTariffsReachedSelector);

    public readonly comparisonTariffItems$: Observable<ComparisonTariffItem[]> =
        this.store$.select(comparisonTariffsSelector);

    public readonly showComparisonBar$: Observable<boolean> =
        this.store$.select(showComparisonBarSelector);

    public readonly comparisonBarVisibility$: Observable<boolean> =
        this.store$.select(comparisonBarVisibilitySelector);

    public readonly comparisonUrl$ = this.comparisonTariffItems$.pipe(
        filter(
            (comparisonTariffItems: ComparisonTariffItem[]) =>
                !!comparisonTariffItems.length
        ),
        withLatestFrom(
            this.activatedRoute.queryParams,
            this.applicationDataService.isAlternativeOffer$,
            this.applicationDataService.isTariffChange$,
            this.applicationDataService.initialProviderId$
        ),
        map(
            ([
                comparisonTariffItems,
                calculationParameter,
                isAlternativeOffer,
                isTariffChange,
                initialProviderId,
            ]: [
                ComparisonTariffItem[],
                CalculationParameterInterface,
                boolean,
                boolean,
                number,
            ]) =>
                this.comparisonUrlBuilder.buildUrl(
                    comparisonTariffItems,
                    calculationParameter,
                    isAlternativeOffer,
                    isTariffChange,
                    initialProviderId
                )
        )
    );

    constructor(
        private readonly store$: Store,
        private readonly comparisonTariffItemBuilder: ComparisonTariffItemBuilder,
        private readonly applicationDataService: ApplicationDataService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly comparisonUrlBuilder: ComparisonUrlBuilder,
        private readonly comparisonUpdatePersistenceService: ComparisonPersistenceService,
        private readonly sessionStorageService: SessionStorageService
    ) {}

    public loadTariffsWithEyes(): void {
        this.store$.dispatch(fetchTariffsWithEyes());
    }

    public getShowEyeIcon$(tariffVersionId: number): Observable<boolean> {
        return this.store$.select(
            comparisonTariffHasEyeIconSelector(tariffVersionId)
        );
    }

    public getTariffComparisonLink$(tariff: ResultItem) {
        return this.comparisonTariffItems$.pipe(
            withLatestFrom(
                of(tariff),
                this.activatedRoute.queryParams,
                this.applicationDataService.isAlternativeOffer$,
                this.applicationDataService.isTariffChange$,
                this.applicationDataService.initialProviderId$
            ),
            map(
                ([
                    comparisonTariffItems,
                    tariff,
                    calculationParameter,
                    isAlternativeOffer,
                    isTariffChange,
                    initialProviderId,
                ]: [
                    ComparisonTariffItem[],
                    ResultItem,
                    CalculationParameterInterface,
                    boolean,
                    boolean,
                    number,
                ]) => {
                    const newComparisonTariffItems = [...comparisonTariffItems];
                    if (newComparisonTariffItems.length < 3) {
                        newComparisonTariffItems.push(
                            this.comparisonTariffItemBuilder.buildComparisonTariffByResultItem(
                                tariff
                            )
                        );
                    }
                    return this.comparisonUrlBuilder.buildUrl(
                        newComparisonTariffItems,
                        calculationParameter,
                        isAlternativeOffer,
                        isTariffChange,
                        initialProviderId
                    );
                }
            )
        );
    }

    public addTariffToComparison(
        tariffItems: (ResultItem | GkvResultItem)[]
    ): void {
        const preparedTariffItems: ComparisonTariffItem[] = [];

        tariffItems.forEach((tariff: ResultItem | GkvResultItem) => {
            if (isGkvResultItemRule(tariff)) {
                preparedTariffItems.push(
                    this.comparisonTariffItemBuilder.buildComparisonTariffByGkvItem(
                        tariff
                    )
                );
                return;
            }
            preparedTariffItems.push(
                this.comparisonTariffItemBuilder.buildComparisonTariffByResultItem(
                    tariff
                )
            );
        });

        this.store$.dispatch(
            addTariffsToComparisonAction({ tariffItems: preparedTariffItems })
        );
    }

    public removeTariffsFromComparison(tariffVersionIds: number[]): void {
        this.store$.dispatch(
            removeTariffsFromComparisonAction({ tariffVersionIds })
        );
    }

    public removeAllTariffsFromComparison(): void {
        this.store$.dispatch(removeAllTariffsFromComparisonAction());
    }

    public toggleComparisonBar(): void {
        this.store$.dispatch(toggleComparisonBarAction());
    }

    public removeGkvTariffFromComparison(): void {
        this.store$.dispatch(removeGkvTariffFromComparisonAction());
    }

    public setComparisonBarVisibility(isVisible: boolean): void {
        this.store$.dispatch(setComparisonBarVisibilityAction({ isVisible }));
    }

    public removeOnBacklinkClick(): void {
        const tariffChosenViaCta: number | undefined =
            this.sessionStorageService.get('comparisonManager.viaCta');
        if (!tariffChosenViaCta) {
            return;
        }
        this.comparisonUpdatePersistenceService.removeOne(tariffChosenViaCta);
        this.removeCtaClickedStorage();
    }

    public removeCtaClickedStorage(): void {
        this.sessionStorageService.remove('comparisonManager.viaCta');
    }

    public addCtaClickedStorage(versionId: number): void {
        this.sessionStorageService.set('comparisonManager.viaCta', versionId);
    }
}
