import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';

const url = '/api/v1/public/lastsearch';

@Injectable({ providedIn: 'root' })
export class LastSearchApiService {
    constructor(private httpClient: HttpClient) {}

    public persist(lastSearch: Params): Observable<void> {
        return this.httpClient
            .post<void>(url, lastSearch)
            .pipe(
                catchError(
                    (error: HttpErrorResponse): Observable<never> =>
                        error.status !== 400 ? throwError(error) : EMPTY
                )
            );
    }

    public update(): Observable<void> {
        return this.httpClient
            .put<void>(url, {})
            .pipe(
                catchError(
                    (error: HttpErrorResponse): Observable<never> =>
                        error.status !== 400 ? throwError(error) : EMPTY
                )
            );
    }
}
