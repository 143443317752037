import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: '[pkv-button]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.less'],
    standalone: true,
    imports: [CommonModule],
})
export class ButtonComponent {}
