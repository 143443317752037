import { Injectable } from '@angular/core';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import { UrlService } from './url.service';

// Todo: Remove deprecated keys in PKV-4873
// On production deploy, a reload (e.g., SSO) on the register page may double-trigger tracking.
const deprecatedDesktopSessionKey = 'c24.pkv.desktop.previousUrl';
const deprecatedMobileSessionKey = 'c24.pkv.mobile.previousUrl';

const storageKey = 'c24.pkv.previousUrl';

@Injectable()
export class PreviousUrlPersistenceService {
    constructor(
        private persistenceService: SessionStorageService,
        private urlService: UrlService
    ) {}

    public set(): void {
        this.persistenceService.set(
            storageKey,
            this.urlService.previousUrlWithoutQueryParams
        );
    }

    public setCurrentUrl(): void {
        this.persistenceService.set(
            storageKey,
            this.urlService.currentUrlWithoutQueryParams
        );
    }

    public get(): string | undefined {
        return (
            this.persistenceService.get(storageKey) ??
            this.persistenceService.get(deprecatedDesktopSessionKey) ??
            this.persistenceService.get(deprecatedMobileSessionKey)
        );
    }
}
