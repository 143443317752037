import { createSelector } from '@ngrx/store';
import { TariffInfo } from '@pkv-frontend/data-domain/result';
import { ResultStateAware } from '../models/result-state.model';

import { silentResultCalculationResponseSelector } from './silent-result-calculation-response.selector';

export const silentResultTariffInfoSelector = createSelector<
    ResultStateAware,
    [ReturnType<typeof silentResultCalculationResponseSelector>],
    TariffInfo | undefined
>(
    silentResultCalculationResponseSelector,
    (
        silentResult: ReturnType<typeof silentResultCalculationResponseSelector>
    ) => {
        return silentResult?.tariffinfo;
    }
);
