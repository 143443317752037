import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
    CalculationParameterInterface,
    LegacyCalculationParameter,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { CalculationParameterQueryParserService } from '../calculation-parameter-query-parser/calculation-parameter-query-parser.service';
import { ParametersParserService } from '../parameter-parser.service';

@Injectable({
    providedIn: 'root',
})
export class LegacyCalculationParameterQueryParserService {
    constructor(
        private parametersParserService: ParametersParserService,
        private calculationParameterQueryParser: CalculationParameterQueryParserService
    ) {}
    public parse(params: Params): LegacyCalculationParameter {
        const calculationParameters: CalculationParameterInterface =
            this.calculationParameterQueryParser.parse(params);
        return this.parametersParserService.downgrade(calculationParameters);
    }
}
