import { ChangeDetectionStrategy, Component, signal } from '@angular/core';

@Component({
    selector: 'development-toolbar',
    templateUrl: './development-toolbar.component.html',
    styleUrls: ['./development-toolbar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevelopmentToolbarComponent {
    public isOpen = signal(false);
}
