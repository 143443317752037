import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CalculateYearlySavingsService {
    public calculate(contribution: number, total: number): number {
        if (total < contribution) {
            return 0;
        }

        return total - contribution;
    }
}
