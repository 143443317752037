import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { ElementIntersectionObserver } from './element-intersection-observer.service';
import { IntersectionStatusEnum } from './intersection-status.enum';

@Directive({
    selector: '[pkvFrontendIntersectionObserver]',
    providers: [ElementIntersectionObserver],
    standalone: true,
})
export class IntersectionObserverDirective implements OnInit, OnDestroy {
    @Input() public intersectionRootMargin = '0px';
    @Input() public intersectionRoot: HTMLElement | undefined;
    @Input() public intersectionThreshold: number | number[] = [0, 1];
    @Input() public intersectionDebounceTime = 0;

    @Output()
    public visibilityChange: EventEmitter<IntersectionStatusEnum> =
        new EventEmitter<IntersectionStatusEnum>();

    private readonly destroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly element: ElementRef,
        private readonly intersectionObserver: ElementIntersectionObserver
    ) {}

    public ngOnInit(): void {
        const element = this.element.nativeElement;

        const config: IntersectionObserverInit = {
            root: this.intersectionRoot,
            rootMargin: this.intersectionRootMargin,
            threshold: this.intersectionThreshold,
        };

        this.intersectionObserver
            .create(element, config)
            .pipe(
                debounceTime(this.intersectionDebounceTime),
                takeUntil(this.destroy$)
            )
            .subscribe((status: IntersectionStatusEnum) => {
                this.visibilityChange.emit(status);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
