import { createSelector } from '@ngrx/store';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterChipsSelector = createSelector<
    FilterStateAware,
    [FilterState],
    string[]
>(filterStateFeatureSelector, (state: FilterState) => state.filterChips);
