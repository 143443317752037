import { Injectable } from '@angular/core';
import { GenderEnum } from '@pkv-frontend/data-domain/consultant';
import { Consultant } from '@pkv-frontend/data-domain/result';
import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';

@Injectable({
    providedIn: 'root',
})
export class QuotePersonalizationService {
    public buildQuote(
        consultant: Consultant,
        ssoUserData: SsoUserDataModel | null
    ): string {
        if (consultant.isPersonalized && ssoUserData?.lastName) {
            return this.buildPersonalizedQuote(consultant.quote, ssoUserData);
        }

        return consultant.quote;
    }

    public buildPersonalizedQuote(
        quote: string,
        ssoUserData: SsoUserDataModel
    ): string {
        const salutation =
            ssoUserData.gender === GenderEnum.Male ? 'Herr' : 'Frau';

        const lowerCasedQuote = quote.charAt(0).toLowerCase() + quote.slice(1);

        if (!ssoUserData.gender) {
            return `Hallo ${ssoUserData.firstName} ${ssoUserData.lastName}, ${lowerCasedQuote}`;
        }

        return `Hallo ${salutation} ${ssoUserData.lastName}, ${lowerCasedQuote}`;
    }
}
