import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ActivityScoreService } from '@pkv-frontend/business-domain/activity-score';
import { SsoUserDataService } from '@pkv-frontend/business-domain/sso-user-data';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';
import { notUndefinedOrNull } from '@pkv-frontend/infrastructure/type-guards';
import { ResultDataService } from '../../services/result-data.service';
import { enterResultPageAction } from '../actions/result.actions';

@Injectable()
export class AddActivityScoreEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly activityScoreService: ActivityScoreService,
        private readonly ssoService: SsoUserDataService,
        private readonly resultDataService: ResultDataService
    ) {}

    public readonly addComparisonActivityScore$: Observable<void> =
        createEffect(
            () => {
                return this.actions$.pipe(
                    ofType(enterResultPageAction),
                    switchMap(() => this.ssoService.deprecatedSsoUserData$),
                    filter(notUndefinedOrNull),
                    concatLatestFrom(
                        () => this.resultDataService.calculationParameter$
                    ),
                    filter(
                        ([, calculationParameter]) => !!calculationParameter
                    ),
                    switchMap(
                        ([, calculationParameter]: [
                            SsoUserDataModel,
                            CalculationParameterInterface,
                        ]) =>
                            this.activityScoreService.addComparisonActivityScore(
                                calculationParameter
                            )
                    )
                );
            },
            { dispatch: false }
        );
}
