import { FollowUpTariffContent } from '../models/follow-up-tariff-content.model';

export const followUpTariffContentConstant: FollowUpTariffContent = {
    label: 'Folgetarif',
    rowCaption: 'Bestmöglicher Folgetarif nach der Ausbildung',
    emptyCellCaption: 'Kein Folgetarif im Vergleich',
    tooltip: `Einige Tarife bieten bei Verbeamtung die Möglichkeit, 
        ohne erneute Gesundheitsprüfung in einen besseren Versicherungsschutz zu wechseln. 
        Ist dies der Fall, zeigen wir den bestmöglichen Versicherungsschutz an.<br>
        Sofern kein Folgetarif hinterlegt ist, kann unsere Kundenberatung 
        Ihnen weitere Informationen zur späteren Absicherung geben.`,
    notDepositedTooltip: `Für diesen Tarif haben wir keinen Folgetarif hinterlegt.<br>
        Unsere Kundenberatung kann Ihnen aber weitere Informationen zur späteren Absicherung geben.`,
};
