import { Injectable } from '@angular/core';

import { LeadCreationPageEnum } from '@pkv-frontend/data-domain/lead-expert';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

import { featureRouteToCreationPageMappings } from '../../constants/feature-route-to-creation-page.mappings';

@Injectable({
    providedIn: 'root',
})
export class LeadCreationPageService {
    constructor(private windowReferenceService: WindowReferenceService) {}

    public getFromUrl(): LeadCreationPageEnum | undefined {
        const pathname = this.prepareForSearch(
            this.windowReferenceService.nativeWindow.location.pathname
        );

        for (const entry of featureRouteToCreationPageMappings) {
            if (pathname.search(this.prepareForSearch(entry.route)) === 0) {
                return entry.creationPage;
            }
        }

        return undefined;
    }

    private prepareForSearch(value: string): string {
        value = value.replace(/^\/+|\/+$/, '');
        value += '/';
        return value;
    }
}
