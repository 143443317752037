import { createSelector } from '@ngrx/store';
import {
    ResultApiParametersModel,
    SortApiParametersModel,
    SortFieldEnum,
} from '@pkv-frontend/data-domain/result';

import { FilterStateAware } from '../models/filter-state.model';

import { resultFilterSortApiParametersSelector } from './result-filter-sort-api-parameters.selector';

export const resultFilterSortfieldSelector = createSelector<
    FilterStateAware,
    [SortApiParametersModel | undefined],
    SortFieldEnum | undefined
>(
    resultFilterSortApiParametersSelector,
    (apiParameters: ResultApiParametersModel | undefined) =>
        apiParameters?.sortfield
);
