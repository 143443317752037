import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
    ComparisonPersistenceService,
    ComparisonTariffItem,
    addTariffsToComparisonAction,
} from '@pkv-frontend/business-domain/comparison';

import { resultApiResultsLoadedAction } from '../actions/result.actions';

@Injectable()
export class LoadComparisonTariffsFromStorageEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly comparisonPersistenceService: ComparisonPersistenceService
    ) {}

    public readonly loadComparisonTariffsFromStorageEffect$: Observable<Action> =
        createEffect(() => {
            return this.actions$.pipe(
                ofType(resultApiResultsLoadedAction),
                take(1),
                map(() => this.comparisonPersistenceService.getAll()),
                filter(
                    (tariffItems: ComparisonTariffItem[] | undefined) =>
                        !!tariffItems?.length
                ),
                map((tariffItems: ComparisonTariffItem[]) =>
                    addTariffsToComparisonAction({ tariffItems })
                )
            );
        });
}
