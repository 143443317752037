import { createSelector } from '@ngrx/store';
import {
    ConsultantCarouselState,
    ConsultantCarouselStateAware,
} from '../models/consultant-carousel-state.model';

import { consultantCarouselStateSelector } from './consultant-carousel-state.selector';
export const shouldShowAdditionalInfoSelector = (carouselId: number) =>
    createSelector<
        ConsultantCarouselStateAware,
        [ConsultantCarouselState],
        boolean
    >(
        consultantCarouselStateSelector,
        (state: ConsultantCarouselState): boolean =>
            state.shouldShowAdditionalInfo?.[carouselId] ?? false
    );
