import { Injectable } from '@angular/core';

import {
    InsuredPersonEnum,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { QueryParams } from '@pkv-frontend/data-domain/location';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';

@Injectable({
    providedIn: 'root',
})
export class GetCustomerProfessionRule {
    private readonly professionKey: string = 'profession';
    private readonly insuredPersonKey: string = 'insured_person';
    private readonly servantOrServantCandidateKey: string =
        'parent_servant_or_servant_candidate';

    public apply(queryParams: QueryParams): ProfessionEnum | undefined {
        if (queryParams[this.insuredPersonKey] === InsuredPersonEnum.Child) {
            return queryParams[this.servantOrServantCandidateKey] ===
                YesNoEnum.Yes
                ? ProfessionEnum.ServantChild
                : ProfessionEnum.Child;
        }

        const professionQueryValue: ProfessionEnum = queryParams[
            this.professionKey
        ] as ProfessionEnum;
        if (Object.values(ProfessionEnum).includes(professionQueryValue)) {
            return professionQueryValue;
        }

        return undefined;
    }
}
