@let coreFeatureToggles = coreFeatureToggles$ | async;
@let calculationParameter = calculationParameter$ | async;

<p>deviceOutput: {{ deviceOutput }}</p>
<p>isConsultantLoggedIn: {{ isConsultantLoggedIn }}</p>
<p>isEffectivePriceEnabled: {{ isEffectivePriceEnabled$ | async }}</p>
<p>isAlternativeOffer: {{ isAlternativeOffer }}</p>
<p>coreFeatureToggles:</p>
<ul>
    @for (featureToggle of coreFeatureToggleList; track featureToggle) {
        <li>
            <p>{{ featureToggle }}:</p>

            @if (coreFeatureToggles) {
                @if (coreFeatureToggles[featureToggle]) {
                    <c24-ng-svg-icon-tick-circle-outline color="color-light"></c24-ng-svg-icon-tick-circle-outline>
                } @else {
                    <c24-ng-svg-icon-cross-circle color="color-light"></c24-ng-svg-icon-cross-circle>
                }
            } @else {
                <c24-ng-svg-icon-circle color="color-light"></c24-ng-svg-icon-circle>
            }
        </li>
    }
</ul>
<p>
    debug:
    @if (calculationParameter) {
        <ui-shared-toggle
            [color]="ToggleColorEnum.Positive"
            [isEnabled]="calculationParameter.debug || false"
            (click)="debugToggleChangeAction(!calculationParameter.debug)"
        ></ui-shared-toggle>
    }
</p>
