import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

export interface ControlToAdd {
    controlName: string;
    control: AbstractControl;
}

@Injectable({ providedIn: 'root' })
export class FormControlsService {
    public static setEnabled(
        control: AbstractControl,
        enable: boolean,
        emitEvent = true
    ) {
        if (enable) {
            control.enable({ emitEvent });
        } else {
            control.disable({ emitEvent });
        }
    }

    public removeControls(form: FormGroup, controlNames: string[]): void {
        controlNames.forEach((controlName: string) => {
            if (form.contains(controlName)) {
                form.removeControl(controlName, {
                    emitEvent: false,
                });
            }
        });
    }

    public addControls(form: FormGroup, controlsToAdd: ControlToAdd[]): void {
        controlsToAdd.forEach((controlToAdd: ControlToAdd) => {
            if (!form.contains(controlToAdd.controlName)) {
                form.addControl(
                    controlToAdd.controlName,
                    controlToAdd.control,
                    {
                        emitEvent: false,
                    }
                );
            }
        });
    }

    public addControlsSilently(
        form: FormGroup,
        controlsToAdd: ControlToAdd[]
    ): void {
        controlsToAdd.forEach((controlToAdd: ControlToAdd) => {
            if (!form.contains(controlToAdd.controlName)) {
                form.addControl(
                    controlToAdd.controlName,
                    controlToAdd.control,
                    { emitEvent: false }
                );
            }
        });
    }

    public removeControlsSilently(
        form: FormGroup,
        controlNames: string[]
    ): void {
        controlNames.forEach((controlName: string) => {
            if (form.contains(controlName)) {
                form.removeControl(controlName, { emitEvent: false });
            }
        });
    }
}
