import { createSelector } from '@ngrx/store';
import { ConsultantCookieDataModel } from '@pkv-frontend/data-domain/consultant';
import {
    ApplicationState,
    ApplicationStateAware,
} from '../models/application-state.model';
import { applicationStateSelector } from './application-state.selector';

export const consultantCookieDataSelector = createSelector<
    ApplicationStateAware,
    [ApplicationState],
    ConsultantCookieDataModel | undefined
>(
    applicationStateSelector,
    (state: ApplicationState): ConsultantCookieDataModel | undefined =>
        state.consultantCookieData
);
