import { MatomoEventTrackingData } from '@vv-ham/ng-tracking';
import { ConsultantCarouselTrackingConfigEnum } from '../enums/consultant-carousel-tracking-config.enum';

export type ConsultantCarouselTrackingConfigConstant = {
    [key in ConsultantCarouselTrackingConfigEnum]: MatomoEventTrackingData;
};

export const consultantCarouselTrackingConfig: ConsultantCarouselTrackingConfigConstant =
    {
        [ConsultantCarouselTrackingConfigEnum.ConsultantCarouselArrowAction]: {
            category: 'Resultpage',
            name: 'result-tariff-item_icon-consultant-arrow',
            action: '', // action is defined at runtime
        },
        [ConsultantCarouselTrackingConfigEnum.ConsultantCarouselInfoAction]: {
            category: 'Resultpage',
            name: 'result-tariff-item_c24_consultant_content-button',
            action: '', // action is defined at runtime
        },
        [ConsultantCarouselTrackingConfigEnum.ConsultantCarouselCtaInstantAppointment]:
            {
                category: 'Expert',
                name: 'expert_carousel_cta_instant-appointment',
                action: 'select',
            },
        [ConsultantCarouselTrackingConfigEnum.ConsultantCarouselCtaAppointment]:
            {
                category: 'Expert',
                name: 'expert_carousel_cta_appointment',
                action: 'select',
            },
    };
