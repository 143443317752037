import { Injectable } from '@angular/core';
import { Observable, Subject, Subscriber } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { IntersectionStatusEnum } from './intersection-status.enum';

@Injectable({ providedIn: 'root' })
export class ElementIntersectionObserver {
    public create(
        element: HTMLElement,
        config?: IntersectionObserverInit
    ): Observable<IntersectionStatusEnum> {
        return this.fromIntersectionObserver(element, config);
    }

    private fromIntersectionObserver(
        element: HTMLElement,
        config?: IntersectionObserverInit
    ): Observable<IntersectionStatusEnum> {
        const destroy$: Subject<void> = new Subject<void>();
        const subject$: Subject<IntersectionObserverEntry> =
            new Subject<IntersectionObserverEntry>();

        return new Observable<IntersectionStatusEnum>(
            (subscriber: Subscriber<IntersectionStatusEnum>) => {
                const intersectionObserver = new IntersectionObserver(
                    (entries: IntersectionObserverEntry[]) => {
                        entries.forEach((entry: IntersectionObserverEntry) => {
                            subject$.next(entry);
                        });
                    },
                    config
                );

                subject$
                    .pipe(distinctUntilChanged(), takeUntil(destroy$))
                    .subscribe((entry: IntersectionObserverEntry) => {
                        subscriber.next(
                            entry.isIntersecting
                                ? entry.intersectionRatio < 1
                                    ? IntersectionStatusEnum.Partial
                                    : IntersectionStatusEnum.Visible
                                : IntersectionStatusEnum.NotVisible
                        );
                    });

                intersectionObserver.observe(element);

                return {
                    unsubscribe: (): void => {
                        intersectionObserver.disconnect();
                        destroy$.next();
                        destroy$.complete();
                    },
                };
            }
        ).pipe(takeUntil(destroy$));
    }
}
