import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LeadCreationTypeEnum } from '@pkv-frontend/business-domain/lead';
import { AppointmentBookingDataResponseModel } from '@pkv-frontend/data-domain/lead-expert';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import { ConsultantOverlayCloseByTypeEnum } from '../enums/consultant-overlay-close-by-type.enum';
import {
    backToForm,
    closeOverlay,
    openOverlay,
} from '../store/actions/consultant-callback-overlay.actions';
import { appointmentBookingDataSelector } from '../store/selectors/appointment-booking-data.selector';
import { leadCreationRequestStateSelector } from '../store/selectors/lead-creation-request-state.selector';

@Injectable({ providedIn: 'root' })
export class ConsultantCallbackOverlayDataService {
    public readonly appointmentBookingData$: Observable<
        AppointmentBookingDataResponseModel | undefined
    > = this.store$.select(appointmentBookingDataSelector);

    public readonly leadCreationState$: Observable<
        RequestStateEnum | undefined
    > = this.store$.select(leadCreationRequestStateSelector);

    public readonly leadCreationNotPending$: Observable<boolean> =
        this.leadCreationState$.pipe(
            map(
                (state: RequestStateEnum | undefined) =>
                    state !== RequestStateEnum.Pending
            )
        );

    constructor(private readonly store$: Store) {}

    public openOverlay(leadCreationType: LeadCreationTypeEnum): void {
        this.store$.dispatch(openOverlay({ leadCreationType }));
    }

    public closeOverlay(closeByType: ConsultantOverlayCloseByTypeEnum): void {
        this.store$.dispatch(closeOverlay({ closeByType }));
    }

    public backToForm(leadCreationType: LeadCreationTypeEnum): void {
        this.store$.dispatch(backToForm({ leadCreationType }));
    }
}
