import { Inject, Injectable } from '@angular/core';
import {
    DEVICE_ENVIRONMENT_TOKEN,
    DeviceEnvironmentEnum,
} from '@pkv-frontend/infrastructure/interfaces';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';
import { ChatFlowEntryEnum } from '../enums/chat-flow-entry.enum';
import { ChattyConfig } from '../models/chatty/chatty-config.interface';

@Injectable({ providedIn: 'root' })
export class Check24AppService {
    constructor(
        private readonly windowReferenceService: WindowReferenceService,
        @Inject(DEVICE_ENVIRONMENT_TOKEN)
        private readonly deviceEnvironment: DeviceEnvironmentEnum
    ) {}

    public setChattyConfig(
        config: ChattyConfig,
        chatFlowEntry: ChatFlowEntryEnum
    ): void {
        if (!this.windowReferenceService.nativeWindow.Check24) {
            return;
        }

        this.windowReferenceService.nativeWindow.Check24.loader.useChatbotApp =
            true;
        if (
            chatFlowEntry !== ChatFlowEntryEnum.StartWireframeAll &&
            this.deviceEnvironment === DeviceEnvironmentEnum.Desktop
        ) {
            this.windowReferenceService.nativeWindow.Check24.loader.addChatbotButton(
                config,
                true
            );
        }
        this.windowReferenceService.nativeWindow.Check24.loader.setContactLayer(
            config,
            true
        );
    }
}
