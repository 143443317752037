import { Inject, Injectable } from '@angular/core';
import { MatomoEventTrackingData } from '@vv-ham/ng-tracking';
import {
    DEVICE_ENVIRONMENT_TOKEN,
    DeviceEnvironmentEnum,
} from '@pkv-frontend/infrastructure/interfaces';
import { ConsultantOverlayTrackingCategoryEnum } from '../enums/consultant-overlay-tracking-category.enum';
import { ConsultantOverlayTrackingConfigEnum } from '../enums/consultant-overlay-tracking-config.enum';

export type ConsultantOverlayTrackingConfig = {
    [key in ConsultantOverlayTrackingConfigEnum]: MatomoEventTrackingData;
};

@Injectable({ providedIn: 'root' })
export class ConsultantOverlayTrackingConfigFactory {
    constructor(
        @Inject(DEVICE_ENVIRONMENT_TOKEN)
        public readonly deviceEnvironment: DeviceEnvironmentEnum
    ) {}

    public createByCategory(
        category: ConsultantOverlayTrackingCategoryEnum
    ): ConsultantOverlayTrackingConfig {
        let categoryPrefix =
            category === ConsultantOverlayTrackingCategoryEnum.Expert
                ? 'expert-'
                : 'compare_expert-';

        if (this.deviceEnvironment === DeviceEnvironmentEnum.Mobile) {
            categoryPrefix =
                category === ConsultantOverlayTrackingCategoryEnum.Expert
                    ? 'm_expert-'
                    : 'm_compare_expert-';
        }

        return {
            [ConsultantOverlayTrackingConfigEnum.ExpertFormGender]: {
                category: category,
                name: categoryPrefix + 'overlay_gender_select',
                action: '', // action is defined at runtime
            },
            [ConsultantOverlayTrackingConfigEnum.ExpertFormFirstName]: {
                category: category,
                name: categoryPrefix + 'overlay_firstname_input-text',
                action: 'firstname',
            },
            [ConsultantOverlayTrackingConfigEnum.ExpertFormLastName]: {
                category: category,
                name: categoryPrefix + 'overlay_lastname_input-text',
                action: 'lastname',
            },
            [ConsultantOverlayTrackingConfigEnum.ExpertFormInputMobileNumber]: {
                category: category,
                name: categoryPrefix + 'overlay_phone_input-text',
                action: 'phone',
            },
            [ConsultantOverlayTrackingConfigEnum.ExpertFormInputEmail]: {
                category: category,
                name: categoryPrefix + 'overlay_email_input-text',
                action: 'email',
            },
            [ConsultantOverlayTrackingConfigEnum.ExpertOverlayCtaSubmit]: {
                category: category,
                name: categoryPrefix + 'overlay_cta-submit',
                action: 'submit',
            },
            [ConsultantOverlayTrackingConfigEnum.ExpertOverlayCloseModalByBacklinkLink]:
                {
                    category: category,
                    name: categoryPrefix + 'overlay_cancel-link',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.ExpertOverlayCloseModalByCloseIcon]:
                {
                    category: category,
                    name: categoryPrefix + 'overlay_c24-modal_close',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.ExpertOverlayCloseModalByBackgroundClick]:
                {
                    category: category,
                    name: categoryPrefix + 'overlay_close_background',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.ExpertOverlayCloseModalByEscKeyDown]:
                {
                    category: category,
                    name: categoryPrefix + 'overlay_close_esc',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.ExpertOverlayAppointmentBookedNotice]:
                {
                    category: category,
                    name: categoryPrefix + 'overlay_appointment-booked-notice',
                    action: 'notice',
                },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentFormInputMobileNumber]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_instant-appointment_phone_input-text',
                    action: 'phone',
                },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentCtaSubmit]: {
                category: category,
                name: categoryPrefix + 'overlay_instant-appointment_cta-submit',
                action: 'submit',
            },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentCloseModalByCloseIcon]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_instant-appointment_c24-modal_close',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentCloseModalByBackgroundClick]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_instant-appointment_close_background',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentCloseModalByEscKeyDown]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_instant-appointment_close_esc',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentError]: {
                category: category,
                name: categoryPrefix + 'overlay_instant-appointment_error',
                action: 'error',
            },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentCtaError]: {
                category: category,
                name:
                    categoryPrefix +
                    'overlay_instant-appointment_error_cta-appointment',
                action: 'select',
            },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentErrorCloseModalByIcon]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_instant-appointment_error_c24-modal_close',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentErrorCloseModalBacklinkLink]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_instant-appointment_error_cancel-link',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentErrorCloseModalByBackgroundClick]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_instant-appointment_error_close_background',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentErrorCloseModalByEscKeyDown]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_instant-appointment_error_close_esc',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.InstantAppointmentSuccess]: {
                category: category,
                name: categoryPrefix + 'overlay_instant-appointment_success',
                action: 'success',
            },
            [ConsultantOverlayTrackingConfigEnum.ExpertOverlayAppointmentBookedNoticeCloseModalByCloseIcon]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_appointment-booked-notice_c24-modal_close',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.ExpertOverlayAppointmentBookedNoticeCloseModalByBackgroundClick]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_appointment-booked-notice_close_background',
                    action: 'close',
                },
            [ConsultantOverlayTrackingConfigEnum.ExpertOverlayAppointmentBookedNoticeCloseModalByEscKeyDown]:
                {
                    category: category,
                    name:
                        categoryPrefix +
                        'overlay_appointment-booked-notice_close_esc',
                    action: 'close',
                },
        };
    }
}
