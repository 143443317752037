import { createReducer, on } from '@ngrx/store';

import { LeadCreationTypeEnum } from '@pkv-frontend/business-domain/lead';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import { ConsultantOverlayContentTypeEnum } from '../../enums/consultant-overlay-content-type.enum';
import {
    appointmentBookingPreCheckRequest,
    changeOverlayContentType,
    createExpertLead,
    finishConsultantCallbackRequest,
    initOverlay,
    leadCreationError,
    leadCreationInvalidUserInput,
    setLeadCreationState,
    openOverlay,
    closeOverlay,
    backToForm,
} from '../actions/consultant-callback-overlay.actions';
import { ConsultantCallbackOverlayState } from '../models/consultant-callback-overlay.model';

const initialState: ConsultantCallbackOverlayState = {
    isVisible: false,
    contentType: ConsultantOverlayContentTypeEnum.Expert,
    appointmentBookingData: undefined,
    leadCreationType: undefined,
    leadCreationState: undefined,
    invalidUserInput: undefined,
};

export const consultantCallbackOverlayReducers = createReducer(
    initialState,
    on(
        initOverlay,
        (
            state: ConsultantCallbackOverlayState
        ): ConsultantCallbackOverlayState => ({
            ...state,
            ...initialState,
        })
    ),
    on(
        openOverlay,
        backToForm,
        (
            state: ConsultantCallbackOverlayState,
            action:
                | ReturnType<typeof openOverlay>
                | ReturnType<typeof backToForm>
        ): ConsultantCallbackOverlayState => {
            return {
                ...state,
                isVisible: true,
                contentType:
                    action.leadCreationType === LeadCreationTypeEnum.Expert
                        ? ConsultantOverlayContentTypeEnum.Expert
                        : ConsultantOverlayContentTypeEnum.ConsultantCallback,
                leadCreationType: action.leadCreationType,
            };
        }
    ),
    on(
        closeOverlay,
        (
            state: ConsultantCallbackOverlayState
        ): ConsultantCallbackOverlayState => {
            return {
                ...state,
                isVisible: false,
            };
        }
    ),
    on(
        changeOverlayContentType,
        (
            state: ConsultantCallbackOverlayState,
            action: ReturnType<typeof changeOverlayContentType>
        ): ConsultantCallbackOverlayState => ({
            ...state,
            contentType: action.contentType,
            appointmentBookingData: action.appointmentBookingData,
            leadCreationState: action.leadCreationState,
        })
    ),
    on(
        createExpertLead,
        (
            state: ConsultantCallbackOverlayState
        ): ConsultantCallbackOverlayState => ({
            ...state,
            leadCreationState: RequestStateEnum.Pending,
        })
    ),
    on(
        setLeadCreationState,
        (
            state: ConsultantCallbackOverlayState,
            action: ReturnType<typeof setLeadCreationState>
        ): ConsultantCallbackOverlayState => ({
            ...state,
            leadCreationState: action.state,
        })
    ),
    on(
        leadCreationError,
        (
            state: ConsultantCallbackOverlayState
        ): ConsultantCallbackOverlayState => {
            const contentType =
                state.contentType ===
                ConsultantOverlayContentTypeEnum.ConsultantCallback
                    ? ConsultantOverlayContentTypeEnum.ConsultantCallbackError
                    : ConsultantOverlayContentTypeEnum.LeadCreationError;

            return {
                ...state,
                contentType: contentType,
                leadCreationState: RequestStateEnum.Error,
            };
        }
    ),
    on(
        leadCreationInvalidUserInput,
        (
            state: ConsultantCallbackOverlayState,
            action: ReturnType<typeof leadCreationInvalidUserInput>
        ): ConsultantCallbackOverlayState => ({
            ...state,
            invalidUserInput: action.errors,
            leadCreationState: RequestStateEnum.Error,
        })
    ),
    on(
        finishConsultantCallbackRequest,
        (
            state: ConsultantCallbackOverlayState
        ): ConsultantCallbackOverlayState => ({
            ...state,
            contentType: ConsultantOverlayContentTypeEnum.Success,
            leadCreationState: RequestStateEnum.Success,
        })
    ),
    on(
        appointmentBookingPreCheckRequest,
        (
            state: ConsultantCallbackOverlayState
        ): ConsultantCallbackOverlayState => ({
            ...state,
            leadCreationState: RequestStateEnum.Pending,
        })
    )
);
