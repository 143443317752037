import { createReducer, on } from '@ngrx/store';
import {
    clearSilentResult,
    effectivePriceToggleChangeAction,
    FilterActionNamesEnum,
    filterChangeAction,
    gkvCrossSellingChangeAction,
    sortChangeAction,
} from '../../../filter/store/actions/filter.actions';
import { gkvProviderChangeAction } from '../../../gkv/store/actions/gkv-provider-change.action';
import {
    resetResult,
    resultApiResultsLoadedAction,
    resultPageHistoryBackTriggeredAction,
    toggleTariffExpansionModeAction,
} from '../actions/result.actions';

import { ResultState } from '../models/result-state.model';

export const initialState: ResultState = {
    tariffExpansionMode: {},
};

export const resultReducers = createReducer(
    initialState,
    on(
        filterChangeAction,
        effectivePriceToggleChangeAction,
        sortChangeAction,
        gkvProviderChangeAction,
        gkvCrossSellingChangeAction,
        resultPageHistoryBackTriggeredAction,
        (state: ResultState, action): ResultState => {
            if (
                action.type === FilterActionNamesEnum.FilterChange &&
                (!!action.consultantFilterChange || !!action.loadResultSilently)
            ) {
                return {
                    ...state,
                    silentResult: undefined,
                };
            }

            return {
                ...state,
                result: undefined,
                tariffExpansionMode: {},
            };
        }
    ),
    on(
        resultApiResultsLoadedAction,
        (
            state: ResultState,
            action: ReturnType<typeof resultApiResultsLoadedAction>
        ): ResultState => {
            const mergedResult =
                state.result?.result && !action.silent
                    ? [...state.result.result]
                    : [];

            if (state.result?.result === undefined) {
                // add empty results on first load
                const resultSize = action.result.paging?.resultsize || 0;
                for (let position = 0; position < resultSize; position++) {
                    mergedResult[position] = {
                        result: {
                            position,
                        },
                        isSkeleton: true,
                    };
                }
            }

            for (const resultItem of Object.values(action.result.result)) {
                const position = resultItem.result.position - 1;
                mergedResult[position] = {
                    ...resultItem,
                    isSkeleton: false,
                };
            }

            // take new lowest prices if available but otherwise fallback to old ones
            const lowestPrices =
                action.result.lowestPrices &&
                Object.keys(action.result.lowestPrices).length > 0
                    ? action.result.lowestPrices
                    : state.result?.lowestPrices;

            if (action.silent && state.result) {
                return {
                    ...state,
                    silentResult: {
                        ...action.result,
                        result: mergedResult,
                        lowestPrices,
                    },
                };
            }

            return {
                ...state,
                result: {
                    ...action.result,
                    result: mergedResult,
                    lowestPrices,
                },
            };
        }
    ),
    on(
        toggleTariffExpansionModeAction,
        (
            state: ResultState,
            action: ReturnType<typeof toggleTariffExpansionModeAction>
        ): ResultState => {
            return {
                ...state,
                tariffExpansionMode: {
                    ...state.tariffExpansionMode,
                    [action.expansionModeKey]: {
                        mode:
                            state.tariffExpansionMode[action.expansionModeKey]
                                ?.mode === action.mode
                                ? undefined
                                : action.mode,
                        feedbackRatingFilter: action.feedbackRatingFilter,
                    },
                },
            };
        }
    ),
    on(resetResult, (state: ResultState): ResultState => {
        return {
            ...state,
            silentResult: undefined,
            result: undefined,
        };
    }),
    on(clearSilentResult, (state: ResultState): ResultState => {
        return {
            ...state,
            silentResult: undefined,
        };
    })
);
