import { createReducer, on } from '@ngrx/store';
import {
    addTariffsToComparisonAction,
    removeAllTariffsFromComparisonAction,
    removeGkvTariffFromComparisonAction,
    removeTariffsFromComparisonAction,
    setComparisonBarVisibilityAction,
    setTariffsWithEyeIconAction,
    toggleComparisonBarAction,
} from '../actions/comparison.actions';

import { ComparisonStateModel } from '../models/comparison-state.model';
import { ComparisonTariffItem } from '../models/comparison-tariff-item.model';

export const MaximumComparisonAmount = 3;

export const initialState: ComparisonStateModel = {
    tariffItems: [],
    isMaximumComparisonAmountReached: false,
    tariffsWithEyeIcon: [],
    shouldShowComparisonBar: false,
    showComparisonBar: true,
};

export const comparisonReducers = createReducer(
    initialState,
    on(
        addTariffsToComparisonAction,
        (
            state: ComparisonStateModel,
            action: ReturnType<typeof addTariffsToComparisonAction>
        ): ComparisonStateModel => {
            if (state.isMaximumComparisonAmountReached) {
                return state;
            }
            const tariffItems = [...state.tariffItems, ...action.tariffItems];

            if (tariffItems.length > MaximumComparisonAmount) {
                return state;
            }

            return {
                ...state,
                tariffItems,
                isMaximumComparisonAmountReached:
                    tariffItems.length === MaximumComparisonAmount,
                shouldShowComparisonBar: true,
            };
        }
    ),
    on(
        removeTariffsFromComparisonAction,
        (
            state: ComparisonStateModel,
            action: ReturnType<typeof removeTariffsFromComparisonAction>
        ): ComparisonStateModel => {
            const tariffItems = state.tariffItems.filter(
                (tariffItem: ComparisonTariffItem) =>
                    !action.tariffVersionIds.includes(
                        tariffItem.tariffVersionId
                    )
            );

            return {
                ...state,
                tariffItems,
                isMaximumComparisonAmountReached:
                    tariffItems.length === MaximumComparisonAmount,
            };
        }
    ),
    on(
        removeAllTariffsFromComparisonAction,
        (state: ComparisonStateModel): ComparisonStateModel => {
            return {
                ...state,
                tariffItems: [],
                isMaximumComparisonAmountReached: false,
            };
        }
    ),
    on(
        setTariffsWithEyeIconAction,
        (
            state: ComparisonStateModel,
            action: ReturnType<typeof setTariffsWithEyeIconAction>
        ): ComparisonStateModel => {
            return {
                ...state,
                tariffsWithEyeIcon: action.tariffsWithEyeIcon ?? [],
            };
        }
    ),
    on(
        toggleComparisonBarAction,
        (state: ComparisonStateModel): ComparisonStateModel => {
            return {
                ...state,
                shouldShowComparisonBar: !state.shouldShowComparisonBar,
            };
        }
    ),
    on(
        removeGkvTariffFromComparisonAction,
        (state: ComparisonStateModel): ComparisonStateModel => {
            const tariffItems: ComparisonTariffItem[] =
                state.tariffItems.filter(
                    (item: ComparisonTariffItem) => !item.isGkv
                );
            return {
                ...state,
                tariffItems,
                isMaximumComparisonAmountReached:
                    tariffItems.length >= MaximumComparisonAmount,
            };
        }
    ),
    on(
        setComparisonBarVisibilityAction,
        (
            state: ComparisonStateModel,
            action: ReturnType<typeof setComparisonBarVisibilityAction>
        ): ComparisonStateModel => {
            return {
                ...state,
                showComparisonBar: action.isVisible,
            };
        }
    )
);
