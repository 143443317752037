import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppointmentAlreadyBookedCheckRequestModel } from '../models/appointment-already-booked-check-request.model';
import {
    AppointmentBookingDataResponseModel,
    NoAppointmentBookedResponseModel,
} from '../models/appointment-already-booked-check-response.model';

const apiUrl =
    '/api/v1/public/lead-creation-process/appointment-booking-pre-check';

@Injectable({
    providedIn: 'root',
})
export class AppointmentAlreadyBookedApiService {
    constructor(private httpClient: HttpClient) {}

    public create(
        data: AppointmentAlreadyBookedCheckRequestModel
    ): Observable<
        AppointmentBookingDataResponseModel | NoAppointmentBookedResponseModel
    > {
        return this.httpClient.post<
            | AppointmentBookingDataResponseModel
            | NoAppointmentBookedResponseModel
        >(apiUrl, this.createFormData(data));
    }

    private createFormData(
        data: AppointmentAlreadyBookedCheckRequestModel
    ): FormData {
        const formData = new FormData();
        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('phone', data.phone);
        formData.append('email', data.email);

        if (data.birthdate) {
            formData.append('birthdate', data.birthdate);
        }

        return formData;
    }
}
