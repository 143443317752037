import { createAction, props } from '@ngrx/store';
import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';

enum SsoUserDataActions {
    FetchSsoUserDataAction = '[SsoUserData] FetchSsoUserData',
    FetchSsoUserDataSuccess = '[SsoUserData] FetchSsoUserDataSuccess',
    FetchSsoUserDataFailure = '[SsoUserData] FetchSsoUserDataFailure',
}

export const fetchSsoUserDataAction = createAction(
    SsoUserDataActions.FetchSsoUserDataAction
);

export const fetchSsoUserDataSuccessAction = createAction(
    SsoUserDataActions.FetchSsoUserDataSuccess,
    props<{ ssoUserData: SsoUserDataModel }>()
);

export const fetchSsoUserDataFailureAction = createAction(
    SsoUserDataActions.FetchSsoUserDataFailure,
    props<{ error: string }>()
);
