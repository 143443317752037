import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';

import { Consultant } from '../models/consultant.model';

@Injectable({
    providedIn: 'root',
})
export class ConsultantCarouselApiClient {
    constructor(private http: HttpClient) {}

    public fetchConsultantData(
        profession: ProfessionEnum
    ): Observable<Consultant[]> {
        return this.http.get<Consultant[]>(
            '/api/v1/public/consultant/quotes?profession=' + profession
        );
    }
}
