import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import { resultGkvTileClosedPersistenceKey } from '../../../result/constants/result.constants';
import { closeGkvTileAction } from '../actions/close-gkv-tile.action';

@Injectable()
export class PersistGkvTileClosedEffect {
    constructor(
        private actions$: Actions,
        private storageService: SessionStorageService
    ) {}

    public persistGkvTileClosedEffect$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeGkvTileAction),
                map(() => {
                    this.storageService.set(
                        resultGkvTileClosedPersistenceKey,
                        true
                    );
                })
            );
        },
        { dispatch: false }
    );
}
