import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FetchGkvTariffsEffect } from './effects/fetch-gkv-tariffs.effect';
import { LoadGkvTileStateFromStorageEffect } from './effects/load-gkv-tile-state-from-storage-effect.service';
import { PersistGkvTileClosedEffect } from './effects/persist-gkv-tile-closed-effect.service';
import { UpdateUrlForGkvProviderChangeEffect } from './effects/update-url-for-gkv-provider-change.effect';

import { gkvFeatureKey } from './models/gkv-state.model';
import { gkvReducers } from './reducers/gkv.reducers';

@NgModule({
    imports: [
        EffectsModule.forFeature([
            FetchGkvTariffsEffect,
            PersistGkvTileClosedEffect,
            LoadGkvTileStateFromStorageEffect,
            UpdateUrlForGkvProviderChangeEffect,
        ]),
        StoreModule.forFeature(gkvFeatureKey, gkvReducers),
    ],
})
export class GkvStoreModule {}
