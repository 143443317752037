/* eslint-disable */
// TODO: The properties in SearchTagsDataProviderService which are public right now have to be private.
//  For this, the implemented interface 'SearchTagsDataProvider' which is part of the c24-ng-tracking
//  library also needs to be modified. To be fixed in ticket PVPKV-9667.
import { Injectable } from '@angular/core';
import { SearchTagsDataProvider as SearchTagsDataProviderInterface } from '@vv-ham/ng-tracking';
import { Subject } from 'rxjs';

interface SearchTagData {
    actionForeignId: string;
    providerName: string;
    tariffName: string;
}

@Injectable({ providedIn: 'root' })
export class SearchTagsDataProviderService
    implements SearchTagsDataProviderInterface
{
    public actionForeignId$: Subject<string> = new Subject<string>();
    public providerName$: Subject<string> = new Subject<string>();
    public tariffName$: Subject<string> = new Subject<string>();

    public setData(values: SearchTagData): void {
        this.actionForeignId$.next(values.actionForeignId);
        this.providerName$.next(values.providerName);
        this.tariffName$.next(values.tariffName);
    }
}
