import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { debounceTime, first, map, takeUntil } from 'rxjs/operators';
import { IntersectionStatusEnum } from '@pkv-frontend/infrastructure/intersection-observer';

import { resultFilterPositionParametersSelector } from '../../filter/store/selectors/result-filter-position-parameters.selector';
import { ResultNavigationService } from './result-navigation.service';

@Injectable({
    providedIn: 'root',
})
export class TariffScrollPositionService implements OnDestroy {
    private readonly destroy$: Subject<void> = new Subject<void>();
    private readonly visibleTariffPositions: Set<number> = new Set<number>();

    public initialScrollTariffPosition$: Observable<number | undefined> =
        this.store$.select(resultFilterPositionParametersSelector).pipe(
            first(),
            map((positionParameters) => positionParameters?.position)
        );

    private positionSubject$: Subject<number | undefined> = new Subject<
        number | undefined
    >();

    constructor(
        private readonly resultNavigationService: ResultNavigationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly store$: Store
    ) {
        this.positionSubject$
            .pipe(debounceTime(200), takeUntil(this.destroy$))
            .subscribe((position: number | undefined) => {
                if (
                    this.activatedRoute.snapshot.queryParams.position ===
                    position?.toString()
                ) {
                    return;
                }

                this.resultNavigationService.navigateAlwaysReplace({
                    position,
                });
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public tariffVisibilityChanged(
        tariffPosition: number,
        status: IntersectionStatusEnum
    ): void {
        if (status === IntersectionStatusEnum.NotVisible) {
            this.visibleTariffPositions.delete(tariffPosition);
        } else {
            this.visibleTariffPositions.add(tariffPosition);
        }

        const positionArray = Array.from(this.visibleTariffPositions).sort(
            (a, b) => a - b
        );
        const position =
            positionArray.length > 0
                ? positionArray[Math.ceil(positionArray.length / 2) - 1]
                : undefined;

        this.positionSubject$.next(position);
    }

    public reset(): void {
        this.visibleTariffPositions.clear();
        this.positionSubject$.next(0);
    }
}
