import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { C24NgEventTrackingService } from '@vv-ham/ng-tracking';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LeadCreationPageService } from '@pkv-frontend/business-domain/checkout';
import {
    AppointmentAlreadyBookedApiService,
    AppointmentBookingDataResponseModel,
    LeadCreationPageEnum,
    NoAppointmentBookedResponseModel,
} from '@pkv-frontend/data-domain/lead-expert';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';

import { ConsultantOverlayContentTypeEnum } from '../../enums/consultant-overlay-content-type.enum';
import { ConsultantOverlayTrackingCategoryEnum } from '../../enums/consultant-overlay-tracking-category.enum';
import {
    ConsultantOverlayTrackingConfig,
    ConsultantOverlayTrackingConfigFactory,
} from '../../factory/consultant-overlay-tracking-config.factory';
import {
    appointmentBookingPreCheckRequest,
    changeOverlayContentType,
    createExpertLead,
} from '../actions/consultant-callback-overlay.actions';

@Injectable()
export class AppointmentAlreadyBookedCheckEffect {
    constructor(
        private actions$: Actions,
        private apiClient: AppointmentAlreadyBookedApiService,
        private eventTrackingService: C24NgEventTrackingService,
        private consultantOverlayTrackingConfigFactory: ConsultantOverlayTrackingConfigFactory,
        private leadCreationPageService: LeadCreationPageService
    ) {}

    public appointmentAlreadyBooked$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(appointmentBookingPreCheckRequest),
            switchMap(
                (
                    action: ReturnType<typeof appointmentBookingPreCheckRequest>
                ) =>
                    this.apiClient
                        .create({
                            firstName: action.leadCreationApiRequest.firstname,
                            lastName: action.leadCreationApiRequest.lastname,
                            phone: action.leadCreationApiRequest.phone,
                            email: action.leadCreationApiRequest.email,
                            birthdate: action.birthdate,
                        })
                        .pipe(
                            map(
                                (
                                    response:
                                        | AppointmentBookingDataResponseModel
                                        | NoAppointmentBookedResponseModel
                                ) => {
                                    if (
                                        response.appointmentTimestamp === null
                                    ) {
                                        return createExpertLead({
                                            leadCreationApiRequest:
                                                action.leadCreationApiRequest,
                                            calculationParameter:
                                                action.calculationParameter,
                                        });
                                    }

                                    const trackingCategory: ConsultantOverlayTrackingCategoryEnum =
                                        this.leadCreationPageService.getFromUrl() ===
                                        LeadCreationPageEnum.Comparison
                                            ? ConsultantOverlayTrackingCategoryEnum.CompareExpert
                                            : ConsultantOverlayTrackingCategoryEnum.Expert;

                                    const trackingConfig: ConsultantOverlayTrackingConfig =
                                        this.consultantOverlayTrackingConfigFactory.createByCategory(
                                            trackingCategory
                                        );

                                    this.eventTrackingService.trackEvent({
                                        matomo: trackingConfig.expertOverlayAppointmentBookedNotice,
                                    });

                                    return changeOverlayContentType({
                                        contentType:
                                            ConsultantOverlayContentTypeEnum.AppointmentAlreadyBooked,
                                        appointmentBookingData: response,
                                        leadCreationState:
                                            RequestStateEnum.Initial,
                                    });
                                }
                            ),
                            catchError(() =>
                                of(
                                    changeOverlayContentType({
                                        contentType:
                                            ConsultantOverlayContentTypeEnum.LeadCreationError,
                                    })
                                )
                            )
                        )
            )
        );
    });
}
