import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomEventPublisherService } from './custom-event-publisher.service';

@NgModule({
    imports: [CommonModule],
    providers: [CustomEventPublisherService],
})
export class CustomEventPublisherModule {}
