import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
} from '@angular/core';

import { ToggleColorEnum } from './enums/toggle-color.enum';

@Component({
    selector: 'ui-shared-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.less'],
})
export class ToggleComponent {
    @HostBinding('class.isEnabled')
    @Input()
    public isEnabled = false;

    @Input() public color: ToggleColorEnum = ToggleColorEnum.Positive;

    @HostBinding('class.isPositive')
    private get isPositive(): boolean {
        return this.color === ToggleColorEnum.Positive;
    }

    @Output()
    public toggleChange: EventEmitter<boolean> = new EventEmitter();

    @HostListener('click')
    public onClick(): void {
        this.isEnabled = !this.isEnabled;
        this.toggleChange.emit(this.isEnabled);
    }
}
