import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ComparisonUpdatePersistenceEffect } from './effects/comparison-update-persistence.effect';
import { LoadTariffsWithEyeIconFromStorageEffect } from './effects/load-tariffs-with-eye-icon-from-storage-effect.service';

import { comparisonFeatureKey } from './models/comparison-state.model';
import { comparisonReducers } from './reducers/comparison.reducers';

@NgModule({
    imports: [
        EffectsModule.forFeature([
            ComparisonUpdatePersistenceEffect,
            LoadTariffsWithEyeIconFromStorageEffect,
        ]),
        StoreModule.forFeature(comparisonFeatureKey, comparisonReducers),
    ],
})
export class ComparisonStoreModule {}
