import { GoogleTagManagerEventTrackingData } from '@vv-ham/ng-tracking';

interface GoogleTagManagerConfig {
    requestConsultantCallback: GoogleTagManagerEventTrackingData;
}

export const googleTagManagerConfig: GoogleTagManagerConfig = {
    requestConsultantCallback: {
        areaId: 'danke-soforttermin',
    },
};
