import { createSelector } from '@ngrx/store';
import { SortFieldEnum } from '@pkv-frontend/data-domain/result';
import {
    TransparencyInfoState,
    TransparencyInfoStateAware,
} from '../models/transparency-info-state.model';

import { transparencyInfoFeatureSelector } from './transparency-info-feature.selector';

export const transparencyInfoSortfieldsSelector = createSelector<
    TransparencyInfoStateAware,
    [TransparencyInfoState],
    SortFieldEnum[]
>(
    transparencyInfoFeatureSelector,
    (state: TransparencyInfoState) => state.sortfields
);
