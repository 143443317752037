import { Injectable } from '@angular/core';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';

@Injectable({
    providedIn: 'root',
})
export class ResultPageStatePersistenceService {
    private readonly storageNamespace: string = 'c24.pkv.desktop.result-page';

    private readonly storageKeys: { [key: string]: string } = {
        hideGkvTariff: 'hide-gkv-tariff',
        alwaysHideGkvTariff: 'always-hide-gkv-tariff',
        defaultGkvInsurer: 'default-gkv-insurer',
    };

    constructor(private sessionStorageService: SessionStorageService) {}

    public set hideGkvTariff(value: boolean) {
        this.sessionStorageService.set(
            this.buildFullStorageKey(this.storageKeys.hideGkvTariff),
            value
        );
    }

    public get hideGkvTariff(): boolean {
        return (
            this.alwaysHideGkvTariff ||
            !!this.sessionStorageService.get(
                this.buildFullStorageKey(this.storageKeys.hideGkvTariff)
            )
        );
    }

    public set alwaysHideGkvTariff(value: boolean) {
        this.sessionStorageService.set(
            this.buildFullStorageKey(this.storageKeys.alwaysHideGkvTariff),
            value
        );
    }

    public get alwaysHideGkvTariff(): boolean {
        return !!this.sessionStorageService.get(
            this.buildFullStorageKey(this.storageKeys.alwaysHideGkvTariff)
        );
    }

    public get defaultGkvInsurer(): number | undefined {
        return this.sessionStorageService.get(
            this.buildFullStorageKey(this.storageKeys.defaultGkvInsurer)
        );
    }

    public set defaultGkvInsurer(value: number | undefined) {
        if (!value) {
            this.clearDefaultGkvInsurer();
        }
        this.sessionStorageService.set(
            this.buildFullStorageKey(this.storageKeys.defaultGkvInsurer),
            value
        );
    }

    public clearDefaultGkvInsurer(): void {
        this.sessionStorageService.remove(
            this.buildFullStorageKey(this.storageKeys.defaultGkvInsurer)
        );
    }

    private buildFullStorageKey(key: string): string {
        return `${this.storageNamespace}.${key}`;
    }
}
