import { AbstractControl } from '@angular/forms';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { RadioListRowVariantEnum } from '../enums/radio-list-row-variant.enum';

export enum AdditionalInformationIconValueEnum {
    Positive = 'positive',
    Warning = 'warning',
}

export interface AdditionalInformation {
    value: AdditionalInformationIconValueEnum;
    text: string;
}

export interface EffectivePriceCallback {
    (control: AbstractControl): boolean;
}

export interface RadioListOption {
    label: string;
    labelSmall?: string;
    labelTiny?: string;
    value: number | string | boolean;
    visible?:
        | boolean
        | ((calculationParameter: CalculationParameterInterface) => boolean);
    lowestPriceKey?: number | string;
    useEffectivePrice?: boolean | EffectivePriceCallback;
    additionalInformation?: AdditionalInformation[];
}

export interface FilterRadioListConfig {
    name?: string;
    radioListVariant: RadioListRowVariantEnum;
    options: RadioListOption[];
}
