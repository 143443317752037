export enum ProviderIdEnum {
    Allianz = 1,
    AlteOldenburger = 2,
    Arag = 3,
    Axa = 4,
    Barmenia = 5,
    Central = 7,
    Concordia = 8,
    Continentale = 9,
    DBV = 10,
    Debeka = 11,
    DeutscherRing = 12,
    Devk = 13,
    Dkv = 14,
    Gothaer = 15,
    Hallesche = 16,
    HanseMerkur = 17,
    HukCoburg = 18,
    Inter = 19,
    Lkh = 20,
    Lvm = 21,
    Mannheimer = 22,
    Mecklenburgische = 23,
    MuenchenerVerein = 24,
    Nuernberger = 25,
    Ottonova = 44,
    Pax = 26,
    RV = 28,
    SdK = 29,
    SignalIduna = 30,
    Union = 31,
    Universa = 32,
    Vkb = 6,
    VghProvinzial = 27,
    Wuerttembergische = 33,
}
