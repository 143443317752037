import { Injectable } from '@angular/core';
import {
    ProviderFilterConfig,
    ProviderFilterEntry,
} from '../models/provider-filter-config.model';

@Injectable({ providedIn: 'root' })
export class ProviderFilterSortingService {
    public sort(
        providers: ProviderFilterConfig,
        isConsultant: boolean
    ): ProviderFilterConfig {
        providers.standardProviderListConfig.sort(
            (a: ProviderFilterEntry, b: ProviderFilterEntry) =>
                this.applySortRule(a, b)
        );

        if (isConsultant) {
            providers.consultantProviderListConfig.sort(
                (a: ProviderFilterEntry, b: ProviderFilterEntry) =>
                    this.applySortRule(a, b)
            );
        }

        return providers;
    }

    private applySortRule(
        a: ProviderFilterEntry,
        b: ProviderFilterEntry
    ): number {
        if (!a.isChecked && b.isChecked) {
            return 1;
        }

        if (a.isChecked && !b.isChecked) {
            return -1;
        }

        return a.name.localeCompare(b.name);
    }
}
