import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import {
    LowestPrice,
    ResultFilterProviderModel,
    PositionParametersModel,
    SortApiParametersModel,
} from '@pkv-frontend/data-domain/result';

import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';

export const filterFeatureKey = 'resultFilter';

export interface FilterState {
    calculationParameters: CalculationParameterInterface | undefined;
    sortApiParameters: SortApiParametersModel | undefined;
    positionParameters: PositionParametersModel | undefined;
    resultFilters: ResultFilter | undefined;
    committedResultFilters: ResultFilter | undefined;
    consultantFilterChange?: boolean;
    customFilterSettings: ResultFilter | undefined;
    lowestCustomPrice: LowestPrice | undefined;
    appliedMessageVisible: boolean;
    crossSellingOverlay: YesNoEnum | undefined;
    filterChips: string[];
    providers: ResultFilterProviderModel[];
    loadResultSilently: boolean;
    simplifiedResponse: boolean;
    isLoading: boolean;
}

export interface FilterStateAware {
    [filterFeatureKey]: FilterState;
}
