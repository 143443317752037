import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';

import { isEmployeeOrFreelancerRule } from '../../result/rules/is-employee-or-freelancer.rule';

export function shouldOpenCrossSellingOverlayRule(
    openCrossSelling: YesNoEnum | undefined,
    profession: ProfessionEnum | undefined
): boolean {
    return shouldOpenCrossSellingOverlayRuleImplementation(
        openCrossSelling,
        profession
    );
}

function shouldOpenCrossSellingOverlayRuleImplementation(
    openCrossSelling: YesNoEnum | undefined,
    profession: ProfessionEnum | undefined
): boolean {
    return (
        isEmployeeOrFreelancerRule(profession) &&
        openCrossSelling === YesNoEnum.Yes
    );
}
