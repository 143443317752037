import { createSelector } from '@ngrx/store';
import { ResultStateAware } from '../models/result-state.model';

import { resultCalculationResponseSelector } from './result-calculation-response.selector';

export const resultTariffNumberSelector = createSelector<
    ResultStateAware,
    [ReturnType<typeof resultCalculationResponseSelector>],
    number
>(
    resultCalculationResponseSelector,
    (result: ReturnType<typeof resultCalculationResponseSelector>) =>
        result?.tariffinfo?.tariffNumber || 0
);
