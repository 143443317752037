import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppointmentAlreadyBookedCheckEffect } from './effects/appointment-already-booked-check.effect';
import { ConsultantCallbackLeadCreationEffect } from './effects/consultant-callback-lead-creation.effect';
import { CreateExpertLeadEffect } from './effects/expert-lead-creation.effect';
import { ExpertLeadRedirectEffect } from './effects/expert-lead-redirect.effect';
import { OverlayClosedTrackingEffect } from './effects/overlay-closed-tracking.effect';
import { OverlayOpenedTrackingEffect } from './effects/overlay-opened-tracking.effect';
import { RequestConsultantCallbackEffect } from './effects/request-consultant-callback.effect';
import { TrackLeadCreationErrorEffect } from './effects/track-lead-creation-error.effect';
import { TrackLeadCreationSuccessEffect } from './effects/track-lead-creation-success.effect';
import { consultantCallbackOverlayStoreFeatureKey } from './models/consultant-callback-overlay.model';
import { consultantCallbackOverlayReducers } from './reducers/consultant-callback-overlay.reducers';

@NgModule({
    imports: [
        EffectsModule.forFeature([
            AppointmentAlreadyBookedCheckEffect,
            CreateExpertLeadEffect,
            ExpertLeadRedirectEffect,
            RequestConsultantCallbackEffect,
            ConsultantCallbackLeadCreationEffect,
            TrackLeadCreationErrorEffect,
            TrackLeadCreationSuccessEffect,
            ConsultantCallbackLeadCreationEffect,
            OverlayOpenedTrackingEffect,
            OverlayClosedTrackingEffect,
        ]),
        StoreModule.forFeature(
            consultantCallbackOverlayStoreFeatureKey,
            consultantCallbackOverlayReducers
        ),
    ],
})
export class ConsultantCallbackOverlayStoreModule {}
