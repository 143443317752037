export enum ConsultantOverlayTrackingConfigEnum {
    ExpertFormGender = 'expertFormGender',
    ExpertFormFirstName = 'expertFormFirstName',
    ExpertFormLastName = 'expertFormLastName',
    ExpertFormInputEmail = 'expertFormInputEmail',
    ExpertFormInputMobileNumber = 'expertFormInputMobileNumber',
    ExpertOverlayCtaSubmit = 'expertOverlayCtaSubmit',
    ExpertOverlayCloseModalByBacklinkLink = 'expertOverlayCloseModalByBacklinkLink',
    ExpertOverlayCloseModalByCloseIcon = 'expertOverlayCloseModalByCloseIcon',
    ExpertOverlayCloseModalByBackgroundClick = 'expertOverlayCloseModalByBackgroundClick',
    ExpertOverlayCloseModalByEscKeyDown = 'expertOverlayCloseModalByEscKeyDown',
    ExpertOverlayAppointmentBookedNotice = 'expertOverlayAppointmentBookedNotice',
    InstantAppointmentFormInputMobileNumber = 'instantAppointmentFormInputMobileNumber',
    InstantAppointmentCtaSubmit = 'instantAppointmentCtaSubmit',
    InstantAppointmentCloseModalByCloseIcon = 'instantAppointmentCloseModalByCloseIcon',
    InstantAppointmentCloseModalByBackgroundClick = 'instantAppointmentCloseModalByBackgroundClick',
    InstantAppointmentCloseModalByEscKeyDown = 'instantAppointmentCloseModalByEscKeyDown',
    InstantAppointmentError = 'instantAppointmentError',
    InstantAppointmentCtaError = 'instantAppointmentCtaError',
    InstantAppointmentErrorCloseModalByIcon = 'instantAppointmentErrorCloseModalByIcon',
    InstantAppointmentErrorCloseModalBacklinkLink = 'instantAppointmentErrorCloseModalBacklinkLink',
    InstantAppointmentErrorCloseModalByBackgroundClick = 'instantAppointmentErrorCloseModalByBackgroundClick',
    InstantAppointmentErrorCloseModalByEscKeyDown = 'instantAppointmentErrorCloseModalByEscKeyDown',
    InstantAppointmentSuccess = 'instantAppointmentSuccess',
    ExpertOverlayAppointmentBookedNoticeCloseModalByBackgroundClick = 'expertOverlayAppointmentBookedNoticeCloseModalByBackgroundClick',
    ExpertOverlayAppointmentBookedNoticeCloseModalByCloseIcon = 'expertOverlayAppointmentBookedNoticeCloseModalByCloseIcon',
    ExpertOverlayAppointmentBookedNoticeCloseModalByEscKeyDown = 'expertOverlayAppointmentBookedNoticeCloseModalByEscKeyDown',
}
