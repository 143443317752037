import { createSelector } from '@ngrx/store';
import { Consultant } from '@pkv-frontend/data-domain/result';
import {
    ConsultantCarouselState,
    ConsultantCarouselStateAware,
} from '../models/consultant-carousel-state.model';

import { consultantCarouselStateSelector } from './consultant-carousel-state.selector';

export const consultantAtCurrentIndexSelector = createSelector<
    ConsultantCarouselStateAware,
    [ConsultantCarouselState],
    Consultant | undefined
>(
    consultantCarouselStateSelector,
    (state: ConsultantCarouselState): Consultant | undefined =>
        state.consultants?.[state.controls.index]
);
