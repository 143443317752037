export enum ContactInformationFormEnum {
    SendConfirmationOffline = 'send_confirmation_offline',
    InsuranceSelection = 'insure',
    InsureGender = 'insure_gender',
    InsureFirstName = 'insure_firstname',
    InsureLastName = 'insure_lastname',
    Gender = 'gender',
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    MobileNumber = 'mobileNumber',
}
