import { Injectable } from '@angular/core';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

const defaultPid = 'checkvers';

@Injectable({
    providedIn: 'root',
})
export class GoogleTagManagerDataLayerService {
    constructor(private readonly windowRefService: WindowReferenceService) {}

    public pushFirstDataLayerEntry(areaId: string, pid?: string): void {
        this.initializeDataLayer();
        this.windowRefService.nativeWindow.dataLayer?.push({
            pid: pid ?? defaultPid,
            cpid: pid ?? defaultPid,
            areaid: areaId,
        });
    }

    private initializeDataLayer(): void {
        if (!this.windowRefService.nativeWindow.dataLayer) {
            this.windowRefService.nativeWindow.dataLayer = [];
        }
    }
}
