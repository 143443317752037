import { Injectable } from '@angular/core';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';

const storageKey = 'pkv.activeFilterParams';

@Injectable({
    providedIn: 'root',
})
export class ActiveFilterParamsPersistenceService {
    constructor(
        private readonly sessionStorageService: SessionStorageService
    ) {}

    public clear(): void {
        this.sessionStorageService.remove(storageKey);
    }
}
