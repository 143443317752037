import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { C24NgEventTrackingService } from '@vv-ham/ng-tracking';
import {
    defaultPdhospitalPayoutAmountValue,
    defaultPdhospitalPayoutStart,
} from '@pkv-frontend/business-domain/calculation-parameter';

import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { MatomoFilterTrackingConfigModel } from '../models/matomo-filter-tracking-config.model';

@Injectable({
    providedIn: 'root',
})
export class UpdatePdHospitalValuesService {
    constructor(
        private readonly eventTrackingService: C24NgEventTrackingService
    ) {}

    public updatePdhospitalValues(
        filterFormGroup: FormGroup,
        resultFilter: ResultFilter,
        lastFilterChange: Partial<ResultFilter>,
        pdhospitalPayoutStartVisible: boolean,
        matomoFilterTrackingConfigModel: MatomoFilterTrackingConfigModel
    ): ResultFilter {
        const noPdhospitalPayoutControl =
            filterFormGroup.get('noPdhospitalPayout');

        const pdhospitalPayoutStartControl = filterFormGroup.get(
            'pdhospitalPayoutStart'
        );

        const pdhospitalPayoutAmountValueControl = filterFormGroup.get(
            'pdhospitalPayoutAmountValue'
        );

        if (
            lastFilterChange.pdhospitalPayoutAmountValue &&
            resultFilter.pdhospitalPayoutStart === 0
        ) {
            resultFilter.noPdhospitalPayout = false;
            resultFilter.pdhospitalPayoutStart = defaultPdhospitalPayoutStart;

            pdhospitalPayoutStartControl?.setValue(
                defaultPdhospitalPayoutStart,
                {
                    emitEvent: false,
                }
            );

            noPdhospitalPayoutControl?.setValue(false, {
                emitEvent: false,
            });

            return resultFilter;
        }

        if (
            lastFilterChange.pdhospitalPayoutStart &&
            resultFilter.pdhospitalPayoutAmountValue === 0
        ) {
            resultFilter.noPdhospitalPayout = false;
            resultFilter.pdhospitalPayoutAmountValue =
                defaultPdhospitalPayoutAmountValue;

            pdhospitalPayoutAmountValueControl?.setValue(
                defaultPdhospitalPayoutAmountValue,
                {
                    emitEvent: false,
                }
            );

            noPdhospitalPayoutControl?.setValue(false, {
                emitEvent: false,
            });

            return resultFilter;
        }

        if (lastFilterChange.noPdhospitalPayout === true) {
            resultFilter.pdhospitalPayoutAmountValue = 0;
            resultFilter.pdhospitalPayoutStart = 0;

            pdhospitalPayoutAmountValueControl?.setValue(0, {
                emitEvent: false,
            });

            pdhospitalPayoutStartControl?.setValue(0, { emitEvent: false });

            noPdhospitalPayoutControl?.setValue(true, {
                emitEvent: false,
            });

            this.eventTrackingService.trackEvent({
                matomo: {
                    ...matomoFilterTrackingConfigModel.pdhospitalPayoutAmountValueSelect,
                    action: '0',
                },
            });

            pdhospitalPayoutStartControl?.markAsDirty();
            pdhospitalPayoutAmountValueControl?.markAsDirty();
            noPdhospitalPayoutControl?.markAsDirty();
            return resultFilter;
        }

        if (lastFilterChange.noPdhospitalPayout === false) {
            resultFilter.pdhospitalPayoutAmountValue =
                defaultPdhospitalPayoutAmountValue;
            resultFilter.pdhospitalPayoutStart = defaultPdhospitalPayoutStart;

            pdhospitalPayoutAmountValueControl?.setValue(
                defaultPdhospitalPayoutAmountValue,
                {
                    emitEvent: false,
                }
            );

            pdhospitalPayoutStartControl?.setValue(
                defaultPdhospitalPayoutStart,
                {
                    emitEvent: false,
                }
            );

            noPdhospitalPayoutControl?.setValue(false, {
                emitEvent: false,
            });

            this.eventTrackingService.trackEvent({
                matomo: {
                    ...matomoFilterTrackingConfigModel.pdhospitalPayoutAmountValueSelect,
                    action: defaultPdhospitalPayoutAmountValue.toString(),
                },
            });
            if (pdhospitalPayoutStartVisible) {
                this.eventTrackingService.trackEvent({
                    matomo: {
                        ...matomoFilterTrackingConfigModel.pdhospitalPayoutStartSelect,
                        action: defaultPdhospitalPayoutStart.toString(),
                    },
                });
            }

            return resultFilter;
        }

        return resultFilter;
    }
}
