import { Injectable } from '@angular/core';

import {
    YesNoEnum,
    boolToYesNo,
} from '@pkv-frontend/infrastructure/interfaces';

import { C24loginTypeEnum } from '../enums/c24-login-type.enum';
import { InsureEnum } from '../enums/insure.enum';
import { ConsultantCallbackApiRequestModel } from '../models/consultant-callback-api-request.model';
import { ExpertLeadApiRequest } from '../models/expert-lead-api-request.model';
import { LeadCreationMapperPayloadModel } from '../models/lead-creation-mapper-payload.model';

@Injectable({
    providedIn: 'root',
})
export class LeadCreationMapper {
    public map(
        payload: LeadCreationMapperPayloadModel
    ): ExpertLeadApiRequest & ConsultantCallbackApiRequestModel {
        return this.filterParams({
            insure: InsureEnum.Own,
            c24login_type: C24loginTypeEnum.None,
            customdata_refresh: YesNoEnum.No,
            gender: payload.formData.gender,
            firstname: payload.formData.firstName,
            lastname: payload.formData.lastName,
            phone: payload.formData.mobileNumber,
            email: payload.formData.email,
            send_confirmation_offline: payload.sendConfirmationEmail,
            forcepoints: boolToYesNo(payload.forcepoints),
            lead_creation_page: payload.leadCreationPage,
            public_lead_access_key: payload.publicLeadAccessKey,
            compared_tariffversion_ids: payload.tariffVersionIds,
        });
    }

    private filterParams<T extends { [key: string]: unknown }>(
        expertLeadApiRequestModel: T
    ): T {
        const filteredRequest: { [key: string]: unknown } = {};

        // eslint-disable-next-line prefer-const
        for (let [key, value] of Object.entries(expertLeadApiRequestModel)) {
            if (value) {
                if (Array.isArray(value)) {
                    key += '[]';
                }

                filteredRequest[key] = value;
            }
        }

        return filteredRequest as T & { [x: string]: unknown };
    }
}
