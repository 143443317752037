import { Injectable } from '@angular/core';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import { ComparisonTariffItem } from '../state/models/comparison-tariff-item.model';

export const ComparisonTariffPersistenceKey = 'comparisonManager.tariffs';

@Injectable({
    providedIn: 'root',
})
export class ComparisonPersistenceService {
    constructor(
        private readonly sessionStorageService: SessionStorageService
    ) {}

    public update(comparisonTariffItems: ComparisonTariffItem[]): void {
        this.sessionStorageService.set(
            ComparisonTariffPersistenceKey,
            comparisonTariffItems
        );
    }

    public removeAll(): void {
        this.sessionStorageService.remove(ComparisonTariffPersistenceKey);
    }

    public getAll(): ComparisonTariffItem[] | undefined {
        return this.getComparisonTariffsFromSessionStorage();
    }

    public removeOne(tariffVersionId: number): number | undefined {
        const comparisonTariffItems =
            this.getComparisonTariffsFromSessionStorage();
        if (!comparisonTariffItems) {
            return;
        }

        const tariffIndex = comparisonTariffItems.findIndex(
            (item: ComparisonTariffItem) =>
                item.tariffVersionId !== tariffVersionId
        );

        this.sessionStorageService.set(
            ComparisonTariffPersistenceKey,
            comparisonTariffItems.filter(
                (item: ComparisonTariffItem) =>
                    item.tariffVersionId !== tariffVersionId
            )
        );

        return tariffIndex === -1 ? undefined : tariffIndex;
    }

    private getComparisonTariffsFromSessionStorage():
        | ComparisonTariffItem[]
        | undefined {
        return this.sessionStorageService.get(ComparisonTariffPersistenceKey);
    }
}
