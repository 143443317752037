import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
    ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import {
    ConsultantDataService,
    DeviceOutputService,
    getEnvironmentRule,
    IsAlternativeOfferService,
    IsTariffChangeService,
} from '@pkv-frontend/data-domain/application';
import { applicationDataInitiatedAction } from '../actions/application.actions';

@Injectable()
export class InitApplicationDataEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly isAlternativeOfferService: IsAlternativeOfferService,
        private readonly isTariffChangeService: IsTariffChangeService,
        private readonly consultantDataService: ConsultantDataService,
        private readonly deviceOutputService: DeviceOutputService
    ) {}

    public readonly loadApplicationData$: Observable<Action> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ROOT_EFFECTS_INIT),
                switchMap(() => {
                    const consultantCookieData =
                        this.consultantDataService.getConsultantData();
                    const deviceOutput =
                        this.deviceOutputService.getDeviceOutput();

                    return combineLatest([
                        this.isAlternativeOfferService.isAlternativeOfferMode$,
                        this.isTariffChangeService.isTariffChange$,
                        this.isTariffChangeService.initialProviderId$,
                    ]).pipe(
                        first(),
                        map(
                            ([
                                isAlternativeOffer,
                                isTariffChange,
                                intialProviderId,
                            ]: [boolean, boolean, number]) =>
                                applicationDataInitiatedAction({
                                    state: {
                                        consultantCookieData,
                                        isConsultantLoggedIn:
                                            !!consultantCookieData,
                                        isAlternativeOffer,
                                        isTariffChange,
                                        intialProviderId,
                                        deviceOutput,
                                        environment: getEnvironmentRule(),
                                    },
                                })
                        )
                    );
                })
            )
    );
}
