export enum PromotionTypeEnum {
    HighBenefit = 'high_benefit',
    HighPriceBenefit = 'high_price_benefit',
    Gkv = 'gkv',
    LowestPrice = 'lowest_price',
    Alternative = 'alternative',
}

export enum PromotionTypeTranslationEnum {
    HighBenefit = 'Leistungs-Empfehlung',
    HighPriceBenefit = 'Preis-Leistungs-Empfehlung',
    Gkv = 'Ihre Krankenkasse',
    LowestPrice = 'Günstigster Preis',
}
