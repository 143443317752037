import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import * as deepEquals from 'fast-deep-equal';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { dentalMap } from '@pkv-frontend/business-domain/calculation-parameter';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { FilterChipConfigModel } from '../../models/filter-chip-config.model';
import { FilterChipsService } from '../../services/filter-chips.service';
import {
    filterChangeAction,
    resetFilterChipsAction,
} from '../actions/filter.actions';

import { resultFilterResultFiltersSelector } from '../selectors/result-filter-result-filters.selector';

@Injectable()
export class UpdateFiltersFromFilterChipsResetEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly filterChipsService: FilterChipsService
    ) {}

    public readonly updateFiltersFromFilterChipsResetEffect$: Observable<Action> =
        createEffect(() => {
            return this.actions$.pipe(
                ofType(resetFilterChipsAction),
                concatLatestFrom(() => [
                    this.store$.select(resultFilterResultFiltersSelector),
                ]),
                map(
                    ([action, currentResultFilters]: [
                        ReturnType<typeof resetFilterChipsAction>,
                        ResultFilter,
                    ]) => {
                        const updatedResultFilters: ResultFilter = {
                            ...currentResultFilters,
                        };

                        action.filterChips.forEach((chip) => {
                            const config:
                                | FilterChipConfigModel<keyof ResultFilter>
                                | undefined =
                                this.filterChipsService.getMatchingFilterChip(
                                    chip
                                );
                            if (config) {
                                updatedResultFilters[chip] =
                                    config?.hiddenValue;
                            }
                        });

                        updatedResultFilters.dentalTreatment =
                            dentalMap[
                                updatedResultFilters.dental
                            ].dentalTreatment;
                        updatedResultFilters.dentalDentures =
                            dentalMap[
                                updatedResultFilters.dental
                            ].dentalDentures;

                        return [updatedResultFilters, currentResultFilters];
                    }
                ),
                filter(
                    ([updatedResultFilters, currentResultFilters]: [
                        ResultFilter,
                        ResultFilter,
                    ]) =>
                        !deepEquals(updatedResultFilters, currentResultFilters)
                ),
                map(([updatedResultFilters]: [ResultFilter, ResultFilter]) =>
                    filterChangeAction({ resultFilters: updatedResultFilters })
                )
            );
        });
}
