import { Injectable } from '@angular/core';

import {
    Actions,
    ROOT_EFFECTS_INIT,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, timeout } from 'rxjs/operators';
import { IsCobrowsingService } from '@pkv-frontend/data-domain/application';
import { setIsCobrowsingAction } from '../actions/application.actions';

const websocketConnectionEventTimeout = 2000;

@Injectable()
export class IsCobrowsingEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly isCobrowsingService: IsCobrowsingService
    ) {}

    public readonly isCobrowsing$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            switchMap(() =>
                this.isCobrowsingService.isCobrowsing$.pipe(
                    timeout(websocketConnectionEventTimeout),
                    first(),
                    map((isCobrowsing: boolean) =>
                        setIsCobrowsingAction({
                            isCobrowsing,
                        })
                    ),
                    catchError(() =>
                        of(
                            setIsCobrowsingAction({
                                isCobrowsing: false,
                            })
                        )
                    )
                )
            )
        )
    );
}
