import { Injectable } from '@angular/core';
import {
    FormControl,
    FormGroup,
    UntypedFormBuilder,
    Validators,
} from '@angular/forms';
import { CalculationParameterInformationService } from '@pkv-frontend/business-domain/calculation-parameter';
import {
    CalculationParameterInterface,
    HospitalizationAccommodationEnum,
    TreatmentAttendingDoctorEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';

import { StepValidator } from '@pkv-frontend/infrastructure/validators';
import {
    betAmountValueConfig,
    pdhospitalPayoutAmountValueConfig,
} from '../configs/filter.config';

const costsharingFilterMin = -1;
const costsharingFilterMax = 5000;

@Injectable({
    providedIn: 'root',
})
export class FilterFormBuilderService {
    constructor(
        private readonly calculationParameterInformationService: CalculationParameterInformationService,
        private readonly formBuilder: UntypedFormBuilder
    ) {}

    public build(
        filterParameter: ResultFilter,
        calculationParameter: CalculationParameterInterface,
        isConsultant: boolean
    ): FormGroup {
        // Add formControl for provider explicit, because the form builder treats
        // the provider array value as an incomplete config value
        const _filterParameter = Object.assign({}, filterParameter);
        delete _filterParameter.provider;

        const filterFormGroup = this.formBuilder.group(_filterParameter);

        const noPdhospitalPayoutValue =
            calculationParameter.pdhospitalPayoutAmountValue === 0 &&
            calculationParameter.pdhospitalPayoutStart === 0;

        const noBetAmountValue = calculationParameter.betAmount ?? 0;

        filterFormGroup.addControl(
            'benefitPreset',
            new FormControl(filterParameter.benefitPreset)
        );

        filterFormGroup.addControl(
            'provider',
            new FormControl(filterParameter.provider)
        );

        filterFormGroup.addControl(
            'effectivePrice',
            new FormControl(filterParameter.effectivePrice ?? false)
        );

        filterFormGroup.patchValue({
            treatmentAttendingDoctor:
                filterParameter.treatmentAttendingDoctor ===
                TreatmentAttendingDoctorEnum.HeadDoctor,
        });

        if (
            this.calculationParameterInformationService.isServantOrServantCandidateOrServantChild(
                calculationParameter
            )
        ) {
            const hospitalizationAccommodationSingleValue =
                calculationParameter.hospitalizationAccommodation ===
                HospitalizationAccommodationEnum.Single;

            filterFormGroup.addControl(
                'hospitalizationAccommodationSingle',
                new FormControl(hospitalizationAccommodationSingleValue)
            );

            filterFormGroup.removeControl('provisionCostsharingLimit');
        }

        if (isConsultant) {
            this.addProvisionCostsharingLimitValidation(
                filterFormGroup,
                calculationParameter
            );

            this.addPdHospitalPayoutAmountValue(
                filterFormGroup,
                calculationParameter
            );

            this.addBetAmountValue(filterFormGroup, noBetAmountValue);
        }

        filterFormGroup.addControl(
            'noPdhospitalPayout',
            new FormControl(noPdhospitalPayoutValue)
        );

        return filterFormGroup;
    }

    private addPdHospitalPayoutAmountValue(
        filterFormGroup: FormGroup,
        calculationParameter: CalculationParameterInterface
    ): void {
        if (
            this.calculationParameterInformationService.isChild(
                calculationParameter
            )
        ) {
            return;
        }

        const pdhospitalPayoutAmountValue = filterFormGroup.get(
            'pdhospitalPayoutAmountValue'
        );

        if (pdhospitalPayoutAmountValue === null) {
            throw new Error('provisionCostsharingLimit cannot be null');
        }

        pdhospitalPayoutAmountValue.setValidators([
            Validators.min(pdhospitalPayoutAmountValueConfig.min),
            Validators.max(pdhospitalPayoutAmountValueConfig.max),
            StepValidator(pdhospitalPayoutAmountValueConfig.step),
        ]);
    }

    private addBetAmountValue(
        filterFormGroup: FormGroup,
        noBetAmountValue: number
    ): void {
        const betAmountValue = filterFormGroup.get('betAmount');
        if (!betAmountValue) {
            filterFormGroup.addControl(
                'betAmount',
                new FormControl(noBetAmountValue, {
                    validators: [
                        Validators.min(betAmountValueConfig.min),
                        Validators.max(betAmountValueConfig.max),
                        StepValidator(betAmountValueConfig.step),
                    ],
                })
            );
            return;
        }

        betAmountValue?.setValidators([
            Validators.min(betAmountValueConfig.min),
            Validators.max(betAmountValueConfig.max),
            StepValidator(betAmountValueConfig.step),
        ]);

        betAmountValue?.setValue(noBetAmountValue);
    }

    private addProvisionCostsharingLimitValidation(
        filterFormGroup: FormGroup,
        calculationParameter: CalculationParameterInterface
    ): void {
        if (
            this.calculationParameterInformationService.isServantOrServantCandidateOrServantChild(
                calculationParameter
            )
        ) {
            return;
        }

        const provisionCostsharingLimit = filterFormGroup.get(
            'provisionCostsharingLimit'
        );

        if (provisionCostsharingLimit === null) {
            throw new Error('provisionCostsharingLimit cannot be null');
        }

        provisionCostsharingLimit.setValidators([
            Validators.min(costsharingFilterMin),
            Validators.max(costsharingFilterMax),
        ]);
    }
}
