import { Injectable } from '@angular/core';
import { EffectivePrice } from '@pkv-frontend/data-domain/result';
import {
    FreelancerStateInsurancePrices,
    StateInsurancePrices,
} from '@pkv-frontend/data-domain/state-insurance';
import { NonNullableProperties } from '@pkv-frontend/infrastructure/interfaces';
import { PriceInfoModel } from '../../result/models/price-info.model';
import { ComparedToGkvViewData } from '../models/view-data.model';

interface PriceData {
    savingsPerYear: number;
    pkvContributionPerYear: number;
    pkvContributionPerMonth: number;
}

export type PriceInfoRequiredSaving = NonNullableProperties<PriceInfoModel>;

@Injectable({
    providedIn: 'root',
})
export class ComparedToGkvViewDataFactory {
    public retrieveForFreelancer(
        tariffPriceInfo: PriceInfoRequiredSaving,
        stateInsurancePrices: FreelancerStateInsurancePrices,
        effectivePrice: EffectivePrice | null,
        showEffectivePrice: boolean
    ): ComparedToGkvViewData {
        const priceData: PriceData =
            showEffectivePrice && effectivePrice
                ? this.getPriceDataIfEffectivePrice(
                      tariffPriceInfo,
                      effectivePrice
                  )
                : ({
                      savingsPerYear: tariffPriceInfo.savingPerYear,
                      pkvContributionPerYear: tariffPriceInfo.total * 12,
                      pkvContributionPerMonth: tariffPriceInfo.total,
                  } as PriceData);

        return {
            pkvContributionPerMonth: priceData.pkvContributionPerMonth,
            pkvContributionPerYear: priceData.pkvContributionPerYear,
            gkvContributionPerYear:
                stateInsurancePrices.freelancerTotalYearlyAmount,
            annualIncome: stateInsurancePrices.freelancerRelevantIncome,
            gkvContributionPercentage:
                stateInsurancePrices.freelancerStatutoryHealthInsurancePercentage,
            gkvLongTermCarePercentage:
                stateInsurancePrices.freelancerLongTermCarePercentage,
            gkvLongTermCareAmount:
                stateInsurancePrices.freelancerLongTermCareYearlyAmount,
            employeeGkvContributionPercentage:
                stateInsurancePrices.freelancerStatutoryHealthInsurancePercentage,
            employeeGkvContributionPerYear:
                stateInsurancePrices.freelancerContributionYearlyAmount,
            savingsPerYear: priceData.savingsPerYear,
            effectivePrice,
        };
    }

    public retrieveForEmployee(
        tariffPriceInfo: PriceInfoRequiredSaving,
        stateInsurancePrices: StateInsurancePrices,
        effectivePrice: EffectivePrice | null,
        showEffectivePrice: boolean
    ): ComparedToGkvViewData {
        const priceData: PriceData =
            showEffectivePrice && effectivePrice
                ? this.getPriceDataIfEffectivePrice(
                      tariffPriceInfo,
                      effectivePrice
                  )
                : ({
                      savingsPerYear: tariffPriceInfo.savingPerYear,
                      pkvContributionPerYear: tariffPriceInfo.contribution * 12,
                      pkvContributionPerMonth: tariffPriceInfo.contribution,
                  } as PriceData);

        return {
            pkvContributionPerMonth: priceData.pkvContributionPerMonth,
            pkvContributionPerYear: priceData.pkvContributionPerYear,
            gkvContributionPerYear:
                stateInsurancePrices.employeeTotalYearlyContribution,
            annualIncome: stateInsurancePrices.contributionCeilingYearly,
            employeeGkvContributionPercentage:
                stateInsurancePrices.employeeContributionPercentage,
            employeeGkvContributionPerYear:
                stateInsurancePrices.employeeContributionYearlyAmount,
            employerGkvContributionPercentage:
                stateInsurancePrices.employerContributionPercentage,
            employerGkvContributionPerYear:
                stateInsurancePrices.employerContributionYearlyAmount,
            savingsPerYear: priceData.savingsPerYear,
            effectivePrice,
        };
    }

    private getPriceDataIfEffectivePrice(
        tariffPriceInfo: PriceInfoModel,
        effectivePrice: EffectivePrice
    ): PriceData {
        return {
            savingsPerYear: tariffPriceInfo.savingWithEffectivePricePerYear,
            pkvContributionPerYear: effectivePrice.yearlyEffectivePrice,
            pkvContributionPerMonth: effectivePrice.effectivePrice,
        } as PriceData;
    }
}
