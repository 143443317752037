import { createSelector } from '@ngrx/store';
import { PositionParametersModel } from '@pkv-frontend/data-domain/result';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterPositionParametersSelector = createSelector<
    FilterStateAware,
    [FilterState],
    PositionParametersModel | undefined
>(filterStateFeatureSelector, (state: FilterState) => state.positionParameters);
