import { Injectable } from '@angular/core';

import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { ResultItem } from '@pkv-frontend/data-domain/result';
import { ComparisonTariffItem } from '../state/models/comparison-tariff-item.model';

@Injectable({
    providedIn: 'root',
})
export class ComparisonTariffItemBuilder {
    public buildComparisonTariffByResultItem(
        item: ResultItem
    ): ComparisonTariffItem {
        return {
            position: item.result.position,
            providerName: item.provider.name,
            isPromotion: item.promotion,
            logoUrl: item.provider.logo,
            tariffVersionId: item.tariff.versionId,
            marketingName: item.tariff.marketingName,
            tariffName: item.tariff.name,
            priceInfo: item.priceInfo,
            effectivePrice: item.effectivePrice,
        };
    }

    public buildComparisonTariffByGkvItem(
        item: GkvResultItem
    ): ComparisonTariffItem {
        return {
            tariffName: item.tariff.name,
            marketingName: undefined,
            tariffVersionId: item.tariff.versionId,
            isPromotion: false,
            position: 0,
            providerName: item.provider.name,
            logoUrl: item.provider.logo,
            priceInfo: {
                total: item.tariff.price,
                contribution: item.tariff.price,
                points: 0,
            },
            isGkv: true,
        };
    }
}
