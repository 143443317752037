import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CustomUriEncoderService } from '@pkv-frontend/infrastructure/http-param-converter';

@Injectable({
    providedIn: 'root',
})
export class PhoneNumberValidationApiClient {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly uriEncoder: CustomUriEncoderService
    ) {}

    public validate(phoneNumber: string): Observable<void> {
        return this.httpClient.post<void>(
            '/api/v1/public/lead/parameter-validation/phone-number',
            this.buildHttpParams(phoneNumber)
        );
    }

    private buildHttpParams(phoneNumber: string): HttpParams {
        return new HttpParams({
            fromObject: { phoneNumber },
            encoder: this.uriEncoder,
        });
    }
}
