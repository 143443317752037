import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { Consultant } from '@pkv-frontend/data-domain/result';

function shouldSkipCurrentConsultantRuleImplementation(
    consultant: Consultant | undefined,
    profession: ProfessionEnum | undefined,
    calculationParameterProfession: ProfessionEnum | undefined
): boolean {
    return (
        !consultant ||
        !profession ||
        (((profession !== ProfessionEnum.Child &&
            calculationParameterProfession !== ProfessionEnum.Servant) ||
            (profession !== ProfessionEnum.ServantChild &&
                calculationParameterProfession !== undefined)) &&
            profession !== calculationParameterProfession)
    );
}

export function shouldSkipCurrentConsultantRule(
    consultant: Consultant | undefined,
    profession: ProfessionEnum | undefined,
    calculationParameterProfession: ProfessionEnum | undefined
): boolean {
    return shouldSkipCurrentConsultantRuleImplementation(
        consultant,
        profession,
        calculationParameterProfession
    );
}
