import { createSelector } from '@ngrx/store';
import { ActiveConsultantModel } from '@pkv-frontend/data-domain/consultant';
import {
    ApplicationState,
    ApplicationStateAware,
} from '../models/application-state.model';
import { applicationStateSelector } from './application-state.selector';

export const activeConsultantSelector = createSelector<
    ApplicationStateAware,
    [ApplicationState],
    ActiveConsultantModel | undefined
>(
    applicationStateSelector,
    (state: ApplicationState): ActiveConsultantModel | undefined =>
        state.activeConsultant
);
