import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { MatomoEventTrackingData } from '@vv-ham/ng-tracking';
import { GetResultCalculationResponse } from '@pkv-frontend/data-domain/result';

import { TariffExpansionModeEnum } from '../../enums/tariff-expansion-mode.enum';

enum ResultActionNamesEnum {
    Enter = '[Result page] Enter',
    UrlChange = '[Result page] Url change',
    ResultsLoaded = '[Result API] Results loaded',
    ToggleTariffExpansionMode = '[Result page] Toggle tariff expansion mode',
    RedirectToRegister = '[Result page] redirect to register',
    HistoryBackTriggered = '[Result page] history back triggered',
    ResetResult = '[Result page] Reset Result',
    SetResult = '[Result page] Set Result',
}

export const enterResultPageAction = createAction(ResultActionNamesEnum.Enter);

export const resultPageUrlChangeAction = createAction(
    ResultActionNamesEnum.UrlChange,
    props<{ params: Params; silent: boolean }>()
);

export const resultApiResultsLoadedAction = createAction(
    ResultActionNamesEnum.ResultsLoaded,
    props<{
        result: GetResultCalculationResponse;
        silent: boolean;
    }>()
);

export const toggleTariffExpansionModeAction = createAction(
    ResultActionNamesEnum.ToggleTariffExpansionMode,
    props<{
        expansionModeKey: string;
        mode: TariffExpansionModeEnum | undefined;
        feedbackRatingFilter?: number;
        trackingConfig?: MatomoEventTrackingData;
    }>()
);

export const redirectToRegisterAction = createAction(
    ResultActionNamesEnum.RedirectToRegister,
    props<{
        tariffVersionId: number;
    }>()
);

export const resultPageHistoryBackTriggeredAction = createAction(
    ResultActionNamesEnum.HistoryBackTriggered
);

export const resetResult = createAction(ResultActionNamesEnum.ResetResult);

export const setResultAction = createAction(
    ResultActionNamesEnum.SetResult,
    props<{
        result: GetResultCalculationResponse;
        silent: boolean;
    }>()
);
