import { createSelector } from '@ngrx/store';
import { StateInsurancePrices } from '@pkv-frontend/data-domain/state-insurance';
import { ResultStateAware } from '../models/result-state.model';

import { resultCalculationResponseSelector } from './result-calculation-response.selector';

export const resultStateInsurancePricesSelector = createSelector<
    ResultStateAware,
    [ReturnType<typeof resultCalculationResponseSelector>],
    StateInsurancePrices | undefined
>(
    resultCalculationResponseSelector,
    (result: ReturnType<typeof resultCalculationResponseSelector>) =>
        result?.stateInsurancePrices
);
