import { Injectable } from '@angular/core';

import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

import { MatomoAbTestingService } from '../services/matomo-ab-testing.service';

@Injectable({ providedIn: 'root' })
export class MatomoAbTestingFactory {
    constructor(
        private readonly matomoAbTestingService: MatomoAbTestingService,
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    public init(): void {
        if (
            'object' ===
                typeof this.windowReferenceService.nativeWindow.Piwik &&
            'object' ===
                typeof this.windowReferenceService.nativeWindow.Matomo
                    ?.AbTesting
        ) {
            // If matomo.js was embedded before this code
            this.matomoAbTestingService.createExperiments();
        } else {
            // If matomo.js is loaded after this code
            const existingListener =
                this.windowReferenceService.nativeWindow
                    .piwikAbTestingAsyncInit;
            this.windowReferenceService.nativeWindow.piwikAbTestingAsyncInit =
                () => {
                    this.matomoAbTestingService.createExperiments();
                    existingListener?.();
                };
        }
    }
}
