import { Injectable } from '@angular/core';
import { differenceInYears } from 'date-fns/differenceInYears';
import { CalculationParameterInformationService } from '@pkv-frontend/business-domain/calculation-parameter';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { UspChip } from '@pkv-frontend/data-domain/result';
import { DateValidator } from '@pkv-frontend/infrastructure/validators';

import { uspChipConstants } from '../constants/usp-chip.constants';

interface RelevantParameter
    extends Pick<
        CalculationParameterInterface,
        'insuredPerson' | 'profession' | 'childrenAge'
    > {
    birthdate: string | Date;
}

@Injectable({
    providedIn: 'root',
})
export class UspChipsMatcherService {
    constructor(
        private calculationParameterInformationService: CalculationParameterInformationService
    ) {}

    public match(item: UspChip, parameter: RelevantParameter): boolean {
        return (
            this.assertProfession(item.professionGroup, parameter) &&
            this.matchAgeGroup(item.ageGroup, parameter)
        );
    }

    private assertProfession(
        professionGroup: string | undefined,
        parameter: RelevantParameter
    ): boolean {
        if (professionGroup === undefined) {
            return false;
        }

        if (professionGroup === uspChipConstants.ALL) {
            return true;
        }

        if (professionGroup === uspChipConstants.PROFESSION_GROUP_CHILD) {
            return this.matchProfessionGroupChild(parameter);
        }

        if (
            professionGroup === uspChipConstants.PROFESSION_GROUP_SERVANT_CHILD
        ) {
            return this.matchProfessionGroupServantChild(parameter);
        }

        if (
            professionGroup ===
            uspChipConstants.PROFESSION_GROUP_CHILD_SERVANT_CHILD
        ) {
            return this.calculationParameterInformationService.isChild(
                parameter
            );
        }

        const whitelistedProfessions =
            uspChipConstants.PROFESSION_GROUP_PROFESSION_MAP[professionGroup] ||
            [];

        return (
            whitelistedProfessions.indexOf(parameter.profession) !== -1 &&
            !this.calculationParameterInformationService.isChild(parameter)
        );
    }

    public matchAgeGroup(
        ageGroup: string | undefined,
        parameter: RelevantParameter
    ): boolean {
        if (ageGroup === undefined) {
            return false;
        }

        if (ageGroup === uspChipConstants.ALL) {
            return true;
        }

        const [from, to] = ageGroup.split('_', 2);
        const parameterAge =
            this.calculationParameterInformationService.isChild(parameter) &&
            parameter.childrenAge
                ? parameter.childrenAge
                : this.calculateAge(parameter.birthdate);

        return (
            parameterAge >= this.toInt(from) && parameterAge <= this.toInt(to)
        );
    }

    private toInt(number: string): number {
        return parseInt(number, 10) || 0;
    }

    private calculateAge(birthdate: string | Date): number {
        if (DateValidator.isDate(birthdate)) {
            return differenceInYears(new Date(), birthdate);
        }

        return differenceInYears(new Date(), new Date(birthdate));
    }

    private matchProfessionGroupChild(parameter: RelevantParameter): boolean {
        return (
            this.calculationParameterInformationService.isChild(parameter) &&
            !this.calculationParameterInformationService.isServantOrServantCandidate(
                parameter
            )
        );
    }

    private matchProfessionGroupServantChild(
        parameter: RelevantParameter
    ): boolean {
        return (
            this.calculationParameterInformationService.isChild(parameter) &&
            this.calculationParameterInformationService.isServantOrServantCandidate(
                parameter
            )
        );
    }
}
