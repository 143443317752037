import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { ComparisonDataService } from '@pkv-frontend/business-domain/comparison';
import { enterResultPageAction } from '../actions/result.actions';

@Injectable()
export class LoadComparisonEyesEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly comparisonStoreService: ComparisonDataService
    ) {}

    public readonly loadComparisonEyesEffect$: Observable<Action> =
        createEffect(
            () => {
                return this.actions$.pipe(
                    ofType(enterResultPageAction),
                    tap((): void => {
                        this.comparisonStoreService.loadTariffsWithEyes();
                    })
                );
            },
            {
                dispatch: false,
            }
        );
}
