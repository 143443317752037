import { createAction, props } from '@ngrx/store';

import { TabTypeEnum } from '../../../result/enums/tab-type.enum';
import { PromotionsActionsEnum } from '../../enums/promotions-actions.enum';

export const toggleTabAction = createAction(
    PromotionsActionsEnum.ToggleTab,
    props<{
        tabType?: TabTypeEnum;
        tariffVersionId?: number;
        feedbackRatingFilter?: number;
    }>()
);
