import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

interface ExtendedDocument extends Document {
    documentMode: undefined | number;
}

@Injectable({ providedIn: 'root' })
export class UserHasUnsupportedBrowserRule {
    constructor(
        private windowReferenceService: WindowReferenceService,
        @Inject(DOCUMENT) private document: ExtendedDocument
    ) {}

    public result(): boolean {
        return (
            !!this.windowReferenceService.nativeWindow.MSInputMethodContext &&
            !!this.document.documentMode
        );
    }
}
