import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BackendFormErrorsMapper } from '@pkv-frontend/business-domain/form';
import {
    LeadCreationApiResponseModel,
    LeadCreationApiService,
} from '@pkv-frontend/data-domain/lead-expert';

import {
    createExpertLead,
    finishExpertLeadCreation,
    leadCreationError,
    leadCreationInvalidUserInput,
} from '../actions/consultant-callback-overlay.actions';

@Injectable()
export class CreateExpertLeadEffect {
    constructor(
        private actions$: Actions,
        private apiClient: LeadCreationApiService,
        private backendFormErrorsMapper: BackendFormErrorsMapper
    ) {}

    public initConsultantData$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(createExpertLead),
            switchMap((action: ReturnType<typeof createExpertLead>) => {
                return this.apiClient
                    .create(
                        action.leadCreationApiRequest,
                        action.calculationParameter
                    )
                    .pipe(
                        map((response: LeadCreationApiResponseModel) => {
                            return finishExpertLeadCreation({
                                redirectUrl: response.redirectUrl,
                            });
                        }),
                        catchError(
                            (
                                errorResponse: HttpErrorResponse
                            ): Observable<Action> => {
                                // return other action if the response contains errors
                                // that come from any field of the form

                                for (const errorKey in errorResponse.error) {
                                    const hasKnownError =
                                        !!this.backendFormErrorsMapper.byErrorKey(
                                            errorKey
                                        );
                                    if (hasKnownError) {
                                        return of(
                                            leadCreationInvalidUserInput({
                                                errors: errorResponse.error,
                                            })
                                        );
                                    }
                                }

                                return of(leadCreationError());
                            }
                        )
                    );
            })
        );
    });
}
