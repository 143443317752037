import { createSelector } from '@ngrx/store';
import { ResultCalculationParameter } from '@pkv-frontend/data-domain/result';
import { ResultState, ResultStateAware } from '../models/result-state.model';

import { resultStateFeatureSelector } from './result-state-feature.selector';

export const resultParameterSelector = createSelector<
    ResultStateAware,
    [ResultState],
    ResultCalculationParameter | undefined
>(resultStateFeatureSelector, (state: ResultState) => state.result?.parameter);
