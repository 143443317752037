import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
    AnnualIncomeValidator,
    GkvAutocompleteValidator,
} from '@pkv-frontend/business-domain/form';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { GkvFormEnum } from '../enums/gkv-form.enum';

export interface GkvTileFormData {
    [GkvFormEnum.Provider]: GkvResultItem;
    [GkvFormEnum.AnnualIncome]?: number;
}

export type GkvTileFormGroup = ReturnType<FormService['createGkvTileForm']>;
export type GkvFreelancerModalFormGroup = ReturnType<
    FormService['createFreelancerModalForm']
>;

@Injectable({
    providedIn: 'root',
})
export class FormService {
    public createGkvTileForm(autocompleteOption$: Observable<GkvResultItem[]>) {
        return new FormGroup({
            [GkvFormEnum.Provider]: new FormControl<GkvResultItem | null>(
                null,
                null,
                [GkvAutocompleteValidator.createValidator(autocompleteOption$)]
            ),
        });
    }

    public createFreelancerModalForm(
        autocompleteOption$: Observable<GkvResultItem[]>
    ) {
        return new FormGroup({
            [GkvFormEnum.Provider]: new FormControl<GkvResultItem | null>(
                null,
                {
                    validators: [Validators.required],
                    asyncValidators: [
                        GkvAutocompleteValidator.createValidator(
                            autocompleteOption$
                        ),
                    ],
                }
            ),
            [GkvFormEnum.AnnualIncome]: new FormControl<number | null>(null, [
                AnnualIncomeValidator,
            ]),
        });
    }
}
