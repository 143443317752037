import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { UserHasUnsupportedBrowserRule } from './user-has-unsupported-browser.rule';

const cookieName = 'c24ieinfo';

@Injectable({ providedIn: 'root' })
export class ShowUnsupportedBrowserBannerRule {
    constructor(
        private userHasUnsupportedBrowserRule: UserHasUnsupportedBrowserRule,
        private cookieService: CookieService
    ) {}

    public result(): boolean {
        return (
            this.userHasUnsupportedBrowserRule.result() &&
            this.cookieService.get(cookieName) !== '1'
        );
    }
}
