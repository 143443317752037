import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
    PositionParametersModel,
    SortApiParametersModel,
    SortFieldEnum,
    SortOrderEnum,
} from '@pkv-frontend/data-domain/result';

import { SORT_DEFAULT_VALUE_CONFIG_TOKEN } from '../constants/sort-default-injection-token.constant';

@Injectable({
    providedIn: 'root',
})
export class ApiParameterService {
    constructor(
        @Inject(SORT_DEFAULT_VALUE_CONFIG_TOKEN)
        private readonly sortFieldDefaultValue: SortFieldEnum
    ) {}

    public parseSortApiParameters(params: Params): SortApiParametersModel {
        return {
            sortfield: params.sortfield || this.sortFieldDefaultValue,
            sortorder: params.sortorder || SortOrderEnum.Asc,
        };
    }

    public parsePositionParameters(
        params: Params
    ): PositionParametersModel | undefined {
        const position = parseInt(params.position, 10);
        return isNaN(position) ? undefined : { position };
    }
}
