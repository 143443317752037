import { LeadCreationPageEnum } from '@pkv-frontend/data-domain/lead-expert';
import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';

export const featureRouteToCreationPageMappings: {
    route: FeatureRouteEnum;
    creationPage: LeadCreationPageEnum;
}[] = [
    {
        route: FeatureRouteEnum.RegisterPage,
        creationPage: LeadCreationPageEnum.Register,
    },
    {
        route: FeatureRouteEnum.ComparisonPage,
        creationPage: LeadCreationPageEnum.Comparison,
    },
    {
        route: FeatureRouteEnum.ResultPage,
        creationPage: LeadCreationPageEnum.Result,
    },
    {
        route: FeatureRouteEnum.DirectConsultationCallbackPage,
        creationPage: LeadCreationPageEnum.DirectConsultationCallback,
    },
    {
        route: FeatureRouteEnum.DirectConsultationCallbackPage2,
        creationPage:
            LeadCreationPageEnum.DirectConsultationCallbackInfoCampaign,
    },
    {
        route: FeatureRouteEnum.DirectConsultationAppointmentBookingPage,
        creationPage: LeadCreationPageEnum.DirectConsultation,
    },
];
