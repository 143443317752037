import {
    BulletPoint,
    BulletPointColorEnum,
    BulletPointTypeEnum,
    UspChip,
    UspChipTypeEnum,
} from '@pkv-frontend/data-domain/result';

export const gkvBulletsConstant: BulletPoint[] = [
    {
        color: BulletPointColorEnum.Red,
        textContent: '50%',
        textLabel: 'Zahnersatz',
        tooltipContent:
            '100% für Zahnbehandlung innerhalb des Leistungskatalogs, höherwertige Versorgung nur mit Eigenanteilen; ca. 50% für Zahnersatz',
        tooltipHeadline: 'Zahnersatz: 50%',
        type: BulletPointTypeEnum.Dental,
    },
    {
        color: BulletPointColorEnum.Green,
        textContent: '100%',
        textLabel: 'Direkt zum Facharzt',
        tooltipContent:
            '100% für ambulante Arztbehandlung innerhalb des Leistungskatalogs, nur bei Ärzten mit kassenärztlicher Zulassung',
        tooltipHeadline: 'Direkt zum Facharzt: 100%\n',
        type: BulletPointTypeEnum.MedicalSpecialistTreatment,
    },
    {
        color: BulletPointColorEnum.Gray,
        textContent: 'Mehrbettzimmer, Stationsarzt',
        textLabel: '',
        tooltipContent:
            'Unterbringung im Mehrbettzimmer, sowie Übernahme von Behandlung durch Stationsarzt',
        tooltipHeadline: 'Mehrbettzimmer, Stationsarzt\n',
    },
    {
        color: BulletPointColorEnum.Green,
        textContent: '0 €',
        textLabel: 'Selbstbeteiligung',
        tooltipContent:
            'Keine pauschale Selbstbeteiligung, jedoch Zuzahlungen in vielen Leistungsbereichen',
        tooltipHeadline: 'Selbstbeteiligung: 0 €\n',
        type: BulletPointTypeEnum.CostSharing,
    },
];

export const gkvUspConstant: UspChip = {
    order: 1,
    labelType: '' as UspChipTypeEnum,
    labelText: 'Stark eingeschränkter Leistungskatalog',
    tooltipText: '',
    professionGroup: 'all',
    ageGroup: 'all',
};
