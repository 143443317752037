import { AbstractControl } from '@angular/forms';
export function DateNotInThePast(
    control: AbstractControl
): { [key: string]: boolean } | null {
    if (control?.value) {
        const today = new Date();
        const dateToCheck = new Date(control.value);
        if (dateToCheck > today) {
            return { dateNotInThePast: true };
        }
    }
    return null;
}
