import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';

import { resultCustomFilterLowestPricePersistenceKey } from '../../../result/constants/result.constants';
import { setCustomPresetLowestPricesAction } from '../actions/filter.actions';

@Injectable()
export class PersistCustomFilterLowestPriceEffect {
    constructor(
        private actions$: Actions,
        private storageService: SessionStorageService,
        private phpFrontendDataService: PhpFrontendDataService
    ) {}

    public persistCustomFilterLowestPriceEffect$: Observable<void> =
        createEffect(
            () => {
                return this.actions$.pipe(
                    ofType(setCustomPresetLowestPricesAction),
                    map(
                        (
                            action: ReturnType<
                                typeof setCustomPresetLowestPricesAction
                            >
                        ) => {
                            if (
                                action.lowestPrice &&
                                !this.phpFrontendDataService.data
                                    .isConsultantLoggedIn
                            ) {
                                this.storageService.set(
                                    resultCustomFilterLowestPricePersistenceKey,
                                    action.lowestPrice
                                );
                            }
                        }
                    )
                );
            },
            { dispatch: false }
        );
}
