import { createSelector } from '@ngrx/store';
import { GkvTariffTileStateEnum } from '../../enums/gkv-tariff-tile-state.enum';
import { GkvStateAware, GkvStateModel } from '../models/gkv-state.model';

import { gkvStateSelector } from './gkv-state.selector';

export const gkvTileStateSelector = createSelector<
    GkvStateAware,
    [GkvStateModel],
    GkvTariffTileStateEnum
>(gkvStateSelector, (state: GkvStateModel) => state.state);
