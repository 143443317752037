import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslatePromotionTypePipe } from './translate-promotion-type.pipe';

@NgModule({
    declarations: [TranslatePromotionTypePipe],
    imports: [CommonModule],
    exports: [TranslatePromotionTypePipe],
})
export class TranslatePromotionTypeModule {}
