import { Injectable } from '@angular/core';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';

import { ComparisonTableIsOpenState } from '../models/comparison-table-is-open-state.model';

const storageKey = 'pkv.comparison.table.is.open.state';

@Injectable({
    providedIn: 'root',
})
export class ComparisonTableIsOpenSatePersistenceService {
    constructor(private persistenceService: SessionStorageService) {}

    public set(values: ComparisonTableIsOpenState): void {
        this.persistenceService.set(storageKey, values);
    }

    public get(): ComparisonTableIsOpenState | undefined {
        return this.persistenceService.get(storageKey);
    }
}
