import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComparisonPersistenceService } from '../../service/comparison-persistence.service';
import {
    addTariffsToComparisonAction,
    removeAllTariffsFromComparisonAction,
    removeGkvTariffFromComparisonAction,
    removeTariffsFromComparisonAction,
} from '../actions/comparison.actions';
import { ComparisonTariffItem } from '../models/comparison-tariff-item.model';
import { comparisonTariffsSelector } from '../selectors/comparison-tariffs.selector';

type ComparisonActionReturnTypes =
    | ReturnType<typeof addTariffsToComparisonAction>
    | ReturnType<typeof removeTariffsFromComparisonAction>
    | ReturnType<typeof removeAllTariffsFromComparisonAction>;

@Injectable()
export class ComparisonUpdatePersistenceEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly comparisonPersistenceService: ComparisonPersistenceService
    ) {}

    public comparisonUpdatePersistenceEffect$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    addTariffsToComparisonAction,
                    removeTariffsFromComparisonAction,
                    removeAllTariffsFromComparisonAction,
                    removeGkvTariffFromComparisonAction
                ),
                concatLatestFrom(() =>
                    this.store$.select(comparisonTariffsSelector)
                ),
                map(
                    ([, comparisonItems]: [
                        ComparisonActionReturnTypes,
                        ComparisonTariffItem[],
                    ]): void => {
                        this.comparisonPersistenceService.update(
                            comparisonItems
                        );
                    }
                )
            );
        },
        { dispatch: false }
    );
}
