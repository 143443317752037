import { createSelector } from '@ngrx/store';
import { TariffInfo } from '@pkv-frontend/data-domain/result';
import { ResultStateAware } from '../models/result-state.model';

import { resultCalculationResponseSelector } from './result-calculation-response.selector';

export const resultTariffInfoSelector = createSelector<
    ResultStateAware,
    [ReturnType<typeof resultCalculationResponseSelector>],
    TariffInfo | undefined
>(
    resultCalculationResponseSelector,
    (result: ReturnType<typeof resultCalculationResponseSelector>) =>
        result?.tariffinfo
);
