import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import * as deepEquals from 'fast-deep-equal';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
    LastSearchBuilder,
    LastSearchParameterParser,
} from '@pkv-frontend/business-domain/last-search';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import {
    LastSearchApiService,
    LastSearchTariffInfo,
} from '@pkv-frontend/data-domain/last-search';

import { resultFilterCalculationParametersSelector } from '../../../filter/store/selectors/result-filter-calculation-parameters.selector';
import { resultApiResultsLoadedAction } from '../../../result/store/actions/result.actions';

@Injectable()
export class PersistLastSearchEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly lastSearchBuilder: LastSearchBuilder,
        private readonly lastSearchParameterParser: LastSearchParameterParser,
        private readonly lastSearchApiService: LastSearchApiService
    ) {}

    public persistLastSearchEffect$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(resultApiResultsLoadedAction),
                concatLatestFrom(() =>
                    this.store$.select(
                        resultFilterCalculationParametersSelector
                    )
                ),
                map(
                    ([action, calculationParameter]: [
                        ReturnType<typeof resultApiResultsLoadedAction>,
                        CalculationParameterInterface,
                    ]) => {
                        const lastSearchTariffInfo: LastSearchTariffInfo = {
                            count:
                                action.result.tariffinfo?.tariffNumber ?? null,
                            price:
                                action.result.tariffinfo?.minTariffPrice ??
                                null,
                            annualSavingsPotential:
                                action.result.tariffinfo?.maxSavingsPerYear ??
                                null,
                        };

                        const lastSearch = this.lastSearchBuilder.build(
                            calculationParameter,
                            lastSearchTariffInfo
                        );

                        return this.lastSearchParameterParser.parse(lastSearch);
                    }
                ),
                distinctUntilChanged(deepEquals),
                map((params: Params) => {
                    this.lastSearchApiService.persist(params).subscribe();
                })
            );
        },
        { dispatch: false }
    );
}
