import { createAction, props } from '@ngrx/store';
import { LeadCreationTypeEnum } from '@pkv-frontend/business-domain/lead';
import { LegacyCalculationParameter } from '@pkv-frontend/data-domain/calculation-parameter';
import {
    AppointmentBookingDataResponseModel,
    ConsultantCallbackApiRequestModel,
    ExpertLeadApiRequest,
    LeadCreationApiResponseModel,
} from '@pkv-frontend/data-domain/lead-expert';

import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import { ConsultantOverlayCloseByTypeEnum } from '../../enums/consultant-overlay-close-by-type.enum';
import { ConsultantOverlayContentTypeEnum } from '../../enums/consultant-overlay-content-type.enum';
import { ServerErrorResponseInterface } from '../models/server-error-response.interface';

enum ConsultantCallbackOverlayActions {
    InitOverlay = '[Consultant-Callback-Overlay] Init overlay',
    OpenOverlay = '[Consultant-Callback-Overlay] Open overlay',
    CloseOverlay = '[Consultant-Callback-Overlay] Close overlay',
    BackToForm = '[Consultant-Callback-Overlay] Back to form',
    ChangeOverlayContentType = '[Consultant-Callback-Overlay] Change overlay content type',
    CreateConsultantCallbackExpertLead = '[Consultant-Callback-Overlay] Create consultant callback expert lead',

    CreateExpertLead = '[Consultant-Callback-Overlay] Create expert lead',
    FinishExpertLeadCreation = '[Consultant-Callback-Overlay] Finish expert lead creation',

    RequestConsultantCallback = '[Consultant-Callback-Overlay] Request consultant callback',
    FinishConsultantCallbackRequest = '[Consultant-Callback-Overlay] Finish consultant callback',

    SetLeadCreationState = '[Consultant-Carousel] Set Lead Creation State',
    LeadCreationError = '[Consultant-Callback-Overlay] Lead creation failed',
    LeadCreationInvalidUserInput = '[Consultant-Callback-Overlay] Lead creation has invalid user input ',
    AppointmentBookingPreCheckRequest = '[Consultant-Callback-Overlay] Appointment booking pre check request',
}

export const initOverlay = createAction(
    ConsultantCallbackOverlayActions.InitOverlay
);

export const openOverlay = createAction(
    ConsultantCallbackOverlayActions.OpenOverlay,
    props<{
        leadCreationType: LeadCreationTypeEnum;
    }>()
);

export const closeOverlay = createAction(
    ConsultantCallbackOverlayActions.CloseOverlay,
    props<{
        closeByType: ConsultantOverlayCloseByTypeEnum;
    }>()
);

export const backToForm = createAction(
    ConsultantCallbackOverlayActions.BackToForm,
    props<{
        leadCreationType: LeadCreationTypeEnum;
    }>()
);

export const leadCreationInvalidUserInput = createAction(
    ConsultantCallbackOverlayActions.LeadCreationInvalidUserInput,
    props<{ errors: ServerErrorResponseInterface }>()
);

export const changeOverlayContentType = createAction(
    ConsultantCallbackOverlayActions.ChangeOverlayContentType,
    props<{
        contentType: ConsultantOverlayContentTypeEnum;
        appointmentBookingData?: AppointmentBookingDataResponseModel;
        leadCreationState?: RequestStateEnum;
    }>()
);

export const setLeadCreationState = createAction(
    ConsultantCallbackOverlayActions.SetLeadCreationState,
    props<{
        state: RequestStateEnum;
    }>()
);

export const createExpertLead = createAction(
    ConsultantCallbackOverlayActions.CreateExpertLead,
    props<{
        leadCreationApiRequest: ExpertLeadApiRequest;
        calculationParameter: LegacyCalculationParameter;
    }>()
);

export const finishExpertLeadCreation = createAction(
    ConsultantCallbackOverlayActions.FinishExpertLeadCreation,
    props<{ redirectUrl: string }>()
);

export const leadCreationError = createAction(
    ConsultantCallbackOverlayActions.LeadCreationError
);

export const createConsultantCallbackLead = createAction(
    ConsultantCallbackOverlayActions.CreateConsultantCallbackExpertLead,
    props<{
        leadCreationApiRequest: ConsultantCallbackApiRequestModel;
        calculationParameter: LegacyCalculationParameter;
    }>()
);

export const requestConsultantCallback = createAction(
    ConsultantCallbackOverlayActions.RequestConsultantCallback,
    props<{
        leadCreationApiResponse: LeadCreationApiResponseModel;
        phoneNumber: string;
    }>()
);

export const finishConsultantCallbackRequest = createAction(
    ConsultantCallbackOverlayActions.FinishConsultantCallbackRequest
);

export const appointmentBookingPreCheckRequest = createAction(
    ConsultantCallbackOverlayActions.AppointmentBookingPreCheckRequest,
    props<{
        leadCreationApiRequest: ExpertLeadApiRequest;
        calculationParameter: LegacyCalculationParameter;
        birthdate: string;
    }>()
);
