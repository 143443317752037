export const professionTranslationConstants = {
    freelancer: 'Selbstständiger/Freiberufler',
    employee: 'Angestellter',
    servant: 'Beamter',
    servant_candidate: 'Beamtenanwärter',
    student: 'Student',
    unemployed: 'Nicht erwerbstätig',
    child: 'Kind',
    servant_child: 'Beamtenkind',
    unknown: 'unbekannt',
};
