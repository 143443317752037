import { QueryParameterMapTypesEnum } from '../enums/query-parameter-map-types.enum';
import { QueryParameterMap } from '../models/query-parameter-map.model';

export const queryParameterMap: QueryParameterMap = {
    directMedicalConsultationBenefit: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'direct_medical_consultation_benefit',
    },
    provisionContributionReimbursement: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'provision_contribution_reimbursement',
    },
    evaluationContributionStability: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'evaluation_contribution_stability',
    },
    dental: {
        type: QueryParameterMapTypesEnum.String,
        key: 'dental',
    },
    dentalTreatment: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'dental_treatment',
        default: '0',
    },
    dentalDentures: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'dental_dentures',
        default: '0',
    },
    hospitalizationAccommodation: {
        type: QueryParameterMapTypesEnum.String,
        key: 'hospitalization_accommodation',
    },
    pdhospitalPayoutAmountValue: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'pdhospital_payout_amount_value',
    },
    pdhospitalPayoutStart: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'pdhospital_payout_start',
    },
    betAmount: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'bet_amount',
        default: '0',
    },
    benefitPreset: {
        type: QueryParameterMapTypesEnum.String,
        key: 'benefit_preset',
    },
    birthdate: {
        type: QueryParameterMapTypesEnum.Date,
        key: 'birthdate',
    },
    check24Grade: {
        type: QueryParameterMapTypesEnum.String,
        key: 'check24_grade',
    },
    insureDate: {
        type: QueryParameterMapTypesEnum.Date,
        key: 'insure_date',
    },
    insuredPerson: {
        type: QueryParameterMapTypesEnum.String,
        key: 'insured_person',
    },
    profession: {
        type: QueryParameterMapTypesEnum.String,
        key: 'profession',
    },
    provisionCostsharingLimit: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'provision_costsharing_limit',
    },
    reducedResults: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'reduced_results',
        default: 'yes',
    },
    amedNonMedPractitionerReimbursement: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'amed_non_med_practitioner_reimbursement',
    },
    treatmentAboveMaximumRate: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'treatment_above_maximum_rate',
    },
    treatmentAttendingDoctor: {
        type: QueryParameterMapTypesEnum.String,
        key: 'treatment_attending_doctor',
    },
    parentServantOrServantCandidate: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'parent_servant_or_servant_candidate',
    },
    provider: {
        type: QueryParameterMapTypesEnum.Array,
        key: 'provider',
    },
    cureAndRehab: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'cure_and_rehab',
    },
    createNewCalculationParameterId: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'create_new_calculationparameter_id',
    },
    calculationParameterId: {
        type: QueryParameterMapTypesEnum.String,
        key: 'calculationparameter_id',
    },
    parent1Insured: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'parent1_insured',
    },
    parent2Insured: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'parent2_insured',
    },
    childrenAge: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'children_age',
    },
    effectivePrice: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'effective_price',
        default: 'no',
    },
    contributionCarrier: {
        type: QueryParameterMapTypesEnum.String,
        key: 'contribution_carrier',
    },
    contributionRate: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'contribution_rate',
    },
    annualIncome: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'annual_income',
    },
    gkvCurrentInsurer: {
        type: QueryParameterMapTypesEnum.Integer,
        key: 'gkv_current_insurer',
    },
    debug: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'debug',
    },
    c24debug: {
        type: QueryParameterMapTypesEnum.Boolean,
        key: 'c24debug',
    },
};
