import { AbstractControl, ValidatorFn } from '@angular/forms';

export function StepValidator(step: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value % step !== 0) {
            return { invalidStep: true };
        }

        return null;
    };
}
