import { getCalendarDataUrl } from './calender-data-url.function';
import { EnvironmentInterface } from './environment.interface';

export const environment: EnvironmentInterface = {
    production: true,
    apiUrls: {
        calendarData: getCalendarDataUrl,
        comparison: '/api/public/v1/comparison/',
        continueAppointmentSession:
            '/api/v1/public/consultant/appointment/continue',
        cancelAppointmentSession:
            '/api/v1/public/consultant/appointment/cancel',
    },
    consultantEmail: 'pkv@check24.de',
    assetsRoot: '/frontend/desktop/assets/',
};
