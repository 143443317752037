import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { applicationFeatureKey } from './models/application-state.model';
import { applicationReducers } from './reducers/application-reducers';

@NgModule({
    imports: [
        StoreModule.forFeature(applicationFeatureKey, applicationReducers),
    ],
})
export class ApplicationStoreModule {}
