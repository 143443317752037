import { Injectable } from '@angular/core';

import { LegacyCalculationParameter } from '../../models/legacy-calculation-parameter.model';

const allowedEmptyParameters = ['treatment_attending_doctor'];

@Injectable({
    providedIn: 'root',
})
export class QueryCalculationParameterParser {
    public parse(
        legacyCalculationParameter: LegacyCalculationParameter
    ): URLSearchParams {
        const queryParams: URLSearchParams = new URLSearchParams();

        Object.entries(legacyCalculationParameter).forEach(([key, value]) => {
            if (this.shouldAppend(value, key)) {
                const actualKey = key.endsWith('[]')
                    ? key.substring(0, key.length - 2)
                    : key;
                queryParams.append(
                    actualKey,
                    encodeURIComponent(String(value))
                );
            }
        });
        return queryParams;
    }

    private shouldAppend(value: unknown, key: string) {
        return (
            this.isNotAnEmptyArray(value) ||
            this.isNotAnEmptyString(value, key) ||
            typeof value === 'number'
        );
    }

    private isNotAnEmptyArray(value: unknown): boolean {
        return Array.isArray(value) && value.length > 0;
    }

    private isNotAnEmptyString(value: unknown, key: string): boolean {
        return (
            typeof value === 'string' &&
            (value.trim() !== '' || allowedEmptyParameters.includes(key))
        );
    }
}
