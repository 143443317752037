import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LegacyCalculationParameter } from '@pkv-frontend/data-domain/calculation-parameter';
import { convertSnakeCaseObjectPropertiesToCamelCase } from '@pkv-frontend/infrastructure/converter';
import { ResultApiParametersModel } from '../filter/models/result-api-parameters.model';
import { GetResultCalculationResponse } from '../models/get-result-calculation-response';

const url = '/api/v1/public/customer-frontend/result/calculation';

@Injectable({
    providedIn: 'root',
})
export class ResultCalculationApiClient {
    constructor(private httpClient: HttpClient) {}

    private buildParams(
        params: LegacyCalculationParameter,
        apiParameters: ResultApiParametersModel
    ): HttpParams {
        let httpParams = new HttpParams();
        // TODO: default value in api?
        httpParams = httpParams.set('product_id', 21);

        for (const [key, value] of Object.entries(params)) {
            if (value === undefined) {
                continue;
            }

            const actualKey = key.endsWith('[]')
                ? key.substring(0, key.length - 2)
                : key;
            httpParams = httpParams.set(actualKey, value);
        }

        for (const [key, value] of Object.entries(apiParameters)) {
            if (value !== undefined) {
                httpParams = httpParams.set(key, value);
            }
        }

        return httpParams;
    }

    public fetch(
        calculationParameter: LegacyCalculationParameter,
        apiParameters: ResultApiParametersModel
    ): Observable<GetResultCalculationResponse> {
        return this.httpClient
            .get<GetResultCalculationResponse>(url, {
                params: this.buildParams(calculationParameter, apiParameters),
            })
            .pipe(map(convertSnakeCaseObjectPropertiesToCamelCase));
    }
}
