import { createSelector } from '@ngrx/store';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import {
    ConsultantCarouselState,
    ConsultantCarouselStateAware,
} from '../models/consultant-carousel-state.model';

import { consultantCarouselStateSelector } from './consultant-carousel-state.selector';

export const callbackRequestStateSelector = createSelector<
    ConsultantCarouselStateAware,
    [ConsultantCarouselState],
    RequestStateEnum
>(
    consultantCarouselStateSelector,
    (state: ConsultantCarouselState): RequestStateEnum =>
        state.callbackRequestState
);
