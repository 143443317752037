import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@pkv-frontend/data-domain/persistence';
import { UserDataPersistenceFieldNamesEnum } from '../enums/user-data-persistence-field-names.enum';
import { UserDataPersistenceModel } from '../models/user-data-persistence.model';
import { USER_DATA_PERSISTENCE_KEY_TOKEN } from '../tokens/user-data-persistence-key.token';

const ALLOWED_FIELD_NAMES: string[] = [
    UserDataPersistenceFieldNamesEnum.InsureInput,
    UserDataPersistenceFieldNamesEnum.Gender,
    UserDataPersistenceFieldNamesEnum.Title,
    UserDataPersistenceFieldNamesEnum.FirstName,
    UserDataPersistenceFieldNamesEnum.LastName,
    UserDataPersistenceFieldNamesEnum.MobileNumber,
    UserDataPersistenceFieldNamesEnum.Email,
    UserDataPersistenceFieldNamesEnum.InsureGender,
    UserDataPersistenceFieldNamesEnum.InsureTitle,
    UserDataPersistenceFieldNamesEnum.InsureFirstname,
    UserDataPersistenceFieldNamesEnum.InsureLastname,
];

@Injectable({ providedIn: 'root' })
export class UserDataPersistenceService {
    constructor(
        private readonly persistenceService: LocalStorageService,
        @Inject(USER_DATA_PERSISTENCE_KEY_TOKEN)
        private readonly persistenceKey: string
    ) {}

    public update(data: UserDataPersistenceModel): void {
        const userData = this.load() || {};

        Object.entries(data).forEach(([key, value]) => {
            if (!ALLOWED_FIELD_NAMES.includes(key) || !value) {
                return;
            }

            userData[key] = value;
        });

        if (Object.keys(userData).length === 0) {
            return;
        }

        this.persistenceService.set(this.persistenceKey, userData);
    }

    public load(): UserDataPersistenceModel | undefined {
        return this.persistenceService.get(this.persistenceKey);
    }
}
