import { Injectable } from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, take, tap } from 'rxjs/operators';
import { PhoneNumberValidationApiClient } from '@pkv-frontend/data-domain/form';

@Injectable({ providedIn: 'root' })
export class PhoneNumberApiValidator implements AsyncValidator {
    constructor(
        private phoneNumberValidationApiClient: PhoneNumberValidationApiClient
    ) {}

    public validate(
        control: AbstractControl
    ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return this.phoneNumberValidationApiClient.validate(control.value).pipe(
            take(1),
            mapTo(null),
            catchError(() => {
                return of({ pattern: true });
            }),
            tap(() => control.markAsTouched())
        );
    }
}
