import { createReducer, on } from '@ngrx/store';
import { SsoUserDataStateEnum } from '../../enums/sso-user-data-state.enum';
import {
    fetchSsoUserDataFailureAction,
    fetchSsoUserDataSuccessAction,
} from '../actions/sso-user-data.actions';
import { SsoUserDataStateModel } from '../models/sso-user-data-state.model';

export const initialState: SsoUserDataStateModel = {
    [SsoUserDataStateEnum.SsoUserData]: undefined,
    [SsoUserDataStateEnum.Error]: undefined,
};

export const ssoUserDataReducers = createReducer(
    initialState,
    on(
        fetchSsoUserDataSuccessAction,
        (
            state: SsoUserDataStateModel,
            action: ReturnType<typeof fetchSsoUserDataSuccessAction>
        ): SsoUserDataStateModel => {
            return {
                ...state,
                [SsoUserDataStateEnum.SsoUserData]: action.ssoUserData,
            };
        }
    ),
    on(
        fetchSsoUserDataFailureAction,
        (
            state: SsoUserDataStateModel,
            action: ReturnType<typeof fetchSsoUserDataFailureAction>
        ): SsoUserDataStateModel => {
            return {
                ...state,
                [SsoUserDataStateEnum.Error]: action.error,
            };
        }
    )
);
