import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import { resultGkvTileClosedPersistenceKey } from '../../../result/constants/result.constants';
import { enterResultPageAction } from '../../../result/store/actions/result.actions';
import { setGkvTileStateFromStorageAction } from '../actions/set-gkv-tile-state-from-storage.action';

@Injectable()
export class LoadGkvTileStateFromStorageEffect {
    constructor(
        private actions$: Actions,
        private storageService: SessionStorageService
    ) {}

    public loadGkvTileStateFromStorageEffect$: Observable<Action> =
        createEffect(() => {
            return this.actions$.pipe(
                ofType(enterResultPageAction),
                map(() => {
                    const isClosed = !!this.storageService.get(
                        resultGkvTileClosedPersistenceKey
                    );

                    return setGkvTileStateFromStorageAction({
                        isClosed,
                    });
                })
            );
        });
}
