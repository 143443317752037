import { createSelector } from '@ngrx/store';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { showEffectivePriceToggleRule } from '../../rules/show-effective-price-toggle.rule';
import { FilterStateAware } from '../models/filter-state.model';

import { resultFilterCalculationParametersSelector } from './result-filter-calculation-parameters.selector';

export const resultFilterShowEffectivePriceToggleSelector = createSelector<
    FilterStateAware,
    [CalculationParameterInterface | undefined],
    boolean
>(resultFilterCalculationParametersSelector, showEffectivePriceToggleRule);
