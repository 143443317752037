import { Inject, Injectable } from '@angular/core';

import { CalculationParameterInformationService } from '@pkv-frontend/business-domain/calculation-parameter';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';
import {
    DEVICE_ENVIRONMENT_TOKEN,
    DeviceEnvironmentEnum,
} from '@pkv-frontend/infrastructure/interfaces';

@Injectable({
    providedIn: 'root',
})
export class ShowPdhospitalPayoutStartRule {
    constructor(
        private readonly calculationParameterInformationService: CalculationParameterInformationService,
        private readonly phpFrontendDataService: PhpFrontendDataService,
        @Inject(DEVICE_ENVIRONMENT_TOKEN)
        private readonly deviceEnvironment: DeviceEnvironmentEnum = DeviceEnvironmentEnum.Mobile
    ) {}

    public apply(calculationParameter: CalculationParameterInterface): boolean {
        return (
            this.calculationParameterInformationService.isFreelancer(
                calculationParameter
            ) ||
            (this.calculationParameterInformationService.isEmployeeAndAdult(
                calculationParameter
            ) &&
                this.phpFrontendDataService.data.isConsultantLoggedIn &&
                this.deviceEnvironment === DeviceEnvironmentEnum.Desktop)
        );
    }
}
