import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoEventTrackingData } from '@vv-ham/ng-tracking';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import {
    LowestPrices,
    ResultCalculationParameter,
    ResultDebugData,
    ResultItem,
    SkeletonResultItem,
} from '@pkv-frontend/data-domain/result';
import { StateInsurancePrices } from '@pkv-frontend/data-domain/state-insurance';
import { effectivePriceToggleChangeAction } from '../../filter';
import { resultFilterCalculationParametersSelector } from '../../filter/store/selectors/result-filter-calculation-parameters.selector';
import { resultFilterEffectivePriceSelector } from '../../filter/store/selectors/result-filter-effective-price.selector';
import { resultFilterIsLoadingSelector } from '../../filter/store/selectors/result-filter-is-loading.selector';
import { resultFilterProviderIdsSelector } from '../../filter/store/selectors/result-filter-provider-ids.selector';
import { GkvTariffTileStateEnum } from '../../gkv/enums/gkv-tariff-tile-state.enum';
import { gkvPriceTooltipDefaultContributionPercentageRule } from '../../gkv/rules/gkv-price-tooltip-default-contribution-percentage.rule';
import { GkvTileFormData } from '../../gkv/services/form.service';
import { closeGkvTileAction } from '../../gkv/store/actions/close-gkv-tile.action';
import { gkvProviderChangeAction } from '../../gkv/store/actions/gkv-provider-change.action';
import { gkvPriceTextSelector } from '../../gkv/store/selectors/gkv-price-text.selector';
import { gkvPriceSelector } from '../../gkv/store/selectors/gkv-price.selector';
import { gkvTariffsSelector } from '../../gkv/store/selectors/gkv-tariffs.selector';
import { gkvTileStateSelector } from '../../gkv/store/selectors/gkv-tile-state.selector';
import { selectedOrAverageGkvSelector } from '../../gkv/store/selectors/selected-or-average-gkv.selector';
import { toggleTabAction } from '../../promotion/store/actions/toggle-tab.action';
import { TabTypeEnum } from '../enums/tab-type.enum';
import { TariffExpansionModeEnum } from '../enums/tariff-expansion-mode.enum';
import {
    enterResultPageAction,
    redirectToRegisterAction,
    resetResult,
    resultPageHistoryBackTriggeredAction,
    resultPageUrlChangeAction,
    toggleTariffExpansionModeAction,
} from '../store/actions/result.actions';

import { resultC24debugSelector } from '../store/selectors/result-c24debug.selector';
import { resultCalculationParameterIdSelector } from '../store/selectors/result-calculation-parameter-id.selector';
import { resultCalculationResultSelector } from '../store/selectors/result-calculation-result.selector';
import { resultLowestPricesSelector } from '../store/selectors/result-lowest-prices.selector';
import { resultParameterSelector } from '../store/selectors/result-parameter.selector';
import { resultStateInsurancePricesSelector } from '../store/selectors/result-state-insurance-prices.selector';
import { resultTariffInfoSelector } from '../store/selectors/result-tariff-info.selector';
import { silentResultLowestPricesSelector } from '../store/selectors/silent-result-lowest-prices.selector';
import { silentResultTariffInfoSelector } from '../store/selectors/silent-result-tariff-info.selector';

@Injectable({
    providedIn: 'root',
})
export class ResultDataService {
    public readonly results$: Observable<
        Array<ResultItem | SkeletonResultItem> | undefined
    > = this.store$.select(resultCalculationResultSelector);

    public readonly lowestPrices$: Observable<LowestPrices | undefined> =
        combineLatest([
            this.store$.select(silentResultLowestPricesSelector),
            this.store$.select(resultLowestPricesSelector),
        ]).pipe(
            map(
                ([silentLowestPrice, resultLowestPrice]: [
                    LowestPrices | undefined,
                    LowestPrices | undefined,
                ]) => silentLowestPrice || resultLowestPrice
            ),
            filter((lowestPrices) => !!lowestPrices)
        );

    public readonly availableProviders$: Observable<number[]> = combineLatest([
        this.store$.select(resultFilterProviderIdsSelector),
        this.lowestPrices$.pipe(startWith(undefined)),
    ]).pipe(
        map(([providerIds, lowestPrices]): number[] => {
            if (!lowestPrices || !lowestPrices.providers) {
                return providerIds;
            }
            const lowestPricePermutations = lowestPrices.providers;
            return providerIds.filter(
                (providerId) =>
                    !!lowestPricePermutations[providerId]?.tariffCount
            );
        })
    );

    public readonly stateInsurancePrices$: Observable<
        StateInsurancePrices | undefined
    > = this.store$.select(resultStateInsurancePricesSelector);

    public readonly calculationParameter$: Observable<
        CalculationParameterInterface | undefined
    > = this.store$.select(resultFilterCalculationParametersSelector);

    public readonly effectivePriceEnabled$ = this.store$.select(
        resultFilterEffectivePriceSelector
    );

    public readonly isLoading$: Observable<boolean> = this.store$.select(
        resultFilterIsLoadingSelector
    );

    public readonly calculationParameterId$: Observable<string | undefined> =
        this.store$.select(resultCalculationParameterIdSelector);

    public readonly tariffInfo$ = this.store$.select(resultTariffInfoSelector);
    public readonly silentResultTariffInfo$ = this.store$.select(
        silentResultTariffInfoSelector
    );

    public readonly parameter$: Observable<
        ResultCalculationParameter | undefined
    > = this.store$.select(resultParameterSelector);

    public readonly c24DebugData$: Observable<ResultDebugData | undefined> =
        this.store$.select(resultC24debugSelector);

    public readonly gkvItems$: Observable<GkvResultItem[] | undefined> =
        this.store$.select(gkvTariffsSelector);

    public readonly currentGkv$: Observable<GkvResultItem | undefined> =
        this.store$.select(selectedOrAverageGkvSelector);

    public readonly gkvTileState$: Observable<GkvTariffTileStateEnum> =
        this.store$.select(gkvTileStateSelector);

    public readonly gkvPrice$: Observable<number | undefined> =
        this.store$.select(gkvPriceSelector);

    public readonly gkvText$: Observable<string> =
        this.store$.select(gkvPriceTextSelector);

    public readonly gkvContributionPercentage$: Observable<number | undefined> =
        combineLatest([
            this.store$.select(selectedOrAverageGkvSelector),
            this.stateInsurancePrices$,
        ]).pipe(
            map(
                ([item, stateInsurancePrices]: [
                    GkvResultItem | undefined,
                    StateInsurancePrices | undefined,
                ]) =>
                    gkvPriceTooltipDefaultContributionPercentageRule(
                        item,
                        stateInsurancePrices
                    )
            )
        );

    public readonly gkvProviderName$: Observable<string | undefined> =
        this.store$
            .select(selectedOrAverageGkvSelector)
            .pipe(
                map((item: GkvResultItem | undefined) => item?.provider.name)
            );

    constructor(private readonly store$: Store) {}

    public enter(): void {
        this.store$.dispatch(enterResultPageAction());
    }

    public commitUrlChange(params: Params, silent = false): void {
        this.store$.dispatch(
            resultPageUrlChangeAction({ params: params, silent: silent })
        );
    }

    public historyBackTriggered(): void {
        this.store$.dispatch(resultPageHistoryBackTriggeredAction());
    }

    public toggleTariffExpansionMode(
        expansionModeKey: string,
        tariffExpansionMode: TariffExpansionModeEnum,
        trackingConfig?: MatomoEventTrackingData,
        feedbackRatingFilter?: number
    ): void {
        this.store$.dispatch(
            toggleTariffExpansionModeAction({
                expansionModeKey: expansionModeKey,
                mode: tariffExpansionMode,
                feedbackRatingFilter,
                trackingConfig,
            })
        );
    }

    public togglePromotionTab(
        tabType: TabTypeEnum,
        tariffVersionId: number,
        feedbackRatingFilter?: number
    ): void {
        this.store$.dispatch(
            toggleTabAction({
                tabType,
                tariffVersionId,
                feedbackRatingFilter,
            })
        );
    }

    public redirectToRegister(tariffVersionId: number): void {
        this.store$.dispatch(
            redirectToRegisterAction({
                tariffVersionId: tariffVersionId,
            })
        );
    }

    public closeGkvTile(): void {
        this.store$.dispatch(closeGkvTileAction());
    }

    public updateGkvProvider(formData: GkvTileFormData): void {
        this.store$.dispatch(gkvProviderChangeAction({ formData }));
    }

    public effectivePriceToggleChange(enabled: boolean): void {
        this.store$.dispatch(effectivePriceToggleChangeAction({ enabled }));
    }

    public resetResult(): void {
        this.store$.dispatch(resetResult());
    }

    public resetSilentResult(): void {
        this.store$.dispatch(resetResult());
    }
}
