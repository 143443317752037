import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
    InstantAppointmentAvailable,
    InstantAppointmentRequestApiClient,
} from '@pkv-frontend/data-domain/instant-appointment';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import {
    finishIsCallbackAvailableCall,
    initCarousel,
    resetCarouselControlsAction,
    startIsCallbackAvailableCall,
} from '../actions/consultant-carousel.actions';

@Injectable()
export class CallbackAvailabilityEffect {
    constructor(
        private actions$: Actions,
        private apiClient: InstantAppointmentRequestApiClient
    ) {}

    public startIsCallbackAvailableCall: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    startIsCallbackAvailableCall,
                    initCarousel,
                    resetCarouselControlsAction
                ),
                exhaustMap(() =>
                    this.apiClient.getCanShowDirectAppointment().pipe(
                        map(
                            (
                                instantAppointmentAvailable: InstantAppointmentAvailable
                            ) =>
                                finishIsCallbackAvailableCall({
                                    isAvailable:
                                        instantAppointmentAvailable.isAvailable,
                                    requestState: RequestStateEnum.Success,
                                })
                        ),
                        catchError(() =>
                            of(
                                finishIsCallbackAvailableCall({
                                    isAvailable: false,
                                    requestState: RequestStateEnum.Error,
                                })
                            )
                        )
                    )
                )
            );
        }
    );
}
