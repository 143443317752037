import { createAction, props } from '@ngrx/store';

import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { Consultant } from '@pkv-frontend/data-domain/result';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import { actionTypeFactoryBuilder } from '@pkv-frontend/infrastructure/store';
import { consultantCarouselFeatureKey } from '../models/consultant-carousel-state.model';

const actionFactory = actionTypeFactoryBuilder(consultantCarouselFeatureKey);

export const initConsultantData = createAction(
    actionFactory('Init consultant data'),
    props<{ profession: ProfessionEnum }>()
);

export const finishInitConsultantData = createAction(
    actionFactory('Finish init consultant data'),
    props<{
        consultants: Consultant[];
    }>()
);

export const initConsultantDataError = createAction(
    actionFactory('Consultant data initialization failed'),
    props<{
        requestState: RequestStateEnum;
    }>()
);

export const startIsCallbackAvailableCall = createAction(
    actionFactory('Start callback availability call')
);

export const finishIsCallbackAvailableCall = createAction(
    actionFactory('Finish callback availability call'),
    props<{ isAvailable: boolean; requestState: RequestStateEnum }>()
);

export const initCarousel = createAction(
    actionFactory('Init Carousel'),
    props<{
        profession: ProfessionEnum;
        carouselId: number;
    }>()
);

export const toggleAdditionalInfoVisibilityAction = createAction(
    actionFactory('Toggle additional info visibility'),
    props<{ carouselId: number }>()
);

export const resetCarouselControlsAction = createAction(
    actionFactory('Reset controls')
);

export const nextConsultantAction = createAction(
    actionFactory('Update carousel pagination index to next')
);

export const previousConsultantAction = createAction(
    actionFactory('Update carousel pagination index to previous')
);
