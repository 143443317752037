import { Injectable } from '@angular/core';
import { PriceDetailKeyEnum } from '@pkv-frontend/data-domain/comparison';
import { priceDetailTranslationConstant } from '../constants/price-detail-translation.constant';
import { ComparisonTableDataInterface } from '../models/comparison-table-data.interface';

@Injectable({
    providedIn: 'root',
})
export class ComparisonTablePriceDetailsRiskPremiumLabelEnricherService {
    private labelsToEnrichWhenRiskPremiumsArePresent: PriceDetailKeyEnum[] = [
        PriceDetailKeyEnum.ReimbursementVariable,
        PriceDetailKeyEnum.YearlyEffectivePrice,
        PriceDetailKeyEnum.EffectivePrice,
    ];

    public enrich(
        data: ComparisonTableDataInterface
    ): ComparisonTableDataInterface {
        for (const row of data.rows) {
            if (!row.key) {
                continue;
            }

            if (
                this.labelsToEnrichWhenRiskPremiumsArePresent.includes(
                    <PriceDetailKeyEnum>row.key
                )
            ) {
                row.label = priceDetailTranslationConstant[row.key] + '*';
            }

            if (row.key === PriceDetailKeyEnum.EffectivePrice) {
                row.subLabel = '*nur näherungsweise';
            }

            if (row.key === PriceDetailKeyEnum.TotalSum) {
                row.label =
                    priceDetailTranslationConstant[row.key] + ' (inkl. RZ)';
            }
        }

        return data;
    }
}
