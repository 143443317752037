import {
    CoreFeatureToggleEnum,
    Environment,
} from '@pkv-frontend/data-domain/application';
import {
    ActiveConsultantModel,
    ConsultantCookieDataModel,
} from '@pkv-frontend/data-domain/consultant';
import { DeviceOutputEnum } from '@pkv-frontend/data-domain/php-frontend-data';

export const applicationFeatureKey = 'application';

export interface ApplicationState {
    activeConsultant?: ActiveConsultantModel;
    consultantCookieData?: ConsultantCookieDataModel;
    isConsultantLoggedIn: boolean;
    isAlternativeOffer: boolean;
    isTariffChange: boolean;
    intialProviderId?: number;
    deviceOutput?: DeviceOutputEnum;
    isCobrowsing?: boolean;
    environment: Environment | undefined;
    coreFeatureToggles: Record<CoreFeatureToggleEnum, boolean> | null;
}

export interface ApplicationStateAware {
    [applicationFeatureKey]: ApplicationState;
}
