import { Injectable } from '@angular/core';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';

const storageKey = 'pkv.tariffTileSavings';
const hide = 'hide';

@Injectable({
    providedIn: 'root',
})
export class ShowSavingsPersistenceService {
    constructor(
        private readonly sessionStorageService: SessionStorageService
    ) {}

    public set show(value: boolean) {
        if (value) {
            this.sessionStorageService.remove(storageKey);
        } else {
            this.sessionStorageService.set(storageKey, hide);
        }
    }

    public get show(): boolean {
        return this.sessionStorageService.get(storageKey) !== hide;
    }
}
