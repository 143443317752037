import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CalculationParameterQueryParserService } from '@pkv-frontend/business-domain/calculation-parameter';
import { resultPageUrlChangeAction } from '../../../result/store/actions/result.actions';
import { ApiParameterService } from '../../services/api-parameter.service';
import { FilterChipsService } from '../../services/filter-chips.service';
import { FilterParameterService } from '../../services/filter-parameter.service';

import { updateFiltersFromUrlAction } from '../actions/filter.actions';
import { loadResultSilentlySelector } from '../selectors/load-result-silent.selector';
import { resultConsultantFilterChangeSelector } from '../selectors/result-consultant-filter-change.selector';
import { resultFilterChipsSelector } from '../selectors/result-filter-chips.selector';

@Injectable()
export class UpdateFiltersFromUrlEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly calculationParameterQueryParserService: CalculationParameterQueryParserService,
        private readonly filterParameterService: FilterParameterService,
        private readonly apiParameterService: ApiParameterService,
        private readonly filterChipsService: FilterChipsService,
        private readonly store$: Store
    ) {}

    public updateFiltersFromUrlEffect$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(resultPageUrlChangeAction),
                concatLatestFrom(() => [
                    this.store$.select(loadResultSilentlySelector),
                    this.store$.select(resultConsultantFilterChangeSelector),
                    this.store$.select(resultFilterChipsSelector),
                ]),
                map(
                    ([action, loadSilently, , existingFilterChips]: [
                        ReturnType<typeof resultPageUrlChangeAction>,
                        boolean,
                        boolean,
                        string[],
                    ]) => {
                        const calculationParameters =
                            this.calculationParameterQueryParserService.parse(
                                action.params
                            );
                        const sortApiParameters =
                            this.apiParameterService.parseSortApiParameters(
                                action.params
                            );
                        const positionParameters =
                            this.apiParameterService.parsePositionParameters(
                                action.params
                            );

                        const resultFilters =
                            this.filterParameterService.generateFromCalculationParameter(
                                calculationParameters
                            );
                        const crossSellingOverlay = action.params['overlay'];
                        const filterChips = loadSilently
                            ? existingFilterChips
                            : action.params['filterChips']
                                  ?.split(',')
                                  .map((chip: string) => chip.trim())
                                  .filter(
                                      (chip: string) =>
                                          !!chip &&
                                          this.filterChipsService.getMatchingFilterChip(
                                              chip
                                          ) !== undefined
                                  ) || [];

                        return updateFiltersFromUrlAction({
                            calculationParameters,
                            sortApiParameters,
                            positionParameters,
                            resultFilters,
                            crossSellingOverlay,
                            filterChips,
                        });
                    }
                )
            );
        }
    );
}
