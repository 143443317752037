import { Injectable } from '@angular/core';

import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';

@Injectable({ providedIn: 'root' })
export class FilterFormDifferenceService {
    public getFilterFormDifference(
        currentFilter: ResultFilter,
        dirtyFilter: Partial<ResultFilter>
    ): Partial<ResultFilter> {
        const difference = {};

        Object.entries(dirtyFilter).forEach(([key, value]): void => {
            if (currentFilter[key] !== value) {
                difference[key] = value;
            }
        });
        return difference;
    }
}
