export enum UserDataPersistenceFieldNamesEnum {
    InsureInput = 'insure',
    Gender = 'gender',
    Title = 'title',
    FirstName = 'firstName',
    LastName = 'lastName',
    MobileNumber = 'mobileNumber',
    Email = 'email',
    InsureGender = 'insure_gender',
    InsureTitle = 'insure_title',
    InsureFirstname = 'insure_firstname',
    InsureLastname = 'insure_lastname',
}
