import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
    SendResultViaEmailApiClient,
    SendResultViaEmailResponseModel,
} from '@pkv-frontend/data-domain/result';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';

import {
    requestResultViaEmailFormCompleteAction,
    submitResultViaEmailFormAction,
} from '../actions/result-via-email.actions';

@Injectable()
export class ResultViaEmailEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly apiClient: SendResultViaEmailApiClient
    ) {}

    public initResultViaEmail$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(submitResultViaEmailFormAction),
            exhaustMap(
                (action: ReturnType<typeof submitResultViaEmailFormAction>) =>
                    this.apiClient.post(action.request).pipe(
                        map((response: SendResultViaEmailResponseModel) =>
                            requestResultViaEmailFormCompleteAction({
                                state: RequestStateEnum.Success,
                                result: response,
                            })
                        ),
                        catchError(() =>
                            of(
                                requestResultViaEmailFormCompleteAction({
                                    state: RequestStateEnum.Error,
                                })
                            )
                        )
                    )
            )
        );
    });
}
