import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DeviceOutputEnum } from '../enums/device-output.enum';
import { PhpFrontendDataService } from './php-frontend-data.service';

@Injectable({
    providedIn: 'root',
})
export class DeviceOutputService {
    constructor(private phpFrontendDataService: PhpFrontendDataService) {}

    public isMobile$: Observable<boolean> = of(this.isMobile);

    public get isMobile(): boolean {
        return (
            this.phpFrontendDataService.data.deviceOutput ===
            DeviceOutputEnum.Mobile
        );
    }

    public get isApp(): boolean {
        return [DeviceOutputEnum.App, DeviceOutputEnum.TabletApp].includes(
            this.phpFrontendDataService.data.deviceOutput
        );
    }

    public get isDesktop(): boolean {
        return (
            this.phpFrontendDataService.data.deviceOutput ===
            DeviceOutputEnum.Desktop
        );
    }

    public get isTablet(): boolean {
        return (
            this.phpFrontendDataService.data.deviceOutput ===
            DeviceOutputEnum.Tablet
        );
    }

    public get isTabletApp(): boolean {
        return (
            this.phpFrontendDataService.data.deviceOutput ===
            DeviceOutputEnum.TabletApp
        );
    }

    public deviceOutputEnum(): DeviceOutputEnum {
        return this.phpFrontendDataService.data.deviceOutput;
    }
}
