import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AddActivityScoreEffect } from './effects/add-activity-score.effect';
import { ClearComparisonOnFilterChangeEffect } from './effects/clear-comparison-on-filter-change-effect.service';
import { LoadComparisonEyesEffect } from './effects/load-comparison-eyes.effect';
import { LoadComparisonTariffsFromStorageEffect } from './effects/load-comparison-tariffs-from-storage.effect';
import { LoadResultCalculationEffect } from './effects/load-result-calculation.effect';
import { PersistLastSearchEffect } from './effects/persist-last-search.effect';
import { RedirectToRegisterEffect } from './effects/redirect-to-register.effect';
import { ResultLoadedEffect } from './effects/result-loaded.effect';
import { TrackToggleTariffExpansionModeEffect } from './effects/track-toggle-tariff-expansion-mode.effect';
import { UpdatePreviousUrlEffect } from './effects/update-previous-url.effect';
import { resultFeatureKey } from './models/result-state.model';
import { resultReducers } from './reducers/result.reducers';

@NgModule({
    imports: [
        EffectsModule.forFeature([
            ClearComparisonOnFilterChangeEffect,
            LoadComparisonEyesEffect,
            LoadComparisonTariffsFromStorageEffect,
            LoadResultCalculationEffect,
            PersistLastSearchEffect,
            RedirectToRegisterEffect,
            ResultLoadedEffect,
            TrackToggleTariffExpansionModeEffect,
            AddActivityScoreEffect,
            UpdatePreviousUrlEffect,
        ]),
        StoreModule.forFeature(resultFeatureKey, resultReducers),
    ],
})
export class ResultStoreModule {}
