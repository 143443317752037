import { createSelector } from '@ngrx/store';
import {
    ConsultantCarouselState,
    ConsultantCarouselStateAware,
} from '../models/consultant-carousel-state.model';

import { consultantCarouselStateSelector } from './consultant-carousel-state.selector';

export const paginationIndexSelector = createSelector<
    ConsultantCarouselStateAware,
    [ConsultantCarouselState],
    number
>(
    consultantCarouselStateSelector,
    (state: ConsultantCarouselState): number => state.controls.index
);
