import { createAction, props } from '@ngrx/store';
import { actionTypeFactoryBuilder } from '@pkv-frontend/infrastructure/store';
import { comparisonFeatureKey } from '../models/comparison-state.model';

import { ComparisonTariffItem } from '../models/comparison-tariff-item.model';

const actionTypeFactory = actionTypeFactoryBuilder(comparisonFeatureKey);

export const fetchTariffsWithEyes = createAction(
    actionTypeFactory('fetchTariffsWithEyes')
);

export const addTariffsToComparisonAction = createAction(
    actionTypeFactory('addTariffsToComparison'),
    props<{ tariffItems: ComparisonTariffItem[] }>()
);

export const removeAllTariffsFromComparisonAction = createAction(
    actionTypeFactory('removeAllTariffsFromComparison')
);

export const removeTariffsFromComparisonAction = createAction(
    actionTypeFactory('removeTariffsFromComparison'),
    props<{ tariffVersionIds: number[] }>()
);

export const setTariffsWithEyeIconAction = createAction(
    actionTypeFactory('set tariffs with eye icon'),
    props<{
        tariffsWithEyeIcon?: number[];
    }>()
);

export const toggleComparisonBarAction = createAction(
    actionTypeFactory('toggle comparison bar')
);

export const removeGkvTariffFromComparisonAction = createAction(
    actionTypeFactory('remove gkv tariff from comparison')
);

export const setComparisonBarVisibilityAction = createAction(
    actionTypeFactory('set comparison bar visibility'),
    props<{ isVisible: boolean }>()
);
