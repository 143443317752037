import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ParametersParserService } from '@pkv-frontend/business-domain/calculation-parameter';
import {
    CalculationParameterInterface,
    LegacyCalculationParameter,
    QueryCalculationParameterParser,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';
import { resultFilterCalculationParametersSelector } from '../../../filter/store/selectors/result-filter-calculation-parameters.selector';

import { redirectToRegisterAction } from '../actions/result.actions';

@Injectable()
export class RedirectToRegisterEffect {
    public redirectToRegisterEffect$: Observable<
        [{ tariffVersionId: number }, CalculationParameterInterface | undefined]
    > = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(redirectToRegisterAction),
                concatLatestFrom(() =>
                    this.store$.select(
                        resultFilterCalculationParametersSelector
                    )
                ),
                tap(
                    ([action, calculationParameter]: [
                        ReturnType<typeof redirectToRegisterAction>,
                        CalculationParameterInterface | undefined,
                    ]) => {
                        if (!calculationParameter) {
                            return;
                        }
                        const legacyCalculationParameter: LegacyCalculationParameter =
                            this.parameterParser.downgrade(
                                calculationParameter
                            );

                        const legacyCalculationUrlParameter: URLSearchParams =
                            this.queryCalculationParameterParser.parse(
                                legacyCalculationParameter
                            );

                        this.windowReferenceService.nativeWindow.location.href =
                            `/${FeatureRouteEnum.RegisterPage}/offer/${action.tariffVersionId}/base/?` +
                            legacyCalculationUrlParameter.toString();
                    }
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly windowReferenceService: WindowReferenceService,
        private readonly parameterParser: ParametersParserService,
        private readonly queryCalculationParameterParser: QueryCalculationParameterParser
    ) {}
}
