import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { finishExpertLeadCreation } from '../actions/consultant-callback-overlay.actions';

@Injectable()
export class ExpertLeadRedirectEffect {
    constructor(private actions$: Actions) {}

    public readonly expertLeadRedirect$: Observable<string> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(finishExpertLeadCreation),
                exhaustMap(
                    (action: ReturnType<typeof finishExpertLeadCreation>) =>
                        (window.location.href = action.redirectUrl)
                )
            );
        },
        { dispatch: false }
    );
}
