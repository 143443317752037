import { createAction, props } from '@ngrx/store';
import {
    SendResultViaEmailRequestModel,
    SendResultViaEmailResponseModel,
} from '@pkv-frontend/data-domain/result';

import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';

enum ResultViaEmailActionsEnum {
    SubmitForm = '[send-result-via-email] submit',
    RequestStateUpdated = '[send-result-via-email] request state updated',
    RequestComplete = '[send-result-via-email] request complete',
    ResetForm = '[send-result-via-email] reset form',
}

export const submitResultViaEmailFormAction = createAction(
    ResultViaEmailActionsEnum.SubmitForm,
    props<{ request: SendResultViaEmailRequestModel }>()
);

export const requestResultViaEmailFormStateChangeAction = createAction(
    ResultViaEmailActionsEnum.RequestStateUpdated,
    props<{ state: RequestStateEnum }>()
);

export const requestResultViaEmailFormCompleteAction = createAction(
    ResultViaEmailActionsEnum.RequestComplete,
    props<{
        state: RequestStateEnum;
        result?: SendResultViaEmailResponseModel;
    }>()
);

export const resetResultViaEmailFormAction = createAction(
    ResultViaEmailActionsEnum.ResetForm
);
