import { Environment } from '../enum/environment.enum';

function getEnvironmentRuleImplementation(): Environment {
    const url = window.location.host;

    if (/check24\.de/.test(url)) {
        return Environment.Prod;
    }
    if (/check24-int\.de/.test(url)) {
        return Environment.Int;
    }
    if (/check24-test\.de/.test(url)) {
        return Environment.Test;
    }
    return Environment.Dev;
}

export function getEnvironmentRule(): Environment {
    return getEnvironmentRuleImplementation();
}
