import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { ComparisonDataService } from '@pkv-frontend/business-domain/comparison';
import { filterChangeAction } from '../../../filter/store/actions/filter.actions';

@Injectable()
export class ClearComparisonOnFilterChangeEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly comparisonStoreService: ComparisonDataService
    ) {}

    public clearBasketOnFilterChangeEffect$: Observable<unknown> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(filterChangeAction),
                tap((): void => {
                    this.comparisonStoreService.removeAllTariffsFromComparison();
                })
            );
        },
        {
            dispatch: false,
        }
    );
}
