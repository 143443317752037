import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationDataService } from '@pkv-frontend/business-domain/application';
import { ParametersParserService } from '@pkv-frontend/business-domain/calculation-parameter';
import { LocalStorageService } from '@pkv-frontend/data-domain/persistence';

import { calcParamPersistenceKey } from '../../../result/constants/result.constants';
import { updateFiltersFromUrlAction } from '../actions/filter.actions';

@Injectable()
export class SaveCalcParamToLocalStorageEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly localStorageService: LocalStorageService,
        private readonly parametersParser: ParametersParserService,
        private readonly applicationDataService: ApplicationDataService
    ) {}

    public saveCalcParamToLocalStorageEffect$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(updateFiltersFromUrlAction),
                concatLatestFrom(
                    () => this.applicationDataService.isConsultantLoggedIn$
                ),
                map(
                    ([action, isConsultantLoggedIn]: [
                        ReturnType<typeof updateFiltersFromUrlAction>,
                        boolean,
                    ]) => {
                        if (isConsultantLoggedIn) {
                            this.localStorageService.remove(
                                calcParamPersistenceKey
                            );
                            return;
                        }
                        if (action.calculationParameters) {
                            this.localStorageService.set(
                                calcParamPersistenceKey,
                                this.parametersParser.downgrade(
                                    action.calculationParameters
                                )
                            );
                        }
                    }
                )
            );
        },
        { dispatch: false }
    );
}
