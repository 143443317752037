import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
    AliasEmailValidator,
    EmailValidator,
    NameValidator,
    PhoneNumberApiValidator,
    phoneNumberSchemaValidator,
} from '@pkv-frontend/business-domain/form';

import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';
import { OverlayFormEnum } from '../enums/overlay-form.enum';

export type ExpertFormGroup = ReturnType<ExpertFormService['createExpertForm']>;

@Injectable({
    providedIn: 'root',
})
export class ExpertFormService {
    constructor(
        private formBuilder: FormBuilder,
        private phoneNumberApiValidator: PhoneNumberApiValidator
    ) {}

    public createExpertForm(prefillData?: SsoUserDataModel) {
        return this.formBuilder.group(
            {
                [OverlayFormEnum.Gender]: [
                    prefillData?.gender,
                    {
                        validators: [Validators.required],
                    },
                ],
                [OverlayFormEnum.FirstName]: [
                    prefillData?.firstName,
                    {
                        validators: [
                            Validators.required,
                            Validators.minLength(2),
                            Validators.maxLength(50),
                            NameValidator,
                        ],
                    },
                ],
                [OverlayFormEnum.LastName]: [
                    prefillData?.lastName,
                    {
                        validators: [
                            Validators.required,
                            Validators.minLength(2),
                            Validators.maxLength(50),
                            NameValidator,
                        ],
                    },
                ],
                [OverlayFormEnum.Email]: [
                    prefillData?.email,
                    {
                        validators: [
                            Validators.required,
                            AliasEmailValidator,
                            EmailValidator,
                        ],
                    },
                ],
                [OverlayFormEnum.MobileNumber]: [
                    prefillData?.mobileNumber,
                    {
                        validators: [
                            Validators.required,
                            phoneNumberSchemaValidator(),
                        ],
                        asyncValidators: [
                            this.phoneNumberApiValidator.validate.bind(
                                this.phoneNumberApiValidator
                            ),
                        ],
                    },
                ],
            },
            { updateOn: 'change' }
        );
    }
}
