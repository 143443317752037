import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
    Consultant,
    ConsultantCarouselApiClient,
} from '@pkv-frontend/data-domain/result';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';

import {
    finishInitConsultantData,
    initCarousel,
    initConsultantData,
    initConsultantDataError,
} from '../actions/consultant-carousel.actions';

@Injectable()
export class ConsultantDataEffect {
    constructor(
        private actions$: Actions,
        private apiClient: ConsultantCarouselApiClient
    ) {}

    public initConsultantData$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(initConsultantData, initCarousel),
            exhaustMap((action: ReturnType<typeof initConsultantData>) =>
                this.apiClient.fetchConsultantData(action.profession).pipe(
                    map((consultants: Consultant[]) =>
                        finishInitConsultantData({
                            consultants: consultants,
                        })
                    ),
                    catchError(() =>
                        of(
                            initConsultantDataError({
                                requestState: RequestStateEnum.Error,
                            })
                        )
                    )
                )
            )
        );
    });
}
