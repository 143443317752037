import { LeadCreationTypeEnum } from '@pkv-frontend/business-domain/lead';
import { AppointmentBookingDataResponseModel } from '@pkv-frontend/data-domain/lead-expert';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import { ConsultantOverlayContentTypeEnum } from '../../enums/consultant-overlay-content-type.enum';
import { ServerErrorResponseInterface } from './server-error-response.interface';

export const consultantCallbackOverlayStoreFeatureKey =
    'consultant-callback-overlay';

export interface ConsultantCallbackOverlayState {
    isVisible: boolean;
    contentType: ConsultantOverlayContentTypeEnum;
    appointmentBookingData?: AppointmentBookingDataResponseModel;
    leadCreationType?: LeadCreationTypeEnum;
    leadCreationState?: RequestStateEnum;
    invalidUserInput?: ServerErrorResponseInterface;
}

export interface OverlayStateAware {
    [consultantCallbackOverlayStoreFeatureKey]: ConsultantCallbackOverlayState;
}
