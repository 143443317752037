import { createSelector } from '@ngrx/store';
import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterCalcParamProfessionSelector = createSelector<
    FilterStateAware,
    [FilterState],
    ProfessionEnum | undefined
>(
    filterStateFeatureSelector,
    (state: FilterState) => state.calculationParameters?.profession
);
