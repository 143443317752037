import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ApplicationDataService } from '@pkv-frontend/business-domain/application';
import {
    ShowUnsupportedBrowserBannerRule,
    unsupportedBrowserBannerClassConstant,
} from '@pkv-frontend/business-domain/browser-detection';
import {
    GoogleTagManagerDataLayerService,
    PreviousUrlPersistenceService,
} from '@pkv-frontend/business-domain/tracking';
import { C24AppService } from '@pkv-frontend/data-domain/c24-app';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';
import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

export const selector = 'app-root';

const thankYouPageAreaId = 'Dankeseite';

@Component({
    selector,
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly previousUrlPersistenceService: PreviousUrlPersistenceService,
        private readonly renderer: Renderer2,
        private readonly showUnsupportedBrowserBannerRule: ShowUnsupportedBrowserBannerRule,
        private readonly applicationDataService: ApplicationDataService,
        private readonly c24AppService: C24AppService,
        private readonly windowRefService: WindowReferenceService,
        private readonly googleTagManagerDataLayerService: GoogleTagManagerDataLayerService,
        private readonly phpFrontendDataService: PhpFrontendDataService
    ) {}

    public ngOnInit(): void {
        this.gtmPushFirstDataLayerEntryOnThankYouPage();
        if (this.showUnsupportedBrowserBannerRule.result()) {
            this.renderer.addClass(
                this.document.body,
                unsupportedBrowserBannerClassConstant
            );
        }
        this.previousUrlPersistenceService.set();
        this.setBodyClasses();
        this.c24AppService.setTrackingProduct();
    }

    // push first dataLayer entry for conversion tracking on thankYouPage
    // todo: remove in PKV-5003
    private gtmPushFirstDataLayerEntryOnThankYouPage() {
        const url = this.windowRefService.nativeWindow.location.href;

        if (url.includes(FeatureRouteEnum.PageThankYou)) {
            this.googleTagManagerDataLayerService.pushFirstDataLayerEntry(
                thankYouPageAreaId,
                this.phpFrontendDataService.data.config.trackingBaseConfig.pid
            );
        }
    }

    private setBodyClasses(): void {
        this.applicationDataService.isCobrowsing$
            .pipe(
                filter(
                    (isCobrowsing: boolean | undefined) =>
                        isCobrowsing !== undefined
                ),
                takeUntil(this.destroy$)
            )
            .subscribe((isCobrowsing: boolean) => {
                if (isCobrowsing) {
                    this.document.body.classList.remove('no-cobrowsing');
                } else {
                    this.document.body.classList.add('no-cobrowsing');
                }
            });

        this.applicationDataService.isAlternativeOffer$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isAlternativeOffer) => {
                if (isAlternativeOffer) {
                    this.document.body.classList.add('is-alternative-offer');
                } else {
                    this.document.body.classList.remove('is-alternative-offer');
                }
            });

        this.applicationDataService.isTariffChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isTariffChange) => {
                if (isTariffChange) {
                    this.document.body.classList.add('is-tariff-change');
                } else {
                    this.document.body.classList.remove('is-tariff-change');
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
