import { AbstractControl, ValidatorFn } from '@angular/forms';
import { differenceInYears } from 'date-fns/differenceInYears';
import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';

export function AgeRangeValidator(minAge: number, maxAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (!control.value) {
            return null;
        }

        const currentDate = new Date();
        const compareDate = parse(control.value, 'dd.MM.yyyy', new Date());

        if (!isValid(compareDate)) {
            return null;
        }

        const age = differenceInYears(currentDate, compareDate);

        if (age < minAge) {
            return { tooYoung: true };
        }

        if (age > maxAge) {
            return { tooOld: true };
        }

        return null;
    };
}
