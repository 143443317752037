import { MatomoSettings } from '@vv-ham/ng-tracking';
import { DeviceOutputEnum } from '@pkv-frontend/data-domain/php-frontend-data';
import { getCookieValueRule } from '@pkv-frontend/infrastructure/cookie';
import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';

const pagesWithoutPhp = [
    `/${FeatureRouteEnum.ThirdViewPage}/`,
    `/${FeatureRouteEnum.ThirdViewPage}/`,
    `/${FeatureRouteEnum.Termination}/`,
];

export function getMatomoOverwritesRule(
    fallbackDeviceOutput: DeviceOutputEnum
): Partial<MatomoSettings> {
    let matomoOverwrites: Partial<MatomoSettings> = {};
    if (
        pagesWithoutPhp.some((route: string) =>
            location.pathname.startsWith(route)
        )
    ) {
        matomoOverwrites = {
            useExistingMatomoInstance: false,
            skipInitialPageViewTracking: true,
            doNotTrack: true,
            secureCookie: true,
            heartBeatTimerDelay: 15,
            customVariables: {
                Wpset: {
                    index: 2,
                    value: getCookieValueRule('wpset') || 'default',
                    scope: 'visit',
                },
                Deviceoutput: {
                    index: 3,
                    value:
                        getCookieValueRule('deviceoutput') ||
                        fallbackDeviceOutput,
                    scope: 'visit',
                },
            },
        };
    }
    return matomoOverwrites;
}
