import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { RadioListRowVariantEnum } from '../enums/radio-list-row-variant.enum';
import { CustomFiltersConfigModel } from '../models/custom-filters-config.model';

import { dentalServiceLevelSelectionConfig } from './dental-service-level-selection.config';
import { costsharingFilterConfig } from './filter.config';
import { gradeFilterConfig } from './grade-filter.config';
import { hospitalizationAccommodationServiceLevelSelectionConfig } from './hospitalization-accomodation-service-level-selection.config';

export const customFiltersConfig = {
    directMedicalConsultationBenefit: {
        name: 'directMedicalConsultationBenefit',
        tooltipContent: {
            headline: 'Direkt zum Facharzt: 100% Erstattung',
            content:
                'Manche Versicherungen wollen, dass Sie bei Beschwerden zuerst zu Ihrem Hausarzt gehen. Die Versicherungen bezahlen nur dann die vollen Behandlungskosten, wenn Ihr Hausarzt Sie zu einem Facharzt überwiesen hat. Ansonsten müssen Sie einen Teil selbst bezahlen. Einige Fachärzte sind von dieser Einschränkung meist ausgenommen – zum Beispiel Augenärzte, Kinderärzte oder Gynäkologen.',
        },
    },
    provisionContributionReimbursement: {
        name: 'provisionContributionReimbursement',
        tooltipContent: {
            headline: 'Beitragsrückerstattung',
            content:
                'Wenn Sie in einem Jahr keine Rechnung bei der Versicherung einreichen, erhalten Sie einen Teil Ihrer bezahlten Beiträge zurück.',
        },
    },
    evaluationContributionStability: {
        name: 'evaluationContributionStability',
        tooltipContent: {
            headline: 'Gute Beitragsstabilität im Alter',
            content:
                'CHECK24 untersucht, wie gut Versicherungen für die Zukunft vorbereitet sind. Bilanzkennzahlen geben Hinweise darauf, wie stabil die Beiträge der Versicherungen im Alter bleiben. Dabei schauen wir z.B. auf die Kapitalerträge und die finanziellen Rückstellungen der Versicherungen sowie die versicherungsgeschäftliche Ergebnisquote und Zuwachsraten an Versicherten. In die Bewertung fließt aber auch die Entwicklung der Beiträge in den letzten Jahren ein.',
        },
    },
    amedNonMedPractitionerReimbursement: {
        name: 'amedNonMedPractitionerReimbursement',
        tooltipContent: {
            headline: 'Heilpraktiker',
            content:
                'Heilpraktikerleistungen gehören zur Alternativmedizin. Hierzu zählen etwa Therapien der Homöopathie, Osteopathie oder Akkupunktur. Bei manchen Tarifen wird ein Teil der Kosten für solche Behandlungen übernommen.',
        },
    },
    cureAndRehab: {
        name: 'cureAndRehab',
        tooltipContent: {
            headline: 'Sehr gute Kurleistungen',
            content:
                'Die Beihilfe erstattet bei Kuren in der Regel nur einen Teil der Kosten. Für Unterkunft und Verpflegung müssen Sie meist selbst aufkommen. Bei manchen Tarifen wird jedoch ein Teil dieser Kosten übernommen.',
        },
    },
    treatmentAboveMaximumRate: {
        name: 'treatmentAboveMaximumRate',
        tooltipContent: {
            headline: 'Stationäre Arzthonorare über Höchstsatz (3,5-fach)',
            content:
                'Die Kosten für die Behandlung durch Ärzte sind in einer Gebührenordnung festgelegt. Wenn ein Arzt mehr als das 3,5-fache berechnet, geht er über den Höchstsatz hinaus. Einige Versicherungen bezahlen die Kosten auch dann.',
        },
    },
    treatmentAttendingDoctor: {
        name: 'treatmentAttendingDoctor',
        tooltipContent: {
            headline: 'Chefarzt / Spezialist',
            content:
                'Manche Versicherer garantieren Ihnen während Ihres Klinikaufenthaltes eine ausschließliche Behandlung durch ausgewiesene Spezialisten, beziehungsweise den Chefarzt.',
        },
    },
    pdhospitalPayout: {
        name: 'pdhospitalPayout',
        tooltipContent: {
            headline: 'Krankentagegeld',
            content: `
                <p>Eine Krankentagegeld-Versicherung wird gebraucht, wenn man längere Zeit krank ist und nicht arbeiten kann. Das Krankentagegeld sollte die eigenen monatlichen Kosten abdecken können. Als Richtwert empfehlen wir 80 Prozent des Brutto-Tagesgehalts.</p>
                <p>Angestellte bekommen in den ersten 42 Tagen einer Krankheit noch Lohn vom Arbeitgeber. Daher ist für sie eine Absicherung ab dem 43. Krankheitstag zu empfehlen.</p>
                <p>Selbstständige und Freiberufler haben keine gesetzliche Lohnfortzahlung. Daher müssen sie selbst einschätzen, ab welchem Krankheitstag sie sich versichern, um den Ausfall des Einkommens auszugleichen.</p>
            `,
        },
    },
    pdhospitalPayoutAmountValue: {
        radioListVariant: RadioListRowVariantEnum.Multi,
        options: [
            {
                value: 0,
                label: '0 €',
                visible: false,
            },
            {
                value: 50,
                label: '50 €',
            },
            {
                value: 75,
                label: '75 €',
            },
            {
                value: 100,
                label: '100 €',
            },
            {
                value: 125,
                label: '125 €',
            },
            {
                value: 150,
                label: '150 €',
            },
            {
                value: 200,
                label: '200 €',
            },
        ],
    },
    pdhospitalPayoutStart: (profession: ProfessionEnum) => {
        return {
            radioListVariant: RadioListRowVariantEnum.Multi,
            options: pdhospitalPayoutStartOptions[profession] ?? [],
            name: 'pdhospitalPayoutStart',
        };
    },
    betAmount: {
        radioListVariant: RadioListRowVariantEnum.Multi,
        options: [
            {
                value: 0,
                label: 'Keine',
            },
            {
                value: 100,
                label: '100 €',
            },
            {
                value: 200,
                label: '200 €',
            },
            {
                value: 300,
                label: '300 €',
            },
            {
                value: 400,
                label: '400 €',
            },
            {
                value: 500,
                label: '500 €',
            },
        ],
    },
    hospitalizationAccommodation: {
        name: 'hospitalizationAccommodation',
        tooltipContent: {
            headline: 'Krankenhausleistungen',
            content:
                'Ohne eine gesonderte Vereinbarung ist im Krankenhaus wie in der gesetzlichen Versicherung ein Mehrbettzimmer Standard. Zahlreiche Tarife sehen aber die Unterbringung im Ein- oder Zweibettzimmer vor. Wichtiger als die Zimmerart sind die damit verbundenen Wahlleistungen, d.h. die Behandlung durch ausgewiesene Spezialisten bzw. den Chefarzt.',
        },
    },
    reducedResults: 'reducedResults',
    insureDate: {
        name: 'insureDate',
        tooltipContent: {
            headline: 'Versicherungsbeginn',
            content:
                'Wann soll Ihre private Krankenversicherung beginnen? Je nach Art der Vorversicherung muss unter Umständen eine Kündigungsfrist eingehalten werden. In manchen Fällen ist auch eine Rückdatierung des Vertrags möglich. Unsere Spezialisten beraten Sie hierzu gerne.',
        },
    },
    noPdhospitalPayout: {
        name: 'noPdhospitalPayout',
    },
    grade: gradeFilterConfig,
    hospitalizationAccommodationServiceLevelSelection:
        hospitalizationAccommodationServiceLevelSelectionConfig,
    dentalServiceLevelSelection: dentalServiceLevelSelectionConfig,
    costsharing: costsharingFilterConfig,
} satisfies CustomFiltersConfigModel;

const pdhospitalPayoutStartOptions = {
    [ProfessionEnum.Freelancer]: [
        {
            value: 0,
            label: 'keine',
            visible: false,
        },
        {
            value: 15,
            label: 'Ab dem 15. Tag',
        },
        {
            value: 22,
            label: 'Ab dem 22. Tag',
        },
        {
            value: 29,
            label: 'Ab dem 29. Tag',
        },
        {
            value: 43,
            label: 'Ab dem 43. Tag',
        },
        {
            value: 92,
            label: 'Ab dem 92. Tag',
            visible: false,
        },
    ],
    [ProfessionEnum.Employee]: [
        {
            value: 0,
            label: 'kein Krankentagegeld',
        },
        {
            value: 43,
            label: 'ab dem 43. Tag',
        },
        {
            value: 92,
            label: 'ab dem 92. Tag',
        },
    ],
};
