import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
    alternativeOfferTrackingField,
    alternativeOfferTrackingValue,
    providerIdUrlParameter,
    tariffChangeUrlParameter,
} from '@pkv-frontend/data-domain/application';
import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';
import { ComparisonTariffItem } from '../state/models/comparison-tariff-item.model';

@Injectable({
    providedIn: 'root',
})
export class ComparisonUrlBuilder {
    public buildUrl(
        comparisonTariffItems: ComparisonTariffItem[],
        calculationParameter: Params,
        isAlternativeOffer = false,
        isTariffChange = false,
        initialProviderId: number | null = null
    ): string {
        if (comparisonTariffItems.length === 0 || !calculationParameter) {
            throw new Error(
                'trying to build comparison-url with no selected comparison items or empty calculation parameters'
            );
        }
        const queryParams: URLSearchParams = new URLSearchParams();

        for (const [key, value] of Object.entries(calculationParameter)) {
            queryParams.append(key, String(value));
        }
        return `/${
            FeatureRouteEnum.ComparisonPage
        }?${this.buildSelectedTariffItemsHttpParams(
            queryParams,
            comparisonTariffItems,
            isAlternativeOffer,
            isTariffChange,
            initialProviderId
        ).toString()}`;
    }

    private buildSelectedTariffItemsHttpParams(
        queryParams,
        comparisonTariffItems: ComparisonTariffItem[],
        isAlternativeOffer: boolean,
        isTariffChange: boolean,
        initialProviderId: number | null
    ): HttpParams {
        if (isAlternativeOffer) {
            queryParams.set(
                alternativeOfferTrackingField,
                alternativeOfferTrackingValue
            );
        }

        if (isTariffChange) {
            queryParams.set(tariffChangeUrlParameter, '1');
        }

        if (initialProviderId) {
            queryParams.set(providerIdUrlParameter, initialProviderId);
        }

        for (const [
            index,
            comparisonTariffItem,
        ] of comparisonTariffItems.entries()) {
            queryParams.set(
                `tariffVersionId${index + 1}`,
                comparisonTariffItem.tariffVersionId
            );
        }

        return queryParams.toString();
    }
}
