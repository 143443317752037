import { Injectable } from '@angular/core';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';

const signUpStorageKey = 'pkv.user.userSessionLoginState.userPointPlanSignUp';
const suggestionStorageKey =
    'pkv.user.userSessionLoginState.hasSuggestedPointPlan';

@Injectable({ providedIn: 'root' })
export class UserSessionPointPlanSignUpStateService {
    constructor(private sessionStorageService: SessionStorageService) {}

    public isPointPlanSignUp(): boolean {
        return !!this.sessionStorageService.get(signUpStorageKey);
    }

    public setPointPlanSignUp(): void {
        this.sessionStorageService.set(signUpStorageKey, true);
    }

    public hasSuggestedPointPlan(): boolean {
        return !!this.sessionStorageService.get(suggestionStorageKey);
    }

    public setHasSuggestedPointPlan(): void {
        this.sessionStorageService.set(suggestionStorageKey, true);
    }
}
