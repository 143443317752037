import { Injectable } from '@angular/core';
import { MatomoEventTrackingData } from '@vv-ham/ng-tracking';
import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';
import { ConsultantOverlayCloseByTypeEnum } from '../enums/consultant-overlay-close-by-type.enum';
import { ConsultantOverlayContentTypeEnum } from '../enums/consultant-overlay-content-type.enum';
import { ConsultantOverlayTrackingCategoryEnum } from '../enums/consultant-overlay-tracking-category.enum';
import { ConsultantOverlayTrackingConfigEnum } from '../enums/consultant-overlay-tracking-config.enum';
import { ConsultantOverlayTrackingConfigFactory } from '../factory/consultant-overlay-tracking-config.factory';

@Injectable({ providedIn: 'root' })
export class OverlayTrackingCloseEventService {
    constructor(
        private readonly overlayTrackingConfigFactory: ConsultantOverlayTrackingConfigFactory
    ) {}

    public getTrackingCloseEvent(
        contentType: ConsultantOverlayContentTypeEnum,
        closeByType: ConsultantOverlayCloseByTypeEnum
    ): MatomoEventTrackingData {
        const overlayConfig =
            this.overlayTrackingConfigFactory.createByCategory(
                window.location.pathname.startsWith(
                    '/' + FeatureRouteEnum.ComparisonPage
                )
                    ? ConsultantOverlayTrackingCategoryEnum.CompareExpert
                    : ConsultantOverlayTrackingCategoryEnum.Expert
            );

        if (
            contentType ===
                ConsultantOverlayContentTypeEnum.ConsultantCallbackError ||
            contentType === ConsultantOverlayContentTypeEnum.LeadCreationError
        ) {
            switch (closeByType) {
                case ConsultantOverlayCloseByTypeEnum.Backlink:
                    return overlayConfig.instantAppointmentErrorCloseModalBacklinkLink;
                case ConsultantOverlayCloseByTypeEnum.Background:
                    return overlayConfig.instantAppointmentErrorCloseModalByBackgroundClick;
                case ConsultantOverlayCloseByTypeEnum.EscapeKeyDown:
                    return overlayConfig.instantAppointmentErrorCloseModalByEscKeyDown;
                default:
                    return overlayConfig.instantAppointmentErrorCloseModalByIcon;
            }
        }

        if (
            contentType === ConsultantOverlayContentTypeEnum.ConsultantCallback
        ) {
            switch (closeByType) {
                case ConsultantOverlayCloseByTypeEnum.Background:
                    return overlayConfig.instantAppointmentCloseModalByBackgroundClick;
                case ConsultantOverlayCloseByTypeEnum.EscapeKeyDown:
                    return overlayConfig.instantAppointmentCloseModalByEscKeyDown;
                default:
                    return overlayConfig.instantAppointmentCloseModalByCloseIcon;
            }
        }

        if (
            contentType ===
            ConsultantOverlayContentTypeEnum.AppointmentAlreadyBooked
        ) {
            switch (closeByType) {
                case ConsultantOverlayCloseByTypeEnum.Background:
                    return overlayConfig[
                        ConsultantOverlayTrackingConfigEnum
                            .ExpertOverlayAppointmentBookedNoticeCloseModalByBackgroundClick
                    ];
                case ConsultantOverlayCloseByTypeEnum.EscapeKeyDown:
                    return overlayConfig[
                        ConsultantOverlayTrackingConfigEnum
                            .ExpertOverlayAppointmentBookedNoticeCloseModalByEscKeyDown
                    ];
                default:
                    return overlayConfig[
                        ConsultantOverlayTrackingConfigEnum
                            .ExpertOverlayAppointmentBookedNoticeCloseModalByCloseIcon
                    ];
            }
        }

        switch (closeByType) {
            case ConsultantOverlayCloseByTypeEnum.Backlink:
                return overlayConfig.expertOverlayCloseModalByBacklinkLink;
            case ConsultantOverlayCloseByTypeEnum.Background:
                return overlayConfig.expertOverlayCloseModalByBackgroundClick;
            case ConsultantOverlayCloseByTypeEnum.EscapeKeyDown:
                return overlayConfig.expertOverlayCloseModalByEscKeyDown;
            default:
                return overlayConfig.expertOverlayCloseModalByCloseIcon;
        }
    }
}
