import { createSelector } from '@ngrx/store';
import {
    ApplicationState,
    ApplicationStateAware,
} from '../models/application-state.model';
import { applicationStateSelector } from './application-state.selector';

export const isAlternativeOfferSelector = createSelector<
    ApplicationStateAware,
    [ApplicationState],
    boolean
>(
    applicationStateSelector,
    (state: ApplicationState): boolean => state.isAlternativeOffer
);
