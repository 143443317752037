import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dentalMap } from '@pkv-frontend/business-domain/calculation-parameter';
import { DentalEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { ResultNavigationService } from '../../../result/services/result-navigation.service';

import { gkvCrossSellingChangeAction } from '../actions/filter.actions';

@Injectable()
export class UpdateUrlForGkvCrossSellingChangeEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly resultNavigationService: ResultNavigationService
    ) {}

    public updateUrlForGkvCrossSellingChangeEffect$: Observable<void> =
        createEffect(
            () => {
                return this.actions$.pipe(
                    ofType(gkvCrossSellingChangeAction),
                    map(
                        (
                            action: ReturnType<
                                typeof gkvCrossSellingChangeAction
                            >
                        ) => {
                            /**
                             * Set default values for dentalTreatment and dentalDentures manually because those parameters are not set by gkv
                             * */
                            const queryParams = {
                                birthdate: action.birthdate,
                                dental_treatment:
                                    dentalMap[DentalEnum.Comfort]
                                        .dentalTreatment,
                                dental_dentures:
                                    dentalMap[DentalEnum.Comfort]
                                        .dentalDentures,
                                overlay: undefined,
                            };

                            this.resultNavigationService.navigateAlwaysReplace(
                                queryParams
                            );
                        }
                    )
                );
            },
            { dispatch: false }
        );
}
