import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ParametersParserService } from '@pkv-frontend/business-domain/calculation-parameter';
import {
    GetResultCalculationResponse,
    ResultCalculationApiClient,
} from '@pkv-frontend/data-domain/result';

import { updateFiltersFromUrlAction } from '../../../filter/store/actions/filter.actions';
import { loadResultSilentlySelector } from '../../../filter/store/selectors/load-result-silent.selector';
import { resultConsultantFilterChangeSelector } from '../../../filter/store/selectors/result-consultant-filter-change.selector';
import { resultFilterSimplifiedResponseSelector } from '../../../filter/store/selectors/result-filter-simplified-response.selector';
import { setResultAction } from '../actions/result.actions';
import { resultCalculationResponseSelector } from '../selectors/result-calculation-response.selector';
import { resultLowestPricesSelector } from '../selectors/result-lowest-prices.selector';

const pagesize = 20;
const tariffsOutOfViewThreshold = 10;

@Injectable()
export class LoadResultCalculationEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly resultCalculationApiClient: ResultCalculationApiClient,
        private readonly parameterParserService: ParametersParserService
    ) {}

    public readonly loadResultCalculationEffect$: Observable<Action> =
        createEffect(() => {
            return this.actions$.pipe(
                ofType(updateFiltersFromUrlAction),
                concatLatestFrom(() => [
                    this.store$.select(resultCalculationResponseSelector),
                    this.store$.select(loadResultSilentlySelector),
                    this.store$.select(resultConsultantFilterChangeSelector),
                    this.store$.select(resultFilterSimplifiedResponseSelector),
                    this.store$.select(resultLowestPricesSelector),
                ]),
                filter(
                    ([, , , consultantChange]: [
                        ReturnType<typeof updateFiltersFromUrlAction>,
                        ReturnType<typeof resultCalculationResponseSelector>,
                        ReturnType<typeof loadResultSilentlySelector>,
                        ReturnType<typeof resultConsultantFilterChangeSelector>,
                        ReturnType<
                            typeof resultFilterSimplifiedResponseSelector
                        >,
                        ReturnType<typeof resultLowestPricesSelector>,
                    ]) => {
                        return !consultantChange;
                    }
                ),
                switchMap(
                    ([
                        action,
                        calculationResult,
                        loadSilent,
                        ,
                        simplifiedResult,
                        lowestPrices,
                    ]: [
                        ReturnType<typeof updateFiltersFromUrlAction>,
                        ReturnType<typeof resultCalculationResponseSelector>,
                        ReturnType<typeof loadResultSilentlySelector>,
                        ReturnType<typeof resultConsultantFilterChangeSelector>,
                        ReturnType<
                            typeof resultFilterSimplifiedResponseSelector
                        >,
                        ReturnType<typeof resultLowestPricesSelector>,
                    ]) => {
                        let pages = this.calculateUniquePages(
                            action,
                            calculationResult
                        );

                        if (loadSilent) {
                            pages = [1];
                        }

                        // simplified response = no lowest prices.
                        // if we have no lowest prices, try to load them.
                        const apiCalls: Observable<GetResultCalculationResponse>[] =
                            pages.map((page: number) =>
                                this.buildApiCall(
                                    action,
                                    page,
                                    simplifiedResult && !!lowestPrices
                                )
                            );

                        return merge(...apiCalls).pipe(
                            map((result: GetResultCalculationResponse) =>
                                setResultAction({
                                    result: result,
                                    silent: loadSilent,
                                })
                            )
                        );
                    }
                )
            );
        });

    private calculateUniquePages(
        action: ReturnType<typeof updateFiltersFromUrlAction>,
        calculationResult: ReturnType<typeof resultCalculationResponseSelector>
    ): number[] {
        const positionsInResult = this.mapPositionsInResult(calculationResult);
        const nonUniquePages = this.calculatePositions(
            action,
            calculationResult
        ).map((position) => this.calculatePage(position, positionsInResult));

        const uniquePages = new Set<number>(
            nonUniquePages.filter(
                (position: number | undefined) =>
                    position !== undefined && position > 0
            ) as number[]
        );

        return Array.from(uniquePages);
    }

    private calculatePage(
        position: number,
        positionsInResult: Set<number>
    ): number | undefined {
        if (positionsInResult.has(position)) {
            return undefined;
        }
        return Math.ceil(position / pagesize);
    }

    private buildApiCall(
        action: ReturnType<typeof updateFiltersFromUrlAction>,
        page: number,
        simplifiedResponse = false
    ): Observable<GetResultCalculationResponse> {
        return this.resultCalculationApiClient.fetch(
            this.parameterParserService.downgrade(action.calculationParameters),
            {
                ...action.sortApiParameters,
                page,
                pagesize,
                simplifiedResponse,
            }
        );
    }

    private calculatePositions(
        action: ReturnType<typeof updateFiltersFromUrlAction>,
        calculationResult: ReturnType<typeof resultCalculationResponseSelector>
    ): number[] {
        const position = action.positionParameters?.position || 1;
        const minPosition = Math.max(position - tariffsOutOfViewThreshold, 1);
        const maxPosition = Math.min(
            position + tariffsOutOfViewThreshold,
            calculationResult?.paging?.resultsize ?? Infinity
        );

        return [minPosition, position, maxPosition];
    }

    private mapPositionsInResult(
        calculationResult: ReturnType<typeof resultCalculationResponseSelector>
    ): Set<number> {
        const result = calculationResult?.result || [];
        return new Set<number>(
            result
                .filter((resultItem) => !resultItem.isSkeleton)
                .map((resultItem) => resultItem.result.position)
        );
    }
}
