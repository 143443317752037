import { createSelector } from '@ngrx/store';
import { CoreFeatureToggleEnum } from '@pkv-frontend/data-domain/application';
import {
    ApplicationState,
    ApplicationStateAware,
} from '../models/application-state.model';
import { applicationStateSelector } from './application-state.selector';

export const coreFeatureToggleSelector = createSelector<
    ApplicationStateAware,
    [ApplicationState],
    Record<CoreFeatureToggleEnum, boolean> | null
>(
    applicationStateSelector,
    (state: ApplicationState): Record<CoreFeatureToggleEnum, boolean> | null =>
        state.coreFeatureToggles
);
