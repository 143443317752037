import { createReducer, on } from '@ngrx/store';
import {
    effectivePriceToggleChangeAction,
    filterChangeAction,
    gkvCrossSellingChangeAction,
    sortChangeAction,
} from '../../../filter/store/actions/filter.actions';

import { gkvProviderChangeAction } from '../../../gkv/store/actions/gkv-provider-change.action';
import { resultPageHistoryBackTriggeredAction } from '../../../result/store/actions/result.actions';
import { toggleTabAction } from '../actions/toggle-tab.action';
import { PromotionState } from '../models/promotion-state.model';

export const initialState: PromotionState = {
    tabType: undefined,
    tariffVersionId: undefined,
};

export const promotionReducers = createReducer(
    initialState,
    on(
        filterChangeAction,
        effectivePriceToggleChangeAction,
        sortChangeAction,
        gkvProviderChangeAction,
        gkvCrossSellingChangeAction,
        resultPageHistoryBackTriggeredAction,
        (): PromotionState => {
            return {
                ...initialState,
            };
        }
    ),
    on(
        toggleTabAction,
        (
            state: PromotionState,
            action: ReturnType<typeof toggleTabAction>
        ): PromotionState => {
            const toggleValuesEqualsState =
                state.tabType === action.tabType &&
                state.tariffVersionId === action.tariffVersionId;

            return {
                ...state,
                tabType: toggleValuesEqualsState ? undefined : action.tabType,
                tariffVersionId: toggleValuesEqualsState
                    ? undefined
                    : action.tariffVersionId,
                feedbackRatingFilter: action.feedbackRatingFilter,
            };
        }
    )
);
