import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryCalculationParameterParser } from '@pkv-frontend/data-domain/calculation-parameter';

import { SendResultViaEmailRequestModel } from '../models/send-result-via-email-request.model';
import { SendResultViaEmailResponseModel } from '../models/send-result-via-email-response.model';

@Injectable({
    providedIn: 'root',
})
export class SendResultViaEmailApiClient {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly queryCalculationParameterParser: QueryCalculationParameterParser
    ) {}

    public post(
        model: SendResultViaEmailRequestModel
    ): Observable<SendResultViaEmailResponseModel> {
        const calculationUrlParameter =
            this.queryCalculationParameterParser.parse(
                model.calculationParameter
            );
        return this.httpClient.post<SendResultViaEmailResponseModel>(
            '/api/v1/public/customer-frontend/result/send-result-via-email',
            {
                email: model.email,
                calculationParameter: this.paramsToObject(
                    calculationUrlParameter
                ),
            }
        );
    }

    private paramsToObject(params: URLSearchParams): { [key: string]: string } {
        const result: { [key: string]: string } = {};
        params.forEach((value: string, key: string): void => {
            result[key] = value;
        });
        return result;
    }
}
