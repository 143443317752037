import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { concat, Observable, Subject, Subscriber } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
    DomainScopeEnum,
    getCookieDomainRule,
    SameSiteEnum,
} from '@pkv-frontend/infrastructure/cookie';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

const COOKIE_NAME = 'initialinfo_approved_kv';

@Injectable({
    providedIn: 'root',
})
export class FirstInformationCookieService {
    private readonly isApprovedSubject$: Subject<boolean> =
        new Subject<boolean>();

    public readonly isApproved$: Observable<boolean> = concat(
        new Observable<boolean>((observer: Subscriber<boolean>): void => {
            observer.next(this.isApproved());
            observer.complete();
        }),
        this.isApprovedSubject$
    ).pipe(shareReplay({ bufferSize: 1, refCount: false }));

    constructor(
        private readonly cookieService: CookieService,
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    public approve(): void {
        this.cookieService.set(
            COOKIE_NAME,
            'true',
            36500,
            '/',
            getCookieDomainRule(
                this.windowReferenceService.nativeWindow.location.hostname,
                DomainScopeEnum.Subdomain
            ),
            true,
            SameSiteEnum.Lax
        );
        this.isApprovedSubject$.next(true);
    }

    private isApproved(): boolean {
        return this.cookieService.check(COOKIE_NAME);
    }
}
