import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SsoUserDataApiModel } from '../models/sso-user-data-api.model';
import { SsoUserDataModel } from '../models/sso-user-data.model';

@Injectable({
    providedIn: 'root',
})
export class SsoUserDataApiService {
    constructor(private readonly httpClient: HttpClient) {}

    public fetch(): Observable<SsoUserDataApiModel> {
        return this.httpClient.get<SsoUserDataApiModel>(
            '/api/public/v1/user/sso-user-data'
        );
    }

    public mapToSsoUserDataModel(
        ssoUserDataApiModel: SsoUserDataApiModel
    ): SsoUserDataModel {
        return {
            gender: ssoUserDataApiModel.gender ?? undefined,
            firstName: ssoUserDataApiModel.firstName ?? undefined,
            lastName: ssoUserDataApiModel.lastName ?? undefined,
            mobileNumber: ssoUserDataApiModel.mobileNumber ?? undefined,
            email: ssoUserDataApiModel.email ?? undefined,
            street: ssoUserDataApiModel.street ?? undefined,
            streetNumber: ssoUserDataApiModel.streetNumber ?? undefined,
            zipCode: ssoUserDataApiModel.zipCode ?? undefined,
            city: ssoUserDataApiModel.city ?? undefined,
            birthdate: ssoUserDataApiModel.birthdate ?? undefined,
            ssoUserId: ssoUserDataApiModel.ssoUserId,
            hasActiveContracts: ssoUserDataApiModel.hasActiveContracts,
        };
    }
}
