import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';

import { GkvTariffTileStateEnum } from '../../enums/gkv-tariff-tile-state.enum';

export const gkvFeatureKey = 'gkvTariffTile';

export interface GkvStateModel {
    tariffs?: GkvResultItem[];
    state: GkvTariffTileStateEnum;
    lastCompactState: GkvTariffTileStateEnum;
}

export interface GkvStateAware {
    [gkvFeatureKey]: GkvStateModel;
}
