import {
    LowestPrices,
    Paging,
    ResultCalculationParameter,
    ResultDebugData,
    ResultItem,
    SkeletonResultItem,
    TariffInfo,
} from '@pkv-frontend/data-domain/result';
import { StateInsurancePrices } from '@pkv-frontend/data-domain/state-insurance';

import { TariffExpansionModeEnum } from '../../enums/tariff-expansion-mode.enum';

export const resultFeatureKey = 'result';

export interface ResultStateResult {
    statusCode?: number;
    /**
     * http or custom status message corresponding to this operation
     */
    statusMessage?: string;
    paging?: Paging;
    result: Array<ResultItem | SkeletonResultItem>;
    promotion?: { [tariffVersionId: string]: ResultItem };
    tariffinfo: TariffInfo;
    stateInsurancePrices?: StateInsurancePrices;
    lowestPrices?: LowestPrices;
    tariffCacheKey?: string;
    parameter: ResultCalculationParameter;
    debug?: string[];
    c24debug?: ResultDebugData;
}

export interface ResultState {
    result?: ResultStateResult;
    silentResult?: ResultStateResult;
    tariffExpansionMode: Record<
        string,
        {
            mode: TariffExpansionModeEnum | undefined;
            feedbackRatingFilter?: number;
        }
    >;
    tariffsWithEyeIcon?: number[];
}

export interface ResultStateAware {
    [resultFeatureKey]: ResultState;
}
