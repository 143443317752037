import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { C24NgEventTrackingService } from '@vv-ham/ng-tracking';
import { Observable } from 'rxjs';
import { map, skipWhile, withLatestFrom } from 'rxjs/operators';
import {
    CalculationParameterInterface,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { Consultant } from '@pkv-frontend/data-domain/result';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import { ConsultantOverlayContentTypeEnum } from '../../consultant-calback-overlay/enums/consultant-overlay-content-type.enum';
import { contentTypeSelector } from '../../consultant-calback-overlay/store/selectors/content-type.selector';
import { isVisibleSelector } from '../../consultant-calback-overlay/store/selectors/is-visible.selector';
import { resultFilterCalculationParametersSelector } from '../../filter/store/selectors/result-filter-calculation-parameters.selector';
import { consultantCarouselTrackingConfig } from '../constants/consultant-carousel-tracking-config.constant';
import { CarouselControls } from '../models/carousel-controls.model';
import { shouldSkipCurrentConsultantRule } from '../rules/should-skip-current-consultant.rule';
import {
    nextConsultantAction,
    previousConsultantAction,
} from '../store/actions/consultant-carousel.actions';

import { allConsultantsSelector } from '../store/selectors/all-consultants.selector';
import { callbackRequestStateSelector } from '../store/selectors/callback-request-state.selector';
import { consultantAtCurrentIndexSelector } from '../store/selectors/consultant-at-current-index.selector';
import { consultantsSelector } from '../store/selectors/consultants.selector';
import { controlsSelector } from '../store/selectors/controls.selector';
import { isCallbackAvailableSelector } from '../store/selectors/is-callback-available.selector';
import { paginationIndexSelector } from '../store/selectors/pagination-index.selector';
import { professionSelector } from '../store/selectors/profession.selector';

@Injectable({
    providedIn: 'root',
})
export class ConsultantCarouselDataService {
    public consultantAtCurrentIndex$: Observable<Consultant | undefined> =
        this.store$.select(consultantAtCurrentIndexSelector);

    public currentConsultant$: Observable<Consultant | undefined> =
        this.consultantAtCurrentIndex$.pipe(
            withLatestFrom(
                this.store$.select(professionSelector),
                this.store$.select(resultFilterCalculationParametersSelector)
            ),
            skipWhile(
                ([consultant, profession, calculationParameter]: [
                    Consultant | undefined,
                    ProfessionEnum | undefined,
                    CalculationParameterInterface | undefined,
                ]) =>
                    shouldSkipCurrentConsultantRule(
                        consultant,
                        profession,
                        calculationParameter?.profession
                    )
            ),
            map(
                ([consultant]: [
                    Consultant | undefined,
                    ProfessionEnum | undefined,
                    CalculationParameterInterface | undefined,
                ]) => consultant
            )
        );

    public readonly contentType$: Observable<
        ConsultantOverlayContentTypeEnum | undefined
    > = this.store$.select(contentTypeSelector);

    public readonly carouselControls$: Observable<CarouselControls> =
        this.store$.select(controlsSelector);

    public readonly carouselConsultants$: Observable<Consultant[] | undefined> =
        this.store$.select(consultantsSelector);

    public readonly allConsultants$: Observable<Consultant[] | undefined> =
        this.store$.select(allConsultantsSelector);

    public readonly isCallbackAvailable$: Observable<boolean | undefined> =
        this.store$.select(isCallbackAvailableSelector);

    public readonly callbackRequestState$: Observable<RequestStateEnum> =
        this.store$.select(callbackRequestStateSelector);

    public readonly isVisibleSelector$: Observable<boolean> =
        this.store$.select(isVisibleSelector);

    public readonly paginationIndex$: Observable<number> = this.store$.select(
        paginationIndexSelector
    );

    constructor(
        private readonly store$: Store,
        private readonly eventTrackingService: C24NgEventTrackingService
    ) {}

    public nextConsultant(): void {
        this.store$.dispatch(nextConsultantAction());

        this.eventTrackingService.trackEvent({
            matomo: {
                ...consultantCarouselTrackingConfig.consultantCarouselArrowAction,
                action: 'right',
            },
        });
    }

    public previousConsultant(): void {
        this.store$.dispatch(previousConsultantAction());

        this.eventTrackingService.trackEvent({
            matomo: {
                ...consultantCarouselTrackingConfig.consultantCarouselArrowAction,
                action: 'left',
            },
        });
    }
}
