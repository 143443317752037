import { createReducer, on } from '@ngrx/store';
import {
    activeConsultantDataLoadedAction,
    applicationDataInitiatedAction,
    coreFeatureTogglesLoadedAction,
    setIsCobrowsingAction,
} from '../actions/application.actions';
import { ApplicationState } from '../models/application-state.model';

export const initialState: ApplicationState = {
    isConsultantLoggedIn: false,
    isAlternativeOffer: false,
    isTariffChange: false,
    environment: undefined,
    intialProviderId: undefined,
    coreFeatureToggles: null,
};

export const applicationReducers = createReducer(
    initialState,
    on(
        applicationDataInitiatedAction,
        (
            state: ApplicationState,
            action: ReturnType<typeof applicationDataInitiatedAction>
        ): ApplicationState => {
            return {
                ...state,
                ...action.state,
            };
        }
    ),
    on(
        activeConsultantDataLoadedAction,
        (
            state: ApplicationState,
            action: ReturnType<typeof activeConsultantDataLoadedAction>
        ): ApplicationState => {
            return {
                ...state,
                activeConsultant: action.activeConsultant,
            };
        }
    ),
    on(
        setIsCobrowsingAction,
        (
            state: ApplicationState,
            action: ReturnType<typeof setIsCobrowsingAction>
        ): ApplicationState => {
            return {
                ...state,
                isCobrowsing: action.isCobrowsing,
            };
        }
    ),
    on(
        coreFeatureTogglesLoadedAction,
        (
            state: ApplicationState,
            action: ReturnType<typeof coreFeatureTogglesLoadedAction>
        ): ApplicationState => {
            return {
                ...state,
                coreFeatureToggles: action.coreFeatureToggles,
            };
        }
    )
);
