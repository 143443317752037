import { createSelector } from '@ngrx/store';
import { TabTypeEnum } from '../../../result/enums/tab-type.enum';
import {
    PromotionState,
    PromotionStateAware,
} from '../models/promotion-state.model';

import { promotionSelector } from './promotion.selector';

export const tabSelector = createSelector<
    PromotionStateAware,
    [PromotionState],
    TabTypeEnum | undefined
>(promotionSelector, (state: PromotionState) => state.tabType);
