import { ProviderFilterConfig } from '../models/provider-filter-config.model';

export const providerFilterConfig: ProviderFilterConfig = {
    standardProviderListConfig: [
        {
            name: 'Allianz',
            providerId: 1,
            isChecked: false,
        },
        {
            name: 'Alte Oldenburger',
            providerId: 2,
            isChecked: false,
        },
        {
            name: 'ARAG',
            providerId: 3,
            isChecked: false,
        },
        {
            name: 'AXA',
            providerId: 4,
            isChecked: false,
        },
        {
            name: 'Barmenia',
            providerId: 5,
            isChecked: false,
        },
        {
            name: 'Concordia',
            providerId: 8,
            isChecked: false,
        },
        {
            name: 'Continentale',
            providerId: 9,
            isChecked: false,
        },
        {
            name: 'DBV',
            providerId: 10,
            isChecked: false,
        },
        {
            name: 'Deutscher Ring',
            providerId: 12,
            isChecked: false,
        },
        {
            name: 'DKV',
            providerId: 14,
            isChecked: false,
        },
        {
            name: 'Gothaer',
            providerId: 15,
            isChecked: false,
        },
        {
            name: 'Hallesche',
            providerId: 16,
            isChecked: false,
        },
        {
            name: 'HanseMerkur',
            providerId: 17,
            isChecked: false,
        },
        {
            name: 'Inter',
            providerId: 19,
            isChecked: false,
        },
        {
            name: 'LKH',
            providerId: 20,
            isChecked: false,
        },
        {
            name: 'Münchener Verein',
            providerId: 24,
            isChecked: false,
        },
        {
            name: 'Nürnberger',
            providerId: 25,
            isChecked: false,
        },
        {
            name: 'ottonova',
            providerId: 44,
            isChecked: false,
        },
        {
            name: 'R+V',
            providerId: 28,
            isChecked: false,
        },
        {
            name: 'SdK',
            providerId: 29,
            isChecked: false,
        },
        {
            name: 'Signal Iduna',
            providerId: 30,
            isChecked: false,
        },
        {
            name: 'Universa',
            providerId: 32,
            isChecked: false,
        },
        {
            name: 'Versicherungs-\nkammer Bayern',
            providerId: 6,
            isChecked: false,
        },
        {
            name: 'VGH Provinzial',
            providerId: 27,
            isChecked: false,
        },
        {
            name: 'Württembergische',
            providerId: 33,
            isChecked: false,
        },
    ],
    consultantProviderListConfig: [
        {
            name: 'Debeka',
            providerId: 11,
            isChecked: false,
        },
        {
            name: 'HUK-Coburg',
            providerId: 18,
            isChecked: false,
        },
    ],
};
