import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { CoreFeatureToggleEnum } from '../enum/core-feature-toggle.enum';

const url = '/api/v1/public/customer-frontend/feature-toggles';

@Injectable({
    providedIn: 'root',
})
export class CoreFeatureToggleApiClient {
    constructor(private readonly httpClient: HttpClient) {}

    public readonly coreFeatureToggle$ = this.httpClient
        .get<Record<CoreFeatureToggleEnum, boolean>>(url)
        .pipe(
            shareReplay({ bufferSize: 1, refCount: false }),
            catchError(() => of(null))
        );
}
