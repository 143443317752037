import { Location } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// by default Angular strips the trailing slash of an url.
// we want to prevent this because our main-page/result-page has an trailing slash
Location.stripTrailingSlash = function _stripTrailingSlash(
    url: string
): string {
    return url;
};

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule, {
        providers: [{ provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' }],
    })
    .catch((err: Error) => console.error(err));
