import { AbstractControl } from '@angular/forms';

export function NoEmojisValidator(
    control: AbstractControl
): { [key: string]: boolean } | null {
    if (
        control.value &&
        typeof control.value === 'string' &&
        control.value.match(
            /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu
        )
    ) {
        return { emoji: true };
    }

    return null;
}
