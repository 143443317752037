import { Injectable } from '@angular/core';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';

const tariffsWithEyeIconPersistenceKey =
    'comparisonManager.tariffs-with-eye-icon';

@Injectable({
    providedIn: 'root',
})
export class TariffsWithEyeIconService {
    constructor(
        private readonly storageService: SessionStorageService,
        private readonly phpFrontendDataService: PhpFrontendDataService
    ) {}

    public getFromStorage(): number[] | undefined {
        return this.storageService.get(tariffsWithEyeIconPersistenceKey);
    }

    public addTariffsToStorage(tariffs: string[]): void {
        if (this.phpFrontendDataService.data.isConsultantLoggedIn) {
            return;
        }

        const tariffsWithEyeIcon = this.getFromStorage() || [];

        tariffs.forEach((tariff: string) => {
            const tariffId = parseInt(tariff, 10);
            if (!tariffsWithEyeIcon.includes(tariffId)) {
                tariffsWithEyeIcon.push(tariffId);
            }
        });

        this.storageService.set(
            tariffsWithEyeIconPersistenceKey,
            tariffsWithEyeIcon
        );
    }

    public clearViewedTariffs(): void {
        this.storageService.remove(tariffsWithEyeIconPersistenceKey);
    }
}
