import {
    CalculationParameterInterface,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

export function gkvPriceTextRule(
    calcParams: CalculationParameterInterface | undefined
): string {
    if (calcParams?.profession === ProfessionEnum.Freelancer) {
        return calcParams.gkvCurrentInsurer
            ? 'monatlich'
            : 'Durchschnittsbeitrag';
    }
    return 'Ihr Anteil';
}
