import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';

const DEFAULT_MIN_LENGTH = 6;
const LANDLINE_MIN_LENGTH = 10;
const GERMAN_COUNTRY_CODE = 'DE';
// eslint-disable-next-line
const PHONE_NUMBER_PATTERN = /^(\+?[\d]+([\/\-]?[(\d)]?\d)*)$/;

export function phoneNumberSchemaValidationRule(value: string): boolean {
    if (!value || value.length < DEFAULT_MIN_LENGTH) {
        return false;
    }

    const phoneNumber = removeWhitespace(value);
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        const number = phoneUtil.parse(phoneNumber, GERMAN_COUNTRY_CODE);
        const minLength =
            phoneUtil.getNumberType(number) === PhoneNumberType.FIXED_LINE
                ? LANDLINE_MIN_LENGTH
                : DEFAULT_MIN_LENGTH;

        return (
            phoneNumber.length >= minLength &&
            phoneNumber.length <= 20 &&
            PHONE_NUMBER_PATTERN.test(phoneNumber) &&
            phoneUtil.isValidNumber(number)
        );
    } catch (e) {
        return false;
    }
}

function removeWhitespace(controlValue: string): string {
    return controlValue.replace(/\s/g, '');
}
