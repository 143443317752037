import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { enterResultPageAction } from '../../../result/store/actions/result.actions';
import { TransparencyInfoCookieService } from '../../services/transparency-info-cookie.service';
import {
    initTransparencyInfoAction,
    transparencyInfoFromCookieLoadedAction,
} from '../actions/transparency-info.actions';

@Injectable()
export class LoadTransparencyInfoFromCookieEffect {
    constructor(
        private actions$: Actions,
        private transparencyInfoCookieService: TransparencyInfoCookieService
    ) {}

    public loadTransparencyInfoFromCookieEffect$: Observable<Action> =
        createEffect(() => {
            return this.actions$.pipe(
                ofType(initTransparencyInfoAction, enterResultPageAction),
                map(() => {
                    return transparencyInfoFromCookieLoadedAction({
                        sortfields: this.transparencyInfoCookieService.get(),
                    });
                })
            );
        });
}
