import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ResultViaEmailEffect } from './store/effects/result-via-email.effect';

import { resultViaEmailFeatureKey } from './store/models/result-via-email-state.model';
import { resultViaEmailReducers } from './store/reducers/result-via-email.reducers';

@NgModule({
    imports: [
        EffectsModule.forFeature([ResultViaEmailEffect]),
        StoreModule.forFeature(
            resultViaEmailFeatureKey,
            resultViaEmailReducers
        ),
    ],
})
export class ResultViaEmailModule {}
