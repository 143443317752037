import { createSelector } from '@ngrx/store';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { GkvStateAware, GkvStateModel } from '../models/gkv-state.model';

import { gkvStateSelector } from './gkv-state.selector';

export const gkvTariffsSelector = createSelector<
    GkvStateAware,
    [GkvStateModel],
    GkvResultItem[] | undefined
>(gkvStateSelector, (state: GkvStateModel) => state.tariffs);
