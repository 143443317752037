export enum PriceDetailKeyEnum {
    Ambulant = 'ambulant',
    Bet = 'bet',
    Dental = 'dental',
    Stationary = 'stationary',
    Hospitalization = 'hospitalization',
    Aid = 'aid',
    Care = 'care',
    Other = 'other',
    StateAddition = 'stateAddition',
    RiskPremium = 'riskPremium',
    TotalSum = 'totalSum',
    EmployeeContribution = 'employeeContribution',
    PkvPricePerYear = 'pkvPricePerYear',
    ReimbursementGuaranteed = 'reimbursementGuaranteed',
    ReimbursementVariable = 'reimbursementVariable',
    EffectivePrice = 'effectivePrice',
    YearlyEffectivePrice = 'yearlyEffectivePrice',
}
