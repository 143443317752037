import { AbstractControl, ValidationErrors } from '@angular/forms';

const NAME_PATTERN = /^(?=\p{L})[\p{L}\p{M} '.-]+$/u;

export function NameValidator(
    control: AbstractControl
): ValidationErrors | null {
    if (!control.value?.match(NAME_PATTERN)) {
        return { pattern: true };
    }

    return null;
}
