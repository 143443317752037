import { Injectable, NgZone } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { DocumentResizeChanges } from './document-resize-changes.model';

@Injectable({
    providedIn: 'root',
})
export class DocumentResizeObserverService {
    constructor(private ngZone: NgZone) {}

    public observe(element: HTMLElement): Observable<DocumentResizeChanges> {
        const resizeObserver$ = new Subject<DocumentResizeChanges>();
        this.ngZone.runOutsideAngular(() => {
            const resizeObserver = new ResizeObserver(() => {
                this.ngZone.run(() => {
                    resizeObserver$.next({
                        offsetHeight: element.offsetHeight,
                        offsetWidth: element.offsetWidth,
                    });
                });
            });

            resizeObserver.observe(element);
        });

        return resizeObserver$;
    }
}
