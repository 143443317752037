import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BenefitPresetEnum } from '@pkv-frontend/data-domain/calculation-parameter';

import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';

import { resultCustomFilterSettingsPersistenceKey } from '../../../result/constants/result.constants';
import { updateFiltersFromUrlAction } from '../actions/filter.actions';

@Injectable()
export class PersistCustomFilterSettingsEffect {
    constructor(
        private actions$: Actions,
        private storageService: SessionStorageService,
        private phpFrontendDataService: PhpFrontendDataService
    ) {}

    public persistCustomFilterSettingsEffect$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(updateFiltersFromUrlAction),
                map((action: ReturnType<typeof updateFiltersFromUrlAction>) => {
                    if (
                        action.resultFilters.benefitPreset ===
                            BenefitPresetEnum.Custom &&
                        !this.phpFrontendDataService.data.isConsultantLoggedIn
                    ) {
                        this.storageService.set(
                            resultCustomFilterSettingsPersistenceKey,
                            action.resultFilters
                        );
                    }
                })
            );
        },
        { dispatch: false }
    );
}
