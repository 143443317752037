import { createSelector } from '@ngrx/store';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterProviderIdsSelector = createSelector<
    FilterStateAware,
    [FilterState],
    number[]
>(filterStateFeatureSelector, (state: FilterState) =>
    state.providers.map((provider) => provider.id)
);
