import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FetchSsoUserDataEffect } from './effects/fetch-sso-user-data.effect';
import { ssoUserDataFeatureKey } from './models/sso-user-data-state.model';
import { ssoUserDataReducers } from './reducers/sso-user-data.reducers';

@NgModule({
    imports: [
        EffectsModule.forFeature([FetchSsoUserDataEffect]),
        StoreModule.forFeature(ssoUserDataFeatureKey, ssoUserDataReducers),
    ],
})
export class SsoUserDataStoreModule {}
