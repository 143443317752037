import { Injectable, signal, WritableSignal } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
    DomainScopeEnum,
    getCookieDomainRule,
} from '@pkv-frontend/infrastructure/cookie';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';
import { featureToggleTitleConstant } from '../constants/feature-toggle-title.constant';
import { FeatureToggleEnum } from '../enums/feature-toggle.enum';
import { FeatureToggleModel } from '../models/feature-toggle.model';
import { FeatureToggleCookieValidator } from '../validators/feature-toggle-cookie.validator';

const ACTIVE_FEATURE_TOGGLES_COOKIE = 'active-feature-toggles';

@Injectable({ providedIn: 'root' })
export class FeatureToggleManagerService {
    public readonly featureToggles: WritableSignal<
        Record<string, FeatureToggleModel>
    >;

    constructor(
        private readonly cookieService: CookieService,
        private readonly windowReferenceService: WindowReferenceService
    ) {
        const activeFeatureToggles = this.activeFeatureTogglesFromCookie;
        this.featureToggles = signal(
            Object.values(FeatureToggleEnum).reduce<
                Record<string, FeatureToggleModel>
            >((accumulator, featureToggle: FeatureToggleEnum) => {
                accumulator[featureToggle] = {
                    title: featureToggleTitleConstant[featureToggle],
                    identifier: featureToggle,
                    isEnabled: activeFeatureToggles.includes(featureToggle),
                };
                return accumulator;
            }, {})
        );
    }

    public isEnabled(featureToggle: FeatureToggleEnum): boolean {
        return this.featureToggles()[featureToggle].isEnabled;
    }

    public get activeFeatureTogglesFromCookie(): FeatureToggleEnum[] {
        const cookieValues = this.cookieService.get(
            ACTIVE_FEATURE_TOGGLES_COOKIE
        );

        try {
            const toggles: FeatureToggleEnum[] = JSON.parse(cookieValues);

            if (!FeatureToggleCookieValidator(toggles)) {
                throw Error('Invalid feature toggles');
            }

            return toggles;
        } catch {
            return [];
        }
    }

    public toggleFeatureStatus(identifier: FeatureToggleEnum | string): void {
        this.featureToggles.update((currentState) => ({
            ...currentState,
            [identifier]: {
                ...currentState[identifier],
                isEnabled: !currentState[identifier].isEnabled,
            },
        }));
        this.setActiveFeatureToggleCookie();
    }

    public clearFeatureToggles(): void {
        this.featureToggles.update((currentState) =>
            Object.keys(currentState).reduce<
                Record<string, FeatureToggleModel>
            >((accumulator, key) => {
                accumulator[key] = {
                    ...currentState[key],
                    isEnabled: false,
                };
                return accumulator;
            }, {})
        );
        this.setActiveFeatureToggleCookie();
    }

    private setActiveFeatureToggleCookie(): void {
        const featureToggles = Object.keys(this.featureToggles()).filter(
            (key) => this.featureToggles()[key].isEnabled
        );

        this.cookieService.set(
            ACTIVE_FEATURE_TOGGLES_COOKIE,
            JSON.stringify([...featureToggles]),
            1,
            '/',
            this.getDomain(),
            true,
            'Strict'
        );
    }

    private getDomain(): string {
        return getCookieDomainRule(
            this.windowReferenceService.nativeWindow.location.hostname,
            DomainScopeEnum.Subdomain
        );
    }
}
