import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    C24NgEventTrackingService,
    GeneralTrackingEventTrackingData,
} from '@vv-ham/ng-tracking';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LeadCreationTypeEnum } from '@pkv-frontend/business-domain/lead';
import { generalTrackingConfig } from '../../constants/general-tracking.config';
import { openOverlay } from '../actions/consultant-callback-overlay.actions';

@Injectable()
export class OverlayOpenedTrackingEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly eventTrackingService: C24NgEventTrackingService
    ) {}

    public readonly overlayOpenedTracking$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openOverlay),
                map((action: ReturnType<typeof openOverlay>) => {
                    (action.leadCreationType === LeadCreationTypeEnum.Expert
                        ? generalTrackingConfig.openExpertOverlay
                        : generalTrackingConfig.openConsultantCallbackOverlay
                    ).forEach((data: GeneralTrackingEventTrackingData) => {
                        this.eventTrackingService.trackEvent({
                            generalTracking: data,
                        });
                    });
                })
            );
        },
        { dispatch: false }
    );
}
