import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { format } from 'date-fns/format';
import { LastSearch } from '@pkv-frontend/data-domain/last-search';

const queryParameterMap: {
    [key: string]: {
        type: 'bool' | 'date' | 'array';
    };
} = {
    directMedicalConsultationBenefit: {
        type: 'bool',
    },
    provisionContributionReimbursement: {
        type: 'bool',
    },
    evaluationContributionStability: {
        type: 'bool',
    },
    birthdate: {
        type: 'date',
    },
    insureDate: {
        type: 'date',
    },
    reducedResults: {
        type: 'bool',
    },
    amedNonMedPractitionerReimbursement: {
        type: 'bool',
    },
    treatmentAboveMaximumRate: {
        type: 'bool',
    },
    parentServantOrServantCandidate: {
        type: 'bool',
    },
    cureAndRehab: {
        type: 'bool',
    },
    effectivePrice: {
        type: 'bool',
    },
    provider: {
        type: 'array',
    },
};

@Injectable({ providedIn: 'root' })
export class LastSearchParameterParser {
    public parse(lastSearch: LastSearch): Params {
        const parsedLastSearch = {};

        Object.keys(lastSearch).forEach((key: string) => {
            if (!(key in queryParameterMap)) {
                parsedLastSearch[key] = lastSearch[key];
                return;
            }

            switch (queryParameterMap[key].type) {
                case 'date':
                    parsedLastSearch[key] = format(
                        new Date(lastSearch[key] ?? '1985-01-01'),
                        'yyyy-MM-dd'
                    );
                    break;
                case 'bool':
                    parsedLastSearch[key] = lastSearch[key] ? 'yes' : 'no';
                    break;
                case 'array':
                    parsedLastSearch[key] = lastSearch[key].join(',');
                    break;
                default:
                    parsedLastSearch[key] = lastSearch[key];
            }
        });

        return parsedLastSearch;
    }
}
