import { createSelector } from '@ngrx/store';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

// When the result should be loaded "silently", the results of the
// Calculation API are saved in "silentResult" in the Result Slice.
// The purpose behind this is to load the latest lowest prices
// without updating the actual result set.
export const loadResultSilentlySelector = createSelector<
    FilterStateAware,
    [FilterState],
    boolean
>(filterStateFeatureSelector, (state: FilterState) => state.loadResultSilently);
