import { createSelector } from '@ngrx/store';
import {
    PromotionState,
    PromotionStateAware,
} from '../models/promotion-state.model';

import { promotionSelector } from './promotion.selector';

export const resultPromotionFeedbackRatingFilterSelector = createSelector<
    PromotionStateAware,
    [PromotionState],
    number | undefined
>(promotionSelector, (state: PromotionState) => state.feedbackRatingFilter);
