import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { C24NgEventTrackingService } from '@vv-ham/ng-tracking';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TariffExpansionModeEnum } from '../../enums/tariff-expansion-mode.enum';

import { toggleTariffExpansionModeAction } from '../actions/result.actions';
import { resultTariffExpansionModeSelector } from '../selectors/result-tariff-expansion-mode.selector';

@Injectable()
export class TrackToggleTariffExpansionModeEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly trackingService: C24NgEventTrackingService
    ) {}

    public trackToggleTariffExpansionModeEffect$: Observable<void> =
        createEffect(
            () => {
                return this.actions$.pipe(
                    ofType(toggleTariffExpansionModeAction),
                    concatLatestFrom(
                        (
                            action: ReturnType<
                                typeof toggleTariffExpansionModeAction
                            >
                        ) =>
                            this.store$.select(
                                resultTariffExpansionModeSelector(
                                    action.expansionModeKey
                                )
                            )
                    ),
                    map(
                        ([action, expansionMode]: [
                            ReturnType<typeof toggleTariffExpansionModeAction>,
                            TariffExpansionModeEnum | undefined,
                        ]) => {
                            if (!action.trackingConfig) {
                                return;
                            }

                            this.trackingService.trackEvent({
                                matomo: {
                                    ...action.trackingConfig,
                                    action:
                                        action.mode === expansionMode
                                            ? 'open'
                                            : 'close',
                                },
                            });
                        }
                    )
                );
            },
            { dispatch: false }
        );
}
