import { PriceDetailKeyEnum } from '../enums/price-detail-key.enum';

export const priceDetailTranslationConstant = {
    [PriceDetailKeyEnum.Ambulant]: 'Grundtarif',
    [PriceDetailKeyEnum.Bet]: 'Beitragsentlastungstarif',
    [PriceDetailKeyEnum.Dental]: 'Zahnleistungen',
    [PriceDetailKeyEnum.Stationary]: 'Stationäre Leistungen',
    [PriceDetailKeyEnum.Hospitalization]: 'Krankentagegeld',
    [PriceDetailKeyEnum.Aid]: 'Beihilfeergänzung',
    [PriceDetailKeyEnum.Other]: 'Kurleistungen',
    [PriceDetailKeyEnum.Care]: 'Pflegepflichtversicherung',
    [PriceDetailKeyEnum.StateAddition]:
        'Gesetzlicher Beitragszuschlag für Altersrückstellungen',
    [PriceDetailKeyEnum.RiskPremium]: 'Risikozuschläge',
    [PriceDetailKeyEnum.TotalSum]: 'Gesamtbeitrag pro Monat',
    [PriceDetailKeyEnum.EmployeeContribution]: 'Ihr Anteil',
    [PriceDetailKeyEnum.PkvPricePerYear]: 'Ihre jährlichen Kosten',
    [PriceDetailKeyEnum.ReimbursementGuaranteed]:
        'Garantierter Beitragsrückerstattung',
    [PriceDetailKeyEnum.ReimbursementVariable]:
        'Variabler Beitragsrückerstattung',
    [PriceDetailKeyEnum.EffectivePrice]:
        'Ihr Beitrag pro Monat inkl. Beitragsrückerstattung',
    [PriceDetailKeyEnum.YearlyEffectivePrice]:
        'Ihr effektiver jährlicher Beitrag',
};
