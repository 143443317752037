import { SortFieldEnum } from '@pkv-frontend/data-domain/result';

export const transparencyInfoFeatureKey = 'transparencyInfo';

export interface TransparencyInfoState {
    sortfields: SortFieldEnum[];
}

export interface TransparencyInfoStateAware {
    [transparencyInfoFeatureKey]: TransparencyInfoState;
}
