import { createSelector } from '@ngrx/store';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { isAverageGkvRule } from '../../rules/is-average-gkv.rule';
import { GkvStateAware } from '../models/gkv-state.model';

import { gkvTariffsSelector } from './gkv-tariffs.selector';

export const averageGkvSelector = createSelector<
    GkvStateAware,
    [GkvResultItem[] | undefined],
    GkvResultItem | undefined
>(
    gkvTariffsSelector,
    (items: GkvResultItem[] | undefined): GkvResultItem | undefined => {
        return items?.find((gkvTariff: GkvResultItem) =>
            isAverageGkvRule(gkvTariff)
        );
    }
);
