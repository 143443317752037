import { createSelector } from '@ngrx/store';
import { CarouselControls } from '../../models/carousel-controls.model';
import {
    ConsultantCarouselState,
    ConsultantCarouselStateAware,
} from '../models/consultant-carousel-state.model';

import { consultantCarouselStateSelector } from './consultant-carousel-state.selector';

export const controlsSelector = createSelector<
    ConsultantCarouselStateAware,
    [ConsultantCarouselState],
    CarouselControls
>(
    consultantCarouselStateSelector,
    (state: ConsultantCarouselState): CarouselControls => state.controls
);
