import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
    C24NgEventTrackingService,
    TrackingProvider,
} from '@vv-ham/ng-tracking';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ConsultantOverlayCloseByTypeEnum } from '../../enums/consultant-overlay-close-by-type.enum';
import { ConsultantOverlayContentTypeEnum } from '../../enums/consultant-overlay-content-type.enum';
import { OverlayTrackingCloseEventService } from '../../services/overlay-tracking-close-event.service';
import { closeOverlay } from '../actions/consultant-callback-overlay.actions';
import { contentTypeSelector } from '../selectors/content-type.selector';

@Injectable()
export class OverlayClosedTrackingEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly eventTrackingService: C24NgEventTrackingService,
        private readonly overlayTrackingCloseEventService: OverlayTrackingCloseEventService
    ) {}

    public readonly overlayClosedTracking$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeOverlay),
                debounceTime(100),
                concatLatestFrom(() => this.store$.select(contentTypeSelector)),
                map(
                    ([action, contentType]: [
                        ReturnType<typeof closeOverlay>,
                        ConsultantOverlayContentTypeEnum,
                    ]) => {
                        if (
                            action.closeByType !==
                            ConsultantOverlayCloseByTypeEnum.Automatic
                        ) {
                            this.eventTrackingService.trackEvent({
                                [TrackingProvider.Matomo]:
                                    this.overlayTrackingCloseEventService.getTrackingCloseEvent(
                                        contentType,
                                        action.closeByType
                                    ),
                            });
                        }
                    }
                )
            );
        },
        { dispatch: false }
    );
}
