import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';
import { notUndefinedOrNull } from '@pkv-frontend/infrastructure/type-guards';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ErrorLoggerService } from './error-logger.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler, OnDestroy {
    private readonly destroy$: Subject<void> = new Subject<void>();
    private readonly lastError: Record<string, boolean> = {};

    constructor(
        private readonly errorLoggerService: ErrorLoggerService,
        private readonly windowReferenceService: WindowReferenceService
    ) {
        this.windowReferenceService.nativeWindow._jsErrorsToLog
            ?.map((event) => this.mapEventToError(event))
            .filter(notUndefinedOrNull)
            .forEach((error) => this.handleError(error));
    }

    public handleError(error: Error): void {
        if (
            this.lastError[
                JSON.stringify(error, Object.getOwnPropertyNames(error))
            ] === undefined
        ) {
            this.lastError[
                JSON.stringify(error, Object.getOwnPropertyNames(error))
            ] = true;
            this.errorLoggerService
                .log(error)
                .pipe(takeUntil(this.destroy$))
                .subscribe();
        }

        console.error('Error', error);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public mapEventToError(
        event: ErrorEvent | PromiseRejectionEvent
    ): Error | undefined {
        if (event instanceof ErrorEvent) {
            return this.mapErrorEventToError(event);
        }
        if (event instanceof PromiseRejectionEvent) {
            return this.mapPromiseRejectionEventToError(event);
        }
        return undefined;
    }

    private mapErrorEventToError(event: ErrorEvent): Error {
        return {
            name: event.error?.name ?? 'Javascript Error',
            message: event.message,
            stack: event.error?.stack,
            filename: event.filename,
            lineno: event.lineno,
            colno: event.colno,
        } as Error;
    }

    private mapPromiseRejectionEventToError(
        event: PromiseRejectionEvent
    ): Error {
        if (event.reason instanceof Error) {
            return event.reason;
        }

        return {
            name: 'Promise Rejection Javascript Error',
            message: JSON.stringify(event.reason) ?? 'Promise Rejection Error',
        };
    }
}
