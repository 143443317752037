import { RadioListRowVariantEnum } from '../enums/radio-list-row-variant.enum';
import { FilterRadioListConfig } from '../models/filter-radio-list.config.model';
export const costsharingFilterConfig: FilterRadioListConfig = {
    radioListVariant: RadioListRowVariantEnum.Multi,
    options: [
        {
            label: '0 €',
            value: 0,
        },
        {
            label: 'bis 500 €',
            value: 500,
        },
        {
            label: 'bis 1.000 €',
            value: 1000,
        },
        {
            label: 'egal',
            value: -1,
        },
    ],
};

export const pdhospitalPayoutAmountValueConfig = {
    min: 0,
    max: 600,
    step: 5,
};

export const betAmountValueConfig = {
    min: 0,
    max: 1000,
    step: 5,
};
