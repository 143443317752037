import { createSelector } from '@ngrx/store';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { FilterStateAware } from '../../../filter/store/models/filter-state.model';
import { resultFilterCalculationParametersSelector } from '../../../filter/store/selectors/result-filter-calculation-parameters.selector';
import { GkvStateAware } from '../models/gkv-state.model';

import { gkvTariffsSelector } from './gkv-tariffs.selector';

export const selectedGkvSelector = createSelector<
    GkvStateAware & FilterStateAware,
    [GkvResultItem[] | undefined, CalculationParameterInterface | undefined],
    GkvResultItem | undefined
>(
    gkvTariffsSelector,
    resultFilterCalculationParametersSelector,
    (
        tariffs: GkvResultItem[] | undefined,
        calcParams: CalculationParameterInterface | undefined
    ): GkvResultItem | undefined => {
        if (!calcParams?.gkvCurrentInsurer) {
            return;
        }
        return tariffs?.find(
            (gkvTariff: GkvResultItem) =>
                gkvTariff.provider.id === calcParams.gkvCurrentInsurer
        );
    }
);
