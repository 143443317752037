import { formatCurrency } from '@angular/common';
import { InjectionToken } from '@angular/core';
import {
    DentalEnum,
    GradeEnum,
    HospitalizationAccommodationEnum,
    TreatmentAttendingDoctorEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { FilterChipConfigModel } from '../models/filter-chip-config.model';

export const baseFilterChipConfig = [
    <FilterChipConfigModel<'provisionCostsharingLimit'>>{
        key: 'provisionCostsharingLimit',
        hiddenValue: -1,
        text: (value) =>
            'Selbstbeteiligung: ' +
            (value > 0 ? 'bis ' : '') +
            formatCurrency(value, 'de-DE', '€', undefined, '1.0-0'),
    },
    <FilterChipConfigModel<'dental'>>{
        key: 'dental',
        hiddenValue: DentalEnum.Basic,
        text: (value) =>
            `Zahnersatz: ${
                value === DentalEnum.Comfort ? '75' : '85'
            }% und besser`,
    },
    <FilterChipConfigModel<'hospitalizationAccommodation'>>{
        key: 'hospitalizationAccommodation',
        hiddenValue: HospitalizationAccommodationEnum.Multi,
        text: (value) =>
            value === HospitalizationAccommodationEnum.Single
                ? '1-Bett-Zimmer'
                : '2-Bett-Zimmer und besser',
    },
    <FilterChipConfigModel<'check24Grade'>>{
        key: 'check24Grade',
        hiddenValue: GradeEnum.All,
        text: (value) =>
            'Note: ' +
            (value === GradeEnum.VeryGood ? 'sehr gut' : 'gut und besser'),
    },
    <FilterChipConfigModel<'treatmentAttendingDoctor'>>{
        key: 'treatmentAttendingDoctor',
        hiddenValue: TreatmentAttendingDoctorEnum.Empty,
        text: () => 'Chefarzt / Spezialist',
    },
    <FilterChipConfigModel<'directMedicalConsultationBenefit'>>{
        key: 'directMedicalConsultationBenefit',
        hiddenValue: false,
        text: () => 'Direkt zum Facharzt',
    },
    <FilterChipConfigModel<'provisionContributionReimbursement'>>{
        key: 'provisionContributionReimbursement',
        hiddenValue: false,
        text: () => 'Beitragsrückerstattung',
    },
    <FilterChipConfigModel<'evaluationContributionStability'>>{
        key: 'evaluationContributionStability',
        hiddenValue: false,
        text: () => 'Stabile Beiträge',
    },
    <FilterChipConfigModel<'treatmentAboveMaximumRate'>>{
        key: 'treatmentAboveMaximumRate',
        hiddenValue: false,
        text: () => 'Stationär über Höchstsatz',
    },
    <FilterChipConfigModel<'cureAndRehab'>>{
        key: 'cureAndRehab',
        hiddenValue: false,
        text: () => 'Kurleistungen: Sehr gut',
    },
    <FilterChipConfigModel<'amedNonMedPractitionerReimbursement'>>{
        key: 'amedNonMedPractitionerReimbursement',
        hiddenValue: false,
        text: () => 'Heilpraktiker',
    },
];

export const FILTER_CHIP_CONFIG_TOKEN = new InjectionToken<
    FilterChipConfigModel<keyof ResultFilter>[]
>('FILTER_CHIP_CONFIG_TOKEN', { factory: () => baseFilterChipConfig });
