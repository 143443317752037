import { createSelector } from '@ngrx/store';
import { ResultState, ResultStateAware } from '../models/result-state.model';

import { resultStateFeatureSelector } from './result-state-feature.selector';

export const resultTariffFeedbackRatingFilterSelector = (
    expansionModeKey: string
) =>
    createSelector<ResultStateAware, [ResultState], number | undefined>(
        resultStateFeatureSelector,
        (state: ResultState) =>
            state.tariffExpansionMode[expansionModeKey]?.feedbackRatingFilter
    );
