import { Component } from '@angular/core';

export const selector = 'web-component-development-toolbar';

@Component({
    selector,
    templateUrl: './development-toolbar.web-component.html',
    styleUrls: ['./development-toolbar.web-component.less'],
})
export class DevelopmentToolbarWebComponent {}
