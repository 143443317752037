import { Injectable } from '@angular/core';
import { format } from 'date-fns/format';
import { CalculationParameterInformationService } from '@pkv-frontend/business-domain/calculation-parameter';
import {
    CalculationParameterInterface,
    GradeEnum,
    HospitalizationAccommodationEnum,
    ProvisionCostsharingLimitEnum,
    TreatmentAttendingDoctorEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { ShouldSelectTreatmentAttendingDoctorRule } from '../rules/should-select-treatment-attending-doctor.rule';

const filterParameterKeys: Array<keyof CalculationParameterInterface> = [
    'amedNonMedPractitionerReimbursement',
    'benefitPreset',
    'check24Grade',
    'dental',
    'dentalTreatment',
    'dentalDentures',
    'directMedicalConsultationBenefit',
    'evaluationContributionStability',
    'hospitalizationAccommodation',
    'provisionContributionReimbursement',
    'provisionCostsharingLimit',
    'reducedResults',
    'treatmentAboveMaximumRate',
    'treatmentAttendingDoctor',
    'pdhospitalPayoutAmountValue',
    'pdhospitalPayoutStart',
    'betAmount',
    'provider',
    'cureAndRehab',
    'insureDate',
    'debug',
    'c24debug',
    'effectivePrice',
];
const provisionCostsharingLimitDefault = 5000;

@Injectable({
    providedIn: 'root',
})
export class FilterParameterService {
    constructor(
        private calculationParameterInformationService: CalculationParameterInformationService,
        private shouldSelectTreatmentAttendingDoctorRule: ShouldSelectTreatmentAttendingDoctorRule
    ) {}

    public generateFromCalculationParameter(
        calculationParameter: CalculationParameterInterface
    ): ResultFilter {
        const filterParameter: object = {};

        Object.keys(calculationParameter).map(
            (key: keyof CalculationParameterInterface) => {
                if (filterParameterKeys.includes(key)) {
                    filterParameter[key] = calculationParameter[key];
                }
            }
        );

        if (
            this.calculationParameterInformationService.isServantOrServantCandidateOrServantChild(
                calculationParameter
            )
        ) {
            filterParameter['hospitalizationAccommodationSingle'] =
                filterParameter['hospitalizationAccommodation'] ===
                HospitalizationAccommodationEnum.Single;
        }

        if (calculationParameter.createNewCalculationParameterId) {
            filterParameter['provisionCostsharingLimit'] =
                filterParameter['provisionCostsharingLimit'] === -1
                    ? provisionCostsharingLimitDefault
                    : filterParameter['provisionCostsharingLimit'];
        }

        filterParameter['noPdhospitalPayout'] =
            filterParameter['pdhospitalPayoutStart'] === 0 &&
            filterParameter['pdhospitalPayoutStart'] === 0;

        return this.setDefaultValues(
            {
                ...(filterParameter as ResultFilter),
            },
            calculationParameter
        );
    }

    private setDefaultValues(
        pristineFilterParameter: ResultFilter,
        calculationParameter: CalculationParameterInterface
    ): ResultFilter {
        const filterParameter = { ...pristineFilterParameter };

        if (filterParameter.check24Grade === undefined) {
            filterParameter.check24Grade = GradeEnum.All;
        }

        if (
            filterParameter.provisionCostsharingLimit !== 0 &&
            !filterParameter.provisionCostsharingLimit
        ) {
            filterParameter.provisionCostsharingLimit =
                this.calculationParameterInformationService.isFreelancer(
                    calculationParameter
                )
                    ? ProvisionCostsharingLimitEnum.All
                    : ProvisionCostsharingLimitEnum.Low;
        }

        filterParameter.treatmentAttendingDoctor =
            this.shouldSelectTreatmentAttendingDoctorRule.apply(filterParameter)
                ? TreatmentAttendingDoctorEnum.HeadDoctor
                : TreatmentAttendingDoctorEnum.Empty;

        filterParameter.insureDate = format(
            new Date(pristineFilterParameter.insureDate),
            'yyyy-MM-dd'
        );

        return filterParameter;
    }
}
