import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

const EMAIL_PATTERN =
    "^[a-zA-Z0-9#$&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$";

export function EmailValidator(
    control: AbstractControl
): ValidationErrors | null {
    if (!control.value?.match(EMAIL_PATTERN)) {
        return { email: true };
    }

    return Validators.email(control);
}
