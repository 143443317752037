import { Injectable } from '@angular/core';

import { ExtendedWindowInterface } from '../models/extended-window.interface';

@Injectable({
    providedIn: 'root',
})
export class WindowReferenceService {
    public get nativeWindow(): ExtendedWindowInterface {
        return window as ExtendedWindowInterface;
    }

    public isWindowTop(): boolean {
        return this.nativeWindow.top === this.nativeWindow;
    }

    public static get nativeWindow(): ExtendedWindowInterface {
        return window as ExtendedWindowInterface;
    }
}
