import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { queryParameterMap } from '@pkv-frontend/business-domain/calculation-parameter';
import { ResultNavigationService } from '../../../result/services/result-navigation.service';
import { GkvFormEnum } from '../../enums/gkv-form.enum';

import { gkvProviderChangeAction } from '../actions/gkv-provider-change.action';

@Injectable()
export class UpdateUrlForGkvProviderChangeEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly resultNavigationService: ResultNavigationService
    ) {}

    public readonly updateUrlForGkvProviderChangeEffect$: Observable<void> =
        createEffect(
            () => {
                return this.actions$.pipe(
                    ofType(gkvProviderChangeAction),
                    map(
                        ({
                            formData,
                        }: ReturnType<typeof gkvProviderChangeAction>) => {
                            const queryParams = {
                                [queryParameterMap.gkvCurrentInsurer.key]:
                                    formData?.[GkvFormEnum.Provider].provider
                                        .id,
                                [queryParameterMap.annualIncome.key]:
                                    formData?.[GkvFormEnum.AnnualIncome],
                                position: undefined,
                            };

                            this.resultNavigationService.navigate(queryParams);
                        }
                    )
                );
            },
            { dispatch: false }
        );
}
