import {
    CalculationParameterInterface,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { GkvResultItem } from '@pkv-frontend/data-domain/gkv';
import { ResultStateResult } from '../../result/store/models/result-state.model';

export function gkvPriceRule(
    calcParams: CalculationParameterInterface | undefined,
    result: ResultStateResult | undefined,
    selectedOrAverageGkv: GkvResultItem | undefined
): number | undefined {
    if (!calcParams) {
        return;
    }

    if (calcParams.profession === ProfessionEnum.Freelancer) {
        if (!result?.stateInsurancePrices?.freelancerTotalYearlyAmount) {
            return;
        }
        return result.stateInsurancePrices.freelancerTotalYearlyAmount / 12;
    }

    return selectedOrAverageGkv?.tariff.price;
}
