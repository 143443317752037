import { Injectable } from '@angular/core';

import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import {
    LastSearch,
    LastSearchTariffInfo,
} from '@pkv-frontend/data-domain/last-search';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';

@Injectable({ providedIn: 'root' })
export class LastSearchBuilder {
    constructor(private phpFrontendDataService: PhpFrontendDataService) {}

    public build(
        calculationParameter: CalculationParameterInterface,
        lastSearchTariffInfo: LastSearchTariffInfo
    ): LastSearch {
        const lastSearch = {
            ...calculationParameter,
            ...lastSearchTariffInfo,
        } as LastSearch;

        lastSearch.deviceoutput = this.phpFrontendDataService.data.deviceOutput;

        return Object.keys(lastSearch).reduce(
            (filtered: LastSearch, key: string) => {
                if (
                    lastSearch[key] === lastSearch[key] && // filter NaN values: NaN === NaN = false
                    lastSearch[key] !== undefined
                ) {
                    filtered[key] = lastSearch[key];
                }
                return filtered;
            },
            {}
        );
    }
}
