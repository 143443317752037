import { createReducer, on } from '@ngrx/store';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import { CarouselActionEnum } from '../../enums/carousel-action.enum';
import {
    finishInitConsultantData,
    finishIsCallbackAvailableCall,
    initCarousel,
    initConsultantDataError,
    nextConsultantAction,
    previousConsultantAction,
    resetCarouselControlsAction,
    startIsCallbackAvailableCall,
    toggleAdditionalInfoVisibilityAction,
} from '../actions/consultant-carousel.actions';

import { ConsultantCarouselState } from '../models/consultant-carousel-state.model';

const initialState: ConsultantCarouselState = {
    consultants: undefined,
    controls: {
        index: 0,
    },
    profession: undefined,
    shouldShowAdditionalInfo: {},
    requestState: RequestStateEnum.Initial,
    callbackRequestState: RequestStateEnum.Initial,
    isCallbackAvailable: undefined,
};

export const consultantCarouselReducers = createReducer(
    initialState,
    on(
        finishInitConsultantData,
        (
            state: ConsultantCarouselState,
            action: ReturnType<typeof finishInitConsultantData>
        ): ConsultantCarouselState => ({
            ...state,
            consultants: action.consultants,
            requestState: RequestStateEnum.Success,
        })
    ),
    on(
        initConsultantDataError,
        (state: ConsultantCarouselState): ConsultantCarouselState => ({
            ...state,
            requestState: RequestStateEnum.Error,
        })
    ),
    on(
        startIsCallbackAvailableCall,
        (state: ConsultantCarouselState): ConsultantCarouselState => ({
            ...state,
            callbackRequestState: RequestStateEnum.Initial,
        })
    ),
    on(
        finishIsCallbackAvailableCall,
        (
            state: ConsultantCarouselState,
            action: ReturnType<typeof finishIsCallbackAvailableCall>
        ): ConsultantCarouselState => ({
            ...state,
            isCallbackAvailable: action.isAvailable,
            callbackRequestState: action.requestState,
        })
    ),
    on(
        initCarousel,
        (
            state: ConsultantCarouselState,
            action: ReturnType<typeof initCarousel>
        ): ConsultantCarouselState => ({
            ...initialState,
            ...state,
            profession: action.profession,
            callbackRequestState: RequestStateEnum.Initial,
            shouldShowAdditionalInfo: {
                ...state.shouldShowAdditionalInfo,
                [action.carouselId]: false,
            },
        })
    ),
    on(
        toggleAdditionalInfoVisibilityAction,
        (
            state: ConsultantCarouselState,
            action: ReturnType<typeof toggleAdditionalInfoVisibilityAction>
        ): ConsultantCarouselState => {
            const shouldShow =
                !!state.shouldShowAdditionalInfo &&
                state.shouldShowAdditionalInfo[action.carouselId];

            return {
                ...state,
                shouldShowAdditionalInfo: {
                    ...state.shouldShowAdditionalInfo,
                    [action.carouselId]: !shouldShow,
                },
            };
        }
    ),
    on(
        resetCarouselControlsAction,
        (state: ConsultantCarouselState): ConsultantCarouselState => ({
            ...state,
            controls: initialState.controls,
        })
    ),
    on(
        nextConsultantAction,
        (state: ConsultantCarouselState): ConsultantCarouselState => ({
            ...state,
            controls: {
                index: state.consultants?.length
                    ? (state.controls.index + 1) % state.consultants.length
                    : 0,
                action: CarouselActionEnum.Next,
            },
        })
    ),
    on(
        previousConsultantAction,
        (state: ConsultantCarouselState): ConsultantCarouselState => {
            let index = 0;
            if (state.consultants?.length) {
                if (state.controls.index <= 0) {
                    index = state.consultants.length - 1;
                } else {
                    index =
                        (state.controls.index - 1) % state.consultants.length;
                }
            }

            return {
                ...state,
                controls: {
                    index,
                    action: CarouselActionEnum.Prev,
                },
            };
        }
    )
);
