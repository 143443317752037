import { AbstractControl } from '@angular/forms';

export function IsObjectValidator(
    control: AbstractControl
): { [key: string]: boolean } | null {
    if (typeof control.value !== 'object') {
        return { notAnObject: true };
    }

    return null;
}
