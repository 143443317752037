import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import {
    CalculationParameterInterface,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { QueryValue } from '@pkv-frontend/data-domain/location';
import { parseDateRule } from '@pkv-frontend/infrastructure/date';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';

import {
    defaultPdhospitalPayoutAmountValue,
    defaultPdhospitalPayoutStart,
} from '../../constants/calculation-parameter-default-values';
import { queryParameterMap } from '../../constants/query-parameter-map.constant';
import { QueryParameterMapTypesEnum } from '../../enums/query-parameter-map-types.enum';

@Injectable({
    providedIn: 'root',
})
export class CalculationParameterQueryParserService {
    public parse(params: Params): CalculationParameterInterface {
        const calculationParameter: Partial<CalculationParameterInterface> = {};

        Object.keys(queryParameterMap).forEach((key: string) => {
            const queryValue = params[queryParameterMap[key].key];

            switch (queryParameterMap[key].type) {
                case QueryParameterMapTypesEnum.Boolean:
                    calculationParameter[key] = this.parseBool(
                        queryValue,
                        queryParameterMap[key].default
                    );
                    break;
                case QueryParameterMapTypesEnum.Integer:
                    // eslint-disable-next-line no-case-declarations
                    const result = parseInt(
                        (<string>queryValue ||
                            queryParameterMap[key].default) ??
                            '',
                        10
                    );
                    if (isNaN(result)) {
                        return;
                    }
                    calculationParameter[key] = result;
                    break;
                case QueryParameterMapTypesEnum.Date:
                    calculationParameter[key] = parseDateRule(
                        <string>queryValue
                    );
                    break;
                case QueryParameterMapTypesEnum.Array:
                    calculationParameter[key] = this.parseProviders(
                        <string[]>queryValue ??
                            params[queryParameterMap[key].key + '[]']
                    );
                    break;
                case QueryParameterMapTypesEnum.String:
                default:
                    calculationParameter[key] = queryValue;
            }
        });

        if (
            calculationParameter.pdhospitalPayoutAmountValue === 0 ||
            calculationParameter.pdhospitalPayoutStart === 0
        ) {
            calculationParameter.pdhospitalPayoutAmountValue = 0;
            calculationParameter.pdhospitalPayoutStart = 0;
        } else {
            calculationParameter.pdhospitalPayoutAmountValue =
                calculationParameter.pdhospitalPayoutAmountValue ||
                defaultPdhospitalPayoutAmountValue;
            calculationParameter.pdhospitalPayoutStart =
                calculationParameter.pdhospitalPayoutStart ||
                defaultPdhospitalPayoutStart;
        }

        if (
            calculationParameter.parentServantOrServantCandidate &&
            !calculationParameter.profession
        ) {
            calculationParameter.profession = ProfessionEnum.Servant;
        }

        return calculationParameter as CalculationParameterInterface;
    }

    private parseBool(
        value: QueryValue | undefined,
        defaultValue?: string
    ): boolean {
        return (value ?? defaultValue) === YesNoEnum.Yes;
    }

    private parseProviders(providers?: string[] | string): number[] {
        // providers can be either an array, or a comma separated string (including '0' for no provider)
        if (typeof providers === 'string') {
            providers = providers
                .split(',')
                .filter((provider) => provider !== '0');
        }

        if (!Array.isArray(providers) || providers.length === 0) {
            return [];
        }

        return providers.map(Number);
    }
}
