import { Injectable } from '@angular/core';

import { Settings, TrackingProvider } from '@vv-ham/ng-tracking';
import { getMatomoOverwritesRule } from '@pkv-frontend/business-domain/tracking';
import {
    DeviceOutputEnum,
    PhpFrontendData,
} from '@pkv-frontend/data-domain/php-frontend-data';
import { NotUndefinedOrNullValidator } from '@pkv-frontend/infrastructure/validators';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

import { environment } from '../../../environments/environment';
import { SearchTagsDataProviderService } from '../../business-rules/services/search-tags-data-provider/search-tags-data-provider.service';

@Injectable({
    providedIn: 'root',
})
export class TrackingSettingsService {
    public static get(): Settings {
        const windowRef = new WindowReferenceService();
        const phpFrontendData: PhpFrontendData =
            NotUndefinedOrNullValidator.validate(
                windowRef.nativeWindow.phpFrontendData,
                '[Tracking] phpFrontendData not defined'
            );

        const matomoOverwrites = getMatomoOverwritesRule(
            DeviceOutputEnum.Desktop
        );

        // @TODO: find a solution for pages without tracking data
        return {
            [TrackingProvider.GeneralTracking]: {
                debug: !environment.production,
                deviceOutput: phpFrontendData.deviceOutput,
                productKey: 'pkv',
                sid: phpFrontendData.config?.trackingBaseConfig.sid,
                pid: phpFrontendData.config?.trackingBaseConfig.pid,
            },
            [TrackingProvider.SearchTags]: {
                debug: !environment.production,
                dataProvider: SearchTagsDataProviderService,
                origin: 'pkv',
            },
            [TrackingProvider.Matomo]: {
                debug: !environment.production,
                siteId: location.hostname.endsWith('check24.de') ? 47 : 123,
                useExistingMatomoInstance: true,
                ...matomoOverwrites,
            },
            [TrackingProvider.GoogleTagManager]: {
                debug: !environment.production,
                container: 'GTM-PZCFFR',
                pid: phpFrontendData.config?.trackingBaseConfig.pid,
            },
        };
    }
}
