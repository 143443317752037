import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ContactInformationFormEnum } from '../enums/contact-information-form.enum';
import { ServerErrorResponse } from '../models/server-error-response.model';

const errorMessagesKeyMap: Record<
    keyof ServerErrorResponse,
    ContactInformationFormEnum
> = {
    phone: ContactInformationFormEnum.MobileNumber,
    insure_firstname: ContactInformationFormEnum.InsureFirstName,
    insure_lastname: ContactInformationFormEnum.InsureLastName,
    insure_gender: ContactInformationFormEnum.InsureGender,
    firstname: ContactInformationFormEnum.FirstName,
    lastname: ContactInformationFormEnum.LastName,
    gender: ContactInformationFormEnum.Gender,
    email: ContactInformationFormEnum.Email,
};

@Injectable({
    providedIn: 'root',
})
export class BackendFormErrorsMapper {
    public byErrorKey(key: string): string | null {
        if (!Object.prototype.hasOwnProperty.call(errorMessagesKeyMap, key)) {
            return null;
        }

        return errorMessagesKeyMap[key];
    }

    public mapBackendErrorsToForm(
        errors: ServerErrorResponse,
        form: FormGroup
    ): void {
        for (const field in errors) {
            const mappedControlName = this.byErrorKey(field);

            if (!mappedControlName) {
                continue;
            }

            const formControl: AbstractControl | null =
                form.get(mappedControlName);

            if (!formControl) {
                continue;
            }

            formControl.setErrors(errors[field], { emitEvent: true });
            formControl.markAsTouched();
        }
    }
}
