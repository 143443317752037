import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BenefitPresetEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';

import { resultApiResultsLoadedAction } from '../../../result/store/actions/result.actions';
import { setCustomPresetLowestPricesAction } from '../actions/filter.actions';
import { resultFilterResultFiltersSelector } from '../selectors/result-filter-result-filters.selector';

@Injectable()
export class LowestPriceForCustomPresetEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store
    ) {}

    public lowestPriceForCustomPreset$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(resultApiResultsLoadedAction),
                concatLatestFrom(() =>
                    this.store$.select(resultFilterResultFiltersSelector)
                ),
                filter(
                    ([, resultFilter]: [
                        ReturnType<typeof resultApiResultsLoadedAction>,
                        ResultFilter | undefined,
                    ]): boolean =>
                        resultFilter?.benefitPreset === BenefitPresetEnum.Custom
                ),
                map(
                    ([{ result }]: [
                        ReturnType<typeof resultApiResultsLoadedAction>,
                        ResultFilter,
                    ]) =>
                        setCustomPresetLowestPricesAction({
                            lowestPrice:
                                result.lowestPrices?.benefitPreset?.custom,
                        })
                )
            );
        }
    );
}
