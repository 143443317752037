import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ActivityScoreApiClient,
    ActivityScoreEventType,
} from '@pkv-frontend/data-domain/activity-score';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { activityScoreForeignIdGeneratorRule } from './activity-score-foreign-id-generator.rule';

@Injectable({
    providedIn: 'root',
})
export class ActivityScoreService {
    constructor(
        private readonly activityScoreApiClient: ActivityScoreApiClient
    ) {}

    public addComparisonActivityScore(
        calculationParameter: CalculationParameterInterface
    ): Observable<void> {
        return this.activityScoreApiClient.postActivity({
            eventTypeName: ActivityScoreEventType.Comparison,
            foreignId:
                activityScoreForeignIdGeneratorRule(calculationParameter),
        });
    }
}
