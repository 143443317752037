import { Action, ActionReducer } from '@ngrx/store';

export const storeMetaReducer =
    <T = unknown>(featureKey: string) =>
    (reducer: ActionReducer<T>) => {
        return function (state: T, action: Action): T {
            if (!action.type.startsWith(`[${featureKey}]`)) {
                return reducer(state, action);
            }

            const currentState = localStorage.getItem(featureKey);
            let nextState: T | undefined = state;

            if (
                currentState !== null &&
                currentState !== JSON.stringify(state)
            ) {
                try {
                    nextState = { ...JSON.parse(currentState) };
                } catch (exception) {
                    nextState = undefined;
                }
            }

            nextState = reducer(nextState, action);

            localStorage.setItem(featureKey, JSON.stringify(nextState));

            return nextState;
        };
    };
