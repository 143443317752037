import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';

import { TariffsWithEyeIconService } from '../../service/tariffs-with-eye-icon.service';
import {
    fetchTariffsWithEyes,
    setTariffsWithEyeIconAction,
} from '../actions/comparison.actions';

@Injectable()
export class LoadTariffsWithEyeIconFromStorageEffect {
    constructor(
        private actions$: Actions,
        private tariffsWithEyeIconService: TariffsWithEyeIconService,
        private phpFrontendDataService: PhpFrontendDataService
    ) {}

    public loadTariffsWithEyeIconFromStorageEffect$: Observable<Action> =
        createEffect(() => {
            return this.actions$.pipe(
                ofType(fetchTariffsWithEyes),
                filter(
                    // @todo kick phpfrontendDataService
                    () => !this.phpFrontendDataService.data.isConsultantLoggedIn
                ),
                map(() =>
                    setTariffsWithEyeIconAction({
                        tariffsWithEyeIcon:
                            this.tariffsWithEyeIconService.getFromStorage(),
                    })
                )
            );
        });
}
