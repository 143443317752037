import {
    Compiler,
    Injectable,
    Injector,
    NgModuleFactory,
    Type,
} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LazyModuleLoadingService {
    constructor(
        private compiler: Compiler,
        private injector: Injector
    ) {}

    public loadModule<T>(importCall: () => Promise<Type<T>>): Promise<T> {
        return importCall()
            .then((elementOrModuleFacory: Type<T>) =>
                this.compiler.compileModuleAsync(elementOrModuleFacory)
            )
            .then((moduleFactory: NgModuleFactory<T>) => {
                const elementModuleRef = moduleFactory.create(this.injector);
                return elementModuleRef.instance;
            });
    }
}
