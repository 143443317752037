import { computed, Injectable, Signal } from '@angular/core';
import { FeatureToggleEnum } from '../enums/feature-toggle.enum';
import { FeatureToggleManagerService } from './feature-toggle-manager.service';

@Injectable({ providedIn: 'root' })
export class FeatureToggleStatusService {
    constructor(
        private featureToggleManagerService: FeatureToggleManagerService
    ) {}

    public getFeatureToggleStatus(
        identifier: FeatureToggleEnum
    ): Signal<boolean> {
        return computed(
            () =>
                this.featureToggleManagerService.featureToggles()[identifier]
                    .isEnabled
        );
    }
}
