import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

@Injectable()
export class UrlService {
    constructor(
        private windowReferenceService: WindowReferenceService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public get previousUrlWithoutQueryParams(): string {
        return this.document.referrer.split('?')[0];
    }

    public get currentUrlWithoutQueryParams(): string {
        return this.windowReferenceService.nativeWindow.location.href.split(
            '?'
        )[0];
    }
}
