import { ItemWidthEnum } from '../../promotion/enums/item-width.enum';

export function savingsWithoutBreaksRule(
    twoNE: boolean,
    itemWidth: ItemWidthEnum,
    canCalculateRelativeSaving: boolean
): boolean {
    return savingsWithoutBreaksRuleImplementation(
        twoNE,
        itemWidth,
        canCalculateRelativeSaving
    );
}

function savingsWithoutBreaksRuleImplementation(
    twoNE: boolean,
    itemWidth: ItemWidthEnum,
    canCalculateRelativeSaving: boolean
): boolean {
    return (
        (canCalculateRelativeSaving &&
            !twoNE &&
            itemWidth !== ItemWidthEnum.Tiny) ||
        twoNE ||
        !canCalculateRelativeSaving
    );
}
