import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SsoUserDataApiService } from '@pkv-frontend/data-domain/sso-user-data';
import {
    fetchSsoUserDataAction,
    fetchSsoUserDataFailureAction,
    fetchSsoUserDataSuccessAction,
} from '../actions/sso-user-data.actions';

@Injectable()
export class FetchSsoUserDataEffect {
    constructor(
        private actions$: Actions,
        private apiClient: SsoUserDataApiService
    ) {}

    public effect$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchSsoUserDataAction),
            switchMap(() => {
                return this.apiClient.fetch().pipe(
                    map((ssoUserDataApiModel) =>
                        fetchSsoUserDataSuccessAction({
                            ssoUserData:
                                this.apiClient.mapToSsoUserDataModel(
                                    ssoUserDataApiModel
                                ),
                        })
                    ),
                    catchError((response) =>
                        of(
                            fetchSsoUserDataFailureAction({
                                error: response.error,
                            })
                        )
                    )
                );
            })
        )
    );
}
