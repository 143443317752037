export enum FeatureRouteEnum {
    Input1 = 'benutzereingaben1/',
    PageThankYou = 'danke',
    PageThankYouAppointmentBooking = 'danke/info',
    RegisterPage = 'register',
    ResultPage = 'vergleichsergebnis/',
    ComparisonPage = 'detailvergleich/',
    ThirdViewPage = 'kundenbereich/vertragsdetails',
    AppointmentBookingPage = 'termine-buchen',
    DirectConsultationAppointmentBookingPage = 'beratungstermin',
    DirectConsultationCallbackPage = 'beratungstermin/rueckruf',
    DirectConsultationCallbackPage2 = 'beratungstermin/rueckruf2',
    DirectConsultationAppointmentThankYouPage = 'beratungstermin/danke',
    UploadPage = 'meine-dokumente',
    ErrorPage = 'error-page',
    CoBrowsingPresenter = 'co-browsing-presenter',
    CoBrowsingDocumentsForm = 'co-browsing-documents-form',
    CoBrowsingApplicationForm = 'co-browsing-application-form',
    CoBrowsingSignature = 'co-browsing-signature',
    CoBrowsingCalculator = 'co-browsing-calculator',
    CoBrowsingFamilyOffer = 'co-browsing-family-offer',
    FriendAdvertisingReward = 'freundschaftswerbung',
    RedirectPage = 'kundenbereich/smartlink',
    Termination = 'kuendigungswunsch',
}
