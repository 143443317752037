import { createSelector } from '@ngrx/store';
import {
    ConsultantCarouselState,
    ConsultantCarouselStateAware,
} from '../models/consultant-carousel-state.model';

import { consultantCarouselStateSelector } from './consultant-carousel-state.selector';

export const isCallbackAvailableSelector = createSelector<
    ConsultantCarouselStateAware,
    [ConsultantCarouselState],
    boolean | undefined
>(
    consultantCarouselStateSelector,
    (state: ConsultantCarouselState): boolean | undefined =>
        state.isCallbackAvailable
);
