import { AbstractControl } from '@angular/forms';

const EMAIL_PATTERN_GENERIC =
    /^[a-zA-Z0-9._%+-]+@as\.check24(-int|-test)?\.de$/;
const EMAIL_PATTERN_HHV_PHV =
    /^(hhv|privathaftpflicht)(-int|-test)?\+[a-zA-Z0-9._%+-]+@check24\.de$/;

export function AliasEmailValidator(
    control: AbstractControl
): { [key: string]: boolean } | null {
    if (
        control.value?.match(EMAIL_PATTERN_GENERIC) ||
        control.value?.match(EMAIL_PATTERN_HHV_PHV)
    ) {
        return { email: true };
    }
    return null;
}
