import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    ActiveConsultantDataApiClient,
    ActiveConsultantModel,
} from '@pkv-frontend/data-domain/consultant';
import {
    activeConsultantDataLoadedAction,
    applicationDataInitiatedAction,
} from '../actions/application.actions';

@Injectable()
export class FetchActiveConsultantDataWhenInAlternativeOfferEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly activeConsultantDataApiClient: ActiveConsultantDataApiClient
    ) {}

    public readonly fetchActiveConsultant$: Observable<Action> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(applicationDataInitiatedAction),
                switchMap(
                    (
                        action: ReturnType<
                            typeof applicationDataInitiatedAction
                        >
                    ) => {
                        if (!action.state.isAlternativeOffer) {
                            return EMPTY;
                        }

                        return this.activeConsultantDataApiClient
                            .fetchData()
                            .pipe(
                                map((consultantData: ActiveConsultantModel) =>
                                    activeConsultantDataLoadedAction({
                                        activeConsultant: {
                                            ...consultantData,
                                        },
                                    })
                                )
                            );
                    }
                )
            )
    );
}
