import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { professionSelector } from '../store/selectors/profession.selector';

@Injectable({
    providedIn: 'root',
})
export class HasProfessionChangedRule {
    constructor(private store$: Store) {}

    public apply$(profession: ProfessionEnum): Observable<boolean> {
        return this.store$
            .select(professionSelector)
            .pipe(
                map(
                    (professionState: ProfessionEnum | undefined) =>
                        professionState !== profession
                )
            );
    }
}
