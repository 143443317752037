import { Injectable } from '@angular/core';

import { ComparisonItem } from '@pkv-frontend/data-domain/comparison';

import {
    ComparisonFooterDataCellInterface,
    ComparisonFooterDataInterface,
} from '../models/comparison-footer-data.interface';

@Injectable({
    providedIn: 'root',
})
export class ComparisonFooterDataBuilderService {
    public build(
        comparisonItems: ComparisonItem[]
    ): ComparisonFooterDataInterface {
        const data: ComparisonFooterDataInterface = { cells: [] };

        for (const comparisonItem of comparisonItems) {
            const cell: ComparisonFooterDataCellInterface = {
                tariffVersionId: comparisonItem.tariff.tariffVersionId,
                providerId: comparisonItem.tariff.providerId,
                isCompletable: comparisonItem.tariff.isCompletable,
            };

            data.cells.push(cell);
        }

        return data as ComparisonFooterDataInterface;
    }
}
