import { createSelector } from '@ngrx/store';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterCalculationParametersSelector = createSelector<
    FilterStateAware,
    [FilterState],
    CalculationParameterInterface | undefined
>(
    filterStateFeatureSelector,
    (state: FilterState) => state?.calculationParameters
);
