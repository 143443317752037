import { createSelector } from '@ngrx/store';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterCrossSellingOverlaySelector = createSelector<
    FilterStateAware,
    [FilterState],
    YesNoEnum | undefined
>(
    filterStateFeatureSelector,
    (state: FilterState) => state.crossSellingOverlay
);
