import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityScoreRequest } from '../model/activity-score-request.model';

const endpoint = '/api/v1/public/customer-frontend/checkout/set-user-journey';

@Injectable({
    providedIn: 'root',
})
export class ActivityScoreApiClient {
    constructor(private readonly http: HttpClient) {}

    public postActivity(requestModel: ActivityScoreRequest): Observable<void> {
        return this.http.post<void>(endpoint, requestModel);
    }
}
