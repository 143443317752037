import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import {
    DocumentResizeChanges,
    DocumentResizeObserverService,
} from '@pkv-frontend/infrastructure/document-resize-observer';

@Injectable({
    providedIn: 'root',
})
export class PageResizeObserverService {
    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly documentResizeObserverService: DocumentResizeObserverService
    ) {}

    public observe(): Observable<DocumentResizeChanges> {
        const wrapperElement = this.document.querySelector('html');

        if (!wrapperElement) {
            throw new Error('html Element not found');
        }

        return this.documentResizeObserverService.observe(wrapperElement).pipe(
            startWith({
                offsetWidth: wrapperElement.offsetWidth,
                offsetHeight: wrapperElement.offsetHeight,
            })
        );
    }
}
