import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { loadResultSilentlySelector } from '../../../filter/store/selectors/load-result-silent.selector';
import {
    resultApiResultsLoadedAction,
    setResultAction,
} from '../../../result/store/actions/result.actions';

@Injectable()
export class ResultLoadedEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store
    ) {}

    public readonly resultLoadedEffect$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setResultAction),
                concatLatestFrom(() =>
                    this.store$.select(loadResultSilentlySelector)
                ),
                map(
                    ([action, loadSilent]: [
                        ReturnType<typeof setResultAction>,
                        boolean,
                    ]) => {
                        return resultApiResultsLoadedAction({
                            result: action.result,
                            silent: loadSilent,
                        });
                    }
                )
            );
        }
    );
}
