export enum RiskPremiumFormEnum {
    Name = 'name',
    Contribution0 = 'contribution0',
    Contribution1 = 'contribution1',
    Contribution2 = 'contribution2',
    Contribution3 = 'contribution3',
}

export const riskPremiumFormEnumContribution = (
    index: number
): RiskPremiumFormEnum => {
    if (index < 0) {
        throw new Error(
            'Invalid contribution index given, expected value to be between 0..2'
        );
    }
    return <RiskPremiumFormEnum>`contribution${index}`;
};
