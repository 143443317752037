import { Injectable } from '@angular/core';
import { CalculationParameterInformationService } from '@pkv-frontend/business-domain/calculation-parameter';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';

@Injectable({
    providedIn: 'root',
})
export class ShowHospitalizationAccommodationServiceLevelSelectionRule {
    constructor(
        private calculationParameterInformationService: CalculationParameterInformationService
    ) {}

    public apply(calculationParameter: CalculationParameterInterface): boolean {
        return !this.calculationParameterInformationService.isServantOrServantCandidateOrServantChild(
            calculationParameter
        );
    }
}
