export enum ComparisonTableDataRowTypeEnum {
    Row = 'row',
    RiskPremiumRow = 'riskPremiumRow',
    Header = 'header',
}

export enum ComparisonTableDataCellTypeEnum {
    File = 'file',
    Icon = 'icon',
    Text = 'text',
    PriceDetail = 'priceDetail',
    FollowUpTariff = 'followUpTariff',
    RiskPremium = 'riskPremium',
}

export enum ComparisonTableDataColorEnum {
    Gray = 'gray',
    Green = 'green',
    Yellow = 'yellow',
}

export enum ComparisonTableDataSignalColorEnum {
    Green = 'green',
    Yellow = 'yellow',
    Red = 'red',
}
