import { DomainScopeEnum } from '../enums/domain-scope.enum';

export function getCookieDomainRule(
    hostname: string,
    scope: DomainScopeEnum
): string {
    switch (scope) {
        case DomainScopeEnum.Domain:
            return getDomainURI(hostname);
        case DomainScopeEnum.Subdomain:
            return getSubdomainUri(hostname);
        case DomainScopeEnum.Platform:
            return getPlatformUri(hostname);
        case DomainScopeEnum.Exact:
            return getExactUri(hostname);
    }
}

function getDomainURI(hostname: string): string {
    const parts = hostname.split('.');
    return parts.slice(-2).join('.');
}

function getSubdomainUri(hostname: string): string {
    const parts = hostname.split('.');
    return parts.slice(-3).join('.');
}

function getPlatformUri(hostname: string): string {
    const parts = hostname.split('.');
    return parts.slice(-4).join('.');
}

function getExactUri(hostname: string): string {
    return hostname;
}
