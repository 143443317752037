import { createReducer, on } from '@ngrx/store';
import {
    closeTransparencyInfoAction,
    transparencyInfoFromCookieLoadedAction,
} from '../actions/transparency-info.actions';

import { TransparencyInfoState } from '../models/transparency-info-state.model';

export const initialState: TransparencyInfoState = {
    sortfields: [],
};

export const transparencyInfoReducers = createReducer(
    initialState,
    on(
        transparencyInfoFromCookieLoadedAction,
        (
            state: TransparencyInfoState,
            action: ReturnType<typeof transparencyInfoFromCookieLoadedAction>
        ): TransparencyInfoState => {
            return {
                ...state,
                sortfields: action.sortfields,
            };
        }
    ),
    on(
        closeTransparencyInfoAction,
        (
            state: TransparencyInfoState,
            action: ReturnType<typeof closeTransparencyInfoAction>
        ): TransparencyInfoState => {
            return {
                ...state,
                sortfields: [...state.sortfields, action.sortfield],
            };
        }
    )
);
