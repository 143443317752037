import { AbstractControl, ValidationErrors } from '@angular/forms';

const maxAnnualIncome = 999999;

export function AnnualIncomeValidator(
    control: AbstractControl
): ValidationErrors | null {
    if (control.value > maxAnnualIncome) {
        return { annualIncome: true };
    }

    return null;
}
