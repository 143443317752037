import { Injectable } from '@angular/core';
import {
    ComparisonItem,
    ComparisonTableDataCellTypeEnum,
    ComparisonTableDataRowTypeEnum,
    TariffFeatureTreeInterface,
    TariffFeatureTreeTypeEnum,
} from '@pkv-frontend/data-domain/comparison';
import {
    ComparisonTableDataCaptionCellInterface,
    ComparisonTableDataCaptionInterface,
    ComparisonTableDataCellInterface,
    ComparisonTableDataInterface,
    ComparisonTableDataRowInterface,
} from '../models/comparison-table-data.interface';

const initialDisplayRowCount = 2;

@Injectable({
    providedIn: 'root',
})
export class ComparisonTableDataBuilderService {
    public build(
        comparisonItems: ComparisonItem[],
        removeEqualItems: boolean
    ): ComparisonTableDataInterface[] {
        const data: { [featureKey: string]: ComparisonTableDataInterface } = {};
        let tariffIndex = 0;

        for (const comparisonItem of comparisonItems) {
            for (const _feature of comparisonItem.features) {
                // deep copy feature
                const feature = JSON.parse(JSON.stringify(_feature));

                if (removeEqualItems) {
                    feature.items = this.removeAllEqualItems(feature.items);
                }

                data[feature.key] = data[feature.key] || {};
                data[feature.key].key = data[feature.key].key || '';
                data[feature.key].caption = data[feature.key].caption || {};
                data[feature.key].rows = data[feature.key].rows || [];

                data[feature.key].caption = this.populateCaption(
                    data[feature.key].caption,
                    feature
                );

                data[feature.key].key = feature.key;

                const hasFeatureEnoughNonCollapsibleRows =
                    this.hasFeatureEnoughNonCollapsibleRows(feature.items);

                for (const item of feature.items) {
                    // Is feature a group? A feature group has feature items
                    if (item.type === TariffFeatureTreeTypeEnum.Group) {
                        data[feature.key].rows = this.addHeaderRow(
                            data[feature.key].rows,
                            item
                        );

                        for (const groupItem of item.items) {
                            data[feature.key].rows = this.populateRows(
                                data[feature.key].rows,
                                groupItem,
                                hasFeatureEnoughNonCollapsibleRows,
                                tariffIndex,
                                comparisonItems.length
                            );
                        }

                        continue;
                    }

                    data[feature.key].rows = this.populateRows(
                        data[feature.key].rows,
                        item,
                        hasFeatureEnoughNonCollapsibleRows,
                        tariffIndex,
                        comparisonItems.length
                    );
                }
            }
            tariffIndex++;
        }

        return Object.values(data);
    }

    private populateCaption(
        caption: ComparisonTableDataCaptionInterface,
        feature: TariffFeatureTreeInterface
    ): ComparisonTableDataCaptionInterface {
        caption.cells = caption.cells || [];
        caption.cells.push(<ComparisonTableDataCaptionCellInterface>{
            points: feature.points,
            maxPoints: feature.maxPoints,
            color: feature.color,
        });

        if (caption.label === undefined && feature.label !== null) {
            caption.label = feature.label;
        }

        return caption;
    }

    private populateRows(
        rows: ComparisonTableDataRowInterface[],
        featureItem: Required<TariffFeatureTreeInterface>,
        hasFeatureEnoughNonCollapsibleRows: boolean,
        tariffIndex: number,
        tariffCount: number
    ): ComparisonTableDataRowInterface[] {
        const cell: ComparisonTableDataCellInterface = {
            type: <ComparisonTableDataCellTypeEnum>featureItem.item.type,
            isBestFeature: featureItem.item.isBestFeature,
            comment: featureItem.item.comment,
            description: featureItem.item.description,
            signal: featureItem.item.signal,
            tooltip: featureItem.tooltip || undefined,
            fileLabel: featureItem.item.fileLabel,
            fileUrl: featureItem.item.fileUrl,
        };

        const featureKeyIndex = this.getRowIndexByKey(rows, featureItem.key);

        if (featureKeyIndex !== -1) {
            if (rows[featureKeyIndex].cells) {
                rows[featureKeyIndex].cells[tariffIndex] = cell;
            }

            return rows;
        }

        const isCollapsible = hasFeatureEnoughNonCollapsibleRows
            ? featureItem.isCollapsible
            : this.rowShouldBeCollapsible(rows);

        const cells: (ComparisonTableDataCellInterface | undefined)[] =
            Array.from({ length: tariffCount }, () => undefined);
        cells[tariffIndex] = cell;

        rows.push({
            type: ComparisonTableDataRowTypeEnum.Row,
            isCollapsible: isCollapsible,
            allItemsEqual: featureItem.allItemsEqual,
            key: featureItem.key,
            label: featureItem.label,
            subLabel: featureItem.subLabel || undefined,
            tooltip: featureItem.tooltip || undefined,
            cells,
        });

        return rows;
    }

    private addHeaderRow(
        rows: ComparisonTableDataRowInterface[],
        featureItem: Required<TariffFeatureTreeInterface>
    ): ComparisonTableDataRowInterface[] {
        if (this.getRowIndexByKey(rows, featureItem.key) !== -1) {
            return rows;
        }

        rows.push({
            type: ComparisonTableDataRowTypeEnum.Header,
            isCollapsible: true,
            key: featureItem.key,
            label: featureItem.label,
            subLabel: featureItem.subLabel || undefined,
        });

        return rows;
    }

    private getRowIndexByKey(
        rows: ComparisonTableDataRowInterface[],
        key: string
    ): number {
        return rows.findIndex((row: ComparisonTableDataRowInterface) => {
            return row.key === key;
        });
    }

    private rowShouldBeCollapsible(
        rows: ComparisonTableDataRowInterface[]
    ): boolean {
        const headerRowOffset =
            rows.filter(
                (row: ComparisonTableDataRowInterface) =>
                    row.type === ComparisonTableDataRowTypeEnum.Header
            ).length || 0;

        return rows.length - headerRowOffset >= initialDisplayRowCount;
    }

    private hasFeatureEnoughNonCollapsibleRows(
        featureItems: TariffFeatureTreeInterface[]
    ): boolean {
        let nonCollapsibleFeaturesCount = 0;

        featureItems.forEach((feature: TariffFeatureTreeInterface) => {
            if (feature.type === TariffFeatureTreeTypeEnum.Group) {
                feature.items?.forEach((item: TariffFeatureTreeInterface) => {
                    nonCollapsibleFeaturesCount += !item.isCollapsible ? 1 : 0;
                });

                return;
            }

            nonCollapsibleFeaturesCount += !feature.isCollapsible ? 1 : 0;
        });

        return nonCollapsibleFeaturesCount === initialDisplayRowCount;
    }

    private removeAllEqualItems(
        featureItems: TariffFeatureTreeInterface[]
    ): TariffFeatureTreeInterface[] {
        return featureItems.filter((item: TariffFeatureTreeInterface) => {
            if (
                item.type === TariffFeatureTreeTypeEnum.Group &&
                item.items !== undefined
            ) {
                item.items = this.removeAllEqualItems(item.items);

                return true;
            }

            return !item.allItemsEqual;
        });
    }
}
