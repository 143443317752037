import { Injectable, Injector, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';

import { WebComponentsMap } from '@pkv-frontend/infrastructure/interfaces';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

@Injectable({
    providedIn: 'root',
})
export class WebComponentRegisterService {
    constructor(private windowReferenceService: WindowReferenceService) {}

    public registerWebComponents(
        webComponentsMap: WebComponentsMap,
        injector: Injector
    ): void {
        setTimeout(() => {
            Object.entries(webComponentsMap).forEach(
                ([selector, webComponent]: [string, Type<unknown>]) => {
                    // prevent registering elements multiple times
                    if (
                        this.windowReferenceService.nativeWindow.customElements.get(
                            selector
                        ) === undefined
                    ) {
                        const component = createCustomElement(webComponent, {
                            injector,
                        });
                        this.windowReferenceService.nativeWindow.customElements.define(
                            selector,
                            component as CustomElementConstructor
                        );
                    }
                }
            );
        });
    }
}
