import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PreviousUrlPersistenceService } from '@pkv-frontend/business-domain/tracking';

import { enterResultPageAction } from '../actions/result.actions';

@Injectable()
export class UpdatePreviousUrlEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly previousUrlPersistenceService: PreviousUrlPersistenceService
    ) {}

    public readonly updatePreviousUrlEffect$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(enterResultPageAction),
                tap((): void =>
                    this.previousUrlPersistenceService.setCurrentUrl()
                )
            );
        },
        {
            dispatch: false,
        }
    );
}
