import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { promotionFeatureKey } from './models/promotion-state.model';
import { promotionReducers } from './reducers/promotion.reducers';

@NgModule({
    imports: [StoreModule.forFeature(promotionFeatureKey, promotionReducers)],
})
export class PromotionStoreModule {}
