import { createSelector } from '@ngrx/store';
import { ResultFilterProviderModel } from '@pkv-frontend/data-domain/result';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterProvidersSelector = createSelector<
    FilterStateAware,
    [FilterState],
    ResultFilterProviderModel[]
>(filterStateFeatureSelector, (state: FilterState) => state.providers);
