import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map } from 'rxjs/operators';
import { ResultNavigationService } from '../../../result/services/result-navigation.service';

import { sortChangeAction } from '../actions/filter.actions';

@Injectable()
export class UpdateUrlForSortChangeEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly resultNavigationService: ResultNavigationService
    ) {}

    public readonly updateUrlForSortChangeEffect$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(sortChangeAction),
                map((action: ReturnType<typeof sortChangeAction>) => {
                    const queryParams = {
                        sortfield: action.sortfield,
                        sortorder: action.sortorder,
                        position: 1,
                    };
                    this.resultNavigationService.navigate(queryParams);
                })
            );
        },
        { dispatch: false }
    );
}
