import { GradeEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { RadioListRowVariantEnum } from '../enums/radio-list-row-variant.enum';
import { FilterRadioListConfig } from '../models/filter-radio-list.config.model';

export const gradeFilterConfig: FilterRadioListConfig = {
    radioListVariant: RadioListRowVariantEnum.Single,
    options: [
        {
            value: GradeEnum.VeryGood,
            label: 'sehr gut',
            lowestPriceKey: 'veryGood',
        },
        {
            value: GradeEnum.Good,
            label: 'gut und besser',
        },
        {
            value: GradeEnum.All,
            label: 'egal',
        },
    ],
};
