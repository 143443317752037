import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { queryParameterMap } from '@pkv-frontend/business-domain/calculation-parameter';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';
import { ResultNavigationService } from '../../../result/services/result-navigation.service';

import { debugToggleChangeAction } from '../actions/filter.actions';
@Injectable({ providedIn: 'root' })
export class UpdateUrlForDebugToggleChangeEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly resultNavigationService: ResultNavigationService
    ) {}

    public readonly updateUrlForDebugToggleChangeEffect$: Observable<void> =
        createEffect(
            () => {
                return this.actions$.pipe(
                    ofType(debugToggleChangeAction),
                    map(
                        (
                            action: ReturnType<typeof debugToggleChangeAction>
                        ) => {
                            const newValue = action.enabled
                                ? YesNoEnum.Yes
                                : YesNoEnum.No;
                            const queryParams = {
                                [queryParameterMap.debug.key]: newValue,
                                [queryParameterMap.c24debug.key]: newValue,
                            };

                            this.resultNavigationService.navigate(queryParams);
                        }
                    )
                );
            },
            { dispatch: false }
        );
}
