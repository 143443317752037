import { Injectable } from '@angular/core';
import {
    BenefitPresetEnum,
    HospitalizationAccommodationEnum,
    TreatmentAttendingDoctorEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';

@Injectable({
    providedIn: 'root',
})
export class ShouldSelectTreatmentAttendingDoctorRule {
    public apply(filterParameter: Partial<ResultFilter>): boolean {
        if (
            filterParameter.treatmentAttendingDoctor ===
            TreatmentAttendingDoctorEnum.HeadDoctor
        ) {
            return true;
        }

        if (
            filterParameter.treatmentAttendingDoctor === undefined &&
            (filterParameter.benefitPreset === BenefitPresetEnum.Comfort ||
                filterParameter.benefitPreset === BenefitPresetEnum.Premium)
        ) {
            return true;
        }

        if (
            filterParameter.treatmentAttendingDoctor === undefined &&
            filterParameter.benefitPreset === BenefitPresetEnum.Custom &&
            filterParameter.hospitalizationAccommodation !==
                HospitalizationAccommodationEnum.Multi
        ) {
            return true;
        }
        return false;
    }
}
