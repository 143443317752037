import { createSelector } from '@ngrx/store';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import {
    ConsultantCallbackOverlayState,
    OverlayStateAware,
} from '../models/consultant-callback-overlay.model';

import { consultantOverlayStateSelector } from './consultant-overlay-state.selector';

export const leadCreationRequestStateSelector = createSelector<
    OverlayStateAware,
    [ConsultantCallbackOverlayState],
    RequestStateEnum | undefined
>(
    consultantOverlayStateSelector,
    (state: ConsultantCallbackOverlayState): RequestStateEnum | undefined =>
        state.leadCreationState
);
