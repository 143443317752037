import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { phoneNumberSchemaValidationRule } from '../rule/phone-number-schema-validation.rule';

const INVALID_PATTERN_RESPONSE = { pattern: true };

export function phoneNumberSchemaValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return INVALID_PATTERN_RESPONSE;
        }

        return phoneNumberSchemaValidationRule(control.value)
            ? null
            : INVALID_PATTERN_RESPONSE;
    };
}
