import { createSelector } from '@ngrx/store';
import { ResultStateAware } from '../models/result-state.model';

import { resultCalculationResponseSelector } from './result-calculation-response.selector';

export const resultCalculationParameterIdSelector = createSelector<
    ResultStateAware,
    [ReturnType<typeof resultCalculationResponseSelector>],
    string | undefined
>(
    resultCalculationResponseSelector,
    (result: ReturnType<typeof resultCalculationResponseSelector>) =>
        result?.parameter.id
);
