import { Injectable } from '@angular/core';
import { add } from 'date-fns/add';
import { CookieService } from 'ngx-cookie-service';
import { SortFieldEnum } from '@pkv-frontend/data-domain/result';
import { SameSiteEnum } from '@pkv-frontend/infrastructure/cookie';

const COOKIE_NAME = 'pkv-result-order-transparency-info';
const COOKIE_TTL_HOURS = 2;
const COOKIE_VALUE_SEPARATOR = ',';
const COOKIE_PATH = '/';
const COOKIE_DOMAIN = '';
const COOKIE_IS_SECURE = true;

@Injectable({ providedIn: 'root' })
export class TransparencyInfoCookieService {
    constructor(private cookieService: CookieService) {}

    public cookieExist(): boolean {
        return this.cookieService.check(COOKIE_NAME);
    }

    public get(): SortFieldEnum[] {
        if (!this.cookieExist()) {
            return [];
        }

        const cookieValue: string = this.cookieService.get(COOKIE_NAME);

        if (cookieValue === '') {
            return [];
        }

        return cookieValue.split(COOKIE_VALUE_SEPARATOR) as SortFieldEnum[];
    }

    public set(value: SortFieldEnum[]): void {
        this.cookieService.set(
            COOKIE_NAME,
            value.join(COOKIE_VALUE_SEPARATOR),
            this.createExpirationDate(),
            COOKIE_PATH,
            COOKIE_DOMAIN,
            COOKIE_IS_SECURE,
            SameSiteEnum.Lax
        );
    }

    private createExpirationDate(): Date {
        return add(new Date(), { hours: COOKIE_TTL_HOURS });
    }
}
