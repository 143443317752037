import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ConsultantDataService } from './consultant-data.service';

export const alternativeOfferTrackingField = 'tracking_id';
export const alternativeOfferTrackingValue = 'CH24_E_Backend';

@Injectable({
    providedIn: 'root',
})
export class IsAlternativeOfferService {
    public hasValidTrackingId$: Observable<boolean> = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        first(),
        map(() => {
            return (
                this.route.snapshot.queryParams[
                    alternativeOfferTrackingField
                ] === alternativeOfferTrackingValue
            );
        })
    );

    public readonly isAlternativeOfferMode$: Observable<boolean> =
        this.hasValidTrackingId$.pipe(
            map(
                (hasValidTrackingId: boolean) =>
                    !!this.consultantDataService.getConsultantData() &&
                    hasValidTrackingId
            )
        );

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly consultantDataService: ConsultantDataService
    ) {}
}
