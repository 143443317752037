import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';
import { SsoUserDataStateEnum } from '../../enums/sso-user-data-state.enum';

export const ssoUserDataFeatureKey = 'ssoUserData';

export interface SsoUserDataStateModel {
    [SsoUserDataStateEnum.SsoUserData]?: SsoUserDataModel;
    [SsoUserDataStateEnum.Error]?: string;
}

export interface SsoUserDataStateAware {
    [ssoUserDataFeatureKey]: SsoUserDataStateModel;
}
