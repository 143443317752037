import { createSelector } from '@ngrx/store';
import {
    ConsultantCallbackOverlayState,
    OverlayStateAware,
} from '../models/consultant-callback-overlay.model';

import { ServerErrorResponseInterface } from '../models/server-error-response.interface';
import { consultantOverlayStateSelector } from './consultant-overlay-state.selector';

export const invalidUserInputSelector = createSelector<
    OverlayStateAware,
    [ConsultantCallbackOverlayState],
    ServerErrorResponseInterface | undefined
>(
    consultantOverlayStateSelector,
    (
        state: ConsultantCallbackOverlayState
    ): ServerErrorResponseInterface | undefined => state.invalidUserInput
);
