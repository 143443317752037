export function FeatureToggleCookieValidator(cookieValue: unknown): boolean {
    if (!Array.isArray(cookieValue)) {
        return false;
    }

    if (!cookieValue.every(checkValueType)) {
        return false;
    }

    return new Set(cookieValue).size === cookieValue.length;
}

function checkValueType(value: unknown): boolean {
    return typeof value === 'string';
}
