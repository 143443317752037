import {
    CalculationParameterInterface,
    InsuredPersonEnum,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

export function showGkvTileRule(
    calculationParameter: CalculationParameterInterface | undefined
): boolean {
    return (
        calculationParameter?.insuredPerson === InsuredPersonEnum.Adult &&
        (calculationParameter?.profession === ProfessionEnum.Employee ||
            calculationParameter?.profession === ProfessionEnum.Freelancer)
    );
}
