import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
    ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CoreFeatureToggleApiClient } from '@pkv-frontend/data-domain/application';
import { coreFeatureTogglesLoadedAction } from '../actions/application.actions';

@Injectable()
export class FetchCoreFeatureTogglesEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly coreFeatureToggleApiClient: CoreFeatureToggleApiClient
    ) {}

    public readonly effect$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            switchMap(() =>
                this.coreFeatureToggleApiClient.coreFeatureToggle$.pipe(
                    map((coreFeatureToggles) =>
                        coreFeatureTogglesLoadedAction({
                            coreFeatureToggles: coreFeatureToggles,
                        })
                    )
                )
            )
        )
    );
}
