import { createSelector } from '@ngrx/store';
import { ResultDebugData } from '@pkv-frontend/data-domain/result';
import {
    ResultStateAware,
    ResultStateResult,
} from '../models/result-state.model';

import { resultCalculationResponseSelector } from './result-calculation-response.selector';

export const resultC24debugSelector = createSelector<
    ResultStateAware,
    [ResultStateResult | undefined],
    ResultDebugData | undefined
>(
    resultCalculationResponseSelector,
    (result: ResultStateResult | undefined) => result?.c24debug
);
