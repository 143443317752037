import { createSelector } from '@ngrx/store';
import { TariffExpansionModeEnum } from '../../enums/tariff-expansion-mode.enum';
import { ResultState, ResultStateAware } from '../models/result-state.model';

import { resultStateFeatureSelector } from './result-state-feature.selector';

export const resultTariffExpansionModeSelector = (expansionModeKey: string) =>
    createSelector<
        ResultStateAware,
        [ResultState],
        TariffExpansionModeEnum | undefined
    >(
        resultStateFeatureSelector,
        (state: ResultState) =>
            state.tariffExpansionMode[expansionModeKey]?.mode
    );
