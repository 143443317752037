import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    LegacyCalculationParameter,
    QueryCalculationParameterParser,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { convertSnakeCaseObjectPropertiesToCamelCase } from '@pkv-frontend/infrastructure/converter';

import { ComparisonApiResponse } from '../models/comparison-api-response.model';

const apiUrl = '/api/public/v1/comparison';

@Injectable({
    providedIn: 'root',
})
export class ComparisonApiClient {
    constructor(
        private httpClient: HttpClient,
        private queryCalculationParameterParser: QueryCalculationParameterParser
    ) {}

    private static buildApiUrl(
        calculationParameter: URLSearchParams,
        ids: string[]
    ): string {
        calculationParameter.append('product_id', '21');
        const url = [apiUrl, ...ids].join('/');
        return url + '?' + calculationParameter.toString();
    }

    public get(
        calculationParameter: LegacyCalculationParameter,
        ids: string[]
    ): Observable<ComparisonApiResponse> {
        const calculationUrlParameter: URLSearchParams =
            this.queryCalculationParameterParser.parse(calculationParameter);
        const url: string = ComparisonApiClient.buildApiUrl(
            calculationUrlParameter,
            ids
        );
        return this.httpClient
            .get<ComparisonApiResponse>(url)
            .pipe(map(convertSnakeCaseObjectPropertiesToCamelCase));
    }
}
