import { createSelector } from '@ngrx/store';
import { LowestPrices } from '@pkv-frontend/data-domain/result';
import { ResultStateAware } from '../models/result-state.model';

import { silentResultCalculationResponseSelector } from './silent-result-calculation-response.selector';

export const silentResultLowestPricesSelector = createSelector<
    ResultStateAware,
    [ReturnType<typeof silentResultCalculationResponseSelector>],
    LowestPrices | undefined
>(
    silentResultCalculationResponseSelector,
    (
        silentResult: ReturnType<typeof silentResultCalculationResponseSelector>
    ) => silentResult?.lowestPrices
);
