import { createSelector } from '@ngrx/store';

import { ComparisonStateAwareModel } from '../models/comparison-state-aware.model';
import { ComparisonStateModel } from '../models/comparison-state.model';

import { comparisonFeatureSelector } from './comparison-feature.selector';

export const showComparisonBarSelector = createSelector<
    ComparisonStateAwareModel,
    [ComparisonStateModel],
    boolean
>(
    comparisonFeatureSelector,
    (state: ComparisonStateModel) => state.shouldShowComparisonBar
);
