import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { InstantAppointmentRequestApiClient } from '@pkv-frontend/data-domain/instant-appointment';

import {
    finishConsultantCallbackRequest,
    leadCreationError,
    requestConsultantCallback,
} from '../actions/consultant-callback-overlay.actions';

@Injectable()
export class RequestConsultantCallbackEffect {
    constructor(
        private actions$: Actions,
        private apiClient: InstantAppointmentRequestApiClient
    ) {}

    public requestConsultantCallback: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(requestConsultantCallback),
            exhaustMap(
                (action: ReturnType<typeof requestConsultantCallback>) => {
                    return this.apiClient
                        .create(
                            {
                                leadId: action.leadCreationApiResponse.leadId,
                                leadAccessKey:
                                    action.leadCreationApiResponse
                                        .leadAccessKey,
                            },
                            action.phoneNumber
                        )
                        .pipe(
                            map(() => finishConsultantCallbackRequest()),
                            catchError(() => of(leadCreationError()))
                        );
                }
            )
        );
    });
}
