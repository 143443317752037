import { Injectable } from '@angular/core';

import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

export interface CustomEventPayload {
    [key: string]: unknown;
}

@Injectable({
    providedIn: 'root',
})
export class CustomEventPublisherService {
    constructor(private windowReferenceService: WindowReferenceService) {}

    public publish(eventName: string, payload: CustomEventPayload): void {
        this.windowReferenceService.nativeWindow.dispatchEvent(
            new CustomEvent(eventName, {
                detail: <CustomEventPayload>payload,
            })
        );
    }
}
