import { createSelector } from '@ngrx/store';
import { Environment } from '@pkv-frontend/data-domain/application';
import {
    ApplicationState,
    ApplicationStateAware,
} from '../models/application-state.model';
import { applicationStateSelector } from './application-state.selector';

export const environmentSelector = createSelector<
    ApplicationStateAware,
    [ApplicationState],
    Environment | undefined
>(
    applicationStateSelector,
    (state: ApplicationState): Environment | undefined => state.environment
);
