import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HideFiltersAppliedMessageEffect } from './effects/hide-filters-applied-message.effect';
import { LoadCustomFilterSettingsFromStorageEffect } from './effects/load-custom-filter-settings-from-storage.effect';
import { LoadFilterProvidersEffect } from './effects/load-filter-providers.effect';
import { LowestPriceForCustomPresetEffect } from './effects/lowest-price-for-custom-preset.effect';
import { PersistCustomFilterLowestPriceEffect } from './effects/persist-custom-filter-lowest-price.effect';
import { PersistCustomFilterSettingsEffect } from './effects/persist-custom-filter-settings.effect';
import { SaveCalcParamToLocalStorageEffect } from './effects/save-calc-param-to-local-storage.effect';
import { ShowFiltersAppliedMessageEffect } from './effects/show-filters-applied-message.effect';
import { UpdateFiltersFromFilterChipsResetEffect } from './effects/update-filters-from-filter-chips-reset.effect';
import { UpdateFiltersFromUrlEffect } from './effects/update-filters-from-url.effect';
import { UpdateUrlForCureAndRehabChangeEffect } from './effects/update-url-for-cure-and-rehab-change.effect';
import { UpdateUrlForDebugToggleChangeEffect } from './effects/update-url-for-debug-toggle-change.effect';
import { UpdateUrlForEffectivePriceToggleChangeEffect } from './effects/update-url-for-effective-price-toggle-change.effect';
import { UpdateUrlForFilterChangeEffect } from './effects/update-url-for-filter-change.effect';
import { UpdateUrlForGkvCrossSellingChangeEffect } from './effects/update-url-for-gkv-cross-selling-change.effect';
import { UpdateUrlForResetToCommitedResultFiltersEffect } from './effects/update-url-for-reset-to-commited-result-filters.effect';
import { UpdateUrlForSortChangeEffect } from './effects/update-url-for-sort-change.effect';

import { filterFeatureKey } from './models/filter-state.model';
import { filterReducers } from './reducers/filter.reducers';

@NgModule({
    imports: [
        EffectsModule.forFeature([
            UpdateFiltersFromUrlEffect,
            UpdateFiltersFromFilterChipsResetEffect,
            LowestPriceForCustomPresetEffect,
            PersistCustomFilterSettingsEffect,
            PersistCustomFilterLowestPriceEffect,
            LoadCustomFilterSettingsFromStorageEffect,
            HideFiltersAppliedMessageEffect,
            ShowFiltersAppliedMessageEffect,
            SaveCalcParamToLocalStorageEffect,
            UpdateUrlForCureAndRehabChangeEffect,
            UpdateUrlForDebugToggleChangeEffect,
            UpdateUrlForEffectivePriceToggleChangeEffect,
            UpdateUrlForFilterChangeEffect,
            UpdateUrlForSortChangeEffect,
            UpdateUrlForGkvCrossSellingChangeEffect,
            LoadFilterProvidersEffect,
            UpdateUrlForResetToCommitedResultFiltersEffect,
        ]),
        StoreModule.forFeature(filterFeatureKey, filterReducers),
    ],
})
export class ResultFilterStoreModule {}
