import { ComparisonTariffItem } from './comparison-tariff-item.model';

export const comparisonFeatureKey = 'comparison';

export interface ComparisonStateModel {
    tariffItems: ComparisonTariffItem[];
    isMaximumComparisonAmountReached: boolean;
    tariffsWithEyeIcon: number[] | undefined;
    shouldShowComparisonBar: boolean;
    showComparisonBar: boolean;
}
