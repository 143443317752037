import { createSelector } from '@ngrx/store';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterIsLoadingSelector = createSelector<
    FilterStateAware,
    [FilterState],
    boolean
>(filterStateFeatureSelector, (state: FilterState) => state.isLoading);
