const regexes = {
    production: /^(m\.)?krankenversicherung\.check24\.de$/,
    staging: /^(m\.)?krankenversicherung\.check24-test\.de$/,
    integration: /^(.+?)\.[dm]\.krankenversicherung\.check24-test\.de$/,
    integrationLegacy: /^(.+?)\.[dm]\.krankenversicherung\.check24-int\.de$/,
    development: /^[dm]\.pkv\.docker\.local$/,
};

export function getBoApiDomainRule(location: Location): string {
    const hostname = getHostname(location.hostname);
    const port = location.port ? `:${location.port}` : '';
    return `${location.protocol}//${hostname}${port}`;
}

function getHostname(hostname: string): string {
    if (hostname.match(regexes.production)) {
        return 'bo.krankenversicherung.check24.de';
    }
    if (hostname.match(regexes.staging)) {
        return 'bo.krankenversicherung.check24-test.de';
    }
    if (hostname.match(regexes.integration)) {
        return hostname.replace(
            regexes.integration,
            '$1.bo.krankenversicherung.check24-test.de'
        );
    }
    if (hostname.match(regexes.integrationLegacy)) {
        return hostname.replace(
            regexes.integrationLegacy,
            '$1.bo.krankenversicherung.check24-int.de'
        );
    }
    if (hostname.match(regexes.development)) {
        return 'bo.pkv.docker.local';
    }

    throw new Error(`Unknown hostname: ${hostname}`);
}
