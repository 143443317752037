import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CalculationParameterInformationService } from '@pkv-frontend/business-domain/calculation-parameter';
import { ShowSavingsPersistenceService } from '@pkv-frontend/business-domain/result-calculation';
import {
    InsuredPersonEnum,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { ResultDataService } from '../services/result-data.service';

@Injectable({ providedIn: 'root' })
export class CanCalculateAbsoluteSavingRule {
    constructor(
        private readonly resultDataService: ResultDataService,
        private readonly calculationParameterInformationService: CalculationParameterInformationService,
        private readonly showSavingsPersistenceService: ShowSavingsPersistenceService
    ) {}

    public apply$: Observable<boolean> = this.resultDataService.parameter$.pipe(
        map(
            (
                calculationParameter:
                    | {
                          profession: ProfessionEnum;
                          insuredPerson: InsuredPersonEnum;
                      }
                    | undefined
            ) => {
                if (calculationParameter === undefined) {
                    return false;
                }
                return (
                    (this.calculationParameterInformationService.isFreelancer(
                        calculationParameter
                    ) ||
                        this.calculationParameterInformationService.isEmployeeAndAdult(
                            calculationParameter
                        )) &&
                    this.showSavingsPersistenceService.show
                );
            }
        )
    );
}
