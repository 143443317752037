import { createSelector } from '@ngrx/store';
import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import {
    ConsultantCarouselState,
    ConsultantCarouselStateAware,
} from '../models/consultant-carousel-state.model';

import { consultantCarouselStateSelector } from './consultant-carousel-state.selector';

export const professionSelector = createSelector<
    ConsultantCarouselStateAware,
    [ConsultantCarouselState],
    ProfessionEnum | undefined
>(
    consultantCarouselStateSelector,
    (state: ConsultantCarouselState) => state.profession
);
