import { Injectable } from '@angular/core';

import { StorageTypeEnum } from '../enums/storage-type.enum';

@Injectable({
    providedIn: 'root',
})
export class StorageCheckService {
    public isStorageSupported(storageType: StorageTypeEnum): Storage | boolean {
        /**
         * Some installations of IE, for an unknown reason, throw "SCRIPT5: Error: Access is denied"
         * when accessing window.localStorage. This happens before you try to do anything with it. Catch
         * that error and allow execution to continue.
         *
         * fix 'SecurityError: DOM Exception 18' exception in Desktop Safari, Mobile Safari
         * when "Block cookies": "Always block" is turned on
         */
        let supported;

        try {
            supported = window[storageType];
        } catch (err) {
            return false;
        }

        /**
         * When Safari (OS X or iOS) is in private browsing mode, it appears as though localStorage and sessionStorage
         * is available, but trying to call .setItem throws an exception below:
         * "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made to add something to storage that exceeded the quota."
         */
        if (supported) {
            const key = '__' + Math.round(Math.random() * 1e7);

            try {
                window[storageType].setItem(key, key);
                window[storageType].removeItem(key);
            } catch (err) {
                return false;
            }
        }

        return supported;
    }
}
