import { createReducer, on } from '@ngrx/store';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import {
    requestResultViaEmailFormCompleteAction,
    requestResultViaEmailFormStateChangeAction,
    resetResultViaEmailFormAction,
    submitResultViaEmailFormAction,
} from '../actions/result-via-email.actions';

import { ResultViaEmailState } from '../models/result-via-email-state.model';

export const initialState: ResultViaEmailState = {
    requestParameters: undefined,
    state: RequestStateEnum.Initial,
    result: undefined,
};

export const resultViaEmailReducers = createReducer(
    initialState,
    on(
        submitResultViaEmailFormAction,
        (
            state: ResultViaEmailState,
            action: ReturnType<typeof submitResultViaEmailFormAction>
        ): ResultViaEmailState => ({
            ...state,
            requestParameters: action.request,
            state: RequestStateEnum.Pending,
        })
    ),
    on(
        requestResultViaEmailFormStateChangeAction,
        (
            state: ResultViaEmailState,
            action: ReturnType<
                typeof requestResultViaEmailFormStateChangeAction
            >
        ): ResultViaEmailState => ({
            ...state,
            state: action.state,
        })
    ),
    on(
        requestResultViaEmailFormCompleteAction,
        (
            state: ResultViaEmailState,
            action: ReturnType<typeof requestResultViaEmailFormCompleteAction>
        ): ResultViaEmailState => ({
            ...state,
            state: action.state,
            result: action.result,
        })
    ),
    on(
        resetResultViaEmailFormAction,
        (): ResultViaEmailState => ({
            ...initialState,
        })
    )
);
