import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { C24NgEventTrackingService } from '@vv-ham/ng-tracking';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { consultantCarouselTrackingConfig } from '../../constants/consultant-carousel-tracking-config.constant';
import { ConsultantCarouselTrackingConfigEnum } from '../../enums/consultant-carousel-tracking-config.enum';

import { toggleAdditionalInfoVisibilityAction } from '../actions/consultant-carousel.actions';
import { shouldShowAdditionalInfoSelector } from '../selectors/should-show-additional-info.selector';

@Injectable()
export class ToggleAdditionalInfoTrackingEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly eventTrackingService: C24NgEventTrackingService
    ) {}

    public toggleAdditionalInfoTracking$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(toggleAdditionalInfoVisibilityAction),
                switchMap(
                    (
                        action: ReturnType<
                            typeof toggleAdditionalInfoVisibilityAction
                        >
                    ) =>
                        this.store$.select(
                            shouldShowAdditionalInfoSelector(action.carouselId)
                        )
                ),
                map((shouldShowAdditionalInfos) => {
                    const trackingAction = shouldShowAdditionalInfos
                        ? 'open'
                        : 'close';

                    const trackingConfig =
                        consultantCarouselTrackingConfig[
                            ConsultantCarouselTrackingConfigEnum
                                .ConsultantCarouselInfoAction
                        ];
                    trackingConfig.action = trackingAction;

                    this.eventTrackingService.trackEvent({
                        matomo: trackingConfig,
                    });
                })
            );
        },
        { dispatch: false }
    );
}
