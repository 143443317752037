import {
    BenefitPresetEnum,
    DentalDenturesEnum,
    DentalEnum,
    DentalTreatmentEnum,
    HospitalizationAccommodationEnum,
    PdHospitalPayoutAmountEnum,
    PdHospitalPayoutStartEnum,
    ProfessionEnum,
    ProvisionCostsharingLimitEnum,
    TreatmentAttendingDoctorEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { BenefitPresetValuesInterface } from '../benefit-preset-values.interface';

export const benefitPresetFilterValues = (
    preset: BenefitPresetEnum,
    profession?: ProfessionEnum | null
): BenefitPresetValuesInterface => {
    switch (preset) {
        case BenefitPresetEnum.Basic:
            return {
                dental: DentalEnum.Basic,
                dentalTreatment: dentalMap[DentalEnum.Basic].dentalTreatment,
                dentalDentures: dentalMap[DentalEnum.Basic].dentalDentures,
                pdhospitalPayoutAmountValue:
                    PdHospitalPayoutAmountEnum.Onehundret,
                pdhospitalPayoutStart: PdHospitalPayoutStartEnum.Fortythree,
                hospitalizationAccommodation:
                    HospitalizationAccommodationEnum.Multi,
                directMedicalConsultationBenefit: false,
                treatmentAboveMaximumRate: false,
                provisionCostsharingLimit:
                    profession === ProfessionEnum.Freelancer
                        ? ProvisionCostsharingLimitEnum.All
                        : ProvisionCostsharingLimitEnum.Low,
                treatmentAttendingDoctor: TreatmentAttendingDoctorEnum.Empty,
            };
        case BenefitPresetEnum.Comfort:
            return {
                dental: DentalEnum.Comfort,
                dentalTreatment: dentalMap[DentalEnum.Comfort].dentalTreatment,
                dentalDentures: dentalMap[DentalEnum.Comfort].dentalDentures,
                pdhospitalPayoutAmountValue:
                    PdHospitalPayoutAmountEnum.Onehundret,
                pdhospitalPayoutStart: PdHospitalPayoutStartEnum.Fortythree,
                hospitalizationAccommodation:
                    HospitalizationAccommodationEnum.Double,
                hospitalizationAccommodationSingle: false,
                directMedicalConsultationBenefit: false,
                treatmentAboveMaximumRate: false,
                provisionCostsharingLimit:
                    profession === ProfessionEnum.Freelancer
                        ? ProvisionCostsharingLimitEnum.All
                        : ProvisionCostsharingLimitEnum.Low,
                treatmentAttendingDoctor:
                    TreatmentAttendingDoctorEnum.HeadDoctor,
            };
        case BenefitPresetEnum.Premium:
            return {
                dental: DentalEnum.Premium,
                dentalTreatment: dentalMap[DentalEnum.Premium].dentalTreatment,
                dentalDentures: dentalMap[DentalEnum.Premium].dentalDentures,
                hospitalizationAccommodation:
                    HospitalizationAccommodationEnum.Single,
                hospitalizationAccommodationSingle: true,
                pdhospitalPayoutAmountValue:
                    PdHospitalPayoutAmountEnum.Onehundret,
                pdhospitalPayoutStart: PdHospitalPayoutStartEnum.Fortythree,
                directMedicalConsultationBenefit: true,
                treatmentAboveMaximumRate: true,
                provisionCostsharingLimit:
                    profession === ProfessionEnum.Freelancer
                        ? ProvisionCostsharingLimitEnum.All
                        : ProvisionCostsharingLimitEnum.Low,
                treatmentAttendingDoctor:
                    TreatmentAttendingDoctorEnum.HeadDoctor,
            };
        case BenefitPresetEnum.Custom:
            return {} as BenefitPresetValuesInterface;
        default:
            throw new Error('Unsupported benefit preset.');
    }
};

export const dentalMap = {
    [DentalEnum.Basic]: {
        dentalTreatment: DentalTreatmentEnum.Basic,
        dentalDentures: DentalDenturesEnum.Basic,
    },
    [DentalEnum.Premium]: {
        dentalTreatment: DentalTreatmentEnum.Premium,
        dentalDentures: DentalDenturesEnum.Premium,
    },
    [DentalEnum.Comfort]: {
        dentalTreatment: DentalTreatmentEnum.Comfort,
        dentalDentures: DentalDenturesEnum.Comfort,
    },
};
