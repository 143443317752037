import { TabTypeEnum } from '../../../result/enums/tab-type.enum';

export const promotionFeatureKey = 'promotion';
export interface PromotionState {
    tabType?: TabTypeEnum;
    tariffVersionId?: number;
    feedbackRatingFilter?: number;
}

export interface PromotionStateAware {
    [promotionFeatureKey]: PromotionState;
}
