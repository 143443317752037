import { createFeatureSelector } from '@ngrx/store';
import {
    ConsultantCallbackOverlayState,
    consultantCallbackOverlayStoreFeatureKey,
} from '../models/consultant-callback-overlay.model';

export const consultantOverlayStateSelector =
    createFeatureSelector<ConsultantCallbackOverlayState>(
        consultantCallbackOverlayStoreFeatureKey
    );
