export enum BulletPointTypeEnum {
    Generic = 'generic',
    AmedNonMedPractitionerReimbursement = 'amedNonMedPractitionerReimbursement',
    ContributionStability = 'contributionStability',
    CostSharing = 'costSharing',
    Cure = 'cure',
    Dental = 'dental',
    MedicalSpecialistTreatment = 'medicalSpecialistTreatment',
    ProvisionContributionReimbursement = 'provisionContributionReimbursement',
    SharedRoom = 'sharedRoom',
    TreatmentAboveMaximumRate = 'treatmentAboveMaximumRate',
}
