import { Pipe, PipeTransform } from '@angular/core';
import { ComparisonTableDataRowTypeEnum } from '@pkv-frontend/data-domain/comparison';
import { ComparisonTableDataRowInterface } from '../models/comparison-table-data.interface';

@Pipe({
    name: 'filterOutCollapsibleComparisonRows',
    standalone: true,
})
export class FilterOutCollapsibleComparisonRowsPipe implements PipeTransform {
    public transform(
        rows: ComparisonTableDataRowInterface[],
        filterOutCollapsible: boolean
    ): ComparisonTableDataRowInterface[] {
        if (!filterOutCollapsible) {
            return rows;
        }

        rows = rows.filter((row: ComparisonTableDataRowInterface) => {
            return (
                row.type !== ComparisonTableDataRowTypeEnum.Header &&
                !row.isCollapsible
            );
        });

        return rows;
    }
}
