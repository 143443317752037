import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
    contributionCarrierTranslationConstants,
    professionTranslationConstants,
} from '@pkv-frontend/business-domain/calculation-parameter';
import {
    InsuredPersonEnum,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { ResultCalculationParameter } from '@pkv-frontend/data-domain/result';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';

import { ResultDataService } from './result-data.service';

@Injectable({ providedIn: 'root' })
export class UserInfoService {
    public readonly parameter$: Observable<
        ResultCalculationParameter | undefined
    > = this.resultDataService.parameter$;
    constructor(private readonly resultDataService: ResultDataService) {}

    public readonly userInfo$: Observable<string> = this.parameter$.pipe(
        filter(
            (parameter: ResultCalculationParameter | undefined): boolean =>
                !!parameter
        ),
        map((parameter: ResultCalculationParameter): string => {
            const headerString: string[] = [];
            if (parameter.insuredPerson === InsuredPersonEnum.Adult) {
                headerString.push(
                    professionTranslationConstants[parameter.profession]
                );
                this.addServantOrServantCandidateContribution(
                    headerString,
                    parameter
                );
                headerString.push(`${parameter.age} Jahre`);
            } else {
                headerString.push('Kind');
                this.addServantOrServantCandidateContribution(
                    headerString,
                    parameter
                );
                headerString.push(
                    `${parameter.childrenAge} ${
                        parameter.childrenAge === 1 ? 'Jahr' : 'Jahre'
                    }`
                );
            }
            return headerString.join(', ');
        })
    );

    private addServantOrServantCandidateContribution(
        headerString: string[],
        parameter: ResultCalculationParameter
    ): void {
        if (
            parameter.profession === ProfessionEnum.Servant ||
            parameter.profession === ProfessionEnum.ServantCandidate ||
            parameter.parentServantOrServantCandidate === YesNoEnum.Yes
        ) {
            headerString.push(
                `${
                    contributionCarrierTranslationConstants[
                        parameter.contributionCarrier
                    ]
                }, ${parameter.contributionRate}%`
            );
        }
    }
}
