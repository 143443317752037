export enum UspChipTypeEnum {
    Top = 'top',
    Tip = 'tip',
    Extra = 'extra',
    Bonus = 'bonus',
    Cashback = 'cashback',
    ProductTest = 'product_test',
    Exclusive = 'exclusive',
    Service = 'service',
    Reimbursement = 'reimbursement',
    Attention = 'attention',
}
