import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getBoApiDomainRule } from '@pkv-frontend/infrastructure/api';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';
import { ActiveConsultantModel } from '../models/active-consultant.model';

@Injectable({
    providedIn: 'root',
})
export class ActiveConsultantDataApiClient {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    public fetchData(): Observable<ActiveConsultantModel> {
        const url = `${this.apiDomain}/api/v1/protected/backoffice/user-management-system/active-consultant-data`;
        return this.httpClient.get<ActiveConsultantModel>(url, {
            withCredentials: true,
        });
    }

    private get apiDomain(): string {
        return getBoApiDomainRule(
            this.windowReferenceService.nativeWindow.location
        );
    }
}
