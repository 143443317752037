import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, shareReplay } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
    SsoUserDataApiService,
    SsoUserDataModel,
} from '@pkv-frontend/data-domain/sso-user-data';
import { fetchSsoUserDataAction } from '../store/actions/sso-user-data.actions';
import { ssoUserDataSelector } from '../store/selectors/sso-user-data.selector';

@Injectable({
    providedIn: 'root',
})
export class SsoUserDataService {
    constructor(
        private readonly store$: Store,
        private readonly apiService: SsoUserDataApiService
    ) {}

    public readonly deprecatedSsoUserData$: Observable<SsoUserDataModel | null> =
        this.apiService.fetch().pipe(
            map((ssoUserDataApiModel) =>
                this.apiService.mapToSsoUserDataModel(ssoUserDataApiModel)
            ),
            catchError(() => of(null)),
            shareReplay({ bufferSize: 1, refCount: false })
        );

    public readonly ssoUserData$: Observable<SsoUserDataModel | null> =
        this.store$.select(ssoUserDataSelector);

    public fetchSsoUserData(): void {
        this.store$.dispatch(fetchSsoUserDataAction());
    }
}
