import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadTransparencyInfoFromCookieEffect } from './effects/load-transparency-info-from-cookie.effect';
import { PersistTransparencyInfoEffect } from './effects/persist-transparency-info.effect';
import { transparencyInfoFeatureKey } from './models/transparency-info-state.model';
import { transparencyInfoReducers } from './reducers/transparency-info.reducers';

@NgModule({
    imports: [
        EffectsModule.forFeature([
            LoadTransparencyInfoFromCookieEffect,
            PersistTransparencyInfoEffect,
        ]),
        StoreModule.forFeature(
            transparencyInfoFeatureKey,
            transparencyInfoReducers
        ),
    ],
})
export class TransparencyInfoStoreModule {}
