import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    BenefitPresetEnum,
    HospitalizationAccommodationEnum,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { FILTER_CHIP_CONFIG_TOKEN } from '../constants/filter-chip-config.constant';
import { FilterChipConfigModel } from '../models/filter-chip-config.model';

@Injectable({
    providedIn: 'root',
})
export class FilterChipsService {
    constructor(
        private readonly activatedRoute: ActivatedRoute,
        @Inject(FILTER_CHIP_CONFIG_TOKEN)
        private readonly filterChipConfig: FilterChipConfigModel<
            keyof ResultFilter
        >[]
    ) {}

    public getUpdatedFilterChips(
        newResultFilters: ResultFilter,
        currentResultFilters: ResultFilter,
        profession: ProfessionEnum | undefined
    ): string | undefined {
        if (newResultFilters.benefitPreset !== BenefitPresetEnum.Custom) {
            return;
        }
        const filterChipParams =
            this.activatedRoute.snapshot.queryParams.filterChips?.split(',');
        let filterChips: string[] = filterChipParams || [];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.filterChipConfig.forEach((config: FilterChipConfigModel<any>) => {
            if (
                newResultFilters[config.key] !==
                currentResultFilters[config.key]
            ) {
                const exists = filterChips.some((chip) => chip === config.key);
                const exception: boolean =
                    (profession === ProfessionEnum.Servant ||
                        ProfessionEnum.ServantCandidate ||
                        ProfessionEnum.ServantChild) &&
                    newResultFilters['hospitalizationAccommodation'] ===
                        HospitalizationAccommodationEnum.Double &&
                    config.key === 'hospitalizationAccommodation';

                if (
                    newResultFilters[config.key] == null ||
                    newResultFilters[config.key] === config.hiddenValue ||
                    (Array.isArray(newResultFilters[config.key]) &&
                        !newResultFilters[config.key].length) ||
                    exception
                ) {
                    // New value shouldn't be displayed in filter chips...
                    if (exists) {
                        // ...if the key already exists we have to delete it
                        filterChips = filterChips.filter(
                            (chip) => chip !== config.key
                        );
                    }
                    return;
                }

                if (exists) {
                    return;
                }

                // ...otherwise we just add a new entry
                filterChips = [...filterChips, config.key];
            }
        });

        return filterChips.length ? filterChips.join(',') : undefined;
    }

    public getMatchingFilterChip(
        configKey
    ): FilterChipConfigModel<keyof ResultFilter> | undefined {
        return this.filterChipConfig.find(
            (config: FilterChipConfigModel<keyof ResultFilter>) =>
                config.key === configKey
        );
    }
}
