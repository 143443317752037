import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { C24AppService } from '@pkv-frontend/data-domain/c24-app';
import {
    LegacyCalculationParameter,
    QueryCalculationParameterParser,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { CustomUriEncoderService } from '@pkv-frontend/infrastructure/http-param-converter';

import { ConsultantCallbackApiRequestModel } from '../models/consultant-callback-api-request.model';
import { ExpertLeadApiRequest } from '../models/expert-lead-api-request.model';
import { LeadCreationApiResponseModel } from '../models/lead-creation-api-response.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }),
};

@Injectable({
    providedIn: 'root',
})
export class LeadCreationApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly uriEncoder: CustomUriEncoderService,
        private readonly queryCalculationParameterParser: QueryCalculationParameterParser,
        private readonly c24AppService: C24AppService
    ) {}

    public create(
        data: ExpertLeadApiRequest | ConsultantCallbackApiRequestModel,
        calculationParameter: LegacyCalculationParameter
    ): Observable<LeadCreationApiResponseModel> {
        const calculationUrlParameter: URLSearchParams =
            this.queryCalculationParameterParser.parse(calculationParameter);
        return this.httpClient
            .post<LeadCreationApiResponseModel>(
                this.buildUrl(calculationUrlParameter),
                this.buildHttpParams(data),
                httpOptions
            )
            .pipe(
                tap((response) => {
                    this.c24AppService.trackConversionFunnel(response.leadId);
                })
            );
    }

    private buildUrl(calculationParameter: URLSearchParams): string {
        return '/experte/abschluss/?' + calculationParameter.toString();
    }

    private buildHttpParams(
        data: ExpertLeadApiRequest | ConsultantCallbackApiRequestModel
    ): HttpParams {
        return new HttpParams({
            fromObject: data as unknown as { [param: string]: string },
            encoder: this.uriEncoder,
        });
    }
}
