import { Injectable } from '@angular/core';

import { StorageTypeEnum } from '../enums/storage-type.enum';

import { StorageCheckService } from './storage-check.service';

class PersistenceService {
    constructor(
        protected readonly storageCheckService: StorageCheckService,
        protected readonly storageType: StorageTypeEnum
    ) {}

    public set<T = unknown>(key: string, value: T): void {
        if (
            this.storageCheckService.isStorageSupported(this.storageType) ===
            false
        ) {
            return;
        }

        window[this.storageType].setItem(key, this.serialize<T>(value));
    }

    public get<T = unknown>(key: string): T | undefined {
        if (
            this.storageCheckService.isStorageSupported(this.storageType) ===
            false
        ) {
            return undefined;
        }

        const storageData = window[this.storageType].getItem(key);

        if (storageData === null) {
            return undefined;
        }

        return this.parse(window[this.storageType].getItem(key) ?? '{}');
    }

    public remove(key: string): void {
        if (
            this.storageCheckService.isStorageSupported(this.storageType) ===
            false
        ) {
            return;
        }

        return window[this.storageType].removeItem(key);
    }

    protected parse<T = unknown>(storage: string): T | undefined {
        try {
            return JSON.parse(storage);
        } catch (exception) {
            return undefined;
        }
    }

    private serialize<T>(value: T): string {
        return JSON.stringify(value);
    }
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService extends PersistenceService {
    constructor(protected storageCheckService: StorageCheckService) {
        super(storageCheckService, StorageTypeEnum.Local);
    }
}

@Injectable({ providedIn: 'root' })
export class SessionStorageService extends PersistenceService {
    constructor(protected storageCheckService: StorageCheckService) {
        super(storageCheckService, StorageTypeEnum.Session);
    }
}
