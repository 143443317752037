import { RadioListRowVariantEnum } from '../enums/radio-list-row-variant.enum';
import { FilterRadioListConfig } from '../models/filter-radio-list.config.model';

export const dentalServiceLevelSelectionConfig: FilterRadioListConfig = {
    radioListVariant: RadioListRowVariantEnum.Single,
    options: [
        {
            label: '85% und besser',
            value: 'premium',
        },
        {
            label: '75% und besser',
            value: 'comfort',
        },
        {
            label: 'egal',
            value: 'basic',
        },
    ],
};
