import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FeatureToggleEnum } from '@pkv-frontend/infrastructure/feature-toggle';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';
import { DeviceOutputEnum } from '../enums/device-output.enum';
import { PhpFrontendData } from '../models/php-frontend-data.model';

@Injectable({
    providedIn: 'root',
})
export class PhpFrontendDataService {
    public data: PhpFrontendData;

    constructor(
        private readonly windowReferenceService: WindowReferenceService,
        private readonly cookieService: CookieService
    ) {}

    public initialize(): void {
        if (
            this.windowReferenceService.nativeWindow.phpFrontendData ===
            undefined
        ) {
            return;
        }

        let data = this.windowReferenceService.nativeWindow.phpFrontendData;

        // birthdate is returned as string -> parse
        // to be compatible with calculationParameter interface
        if (typeof data.calculationParameter?.birthdate === 'string') {
            data = JSON.parse(JSON.stringify(data));
            data.calculationParameter.birthdate = new Date(
                data.calculationParameter.birthdate
            );
        }

        this.data = data;

        const activeFeatureToggles = this.cookieService.get(
            'active-feature-toggles'
        );

        if (
            activeFeatureToggles.includes(FeatureToggleEnum.KillPhpFrontendData)
        ) {
            this.data.deviceOutput = <DeviceOutputEnum>(
                this.cookieService.get('deviceoutput')
            );
        }
    }
}
