import * as SHA1 from 'crypto-js/sha1';
import {
    CalculationParameterInterface,
    InsuredPersonEnum,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

function activityScoreForeignIdGeneratorRuleImplementation(
    calculationParameter: CalculationParameterInterface
): string {
    let profession = calculationParameter.profession;
    let ageInformation: Date | number | undefined =
        calculationParameter.birthdate;

    if (calculationParameter.insuredPerson === InsuredPersonEnum.Child) {
        profession = calculationParameter.parentServantOrServantCandidate
            ? ProfessionEnum.ServantChild
            : ProfessionEnum.Child;
        ageInformation = calculationParameter.childrenAge;
    }
    return SHA1(
        [profession.toString(), ageInformation?.toString()].join()
    ).toString();
}

export function activityScoreForeignIdGeneratorRule(
    calculationParameter: CalculationParameterInterface
): string {
    return activityScoreForeignIdGeneratorRuleImplementation(
        calculationParameter
    );
}
