import { Inject, Injectable } from '@angular/core';
import {
    Environment,
    getEnvironmentRule,
} from '@pkv-frontend/data-domain/application';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';
import { ChatFlowEntryEnum } from '../enums/chat-flow-entry.enum';
import { ChattyConfig } from '../models/chatty/chatty-config.interface';
import { CHATTY_VERSION } from '../tokens/chatty-version.token';
import { C24AppService } from './c24-app.service';
import {
    ChattyConfigBuilderService,
    ChattyEnvironment,
} from './chatty-config-builder.service';
import { Check24AppService } from './check24-app.service';

@Injectable({ providedIn: 'root' })
export class ChattyService {
    constructor(
        private readonly c24AppService: C24AppService,
        private readonly chattyConfigBuilderService: ChattyConfigBuilderService,
        @Inject(CHATTY_VERSION) private readonly chattyVersion: string,
        private readonly check24AppService: Check24AppService,
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    public initialize(
        chatFlowEntry = ChatFlowEntryEnum.StartWireframeAll,
        leadId?: number
    ): ChattyConfig {
        const environment = getEnvironmentRule();
        const config = this.chattyConfigBuilderService.build(
            this.chattyVersion,
            this.getCorrespondingChattyEnvironmentFromCurrentEnvironment(
                environment
            ),
            chatFlowEntry,
            leadId
        );

        this.check24AppService.setChattyConfig(config, chatFlowEntry);

        this.c24AppService.c24App.then((c24App) => {
            c24App?.setSupportDialogHandler(() => {
                this.openChatty(config);
                return true;
            });
        });

        return config;
    }

    public sendMessageFromChattyTile(
        chattyConfig: ChattyConfig,
        userInput: string,
        isCustomText: boolean
    ): void {
        const randomNumber = window.crypto.getRandomValues(
            new Uint32Array(1)
        )[0];

        chattyConfig.name = `chatty-${randomNumber}`;
        chattyConfig.chatbot.client.clientDetails = {
            ...chattyConfig.chatbot.client.clientDetails,
            ...{
                userInput: userInput,
                startFromTile: !isCustomText,
                connectionInfoDetails: {
                    details: {
                        startFromBar: isCustomText,
                    },
                },
            },
        };
        chattyConfig.chatbot.forceNewSession = true;
        chattyConfig.chatbot.client.chatFlowEntry =
            ChatFlowEntryEnum.StartThirdViewTile;

        this.check24AppService.setChattyConfig(
            chattyConfig,
            ChatFlowEntryEnum.StartThirdViewTile
        );

        this.openChatty(chattyConfig);
        return;
    }

    private openChatty(config: ChattyConfig): void {
        this.windowReferenceService.nativeWindow.Check24?.loader.openChatbot(
            config
        );
    }

    private getCorrespondingChattyEnvironmentFromCurrentEnvironment(
        environment: Environment
    ): ChattyEnvironment {
        if ([Environment.Test, Environment.Prod].includes(environment)) {
            return environment as ChattyEnvironment;
        }
        return Environment.Test;
    }
}
