import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

import { QueryParams, QueryValue } from '../models/query-params.interface';

@Injectable({
    providedIn: 'root',
})
export class LocationDataService {
    private readonly _queryParams$: BehaviorSubject<QueryParams | null> =
        new BehaviorSubject<QueryParams | null>(null);

    public readonly queryParams$: Observable<QueryParams | null> =
        this._queryParams$.asObservable();

    constructor(private windowReferenceService: WindowReferenceService) {
        this.loadQueryParams();
    }

    public loadQueryParams(): void {
        this._queryParams$.next(this.getQueryParams());
    }

    public getQueryParams(): QueryParams {
        const query =
            this.windowReferenceService.nativeWindow.location.search.replace(
                /^\?/,
                ''
            );
        if (!query) {
            return {};
        }
        const paramsArray = query.split('&');
        const params = {};
        for (const param of paramsArray) {
            const [key, value] = param.split('=', 2);
            const decodedKey = decodeURIComponent(key);
            const decodedValue = decodeURIComponent(value);

            // providers can be either an array, or a comma separated string (including '0' for no provider)
            if (decodedKey === 'provider') {
                params['provider'] = params['provider'] || [];
                params['provider'].push(
                    ...decodedValue
                        .split(',')
                        .filter((provider) => provider !== '0')
                );
                continue;
            }
            if (decodedKey === 'provider[]') {
                params['provider'] = params['provider'] || [];
                params['provider'].push(decodedValue);
                continue;
            }
            params[key] = decodeURIComponent(decodedValue);
        }

        return params;
    }

    public getQueryParamValue(name: string): QueryValue | undefined {
        const queryParamValue = this._queryParams$.getValue();

        if (queryParamValue == null) {
            return undefined;
        }

        return queryParamValue[name];
    }
}
