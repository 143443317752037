import { createSelector } from '@ngrx/store';
import { LowestPrice } from '@pkv-frontend/data-domain/result';
import { FilterState, FilterStateAware } from '../models/filter-state.model';

import { filterStateFeatureSelector } from './filter-state-feature.selector';

export const resultFilterLowestCustomPriceSelector = createSelector<
    FilterStateAware,
    [FilterState],
    LowestPrice | undefined
>(filterStateFeatureSelector, (state: FilterState) => state.lowestCustomPrice);
