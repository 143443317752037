import { Injectable } from '@angular/core';

import {
    Actions,
    createEffect,
    ofType,
    ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { FirstInformationCookieService } from '@pkv-frontend/business-domain/first-information';
import {
    LocationDataService,
    QueryParams,
} from '@pkv-frontend/data-domain/location';

const queryParamName = 'skipFirstInfo';

@Injectable()
export class SetFirstInfoCookieEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly locationDataService: LocationDataService,
        private readonly firstInformationCookieService: FirstInformationCookieService
    ) {}

    public readonly setFirstInfoCookieEffect$: Observable<QueryParams> =
        createEffect(
            () =>
                this.actions$.pipe(
                    ofType(ROOT_EFFECTS_INIT),
                    switchMap(() =>
                        this.locationDataService.queryParams$.pipe(
                            filter(
                                (queryParams): queryParams is QueryParams =>
                                    !!queryParams
                            ),
                            first(),
                            tap((queryParams) => {
                                if (queryParams?.[queryParamName] === '1') {
                                    this.firstInformationCookieService.approve();
                                }
                            })
                        )
                    )
                ),
            { dispatch: false }
        );
}
