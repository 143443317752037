import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ConsultantDataService } from './consultant-data.service';

export const tariffChangeUrlParameter = 'tariff_change';
export const providerIdUrlParameter = 'provider_id';

@Injectable({
    providedIn: 'root',
})
export class IsTariffChangeService {
    public hasTariffChangeUrlParameter$: Observable<boolean> =
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            first(),
            map(
                () =>
                    this.route.snapshot.queryParams[
                        tariffChangeUrlParameter
                    ] === '1'
            )
        );

    public readonly isTariffChange$: Observable<boolean> =
        this.hasTariffChangeUrlParameter$.pipe(
            map(
                (hasTariffChangeParameter: boolean) =>
                    hasTariffChangeParameter &&
                    !!this.consultantDataService.getConsultantData()
            )
        );

    public readonly initialProviderId$: Observable<number> =
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            first(),
            map(() => this.route.snapshot.queryParams[providerIdUrlParameter]),
            map(Number)
        );

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly consultantDataService: ConsultantDataService
    ) {}
}
