import { ProfessionEnum } from '@pkv-frontend/data-domain/calculation-parameter';
import { Consultant } from '@pkv-frontend/data-domain/result';
import { RequestStateEnum } from '@pkv-frontend/infrastructure/api';
import { CarouselControls } from '../../models/carousel-controls.model';

export const consultantCarouselFeatureKey = 'consultant-carousel';

export interface ConsultantCarouselState {
    consultants: Consultant[] | undefined;
    controls: CarouselControls;
    profession: ProfessionEnum | undefined;
    shouldShowAdditionalInfo: { [key: number]: boolean };
    requestState: RequestStateEnum;
    callbackRequestState: RequestStateEnum;
    isCallbackAvailable: boolean | undefined;
}

export interface ConsultantCarouselStateAware {
    [consultantCarouselFeatureKey]: ConsultantCarouselState;
}
