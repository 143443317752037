import { ExperimentNamesEnum } from '../enums/experiment-names.enum';
import { ExperimentVariantsEnum } from '../enums/experiment-variants.enum';
import {
    ExperimentActivationEvent,
    ExperimentConfig,
} from '../model/experiment-config.model';

export const ExperimentVariants: Partial<{
    [name in ExperimentNamesEnum]: {
        [groupName: string]: ExperimentVariantsEnum;
    };
}> = {};

export const experimentsConfig: ExperimentConfig[] = [];

function setCookie(event: ExperimentActivationEvent): void {
    document.cookie = `ab_test_value=${
        event.experiment.name
    }:${event.experiment.getActivatedVariationName()};path=/;secure`;
}
