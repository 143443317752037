import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { hideFiltersAppliedMessageDelay } from '../../../result/constants/result.constants';
import {
    hideFiltersAppliedMessageAction,
    showFiltersAppliedMessageAction,
} from '../actions/filter.actions';

@Injectable()
export class HideFiltersAppliedMessageEffect {
    constructor(private actions$: Actions) {}

    public hideFiltersAppliedMessageEffect$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(showFiltersAppliedMessageAction),
                debounceTime(hideFiltersAppliedMessageDelay),
                map(() => hideFiltersAppliedMessageAction())
            );
        }
    );
}
