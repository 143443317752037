import { createSelector } from '@ngrx/store';
import { ConsultantOverlayContentTypeEnum } from '../../enums/consultant-overlay-content-type.enum';
import {
    ConsultantCallbackOverlayState,
    OverlayStateAware,
} from '../models/consultant-callback-overlay.model';

import { consultantOverlayStateSelector } from './consultant-overlay-state.selector';

export const contentTypeSelector = createSelector<
    OverlayStateAware,
    [ConsultantCallbackOverlayState],
    ConsultantOverlayContentTypeEnum | undefined
>(
    consultantOverlayStateSelector,
    (
        state: ConsultantCallbackOverlayState
    ): ConsultantOverlayContentTypeEnum | undefined => state.contentType
);
