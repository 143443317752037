import { createAction, props } from '@ngrx/store';
import { CalculationParameterInterface } from '@pkv-frontend/data-domain/calculation-parameter';
import {
    LowestPrice,
    ResultFilterProviderModel,
    SortOrderEnum,
    PositionParametersModel,
    SortApiParametersModel,
    SortFieldEnum,
} from '@pkv-frontend/data-domain/result';

import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';
import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';

export enum FilterActionNamesEnum {
    UpdateFiltersFromUrl = '[Result filter] Update filters from URL',
    SetCustomPresetLowestPrices = '[Result filter] Set custom preset lowest prices',
    SetCustomFilterSettings = '[Result filter] Set custom filter settings',
    ShowFiltersAppliedMessage = '[Result filter] Show filters applied message',
    HideFiltersAppliedMessage = '[Result filter] Hide filters applied message',
    FilterChange = '[Result filter] Filter change',
    EffectivePriceToggleChange = '[Result filter] Effective price toggle change',
    CureAndRehabChange = '[Result filter] Cure and Rehab change',
    DebugToggleChange = '[Result filter] Debug toggle change',
    SortChange = '[Result filter] Sort change',
    GkvCrossSellingChange = '[Result filter] GKV cross-selling change',
    ResetFilterChips = '[Result filter] Reset filter chips',
    ResetConsultantFilterChange = '[Result filter] Reset consultant filter change',
    SetFilterProviders = '[Result filter] Set filter providers',
    LoadResultSilently = '[Result filter] Load Result Silent',
    ClearSilentResult = '[Result filter] Clear Silent Result',
}

export const updateFiltersFromUrlAction = createAction(
    FilterActionNamesEnum.UpdateFiltersFromUrl,
    props<{
        calculationParameters: CalculationParameterInterface;
        sortApiParameters: SortApiParametersModel;
        positionParameters?: PositionParametersModel;
        resultFilters: ResultFilter;
        crossSellingOverlay: YesNoEnum;
        filterChips: string[];
    }>()
);

export const setCustomPresetLowestPricesAction = createAction(
    FilterActionNamesEnum.SetCustomPresetLowestPrices,
    props<{
        lowestPrice: LowestPrice | undefined;
    }>()
);

export const setCustomFilterSettingsAction = createAction(
    FilterActionNamesEnum.SetCustomFilterSettings,
    props<{
        resultFilters: ResultFilter;
        lowestPrice?: LowestPrice;
    }>()
);

export const showFiltersAppliedMessageAction = createAction(
    FilterActionNamesEnum.ShowFiltersAppliedMessage
);

export const hideFiltersAppliedMessageAction = createAction(
    FilterActionNamesEnum.HideFiltersAppliedMessage
);

export const filterChangeAction = createAction(
    FilterActionNamesEnum.FilterChange,
    props<{
        resultFilters: ResultFilter;
        consultantFilterChange?: boolean;
        loadResultSilently?: boolean;
    }>()
);

export const resetConsultantFilterChangeAction = createAction(
    FilterActionNamesEnum.ResetConsultantFilterChange,
    props<{
        resetFiltersToCommittedFilters: boolean;
    }>()
);

export const effectivePriceToggleChangeAction = createAction(
    FilterActionNamesEnum.EffectivePriceToggleChange,
    props<{
        enabled: boolean;
    }>()
);

export const cureAndRehabChangeAction = createAction(
    FilterActionNamesEnum.CureAndRehabChange,
    props<{ enabled: boolean }>()
);

export const debugToggleChangeAction = createAction(
    FilterActionNamesEnum.DebugToggleChange,
    props<{
        enabled: boolean;
    }>()
);

export const sortChangeAction = createAction(
    FilterActionNamesEnum.SortChange,
    props<{
        sortfield: SortFieldEnum;
        sortorder?: SortOrderEnum;
    }>()
);

export const gkvCrossSellingChangeAction = createAction(
    FilterActionNamesEnum.GkvCrossSellingChange,
    props<{
        birthdate: string;
    }>()
);

export const resetFilterChipsAction = createAction(
    FilterActionNamesEnum.ResetFilterChips,
    props<{
        filterChips: string[];
    }>()
);

export const setFilterProvidersAction = createAction(
    FilterActionNamesEnum.SetFilterProviders,
    props<{
        providers: ResultFilterProviderModel[];
    }>()
);

export const setLoadResultSilently = createAction(
    FilterActionNamesEnum.LoadResultSilently,
    props<{
        loadResultSilently: boolean;
    }>()
);

export const clearSilentResult = createAction(
    FilterActionNamesEnum.ClearSilentResult
);
